<div id="app-layout">
  <div
    id="app-sidebar"
    [ngClass]="{ 'sidenav-closed': !sidebarExpanded, 'sidenav-open': sidebarExpanded }"
    (mouseover)="changeNavOpen(true)"
    (mouseleave)="changeNavOpen(false)"
  >
    <div id="logo-container" class="row justify-content-center">
      <img id="logo" src="/assets/images/logo-un.png" />
    </div>
    <div style="margin-top: 30px" id="nav-area">
      <ng-container *ngFor="let item of userNavigationLinks">
        <ng-container *ngIf="item.enabled">
          <app-menu-item
            [navOpened]="sidebarExpanded"
            [currentParentMenu]="currentParentMenu"
            [showChildrenMenu]="showChildrenMenu"
            [currentChildMenu]="currentChildMenu"
            [menuItem]="item"
            (openChildrenMenu)="openChildrenMenu($event)"
            (changeParentMenu)="changeParentMenu($event)"
            (changeCurrentChildMenu)="changeCurrentChildMenu($event)"
          ></app-menu-item>
        </ng-container>
      </ng-container>
    </div>

    <div class="menu-footer">
      <div class="sidenav-line"></div>
      <ng-container *ngFor="let item of adminRoutes">
        <ng-container *ngIf="item.enabled">
          <app-menu-item
            [navOpened]="sidebarExpanded"
            [currentParentMenu]="currentParentMenu"
            [showChildrenMenu]="showChildrenMenu"
            [currentChildMenu]="currentChildMenu"
            [menuItem]="item"
            (openChildrenMenu)="openChildrenMenu($event)"
            (changeParentMenu)="changeParentMenu($event)"
            (changeCurrentChildMenu)="changeCurrentChildMenu($event)"
          ></app-menu-item>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div id="app-content" [class.expanded]="sidebarExpanded">
    <div id="app-menu">
      <div id="upper-menu">
        <div class="col-3 p-0 search-content">
          <mat-form-field id="searchBar" appearance="outline">
            <img matPrefix src="assets/svgs/delivery-search.svg" width="35px" height="35px" />
            <input type="text" matInput (keyup)="initiateSearch($event.keyCode)" [(ngModel)]="searchValue" />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''; initiateSearch()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-label>Search Shipment #, Purchase Order #{{orgMode !== orgModes.Lite ? ', Booking #' : ''}} ...</mat-label>
          </mat-form-field>
        </div>
        <div class="col-9 p-0 upper-right">
          <app-om-global-network-selector></app-om-global-network-selector>
          <ng-container *ngIf="orgMode !== orgModes.Lite">
            <app-om-admin-org-selector></app-om-admin-org-selector>
          </ng-container>
          <div id="timezoneArea">
            <app-time-zone-selector></app-time-zone-selector>
          </div>
          <div id="userArea">
            <span id="userButton" [matMenuTriggerFor]="menu" #userMenuTrigger="matMenuTrigger">
              <div class="row">
                <div class="userCircle">{{ (userService.user$ | async)?.initials }}</div>
                <div class="pl-2" style="line-height: 60px">
                  {{ (userService.user$ | async)?.firstName }} {{ (userService.user$ | async)?.lastName }}
                </div>
                <div class="my-auto">
                  <div class="icon-wrap" [ngClass]="{ flip: userMenuTrigger.menuOpen }">
                    <mat-icon class="navbar-arrow" svgIcon="down-arrow"></mat-icon>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <mat-menu #menu="matMenu" backdropClass="userMenu">
            <ul class="user-panel-options">
              <li *ngIf="userService.currentOrg" class="text-overflow">
                <button id="userOrg" class="text-overflow" mat-button disabled>
                  {{ userService.currentOrg.name }}
                  <br />
                  {{ userService.currentOrg.type }}
                </button>
              </li>
              <!-- <li>
                <button mat-menu-item routerLink="/app/notifications/notification-settings" class="text-overflow">
                  NOTIFICATION SETTINGS
                </button>
              </li> -->
              <ng-container>
                <li *ngIf="!(authService.isImpersonating$ | async) && userMode === userModes.OmsAdmin">
                  <button mat-menu-item (click)="impersonateUser()">IMPERSONATE USER</button>
                </li>
                <li *ngIf="authService.isImpersonating$ | async">
                  <button mat-menu-item (click)="authService.stopImpersonating()">STOP IMPERSONATING</button>
                </li>
              </ng-container>
              <li>
                <button (click)="authService.logout()" mat-menu-item>LOGOUT</button>
              </li>
            </ul>
          </mat-menu>
        </div>
      </div>
    </div>
    <div id="content">
      <div id="main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
