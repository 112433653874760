import { HttpParams } from '@angular/common/http';
import { Organization } from '@models/organization.model';

export interface FilterDelegate {
  registeredFilters: { [key: string]: BaseFilter };
  registerFilter: (filterKey: string, filter: BaseFilter) => void;
  unregisterFilter: (filterKey: string) => void;
  filterData: (data: any[]) => void;
}

export type PrimitiveArrays = string[] | number[] | Date[];
export type FilterRequestObject = Filter | DateFilterRequest | DateRangeFilterRequest;

export abstract class BaseFilter {
  accessors: string[] | null;
  exactMatch: boolean;
  searchOverride: boolean;
  filterValues: number[]

  constructor(acessors: string[] | null, exactMatch: boolean = true, searchOverride = false) {
    this.accessors = acessors;
    this.exactMatch = !!exactMatch;
    this.searchOverride = !!searchOverride;
  }

  abstract compare(value: any): boolean;
  abstract hasValue(): boolean;

  public stringifyValue(value: any): string {
    const valueAsArray = !Array.isArray(value) ? [value] : value;
    return valueAsArray.map(v => v.toString().toLowerCase().split(' ').join('')).join('');
  }

  public filterData(data: any[]): any[] {
    let dataCopy = data.slice(0);
    if (!this.hasValue()) {
      // Ignore filters and return original array if there is no value to compare.
      return dataCopy;
    } else {
      dataCopy = dataCopy.filter(obj => {
        // Get objects keys to compare
        let keys: string[] | null;
        keys =
          this.accessors === null
            ? Object.keys(obj)
            : !Array.isArray(this.accessors)
            ? [this.accessors]
            : this.accessors;
        const comparableObj = keys.reduce((a, v) => ({ ...a, [v]: obj[v] }), {});
        if (this.searchOverride) return this.simpleSearch(comparableObj);
        return this.recursionSearch(comparableObj, this);
      });
      return dataCopy;
    }
  }

  private simpleSearch(obj: any): boolean {
    let index = Object.values(obj).findIndex(value => this.compare(value));
    return index >= 0;
  }

  private recursionSearch(prop: any, filterRequest: BaseFilter): boolean {
    if (prop == undefined || prop == null) return false;

    const type = Array.isArray(prop) ? 'array' : typeof prop;
    switch (type) {
      case 'array': {
        return prop.find(el => this.recursionSearch(el, filterRequest));
      }
      case 'object': {
        const values = Object.values(prop) as any[];
        let index = values.findIndex(el => this.recursionSearch(el, filterRequest));
        return index >= 0;
      }
      default: {
        const matched = filterRequest.compare(prop);
        return matched;
      }
    }
  }
}

export class Filter extends BaseFilter {
  value: string | null;

  constructor(accessors: string[] | null = null, values: PrimitiveArrays | null = null, exactMatch?: boolean) {
    super(accessors, !!exactMatch);
    this.value = super.stringifyValue(values);
  }

  public compare(value: any): boolean {
    const stringValue = super.stringifyValue(value);

    if (this.exactMatch) {
      return this.value.indexOf(stringValue) > -1;
    } else {
      return stringValue.includes(this.value);
    }
  }

  public hasValue(): boolean {
    return this.value !== null && this.value !== undefined && this.value !== '';
  }
}

export class NumberFilter extends BaseFilter {
  public filterValues: number[];

  constructor(accessors: string[] | null = null, values: number[]) {
    super(accessors);
    this.filterValues = values;
  }

  public compare(value: number): boolean {
    return this.filterValues.includes(value);
  }

  public hasValue(): boolean {
    return !!this.filterValues && this.filterValues.length > 0;
  }
}

export class DateFilterRequest extends BaseFilter {
  public date: Date;
  public operator: 'eq' | 'lt' | 'gt' | 'gte' | 'lte';

  constructor(accessors: string[], filterDate: Date, operator: 'eq' | 'lt' | 'gt' | 'gte' | 'lte' = 'eq') {
    super(accessors);
    this.date = filterDate;
    this.operator = operator;
  }

  public compare(value: Date): boolean {
    const filterTime = this.date.getTime();
    const time = value.getTime();

    switch (this.operator) {
      case 'eq': {
        return time === filterTime;
      }
      case 'lt': {
        return time < filterTime;
      }
      case 'gt': {
        return time > filterTime;
      }
      case 'gte': {
        return time >= filterTime;
      }
      case 'lte': {
        return time <= filterTime;
      }
    }
  }

  public hasValue(): boolean {
    return !!this.date;
  }
}

export class DateRangeFilterRequest extends BaseFilter {
  public startDate: Date;
  public endDate: Date;

  constructor(accessors: string[], startDate: Date, endDate: Date) {
    super(accessors);
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public compare(value: any): boolean {
    const time = Date.parse(value);
    return time >= this.startDate.getTime() && time <= this.endDate.getTime();
  }

  public hasValue(): boolean {
    return !!this.startDate && !!this.endDate;
  }
}

export class OrganizationFilter extends BaseFilter {
  public organization: Organization;

  constructor(accessors: string[], organization: Organization) {
    super(accessors, true, true);
    this.organization = organization;
  }

  compare(value: Organization): boolean {
    return value.code == this.organization.code;
  }

  hasValue(): boolean {
    return !!this.organization;
  }
}

export class ListFilter {
  public pageNumber: number;
  public pageSize: number;
  public sortField: string;
  public sortByDescending: boolean;
  public keyWord: string;
  public filter: object;
  public columnConfigs: any;

  constructor(init?: Partial<ListFilter>) {
    this.pageNumber = init.pageNumber ? init.pageNumber : 1;
    this.pageSize = init.pageSize ? init.pageSize : 10;
    this.sortField = init.sortField ? init.sortField : '';
    this.sortByDescending = init.sortByDescending ? init.sortByDescending : false;
    this.keyWord = init.keyWord ? init.keyWord : '';
    this.filter = init.filter ? init.filter : {};
  }

  getHttpParams(): HttpParams {
    const data = new HttpParams({
      fromObject: {
        PageNumber: this.pageNumber,
        PageSize: this.pageNumber,
        SortField: this.sortField,
        SortByDescending: this.sortByDescending,
        KeyWord: this.keyWord,
      },
    });

    return data;
  }
}
