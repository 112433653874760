import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-info-icon',
  templateUrl: './error-info-icon.component.html',
  styleUrls: ['./error-info-icon.component.scss'],
})
export class ErrorInfoIconComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}
