<div class="row my-4">
  <div class="col-8 justify-content-left section-header">Enabled Columns</div>
  <div class="col-4 justify-content-right">
    <div class="filter-override">Restore Defaults&nbsp;<mat-icon (click)="resetColumns()">restart_alt</mat-icon></div>
    Enable/Disable All&nbsp;<mat-slide-toggle [checked]="masterToggle" (change)="toggleAllColumns($event.checked)"> </mat-slide-toggle>
  </div>
</div>
<div cdkDropList (cdkDropListDropped)="drop($event)">
  <span *ngFor="let column of preferencesService.getColumnPreferences(true); let i = index">
    <div class="row mb-3">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row dragDrop" cdkDrag>
          <div class="col-1 dragDrop">
            <mat-icon class="drag-indicator">drag_indicator</mat-icon>
          </div>
          <div class="col-11 dragDrop enabled">
            <mat-card class="d-flex h-100 align-items-center justify-content-between px-2 py-1 mat-elevation-z2">
              <span>{{ column.name }}</span>
              <mat-slide-toggle [checked]="true" (change)="toggleColumn(column, i)"></mat-slide-toggle>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </span>
</div>
<div class="row mt-4 row-border"></div>
<div class="row justify-content-center mt-2 mb-2 section-header">Disabled Columns</div>
<span *ngFor="let column of preferencesService.getColumnPreferences(false)">
  <div class="row mb-3">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="row">
        <div class="col-1"></div>

        <div class="col-11 disabled">
          <mat-card class="px-2 py-1 mat-elevation-z2">
            <span class="disabled-column-name">{{ column.name }}</span>
            <span class="float-right">
              <mat-slide-toggle [checked]="false" (change)="toggleColumn(column, null)"></mat-slide-toggle>
            </span>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
</span>
<div class="row m-4"></div>
