import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatButtonToggleChange} from "@angular/material/button-toggle";

@Component({
  selector: 'app-om-table-static-filter-box',
  styles: [''],
  template: `
  <mat-button-toggle-group
    name="staticFilter"
    aria-label="Static Filter"
    (change)="toggleFilter($event)">

    <mat-button-toggle *ngFor="let item of filterConfig" [value]="item" [checked]="(selectedFilter && item.value === selectedFilter.value)">{{ item.name }}</mat-button-toggle>
  </mat-button-toggle-group>
  `
})
export class OmTableStaticFilterComponent {
  @Input() filterConfig: any[] = [];
  @Input() selectedFilter: any = null;
  @Output() filterSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public toggleFilter(event: MatButtonToggleChange): void {
    this.filterSelected.emit(event.value);
  }
}
