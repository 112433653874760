import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/modules/auth/services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImpersonateUserComponent } from 'src/app/modules/auth/components/impersonate-user/impersonate-user.component';
import { AuthService } from '@modules/auth/services/auth.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { PageOrgMode, UserMode } from '@models/helpers';
import { EventIconMap } from '@models/event.model';
import { MenuItem } from '@models/menu.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SearchService } from '@modules/search/services/search.service';

export interface INavLink {
  url: string;
  name: string;
  svgIcon: string;
  userMode: number;
}

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('fadeInOutTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppLayoutComponent implements OnInit {
  userMode: UserMode;
  orgMode: PageOrgMode;
  public userModes = UserMode;
  public orgModes = PageOrgMode;
  public sidebarExpanded = false;
  public currentParentMenu = 'Dashboard';
  public showChildrenMenu = 'Dashboard';
  public currentChildMenu = '';
  public initialRoute = true;
  public initialRouteAddress = '';

  private eventIconMap = EventIconMap;

  public searchValue = '';

  public omsLiteNavLinks: MenuItem[] = [
    {
      routeAddress: 'app/dashboard/home',
      labelName: 'Dashboard',
      imageName: 'shapes-and-symbols',
      userMode: UserMode.User,
      showArrow: false,
      enabled: true,
    },
    {
      routeAddress: '',
      labelName: 'Order Management',
      imageName: 'clipboard',
      userMode: UserMode.User,
      showArrow: true,
      enabled: true,
      childrenMenu: [
        {
          routeAddress: 'app/purchase-orders',
          labelName: 'Purchase Orders',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/purchase-orders-assignment',
          labelName: 'PO Assignment',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
      ],
    },
    {
      routeAddress: '',
      labelName: 'Admin Tools',
      imageName: 'configuration',
      userMode: UserMode.Clearfreight,
      showArrow: true,
      enabled: true,
      childrenMenu: [
        {
          routeAddress: 'app/products',
          labelName: 'Products',
          userMode: UserMode.Clearfreight,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/customer-networks',
          labelName: 'Networks',
          userMode: UserMode.OmsAdmin,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/business-rules',
          labelName: 'Business Rules',
          userMode: UserMode.Clearfreight,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/users/user-management',
          labelName: 'Users',
          userMode: UserMode.OmsAdmin,
          externalLink: false,
          enabled: true,
        },
      ],
    },
  ];

  public omsNavLinks: MenuItem[] = [
    {
      routeAddress: 'app/dashboard/home',
      labelName: 'Dashboard',
      imageName: 'shapes-and-symbols',
      userMode: UserMode.User,
      showArrow: false,
      enabled: true,
    },
    {
      routeAddress: '',
      labelName: 'Order Management',
      imageName: 'clipboard',
      userMode: UserMode.User,
      showArrow: true,
      enabled: true,
      childrenMenu: [
        {
          routeAddress: 'app/purchase-orders',
          labelName: 'Purchase Orders',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/bookings',
          labelName: 'Bookings',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/shipments/sea-shipments',
          labelName: 'Sea Shipments',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/shipments/air-shipments',
          labelName: 'Air Shipments',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/shipments/roa-shipments',
          labelName: 'Road Shipments',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/shipments/rai-shipments',
          labelName: 'Rail Shipments',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/containers',
          labelName: 'Containers',
          userMode: UserMode.User,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/reports',
          labelName: 'Reports',
          userMode: UserMode.User,
          externalLink: false,
          enabled: false,
        },
      ],
    },
    {
      routeAddress: '',
      labelName: 'Admin Tools',
      imageName: 'configuration',
      userMode: UserMode.NetworkAdmin,
      showArrow: true,
      enabled: true,
      childrenMenu: [
        {
          routeAddress: 'app/products',
          labelName: 'Products',
          userMode: UserMode.NetworkAdmin,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/customer-networks',
          labelName: 'Networks',
          userMode: UserMode.OmsAdmin,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/business-rules',
          labelName: 'Business Rules',
          userMode: UserMode.Clearfreight,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/routing-guide-admin',
          labelName: 'Routing Guides',
          userMode: UserMode.Clearfreight,
          externalLink: false,
          enabled: true,
        },
        {
          routeAddress: 'app/users/user-management',
          labelName: 'Users',
          userMode: UserMode.OmsAdmin,
          externalLink: false,
          enabled: true,
        },
      ],
    },
    {
      routeAddress: '',
      labelName: 'Setting',
      imageName: 'track-package',
      userMode: UserMode.OrgAdmin,
      showArrow: true,
      enabled: false,
      childrenMenu: [
        {
          routeAddress: 'app/setting/org-setting',
          labelName: 'Organization Setting',
          userMode: UserMode.OrgAdmin,
          externalLink: false,
          enabled: false,
        },
      ],
    },
  ];

  public adminRoutes: MenuItem[] = [
    {
      imageName: 'people-setting',
      routeAddress: 'app/users/user-profile',
      labelName: 'My Account',
      userMode: UserMode.User,
      showArrow: false,
      enabled: true,
    },
  ];

  public userNavigationLinks: MenuItem[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public userService: UserService,
    public matDialog: MatDialog,
    private router: Router,
    public searchService: SearchService,
    public authService: AuthService
  ) {
    this.matIconRegistry.addSvgIcon(
      'shapes-and-symbols',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/shapes-and-symbols.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'right-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'left-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'down-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delivery-search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/delivery-search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'storage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/storage.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'account_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/account_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu-book',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/menu_book.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/clipboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar_view_week',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/calendar_view_week.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stream',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/stream.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'people',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/people.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'import',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/import.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rejection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/rejection.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'question-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/question_mark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/configurations.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-excel-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-lines',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-lines-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-pdf-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-word',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-word-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-zipper',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-zipper-solid.svg')
    );
    this.matIconRegistry.addSvgIcon('route', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/route.svg'));
    this.matIconRegistry.addSvgIcon(
      'shipments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/shipments.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'people-setting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/people-setting.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'track-package',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/track-package.svg')
    );
    this.addEventIcons();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      /** Set initial route */
      if (this.initialRoute) {
        this.initialRouteAddress = event.urlAfterRedirects.slice(1);
      }
      /* Set menu when change org/network */
      if (!this.initialRoute) {
        this.setCurrentMenu(event.urlAfterRedirects.slice(1));
      }
    });
  }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (Object.keys(user).length) {
        this.userMode = this.userService.userMode;
        this.orgMode = this.userService.orgMode;
        this.configureNavigationForUser();
      }
    });
  }

  public impersonateUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '500px';
    dialogConfig.width = 'auto';
    this.matDialog.open(ImpersonateUserComponent, dialogConfig).afterClosed().subscribe();
  }

  private configureNavigationForUser(): void {
    if (this.orgMode === PageOrgMode.Lite) {
      this.userNavigationLinks = [...this.omsLiteNavLinks.slice(0)];
    } else {
      this.userNavigationLinks = [...this.omsNavLinks.slice(0)];
    }

    // filter first level menu
    this.userNavigationLinks = this.userNavigationLinks.filter(nl => nl.userMode <= this.userMode);

    // filter second level menu
    this.userNavigationLinks.forEach(item => {
      if (item.childrenMenu && item.childrenMenu.length > 0) {
        item.childrenMenu = item.childrenMenu.filter(child => child.userMode <= this.userMode);
        if (
          item.labelName === 'Setting' &&
          (this.orgMode === PageOrgMode.Supplier || this.orgMode === PageOrgMode.Client) &&
          this.userMode <= UserMode.NetworkAdmin
        ) {
          item.enabled = true;
          item.childrenMenu.forEach(child => {
            child.enabled = true;
          });
        }

        if (item.labelName === 'Order Management' && this.orgMode === PageOrgMode.Client) {
          item.childrenMenu.forEach(child => {
            if (child.labelName === 'Reports') child.enabled = true;
          });
        } else if (item.labelName === 'Order Management' && this.orgMode !== PageOrgMode.Client) {
          item.childrenMenu.forEach(child => {
            if (child.labelName === 'Reports') child.enabled = false;
          });
        }
      }
    });

    if (this.initialRoute) {
      this.initialRoute = false;
      this.setCurrentMenu(this.initialRouteAddress);
    }
  }

  private addEventIcons(): void {
    for (let [key, iconFileName] of this.eventIconMap) {
      this.matIconRegistry.addSvgIcon(
        iconFileName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/svgs/event-icons/${iconFileName}.svg`)
      );
    }
  }

  public changeNavOpen(event: boolean): void {
    this.sidebarExpanded = event;
  }

  public openChildrenMenu(event: string): void {
    this.showChildrenMenu = event;
  }

  public changeParentMenu(event: string): void {
    this.currentParentMenu = event;
  }

  public changeCurrentChildMenu(event: string): void {
    this.currentChildMenu = event;
  }

  public setCurrentMenu(routerUrl: string): void {
    let enter = false;
    this.userNavigationLinks.forEach(item => {
      if (!item.childrenMenu && routerUrl.indexOf(item.routeAddress) != -1) {
        this.openChildrenMenu('');
        this.changeCurrentChildMenu('');
        this.changeParentMenu(item.labelName);
        let enter = true;
      } else if (item.childrenMenu) {
        item.childrenMenu.forEach(child => {
          if (routerUrl.indexOf(child.routeAddress) != -1) {
            this.openChildrenMenu(item.labelName);
            this.changeCurrentChildMenu(child.labelName);
            this.changeParentMenu(item.labelName);
            let enter = true;
          }
        });
      }
    });

    if (!enter) {
      this.adminRoutes.forEach(item => {
        if (!item.childrenMenu && routerUrl.indexOf(item.routeAddress) != -1) {
          this.openChildrenMenu('');
          this.changeCurrentChildMenu('');
          this.changeParentMenu(item.labelName);
          let enter = true;
        }
      });
    }
  }

  public initiateSearch(eventKey?: number): void {
    if (this.router.url.indexOf('app/search') < 0) {
      this.router.navigateByUrl('app/search?recentUrl=' + this.router.url);
    }
    this.searchService.searchSubject.next(this.searchValue);
  }
}
