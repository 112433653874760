import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdatePacklineColumns } from './packlines.actions';
import {PacklineColumn, ShipmentPackLine} from "@modules/shipment/models/cw-shipment.model";

export class PacklineColumnStateModel {
  public columns: PacklineColumn[];
}

@State<PacklineColumnStateModel>({
  name: 'PacklineColumnState',
  defaults: {
    columns: ShipmentPackLine.PacklineColumns,
  },
})
@Injectable({
  providedIn: 'root',
})
export class PacklineColumnState {
  constructor() {}

  @Selector()
  public static getColumnPreferences(state: PacklineColumnStateModel): PacklineColumn[] {
    return state.columns;
  }

  @Action(UpdatePacklineColumns)
  UpdatePacklineColumns(ctx: StateContext<PacklineColumnStateModel>, action: UpdatePacklineColumns) {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      ...action.payload,
    });
  }
}
