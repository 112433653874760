import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ResetShipmentState, SelectShipment } from './shipment.actions';
import { IShipment, ShipmentState } from '../shipment/shipment.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShipmentActionsHandler implements OnDestroy {
  private PREV_ROUTE: string = null;
  @Select(ShipmentState.shipments) public shipments$: Observable<IShipment[]>;
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private actions$: Actions, private store: Store) {
    this.actions$.pipe(ofActionSuccessful(SelectShipment), takeUntil(this.ngUnsubscribe)).subscribe(({id, isNew}) => {
      const sl = this.store.selectSnapshot(ShipmentState.shipments);
      const shipmentIndex = sl.findIndex(s => s.shipment.id === id);
      const shipment = sl[shipmentIndex];

      // If the shipment is not found within the shipment state context reset
      // the state as it could still be a valid shipment id but it was not
      // visible upon the original shipment selection
      if (!shipment) {
        this.store.dispatch(new ResetShipmentState());
        return;
      } else {
        const url = shipment.isAir
          ? `app/shipments/air-shipments/${id}`
          : shipment.isRai
          ? `app/shipments/rai-shipments/${id}`
          : shipment.isRoa
          ? `app/shipments/roa-shipments/${id}`
          : shipment.isSea
          ? `app/shipments/sea-shipments/${id}`
          : `app/shipments/sea-shipments/${id}`;
        isNew ? window.open(url) : this.router.navigate([url]);
      }
    });

    this.router.events.subscribe(event => {
      // NOTE: NavigationStart event is not fired on a page refresh
      if (event instanceof NavigationStart) {
        if (this.previouslyOnShipmentsPage()) {
          const toBaseUrl = this.getBaseUrl(event.url);
          const fromBaseUrl = this.getBaseUrl(this.PREV_ROUTE);

          if (toBaseUrl === fromBaseUrl && event.url !== this.PREV_ROUTE) {
            // When routing to detail views on the same route a NavigationEnd
            // event will not fire so we need to store the last known route
            // without waiting for the end event
            this.PREV_ROUTE = event.url;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        if (this.previouslyOnShipmentsPage()) {
          const toURL = event.urlAfterRedirects;
          if (
            !toURL.includes('sea-shipments') &&
            !toURL.includes('air-shipments') &&
            !toURL.includes('roa-shipments') &&
            !toURL.includes('rai-shipments')
          ) {
            // On leaving shipments pages reset shipment state
            this.store.dispatch(new ResetShipmentState());
          }
        }

        // On successful navigation to a new route update the last known route
        this.PREV_ROUTE = event.urlAfterRedirects;
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.PREV_ROUTE = null;
  }

  private getBaseUrl(url: string): string {
    const urlFragments = url.split('/');
    if (!urlFragments.length) {
      return '';
    }
    return url.split('/')[1];
  }

  private previouslyOnShipmentsPage(): boolean {
    if (!this.PREV_ROUTE) {
      return false;
    }
    return (
      this.PREV_ROUTE.includes('sea-shipments') ||
      this.PREV_ROUTE.includes('air-shipments') ||
      this.PREV_ROUTE.includes('roa-shipments') ||
      this.PREV_ROUTE.includes('rai-shipments')
    );
  }
}
