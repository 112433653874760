import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Type } from '../../constants';
import { FileSelectorComponent } from './file-selector.component';

@Component({
  selector: 'app-file-upload',
  styles: [''],
  template: `
    <button mat-raised-button color="primary" (click)="openDialog()">
      <div class="icon-text" style=" display: flex; align-items: center;">
        <mat-icon>publish</mat-icon>
        <span>UPLOAD</span>
      </div>
    </button>
  `,
})
export class FileUploadComponent {
  @Input() uploadURL: string = '';
  @Input() fileTypeOptions: Type[] = [];
  @Input() parent: number;
  @Input() parentId: number;
  @Input() fileStorageType: number;
  @Output() filesUploaded: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  public openDialog() {
    const dialogRef = this.dialog.open(FileSelectorComponent, {
      panelClass: 'full-width-dialog',
      data: {
        uploadURL: this.uploadURL,
        fileTypeOptions: this.fileTypeOptions,
        parent: this.parent,
        parentId: this.parentId,
        fileStorageType: this.fileStorageType,
      },
    });

    dialogRef.afterClosed().subscribe(succeeded => {
      if (succeeded) {
        this.filesUploaded.emit();
      }
    });
  }
}
