import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { Constants } from 'src/app/shared/constants';
import { TransportMode } from '@models/helpers';

@Component({
  selector: 'app-om-job-booking-details-form',
  styleUrls: ['./job-booking-details-form.component.scss'],
  templateUrl: './job-booking-details-form.component.html',
})
export class JobBookingDetailsFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() shippers: Organization[];
  @Input() buyers: Organization[];
  @Input() consignees: Organization[];
  @Input() manufacturers: Organization[];
  @Input() customerLocations: Location[];
  @Input() shipperLocations: Location[];
  @Input() consigneeLocations: Location[];
  @Input() cargoReadyDateMarked = false;
  @Input() estimatedDeliveryDateMarked = false;
  @Input() panelOpenState0 = true;
  @Input() panelOpenState1 = false;
  @Input() panelOpenState2 = false;
  @Output() public panelStateChanged = new EventEmitter<{
    panelIndex: number;
    panelState: boolean;
  }>();

  public incoterms = Constants.incoterms;
  public defaultOrg = new Organization();
  public Transport_Mode = TransportMode;

  constructor() {}

  ngOnInit(): void {}

  onDateSelected(formName: string, date: Date) {
    this.form.get(formName)?.setValue(date);
  }

  public compareFn(optionA: any, optionB: string | any): boolean {
    if (optionA && optionB) {
      return optionA.code == optionB.code;
    } else {
      return false;
    }
  }

  public orgCompareFn(o1: Organization, o2: Organization): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  public locationCompareFn(l1: Location, l2: Location): boolean {
    return l1 && l2 ? l1.code === l2.code : l1 === l2;
  }

  public clearField(event: any, field: string) {
    event.stopImmediatePropagation();
    this.form.get(field).setValue(null);
  }

  public changePanelState(index: number, state: boolean) {
    if (index === 0) {
      this.panelOpenState0 = state;
    } else if (index === 1) {
      this.panelOpenState1 = state;
    } else if (index === 2) {
      this.panelOpenState2 = state;
    }
    this.panelStateChanged.emit({
      panelIndex: index,
      panelState: state,
    });
  }
}
