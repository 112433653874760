import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateColumns } from './column-settings.actions';
import { PoColumn } from './column.model';

export class PurchaseOrderColumnStateModel {
  public columns: PoColumn[];
}

export const DEFAULT_PO = {
  columns: [
    new PoColumn('poNumber'),
    new PoColumn('poStatus'),
    new PoColumn('poDate').setType('date'),
    new PoColumn('customerPoNumber'),
    new PoColumn('expectedCargoReadyDate').setType('date'),
    new PoColumn('inDistCenterDate').setType('date'),
    new PoColumn('firstShipDate').setType('date'),
    new PoColumn('lastShipDate').setType('date'),
    new PoColumn('bookByDate').setType('date'),
    new PoColumn('originCountry'),
    new PoColumn('shipToLocation.name', 'Ship To'),
    new PoColumn('polCode'),
    new PoColumn('podCode'),
    new PoColumn('incoterm'),
    new PoColumn('htsCode'),
    new PoColumn('supplier.name', 'Supplier'),
    new PoColumn('customer.name', 'Customer'),
    new PoColumn('buyingAgent.name', 'Buying Agent'),
    new PoColumn('consignee.name', 'Consignee'),
    new PoColumn('manufacturer.name', 'Manufacturer'),
    new PoColumn('containerNumber')
  ],
};

@State<PurchaseOrderColumnStateModel>({
  name: 'PurchaseOrderColumnsState',
  defaults: {
    columns: DEFAULT_PO.columns
  },
})
@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderColumnsState {
  constructor() {}

  @Selector()
  public static getColumnPreferences(state: PurchaseOrderColumnStateModel): PoColumn[] {
    let tableColumns = [];
    if (state.columns) {
      // Remove columns in the saved state that are no longer in the DEFAULT columns
      for (let i = state.columns.length - 1; i >= 0; --i) {
        const found = (DEFAULT_PO.columns.findIndex(c => c.accessor === state.columns[i].accessor) > -1);
        if (!found) {
          state.columns.splice(i, 1);
        }
      }

      tableColumns = state.columns;
      DEFAULT_PO.columns.forEach(col => {
        // Add any new DEFAULT columns that were not already in the saved state.
        const found = (state.columns.findIndex(c => c.accessor === col.accessor) > -1);
        if (!found) {
          tableColumns.push(col);
        }
      });
      state.columns = tableColumns;
    }
    return state.columns;
  }

  @Action(UpdateColumns)
  public updateColumns(ctx: StateContext<PurchaseOrderColumnStateModel>, action: UpdateColumns): void {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      ...action.payload,
    });
  }
}
