<div class="row">
  <label class="detail-field-title">{{ label }}</label>
</div>
<div class="row detail-field-content">
  <mat-form-field [appearance]="appearance" [ngClass]="{ 'extended-width': extendedWidth }">
    <input
      matInput
      [matDatepicker]="picker"
      (click)="openPicker()"
      (dateChange)="onDateChanged($event)"
      [formControl]="date"
      [required]="required"
      [disabled]="disabled"
      [max]="maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
