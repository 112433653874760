<div>
  <div class="title-text">ASN Trigger</div>
  <div class="row justify-content-start mt-3" *ngIf="asnTriggerDefinition">
    <div class="col-12">
      <div class="col-12">
        <span class="type-title">Containerized:</span>
        <span class="type-switch">
          <ui-switch
            size="small"
            defaultBgColor="#26282D3F"
            checkedLabel="&nbsp;&nbsp;&nbsp;"
            uncheckedLabel="&nbsp;&nbsp;&nbsp;"
            [checked]="asnTriggerDefinition.hasContainerizedTrigger"
            (change)="toggleAsnTriggerEnabled('Containerized', $event)"
          ></ui-switch>
        </span>
      </div>
      <div class="col-12 mt-1">
        <mat-radio-group
          color="primary"
          [value]="asnTriggerDefinition.containerizedTrigger"
          [disabled]="!asnTriggerDefinition.hasContainerizedTrigger"
          (change)="hasContainerizedTriggerChanged($event)"
        >
          <mat-radio-button [value]="asnTriggerType.ContainersSealed">All Containers Sealed</mat-radio-button>
          <mat-radio-button [value]="asnTriggerType.PackingCompleted">Complete Packing</mat-radio-button>
          <mat-radio-button [value]="asnTriggerType.AcutalDepartureDate">Departure Date from ES</mat-radio-button>
          <mat-radio-button [value]="asnTriggerType.FreightLoaded">Freight loaded</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-12">
      <div class="col-12">
        <span class="type-title">Non-Containerized:</span>
        <span class="type-switch">
          <ui-switch
            size="small"
            defaultBgColor="#26282D3F"
            checkedLabel="&nbsp;&nbsp;&nbsp;"
            uncheckedLabel="&nbsp;&nbsp;&nbsp;"
            [checked]="asnTriggerDefinition.hasNonContainerizedTrigger"
            (change)="toggleAsnTriggerEnabled('NonContainerized', $event)"
          ></ui-switch>
        </span>
      </div>
      <div class="col-12 mt-3">
        <mat-radio-group
          color="primary"
          [value]="asnTriggerDefinition.nonContainerizedTrigger"
          [disabled]="!asnTriggerDefinition.hasNonContainerizedTrigger"
          (change)="hasNonContainerizedTriggerChanged($event)"
        >
          <mat-radio-button [value]="asnTriggerType.AcutalDepartureDate">Departure Date from ES</mat-radio-button>
          <mat-radio-button [value]="asnTriggerType.CargoPickedUpDate">
            Date Cargo Picked Up from Supplier
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-12">
      <div class="col-12">
        <span class="type-title">Deliver if empty report</span>
      </div>
      <div class="col-12 mt-3">
        <mat-radio-group
          color="primary"
          [value]="asnTriggerDefinition.deliverEmpty"
          [disabled]="!asnTriggerDefinition.hasNonContainerizedTrigger && !asnTriggerDefinition.hasContainerizedTrigger"
          (change)="onDeliverEmptyReportChange($event)"
        >
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-12 mt-3 recipient-setting">
      <div class="col-12">
        <span class="type-title">Recipient Setting</span>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <mat-checkbox
            color="primary"
            [checked]="asnTriggerDefinition.recipientSetting.sendToClient"
            [disabled]="
              !asnTriggerDefinition.hasNonContainerizedTrigger && !asnTriggerDefinition.hasContainerizedTrigger
            "
            (change)="recipientCheckboxChanged('client', $event)"
          >
            Send to Client
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            [checked]="asnTriggerDefinition.recipientSetting.sendToSupplier"
            [disabled]="
              !asnTriggerDefinition.hasNonContainerizedTrigger && !asnTriggerDefinition.hasContainerizedTrigger
            "
            (change)="recipientCheckboxChanged('supplier', $event)"
          >
            Send to Supplier
          </mat-checkbox>
        </div>
        <div class="col-9">
          <div class="row column-header">
            <mat-icon>person_add</mat-icon>
            External Users
          </div>
          <div class="row mt-2 external-explain">
            Please type any external emails one by one and press ENTER after each
          </div>
          <mat-form-field appearance="outline" class="mt-2">
            <input
              matInput
              placeholder="External Emails"
              type="text"
              #emailInput
              (keyup.enter)="addChip($event)"
              [disabled]="
                !asnTriggerDefinition.hasNonContainerizedTrigger && !asnTriggerDefinition.hasContainerizedTrigger
              "
            />
          </mat-form-field>
          <div class="external-scroll-section">
            <mat-chip
              class="email-chip mt-2"
              *ngFor="let email of asnTriggerDefinition.recipientSetting.otherRecipients"
              [selectable]="false"
              [removable]="true"
              (removed)="removeChip(email)"
            >
              {{ email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
