import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slicer-menu',
  templateUrl: './slicer-menu.component.html',
  styleUrls: ['./slicer-menu.component.scss']
})
export class SlicerMenuComponent implements OnInit {
	@Input()
	title!: string;
	
	@Input()
	icon?: string | null;

  constructor() { }

  ngOnInit(): void {}
}
