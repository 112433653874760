<h1 mat-dialog-title class="border-bottom">Select User to Impersonate</h1>

<mat-dialog-content>
  <div class="w-100 border-bottom">
    <mat-form-field class="w-100" appearance="outline">
      <input type="text" matInput [(ngModel)]="searchTerm" />
      <mat-label>Filter email list</mat-label>
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <mat-selection-list class="user-list" #users [multiple]="false">
    <mat-list-option *ngFor="let user of userList" [value]="user" (click)="impersonate(user.externalId)">
      {{ user.email }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" class="destructive" mat-dialog-close>Cancel</button>
</div>
