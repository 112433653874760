import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingService } from '@services/setting.service';
import { IconUploadDialogComponent } from '../icon-upload-dialog/icon-upload-dialog.component';

@Component({
  selector: 'app-org-icon-setting',
  templateUrl: './org-icon-setting.component.html',
  styleUrls: ['./org-icon-setting.component.scss'],
})
export class OrgIconSettingComponent implements OnInit {
  @Input() public title = 'Organization Logo';
  @Input() public orgId: number;

  public logoUrl: string = '';

  public loading = false;

  constructor(private dialog: MatDialog, private settingService: SettingService) {}

  ngOnInit(): void {
    this.getOrgSetting();
  }

  getOrgSetting(): void {
    this.settingService.getOrganizationSetting(this.orgId).subscribe(res => {
      this.logoUrl = res.logoFileUrl;
    });
  }

  openUploadIconDialog(event: any) {
    const dialogRef = this.dialog.open(IconUploadDialogComponent, {
      width: '70vw',
      height: '520px',
      maxWidth: '1280px',
      disableClose: true,
    });
    dialogRef.componentInstance.orgId = this.orgId;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // todo
        this.logoUrl = result.fileUrl;
      }
    });
  }
}
