<div @fadeInOutTrigger id="alertsDarkOverlay" (click)="alertService.closeAlerts()"></div>
<div @fadeInOutTrigger class="alerts-wrapper">
  <div class="row mx-2 mb-3 title-row">
    <div class="col-auto">{{ metadata.header }}</div>
    <div class="col-auto ml-auto text-right">
      <span class="material-icons close-icon" (click)="alertService.closeAlerts()">close</span>
    </div>
  </div>
  <ng-container *ngFor="let alert of alerts">
    <ng-container [ngSwitch]="alert.type">
      <!-- <ng-container *ngSwitchCase="'PO_QUERY'">
        <app-om-po-query-alert [alert]="alert"></app-om-po-query-alert>
      </ng-container> -->
      <ng-container *ngSwitchDefault>
        <app-om-alert [alert]="alert">
          <ng-container>
            <div class="row">
              <div class="col alert-link-col" *ngIf="alert.keySource === 'booking'">
                <div>
                  Booking #:
                  <a routerLink="/app/bookings/{{ alert.key }}" class="alert-link-text">{{ alert.displayName }}</a>
                </div>
                <div class="relatedPo">
                  PO Refs:
                  <div
                    class="relatedPo-text"
                    *ngIf="alert.relatedValues.length > 0"
                    (click)="openPoRefDialog($event, alert.relatedValues)"
                  >
                    <div class="po-ref">{{ alert.relatedValues[0].value }}</div>
                    <div class="po-ref-other" *ngIf="alert.relatedValues?.length >= 2">
                      +{{ alert.relatedValues?.length - 1 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col alert-link-col" *ngIf="alert.keySource === 'purchase_order'">
                Purchase Order #:
                <a routerLink="/app/purchase-orders/{{ alert.key }}" class="alert-link-text">{{ alert.displayName }}</a>
              </div>
            </div>
            <div *ngFor="let message of alert.content.messages">
              <span class="row ml-3">{{ message }}</span>
            </div>
          </ng-container>
        </app-om-alert>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
