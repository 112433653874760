import { Location } from '@models/location.model';

// This model maps to the Domain.Ogranization
// on the API.
export class Organization {
  public id: number;
  public name: string;
  public code: string;
  public type: string;
  public cwId: string;
  public locations: Location[];
  public networkEnabled: boolean;

  constructor(init?: Partial<Organization>) {
    if (init?.id) this.id = init.id;
    if (init?.name) this.name = init.name;
    if (init?.code) this.code = init.code;
    if (init?.type) this.type = init.type;
    if (init?.cwId) this.cwId = init.cwId;
    this.locations = init?.locations ? init.locations : [];
    if (init?.networkEnabled) this.networkEnabled = init.networkEnabled;
  }
}

// This model maps to the Domain.Ogranization.UserOrg
// on the API.
export class UserOrg {
  public id: number;
  public code: string;
  public type: string;
  public name: string;
  public isOrganizationAdmin: boolean;
  public isNetworkAdmin: boolean;
  public isClearfreight: boolean;
  public network: Network;
  public chains: any[];

  constructor(init?: Partial<UserOrg>) {
    if (init?.id) this.id = init.id;
    if (init?.code) this.code = init.code;
    if (init?.type) this.type = init.type;
    if (init?.name) this.name = init.name;
    if (init?.isOrganizationAdmin) this.isOrganizationAdmin = init.isOrganizationAdmin;
    if (init?.isNetworkAdmin) this.isNetworkAdmin = init.isNetworkAdmin;
    if (init?.isClearfreight) this.isClearfreight = init.isClearfreight;
    if (init?.network) this.network = init.network;
    this.chains = init?.chains ? init.chains : [];
  }
}

// This model maps to the Dto.NetworkDto
// on the API.
export class Network {
  public id: number;
  public customerOrgId: number;
  public customerCode: string;
  public customerName: string;
  public customerLegacyId: string;
  public customerEnabled: boolean; // Frontend Only
  public customerLiteEnabled: boolean; // FrontendOnly

  constructor(init?: Partial<Network>) {
    if (init?.id) this.id = init.id;
    if (init?.customerOrgId) this.customerOrgId = init.customerOrgId;
    if (init?.customerCode) this.customerCode = init.customerCode;
    if (init?.customerName) this.customerName = init.customerName;
    if (init?.customerLegacyId) this.customerLegacyId = init.customerLegacyId;
    if (init?.customerEnabled) this.customerEnabled = init.customerEnabled;
    if (init?.customerLiteEnabled) this.customerLiteEnabled = init.customerLiteEnabled;
  }
}
