import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shipment, ShipmentLeg } from '@modules/shipment/models/cw-shipment.model';
import { PurchaseOrder } from '@modules/shipment/models/container.model';
import { DocumentDownloadRequest } from '@models/document.model';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  public import = true;

  public airShipmentList: Shipment[] = [];
  public seaShipmentList: Shipment[] = [];
  public roaShipmentList: Shipment[] = [];
  public raiShipmentList: Shipment[] = [];

  public airSortByField = 'status.estimatedDelivery';
  public airSortByAscending = false;
  public seaSortByField = 'status.estimatedDelivery';
  public isSortFieldTextField = false;
  public seaSortByAscending = false;
  public roaSortByField = 'status.estimatedDelivery';
  public roaSortByAscending = false;
  public raiSortByField = 'status.estimatedDelivery';
  public raiSortByAscending = false;

  public triedRedirect = false;

  constructor(private httpClient: HttpClient, private toastr: ToastrService) {}

  public getMainLeg(legs: ShipmentLeg[]): ShipmentLeg {
    if (legs) {
      let leg = legs.find(l => l.transportType === 'MAI');
      if (leg === undefined) {
        leg = legs.find(l => l.vessel !== null || l.vessel !== '');
      }
      return leg;
    } else {
      return null;
    }
  }

  public fetchShipments(reqObj): any {
    return this.httpClient.post<any>(`${environment.apiBaseURL}Shipment/getShipmentsWithPagination`, reqObj);
  }

  public getShipmentByNumber(id: string): any {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Shipment/getShipmentByNumber/${id}`);
  }

  public getShipment(shipmentID: string): Observable<Shipment> {
    return this.httpClient.get<Shipment>(`${environment.apiBaseURL}shipment/getShipment/${shipmentID}`);
  }

  public getPurchaseOrdersOnShipments(shipmentNumbers: string[]): Observable<PurchaseOrder[]> {
    return this.httpClient.post<PurchaseOrder[]>(
      `${environment.apiBaseURL}purchaseOrder/shipmentLines`,
      shipmentNumbers
    );
  }

  public getDocumentTypes(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}clearView/documentTypes`);
  }

  public getDocuments(request: DocumentDownloadRequest): any {
    return this.httpClient.post(`${environment.apiBaseURL}clearView/getDocuments`, request, {
      responseType: 'arraybuffer',
    });
  }

  public downloadPurchaseOrders(payload: any): Subject<boolean> {
    this.toastr.info('Excel file will download in the background.', 'Downloading');
    const fileDownloadStatus = new Subject<boolean>();

    this.httpClient
      .post(`${environment.apiBaseURL}ClearView/purchaseOrders/download`, payload, { responseType: 'arraybuffer' })
      .subscribe(
        (res: any) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([res], { type: res.type }));
          downloadLink.setAttribute('download', 'Purchase Orders.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.toastr.success('Download completed.');
          fileDownloadStatus.next(true);
        },
        _ => {
          fileDownloadStatus.next(false);
          this.toastr.error('Please try again later.', 'Error downloading file.');
        }
      );
    return fileDownloadStatus;
  }
}
