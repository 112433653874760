import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { Container, PurchaseOrder } from '@modules/shipment/models/container.model';
@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  public containerList: Container[] = [];
  public conSortByField = 'shipmentLegs.etd';
  public isSortFieldTextField = false;
  public conSortByAscending = false;

  constructor(private httpClient: HttpClient) {}

  public getPurchaseOrdersOnContainers(containerNumbers: string[]): Observable<PurchaseOrder[]> {
    return from(this.getEquipmentTypePromise(containerNumbers));
  }

  private async getEquipmentTypePromise(containerNumbers: string[]) {
    const response = await fetch(`${environment.apiBaseURL}purchaseOrder/containerLines`, {
      method: 'POST',
      body: JSON.stringify(containerNumbers),
      headers: new Headers({ 'content-type': 'application/json' }),
    });
    const pos = await response.json();
    return pos as Promise<PurchaseOrder[]>;
  }

  public getContainer(containerID: string): Observable<Container> {
    return this.httpClient.get<Container>(`${environment.apiBaseURL}Container/getContainer/${containerID}`);
  }

  public fetchContainers(reqObj) {
    return this.httpClient.post<any>(`${environment.apiBaseURL}ClearView/getContainersWithPagination`, reqObj);
  }
}
