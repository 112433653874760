<ng-content select="[customCells]"></ng-content>

<div class="table-header-toolbar" #tableHeaderToolbar>
  <ng-content select="[tableToolbar]"></ng-content>
</div>

<div
  [ngClass]="{
    'table-container': true,
    'table-rounded': !nested,
    'table-header-top': headerOnTop,
    'gray-scrollbar-corner': grayScrollCorner
  }"
  appTransformObserver
  #appTransformObserver="appTransformObserver"
  appScrollObserver
  #appScrollObserver="appScrollObserver"
  [ngStyle]="{ height: containerHeight ? containerHeight - 100 + 'px' : !nested && tableHeight ? tableHeight : 'auto' }"
>
  <table
    mat-table
    matSort
    [style.width.px]="appTransformObserver.observedSizes.width"
    [dataSource]="matTableDataSource"
    multiTemplateDataRows
    class="om-table"
    (matSortChange)="sortChange($event)"
    [ngClass]="{ 'white-background': whiteBackground, 'child-table': childTable, equipmentTable: equipmentTable }"
  >
    <!-- DEFAULT CELL TEMPLATES -->
    <ng-container *ngFor="let column of tableConfig.renderColumns">
      <ng-container *ngIf="column.type !== 'custom'">
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'arrow'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element"></th>
            <td mat-cell *matCellDef="let element">
              <div class="arrow" [@rotatedState]="element.isExpanded ? 'rotated' : 'default'">
                <mat-icon>expand_more</mat-icon>
              </div>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'hyperlink'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <a href="javascript:void(0)" (click)="onHyperLinkClick($event, element, column.hyperLinkConfig)">
                  {{ element[column.key] }}
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'externalHyperlink'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <a
                  href="javascript:void(0)"
                  (click)="onExternalHyperlinkClick($event, element, column.hyperLinkConfig)"
                >
                  {{ element[column.key] }}
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'bookingStatus'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element" [ngSwitch]="element[column.key]">
              <div *ngSwitchCase="bookingStatusEnum.AwaitingClient" class="td-div await-approval-status">
                <div class="booking-status-text">
                  {{ element[column.key] | bookingStatus }}
                  <mat-icon
                    title="Lock for editing"
                    *ngIf="element.isEditing"
                    matSuffix
                    class="icon-action-red"
                  >
                    lock
                  </mat-icon>
                </div>
              </div>
              <div *ngSwitchCase="bookingStatusEnum.Accepted" class="td-div approved-status">
                <div class="booking-status-text">
                  {{ element[column.key] | bookingStatus }}
                </div>
              </div>
              <div *ngSwitchCase="bookingStatusEnum.AwaitingSupplier" class="td-div rejected-status">
                <div class="booking-status-text">
                  {{ element[column.key] | bookingStatus }}
                </div>
              </div>
              <div *ngSwitchCase="bookingStatusEnum.Packed" class="td-div packed-status">
                <div class="booking-status-text">
                  {{ element[column.key] | bookingStatus }}
                </div>
              </div>
              <div *ngSwitchDefault class="td-div cancel-status">
                <div class="booking-status-text">
                  {{ element[column.key] | bookingStatus }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'poStatus'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element" [ngSwitch]="element[column.key]">
              <div *ngSwitchCase="poStatusEnum.AwaitingClient" class="td-div cancel-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchCase="poStatusEnum.Accepted" class="td-div approved-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchCase="poStatusEnum.Rejected" class="td-div rejected-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchCase="poStatusEnum.AwaitingSupplier" class="td-div await-approval-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchCase="poStatusEnum.Booked" class="td-div approved-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchCase="poStatusEnum.ForwarderAssigned" class="td-div approved-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
              <div *ngSwitchDefault class="td-div cancel-status">
                <div class="booking-status-text">
                  {{ element[column.key] | poStatus }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'colorText'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <div [className]="element[column.key + 'Color']">
                  {{ element[column.key] }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'relatedObj'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element">{{ column.name }}</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="related-div"
                [ngClass]="{ 'related-none-background': column.relatedObjConfig.background === 'none' }"
              >
                <div class="related-content" (click)="openRelatedDialog($event, element, column)">
                  <span [class.related-span]="i !== 0" *ngFor="let item of element[column.key]; index as i">
                    {{ '' + item[column.relatedObjConfig.textKey] }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'actionBtn'" [matColumnDef]="column.key" stickyEnd>
            <th mat-header-cell *matHeaderCellDef="let element" class="om-table-header"></th>
            <td mat-cell *matCellDef="let element" class="actions-td">
              <div class="actions-cell-content-wrapper actions-btn" *ngIf="element.isExpanded">
                <button mat-stroked-button (click)="selectAllPoLines($event, element)">
                  <mat-icon color="primary">add_box</mat-icon>
                  Add All
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'bool'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <mat-checkbox [disabled]="true" color="primary" [checked]="element[column.key]"></mat-checkbox>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'location'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                {{ element[column.key] | location : 'table' }}
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchDefault [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                {{ element | tableCell : column }}
              </div>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container [ngSwitch]="allowRowExpansion">
      <ng-container *ngSwitchCase="false">
        <tr mat-header-row *matHeaderRowDef="tableConfig.displayColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: tableConfig.displayColumns"
          (click)="rowSelected.emit(element)"
        ></tr>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <tr mat-header-row *matHeaderRowDef="tableConfig.displayColumns"></tr>
        <!-- EXPANDED CELL TEMPLATE -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="tableConfig.displayColumns.length"
            class="expanded-cell"
          >
            <div class="example-element-detail" [ngClass]="{ 'table-expanded': element.isExpanded }" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
              <div
                class="nested-table-container"
                [style.width.px]="appTransformObserver.observedSizes.width"
                [style.left.px]="appScrollObserver.scrollLeft"
              >
                <ng-container
                  [ngTemplateOutlet]="templateRef"
                  [ngTemplateOutletContext]="{ element: element }"
                ></ng-container>
              </div>
            </div>
          </td>
        </ng-container>
        <tr
          mat-row
          *matRowDef="let element; columns: tableConfig.displayColumns"
          [class.example-expanded-row]="element.isExpanded"
          [class.selectable]="true"
          (click)="toggleRow(element)"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </ng-container>
    </ng-container>

    <!-- NO DATA ROW -->
    <ng-container *matNoDataRow>
      <tr class="mat-row" *ngIf="!tableLoading">
        <td class="mat-cell" colspan="9999">
          <div class="td-div">No data available to display.</div>
        </td>
      </tr>
    </ng-container>
  </table>

  <app-loading-indicator *ngIf="tableLoading"></app-loading-indicator>
</div>
<app-om-table-paginator
  [dataLength]="dataLength"
  [hidePaginator]="hidePaginator"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (pageChanged)="pageChanged.emit($event)"
></app-om-table-paginator>
