import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@models/contact.model';
import { RoutingGuide } from '@models/routing-guide.model';
import { ToastrService } from 'ngx-toastr';
import { PurchaseOrder } from '@models/purchase-order.model';
import { Booking, SaveBooking } from '@models/booking.model';
import { JobPurchaseOrderEditorComponent } from '@modules/job/components/dialogs/job-purchase-order-editor.component';
import { JobEquipmentEditorComponent } from '@modules/job/components/dialogs/job-equipment-editor.component';
import { Equipment } from '@models/equipment.model';
import { OmCacheService } from '@services/om-cache.service';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { UserService } from '@modules/auth/services/user.service';
import { BOOKING_STATUS } from '@models/helpers';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { BookingApiService } from '@services/booking-api.service';
import { Router } from '@angular/router';
import { getPoLineUnitText } from '../../../shared/utils/po-lines-unit';
import { getRequiredFields } from 'src/app/shared/utils/formGroup-utils';
import { TransportMode } from '@models/helpers';
import { e } from 'mathjs';
import { UnlocoApiService } from '@services/unloco-api.service';
import { DialogService } from '@services/dialog.service';

@Component({
  styleUrls: ['./create-booking-editor.component.scss'],
  template: `
    <app-om-dialog
      [title]="title"
      [description]="description"
      [showFooter]="true"
      (closeDialog)="discard()"
      [loading]="btnDisabled"
    >
      <app-loading-indicator *ngIf="loading; else jobDetailsForm"></app-loading-indicator>
      <ng-template #jobDetailsForm>
        <div class="dialog-content">
          <mat-tab-group class="job-details" [(selectedIndex)]="selectTableIndex">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">description</mat-icon>
                DETAILS
              </ng-template>
              <div>
                <app-om-job-booking-details-form
                  [form]="form"
                  [shippers]="shippers$ | async"
                  [buyers]="buyers$ | async"
                  [consignees]="consignees$ | async"
                  [manufacturers]="manufacturers$ | async"
                  [forwarders]="forwarders"
                  [customerLocations]="customerLocations"
                  [shipperLocations]="shipperLocations"
                  [consigneeLocations]="consigneeLocations"
                  [contacts]="contacts"
                  [routingGuides]="routingGuides"
                  [cargoReadyDateMarked]="cargoReadyDateMarked"
                  [estimatedDeliveryDateMarked]="estimatedDeliveryDateMarked"
                  [panelOpenState0]="detailPanelOpenState0"
                  [panelOpenState1]="detailPanelOpenState1"
                  [panelOpenState2]="detailPanelOpenState2"
                  (panelStateChanged)="panelStateChanged($event)"
                ></app-om-job-booking-details-form>
              </div>
            </mat-tab>
            <mat-tab label="ROUTING">
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">route</mat-icon>
                ROUTING
              </ng-template>
              <div>
                <app-om-job-booking-routing-form
                  [form]="form"
                  [loadingPortMarked]="loadingPortMarked"
                  [dischargePortMarked]="dischargePortMarked"
                  [forwarders]="forwarders"
                  [contacts]="contacts"
                ></app-om-job-booking-routing-form>
                <app-om-card>
                  <app-om-card-content>
                    <app-om-table
                      tableKey="routing-guide-table"
                      [dataSource]="routingGuides"
                      [allowRowExpansion]="false"
                      class="booking-routing-table"
                      [whiteBackground]="true"
                    >
                      <app-om-table-custom-cells-wrapper customCells>
                        <ng-container matColumnDef="actions" stickyEnd>
                          <th mat-header-cell class="om-table-header" *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let element">
                            <button
                              mat-raised-button
                              color="primary"
                              (click)="applyRoutingPreference($event, element); $event.stopImmediatePropagation()"
                            >
                              Set as Default
                            </button>
                          </td>
                        </ng-container>
                      </app-om-table-custom-cells-wrapper>
                    </app-om-table>
                  </app-om-card-content>
                </app-om-card>
              </div>
            </mat-tab>
            <mat-tab label="PURCHASE ORDERS">
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">work</mat-icon>
                PURCHASE ORDERS
              </ng-template>
              <div>
                <app-om-card>
                  <app-om-card-content>
                    <app-om-job-purchase-orders
                      [job]="booking"
                      [canEdit]="true"
                      [containerHeight]="dialogService.dialogHeight - 160"
                      (togglePurchaseOrderEditor)="onTogglePurchaseOrderEditor()"
                    ></app-om-job-purchase-orders>
                  </app-om-card-content>
                </app-om-card>
              </div>
            </mat-tab>
            <mat-tab label="EQUIPMENT">
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">corporate_fare</mat-icon>
                EQUIPMENT
              </ng-template>
              <div>
                <app-om-card>
                  <app-om-card-content>
                    <app-om-job-equipment
                      [job]="booking"
                      [canEdit]="true"
                      (toggleEquipmentEditor)="toggleEquipmentEditor()"
                    ></app-om-job-equipment>
                  </app-om-card-content>
                </app-om-card>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
      <ng-container footer>
        <button mat-raised-button color="primary" class="destructive" [disabled]="btnDisabled" (click)="discard()">
          Discard Changes
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="constructive" [disabled]="btnDisabled" (click)="save()">
          Save
          <mat-icon>save</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="send" [disabled]="btnDisabled" (click)="confirm()">
          Send To Buyer
          <mat-icon>send</mat-icon>
        </button>
      </ng-container>
    </app-om-dialog>
  `,
})
export class CreateBookingEditorComponent implements OnInit {
  public form: UntypedFormGroup;
  public buyers$: Observable<Organization[]>;
  public shippers$: Observable<Organization[]>;
  public consignees$: Observable<Organization[]>;
  public manufacturers$: Observable<Organization[]>;
  public forwarders: Organization[];
  public customerLocations: Location[] = [];
  public shipperLocations: Location[] = [];
  public consigneeLocations: Location[] = [];
  public contacts: Contact[] = [];
  public allContacts: Contact[] = [];
  public routingGuides: RoutingGuide[];
  public allRoutingGuides: RoutingGuide[];
  public booking = new Booking();

  public loading: boolean = false;
  public btnDisabled: boolean = false;
  public loadingPortMarked: boolean = false;
  public dischargePortMarked: boolean = false;
  public selectTableIndex: number = 0;
  public Transport_Mode: TransportMode;
  public convertPo: PurchaseOrder;
  public cargoReadyDateMarked: boolean = false;
  public estimatedDeliveryDateMarked: boolean = false;

  public title = 'EDIT DETAILS';
  public description = '';
  public detailPanelOpenState0 = true;
  public detailPanelOpenState1 = false;
  public detailPanelOpenState2 = false;

  constructor(
    private dialogRef: MatDialogRef<CreateBookingEditorComponent>,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private api: BookingApiService,
    private router: Router,
    private dialog: MatDialog,
    private omCache: OmCacheService,
    private uns: UserNetworkApiService,
    private userService: UserService,
    private unlocoService: UnlocoApiService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.booking.networkId = this.userService.userValue.currentNetworkId;
    this.form = this.fb.group({
      customer: [null, Validators.required],
      consignee: [null, Validators.required],
      manufacturer: [null, Validators.required],
      shipper: [null, Validators.required],
      forwarder: [new Organization()],
      forwarderContact: [null],
      contactIds: [null],
      pickupLocation: [null],
      shipToLocation: [null],
      incoterm: [null],
      goodDescription: [null],
      cargoReadyDateValue: [null, Validators.required],
      estimatedDeliveryDateValue: [null, Validators.required],
      etdValue: [null],
      etaValue: [null],
      revisedCargoReadyDate: [null],
      portOfLoading: [null, Validators.required],
      portOfDischarge: [null, Validators.required],
      portOfReceipt: [null],
      portOfDestination: [null],
      portOfLoadingTimezone: [null],
      portOfDischargeTimezone: [null],
      consigneeLocation: [null, Validators.required],
      shipperLocation: [null, Validators.required],
      // customerLocation: [null, Validators.required],
      transportMode: [null, Validators.required],
      isContainerized: [true],
    });

    const currentOrg = this.userService.currentOrg;
    forkJoin({
      routingGuides: this.uns.getRoutingGuides(),
      contacts: this.uns.getContactsInCurrentNetwork(),
    }).subscribe(value => {
      this.routingGuides = value.routingGuides.filter(r => r.supplier.id === currentOrg.id);
      this.allContacts = value.contacts;

      this.form.controls.portOfLoading.valueChanges.subscribe(data => {
        if (data && this.form.get('portOfDischarge').value) {
          const matchingGuides = this.routingGuides.filter(
            r =>
              r.supplier.code === this.form.controls.shipper.value.code &&
              r.pol === data &&
              r.pod === this.form.controls.portOfDischarge.value
          );
          if (matchingGuides && matchingGuides.length > 0 && !this.form.controls.forwarder.value.id) {
            this.form.controls.forwarder.patchValue(matchingGuides[0].forwarder);
          }
          if (matchingGuides && matchingGuides.length > 0 && !this.form.controls.contactIds.value) {
            this.form.controls.contactIds.patchValue(matchingGuides[0].contactIds);
          }
        }
      });

      this.form.controls.portOfDischarge.valueChanges.subscribe(data => {
        if (data && this.form.get('portOfLoading').value) {
          const matchingGuides = this.routingGuides.filter(
            r =>
              r.supplier.code === this.form.controls.shipper.value.code &&
              r.pod === data &&
              r.pol === this.form.controls.portOfLoading.value
          );
          if (matchingGuides && matchingGuides.length > 0 && !this.form.controls.forwarder.value?.id) {
            this.form.controls.forwarder.patchValue(matchingGuides[0].forwarder);
          }
          if (matchingGuides && matchingGuides.length > 0 && !this.form.controls.contactIds.value) {
            this.form.controls.contactIds.patchValue(matchingGuides[0].contactIds);
          }
        }
      });
    });

    this.buyers$ = this.omCache.buyerOrgs$;
    this.consignees$ = this.omCache.consigneeOrgs$;
    this.shippers$ = this.omCache.shipperOrgs$;
    this.manufacturers$ = this.omCache.manufacturerOrgs$;
    this.forwarders = this.omCache.forwarderOrgs;

    this.form.valueChanges.subscribe(formValue => {
      this.booking = Object.assign(this.booking, formValue);
    });

    this.form.controls.consignee.valueChanges.subscribe(data => {
      this.consigneeLocations = data ? data.locations : [];
    });
    this.form.controls.shipper.valueChanges.subscribe(data => {
      if (data) {
        this.shipperLocations = data.locations;
        this.patchSupplierDefaults(data.id);
      } else {
        this.shipperLocations = [];
      }
    });

    this.form.controls.customer.valueChanges.subscribe(data => {
      this.customerLocations = data ? data.locations : [];
    });

    //set default value
    let shipper = this.omCache.shipperOrgs.find(o => o.id == this.userService.currentOrg.id);
    this.form.controls.shipper.setValue(shipper);
    this.form.controls.shipper.disable();

    this.form.controls.customer.setValue(this.omCache.customer);
    this.form.controls.customer.disable();

    this.form.controls.contactIds.disable();
    this.form.controls.forwarder.valueChanges.subscribe(data => {
      if (data) {
        this.contacts = [...this.allContacts.filter(c => c.organizationIds.indexOf(data.id) >= 0)];
        if (this.form.controls.contactIds.disabled) this.form.controls.contactIds.enable();
      } else {
        this.form.controls.contactIds.reset(null);
        this.form.controls.contactIds.disable();
      }
    });

    this.description = `(Shipper: ${shipper.name} | Buyer: ${this.omCache.customer.name})`;

    // set convert value
    if (this.convertPo) {
      this.convertPo.poLines.forEach(l => {
        l.bookingQuantity = l.balanceQuantity;
      });
      this.booking.bookedPurchaseOrders = [this.convertPo];
      this.form.get('transportMode').setValue(this.convertPo.transportMode);
      this.form.get('cargoReadyDateValue').setValue(new Date(this.convertPo.expectedCargoReadyDateValue));
      if (this.convertPo.inDistCenterDateValue){
        this.form.get('estimatedDeliveryDateValue').setValue(new Date(this.convertPo.inDistCenterDateValue));
      }
      this.form.get('pickupLocation').setValue(this.convertPo.pickupLocation);
      this.form.get('incoterm').setValue(this.convertPo.incoterm);
      // consignee & consignee location & ship to location & customer location
      this.form.get('consignee').setValue(this.convertPo.consignee);
      this.consignees$.subscribe(consignees => {
        consignees.forEach(consignee => {
          if (consignee.id === this.convertPo.consignee.id) {
            this.consigneeLocations = consignee ? consignee.locations : [];
            consignee.locations.forEach(loc => {
              if (loc.code === this.convertPo.shipToLocation.code) {
                this.form.get('consigneeLocation').setValue(loc);
                this.form.get('shipToLocation').setValue(loc);
                if (this.convertPo.customer.id === this.convertPo.customer.id) {
                  this.customerLocations = consignee ? consignee.locations : [];
                  // this.form.get('customerLocation').setValue(loc);
                }
              }
            });
          }
        });
      });
      // port of loading
      if (this.convertPo.polCode) {
        this.unlocoService.getUnlocoCodes(this.convertPo.polCode).subscribe(codes => {
          var currentInput = this.convertPo.polCode.toUpperCase();
          const matchingUnloco = codes.find(u => u.locode === currentInput || u.name.toUpperCase() === currentInput);
          if (matchingUnloco) {
            this.form.controls.portOfLoading.setValue(matchingUnloco.locode);
            this.form.controls.portOfLoadingTimezone.setValue(matchingUnloco.timezone);
          }
        });
      }
      // port of discharge
      if (this.convertPo.podCode) {
        this.unlocoService.getUnlocoCodes(this.convertPo.podCode).subscribe(codes => {
          var currentInput = this.convertPo.podCode.toUpperCase();
          const matchingUnloco = codes.find(u => u.locode === currentInput || u.name.toUpperCase() === currentInput);
          if (matchingUnloco) {
            this.form.controls.portOfDischarge.setValue(matchingUnloco.locode);
            this.form.controls.portOfDischargeTimezone.setValue(matchingUnloco.timezone);
          }
        });
      }
    }
  }

  save(): void {
    this.saveBooking(true);
  }

  confirm(): void {
    this.saveBooking(false);
  }

  saveBooking(isUnsent) {
    if (!this.form.valid) {
      const requiredFields = getRequiredFields(this.form, ['portOfLoading', 'portOfDischarge']);
      const requiredPanel0Fields = ['transportMode'];
      const requiredPanel1Fields = ['consignee', 'consigneeLocation', 'shipperLocation'];
      const requiredPanel2Fields = ['cargoReadyDateValue', 'estimatedDeliveryDateValue'];
      const fields = this.findInvalidControls();
      let detailError = false;
      fields.forEach(item => {
        this.form.controls[item].markAsTouched();
        if (item === 'portOfLoading') {
          this.loadingPortMarked = true;
        } else if (item === 'portOfDischarge') {
          this.dischargePortMarked = true;
        } else if (item === 'cargoReadyDateValue') {
          this.cargoReadyDateMarked = true;
        } else if (item === 'estimatedDeliveryDateValue'){
          this.estimatedDeliveryDateMarked = true;
        }

        if (requiredFields.indexOf(item) > -1) detailError = true;

        if (requiredPanel0Fields.indexOf(item) > -1) this.detailPanelOpenState0 = true;
        if (requiredPanel1Fields.indexOf(item) > -1) this.detailPanelOpenState1 = true;
        if (requiredPanel2Fields.indexOf(item) > -1) this.detailPanelOpenState2 = true;
      });

      if (detailError) {
        this.selectTableIndex = 0;
      } else {
        this.selectTableIndex = 1;
      }
      this.toastr.warning('Please fill out all of the required fields.');
    } else {
      if (isUnsent) {
        this.btnDisabled = true;
        this.booking.bookingStatus = BOOKING_STATUS.Unsent;
        this.saveBookingToBackend();
      } else {
        this.btnDisabled = true;
        if (this.booking.bookedPurchaseOrders.length == 0) {
          this.toastr.warning('Please assign Purchase Order for this booking before sending to buyer.');
          this.btnDisabled = false;
          return;
        }

        const seaPOs = this.booking.bookedPurchaseOrders.filter(p => p.transportMode === 'SEA');
        const airPOs = this.booking.bookedPurchaseOrders.filter(p => p.transportMode === 'AIR');

        let isContainerized = this.form.controls.isContainerized.value;
        if (this.form.controls.transportMode.value === TransportMode.AIR) {
          isContainerized = false;
        }

        if (isContainerized && this.booking.bookedEquipments.length == 0) {
          this.toastr.warning('Please assign Equipment for this booking before sending to buyer.');
          this.btnDisabled = false;
          return;
        }

        let msg = 'Are you sure you want to save this Booking and send to buyer?';
        if (seaPOs.length > 0 && airPOs.length > 0) {
          msg =
            'The POs you have selected contain different transport modes. Are you sure you want to save this Booking and send to buyer?';
        }

        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          width: '400px',
          data: msg,
        });
        confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.booking.bookingStatus = BOOKING_STATUS.AwaitingClient;
            if (seaPOs.length > 0 && airPOs.length > 0) {
              this.booking.multiTransportModeConfirm = true;
            }
            this.saveBookingToBackend();
          } else {
            this.btnDisabled = false;
          }
        });
      }
    }
  }

  public saveBookingToBackend() {
    const saveBooking = new SaveBooking(this.booking);
    saveBooking.bookedPoLines = [];
    this.booking.bookedPurchaseOrders.forEach(po => {
      po.poLines.forEach(pl => {
        saveBooking.bookedPoLines.push({
          purchaseOrderLineId: pl.id,
          quantity: pl.bookingQuantity,
        });
      });
    });
    saveBooking.bookedEquipments = [];
    this.booking.bookedEquipments.forEach(item => {
      saveBooking.bookedEquipments.push({
        id: item.id,
        containerTypeId: item.containerTypeId,
        quantity: item.quantity,
      });
    });
    // handle location id
    if (this.booking.consigneeLocation.id) {
      this.booking.consigneeLocation.id = null;
    }
    if (this.booking.shipperLocation.id) {
      this.booking.shipperLocation.id = null;
    }
    if (this.booking.shipToLocation.id) {
      this.booking.shipToLocation.id = null;
    }
    if (this.booking.pickupLocation.id) {
      this.booking.pickupLocation.id = null;
    }
    this.loading = true;
    this.api.create(saveBooking).subscribe(
      (bookingId: number) => {
        this.loading = false;
        this.dialogRef.close(bookingId);
      },
      _ => {
        this.loading = false;
        this.btnDisabled = false;
      }
    );
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  public panelStateChanged(event: any) {
    if (event.panelIndex === 0) {
      this.detailPanelOpenState0 = event.panelState;
    } else if (event.panelIndex === 1) {
      this.detailPanelOpenState1 = event.panelState;
    } else if (event.panelIndex === 2) {
      this.detailPanelOpenState2 = event.panelState;
    }
  }

  discard(): void {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to discard changes?',
    });
    confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.dialogRef.close(null);
      }
    });
  }

  applyRoutingPreference(event: any, routingGuide: RoutingGuide): void {
    event.stopImmediatePropagation();
    if (this.form.controls.portOfLoading) this.form.controls.portOfLoading.patchValue(routingGuide.pol);
    if (this.form.controls.portOfDischarge) this.form.controls.portOfDischarge.patchValue(routingGuide.pod);
    if (this.form.controls.portOfLoadingTimezone)
      this.form.controls.portOfLoadingTimezone.patchValue(routingGuide.polTimezone);
    if (this.form.controls.portOfDischargeTimezone)
      this.form.controls.portOfDischargeTimezone.patchValue(routingGuide.podTimezone);
    if (this.form.controls.forwarder) this.form.controls.forwarder.patchValue(routingGuide.forwarder);
    if (this.form.controls.contactIds) this.form.controls.contactIds.patchValue(routingGuide.contactIds);
  }

  toggleEquipmentEditor(): void {
    const dRef = this.dialog.open(JobEquipmentEditorComponent, {
      disableClose: true,
      width: '40vw',
      minWidth: '580px',
      maxHeight: '95vh',
      data: { job: this.booking },
    });
    dRef.afterClosed().subscribe((equipment: Equipment[]) => {
      if (equipment) {
        let updatedJob = new Booking(this.booking);

        updatedJob.bookedEquipments = [];
        this.booking = updatedJob;

        setTimeout(() => {
          updatedJob.bookedEquipments = equipment;
          this.booking = updatedJob;
        });
      }
    });
  }

  onTogglePurchaseOrderEditor(): void {
    const currentDialogHeight = this.dialogService.dialogHeight;
    const dRef = this.dialog.open(JobPurchaseOrderEditorComponent, {
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { job: this.booking },
    });
    dRef.afterClosed().subscribe((purchaseOrders: PurchaseOrder[]) => {
      this.dialogService.dialogHeight = currentDialogHeight;
      if (purchaseOrders) {
        let updatedJob = new Booking(this.booking);
        purchaseOrders.forEach(po => {
          po.poLines.forEach(line => {
            getPoLineUnitText(line);
          });
        });
        updatedJob.bookedPurchaseOrders = purchaseOrders;

        // Update booking details using first purchase order added to booking
        const firstPO = updatedJob.bookedPurchaseOrders[0];
        if (!updatedJob.shipper) updatedJob.shipper = firstPO.supplier;
        if (!updatedJob.consignee) updatedJob.consignee = firstPO.consignee;
        if (!updatedJob.manufacturer) updatedJob.manufacturer = firstPO.manufacturer;
        if (!updatedJob.cargoReadyDateValue) {
          updatedJob.cargoReadyDateValue = firstPO.actualCargoReadyDate;
        }
        if (!updatedJob.estimatedDeliveryDateValue) {
          updatedJob.estimatedDeliveryDateValue = firstPO.inDistCenterDateValue;
        }
        if (!updatedJob.incoterm) updatedJob.incoterm = firstPO.incoterm;
        if (!updatedJob.shipperLocation) updatedJob.shipperLocation = firstPO.pickupLocation;
        if (!updatedJob.consigneeLocation) updatedJob.consigneeLocation = firstPO.shipToLocation;
        if (!updatedJob.portOfLoading) updatedJob.portOfLoading = firstPO.polCode;
        if (!updatedJob.portOfDischarge) updatedJob.portOfDischarge = firstPO.podCode;

        this.booking = updatedJob;
        this.form.patchValue(this.booking);
      }
    });
  }

  patchSupplierDefaults(supplierId: number) {
    this.uns.getBusinessRules(supplierId).subscribe(res => {
      if (res) {
        if (!this.form.controls.shipperLocation.value) {
          const defaultShipperLoc = this.shipperLocations.find(l => l.code == res.bookingDefaults.shipperLocationCode);
          if (defaultShipperLoc) this.form.controls.shipperLocation.setValue(defaultShipperLoc);
        }
        if (!this.form.controls.consignee.value) {
          const defaultConsignee = this.omCache.consigneeOrgs.find(c => c.code == res.bookingDefaults.consigneeOrgCode);
          if (defaultConsignee) {
            this.form.controls.consignee.setValue(defaultConsignee, { emitEvent: false });
            this.consigneeLocations = defaultConsignee.locations;
            const defaultShipTo = this.consigneeLocations.find(l => l.code == res.bookingDefaults.shipToLocationCode);
            if (defaultShipTo) this.form.controls.shipToLocation.setValue(defaultShipTo);
            const defaultConsigneeLocation = this.consigneeLocations.find(
              l => l.code == res.bookingDefaults.consigneeLocationCode
            );
            if (defaultConsigneeLocation) this.form.controls.consigneeLocation.setValue(defaultConsigneeLocation);
          }
        }

        if (!this.form.controls.incoterm.value) this.form.controls.incoterm.setValue(res.bookingDefaults.incoterm);
        if (!this.form.controls.portOfLoading.value) this.form.controls.portOfLoading.setValue(res.bookingDefaults.pol);
        if (!this.form.controls.portOfDischarge.value)
          this.form.controls.portOfDischarge.setValue(res.bookingDefaults.pod);

        if (!this.form.controls.manufacturer.value) {
          const defaultManufacturer = this.omCache.manufacturerOrgs.find(
            m => m.code == res.bookingDefaults.manufacturerOrgCode
          );
          if (defaultManufacturer) this.form.controls.manufacturer.setValue(defaultManufacturer);
        }

        const defaultForwarder = this.omCache.forwarderOrgs.find(m => m.code == res.bookingDefaults.forwarderOrgCode);
        if (defaultForwarder) this.form.controls.forwarder.setValue(defaultForwarder);

        if (!this.form.controls.contactIds.value) {
          const defaultForwarderContact = this.omCache.networkContacts.find(
            c => c.id == res.bookingDefaults.forwarderContactId
          );
          if (defaultForwarderContact) this.form.controls.contactIds.setValue([defaultForwarderContact]);
        }

        if (!this.form.controls.pickupLocation.value) {
          const defaultPickup = this.shipperLocations.find(l => l.code == res.bookingDefaults.pickupLocationCode);
          if (defaultPickup) this.form.controls.pickupLocation.setValue(defaultPickup);
        }

        // if (!this.form.controls.customerLocation.value) {
        //   const defaultCustomerLoc = this.customerLocations.find(
        //     l => l.code == res.bookingDefaults.customerLocationCode
        //   );
        //   if (defaultCustomerLoc) this.form.controls.customerLocation.setValue(defaultCustomerLoc);
        // }
      }
    });
  }
}
