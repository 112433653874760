import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkDenyReason } from '@models/networkDenyReason.model';
import { NetworkDenyReasonType } from '@models/helpers';

@Injectable({
  providedIn: 'root',
})
export class NetworkDenyReasonApiService {
  constructor(private httpClient: HttpClient) {}

  public getNetworkDenyReasons(
    networkId: number,
    type: NetworkDenyReasonType,
    includeOther: boolean
  ): Observable<NetworkDenyReason[]> {
    return this.httpClient.get<NetworkDenyReason[]>(
      `${environment.apiBaseURL}DenyReason/${networkId}/${type}?includeOther=${includeOther}`
    );
  }

  public saveNetworkDenyReasons(
    networkId: number,
    type: NetworkDenyReasonType,
    reasons: NetworkDenyReason[]
  ): Observable<any> {
    return this.httpClient.post<NetworkDenyReason[]>(
      `${environment.apiBaseURL}DenyReason/${networkId}/${type}`,
      reasons
    );
  }
}
