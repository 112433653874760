export interface Type {
  code: string;
  description: string;
}

export class Constants {
  public static incoterms: Type[] = [
    { code: 'FOB', description: 'Free on Board' },
    { code: 'FCA', description: 'Free Carrier (Seller is responsible for origin, buyer for loading)' },
    { code: 'FC2', description: 'Free Carrier (Buyer is responsible for origin and loading)' },
    { code: 'FC1', description: 'Free Carrier (Seller is responsible for origin and loading)' },
    { code: 'FAS', description: 'Free Alongside Ship' },
    { code: 'EXW', description: 'Ex Works' },
    { code: 'DPU', description: 'Delivered at Place Unloaded' },
    { code: 'DDP', description: 'Delivered Duty Paid' },
    { code: 'DAT', description: 'Delivered at Terminal' },
    { code: 'DAP', description: 'Delivered at Place' },
    { code: 'CPT', description: 'Carriage Paid To' },
    { code: 'CIP', description: 'Carriage and Insurance Paid to' },
    { code: 'CIF', description: 'Cost, Insurance and Freight' },
    { code: 'CFR', description: 'Cost and Freight' },
  ];

  public static documents: Type[] = [
    { code: 'HBL', description: 'House Waybill/Bill of Lading' },
    { code: 'ISF', description: 'Importer Security Filing' },
    { code: 'CIV', description: 'Commercial Invoice' },
    { code: 'CIE', description: 'Commercial Invoice for Export' },
    { code: 'PKL', description: 'Packing List' },
    { code: 'BKN', description: 'Booking Confirmation' },
    { code: 'VIC', description: 'Client Invoice' },
    { code: 'INV', description: 'Invoice' },
    { code: 'ARN', description: 'Arrival Notice' },
    { code: 'EPR', description: 'Entry Print' },
    { code: 'CAD', description: 'Cartage Advice' },
    { code: 'DOR', description: 'Delivery Order' },
    { code: 'IMD', description: 'Entry/Immediate Delivery - 3461' },
    { code: 'POD', description: 'Proof of Delivery' },
    { code: 'COO', description: 'Certificate of Origin' },
    { code: 'BPL', description: 'Packing List (Customs Purposes)' },
    { code: 'BBL', description: 'Bill of Lading (Customs Purposes)' },
    { code: 'BAN', description: 'Arrival Notice (Customs Purposes)' },
    { code: 'BCI', description: 'Commercial Invoice (Customs Purposes)' },
  ];

  public static routingMoves: Type[] = [
    { code: 'MAI', description: 'Main Vessel' },
    { code: 'PRE', description: 'Pre-Carriage Vessel' },
    { code: 'ONF', description: 'On-Forwarding Vessel' },
    { code: 'OTH', description: 'Other' },
  ];

  public static transportModes: Type[] = [
    { code: 'AIR', description: 'Air Freight' },
    { code: 'SEA', description: 'Sea Freight' },
    { code: 'ROA', description: 'Road Freight' },
    { code: 'RAI', description: 'Rail Freight' },
    { code: 'STO', description: 'Storage' },
    { code: 'IWT', description: 'Inland Waterways' },
  ];
}
