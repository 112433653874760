<div id="app-layout">
  <nav id="app-sidebar">
    <ul class="nav-items">
      <li class="logo-nav-item"><img id="app-logo" src="/assets/images/logo-un.png"></li>
    </ul>
  </nav>

  <div id="app-content">
    <div id="app-menu">
      <div id="upper-menu">
      </div>
    </div>
    <div id="content">
      <div id="main-content">
        <app-page-header>
          <ng-container title>Create Account</ng-container>
        </app-page-header>
        <app-om-card>
          <div class="row" style="padding: 4vh 7vw;">
            <div class="col" style="margin-top: 7vh;">
              <oms-user-password-form [passwordForm]="passwordForm"></oms-user-password-form>
            </div>
            <div class="col">
              <div class="p-3 mb-5 bg-white rounded password-border">
                <div>
                  Password Requirements:
                </div>
                <ul>
                  <li [ngClass]="passwordForm.controls['password'].hasError('minlength')  ? 'invalid-text' : '' ">
                    At least 8 characters</li>
                  <li [ngClass]="passwordForm.controls['password'].hasError('hasNumber')  ? 'invalid-text' : '' ">
                    At least one number</li>
                  <li [ngClass]="passwordForm.controls['password'].hasError('hasLower')  ? 'invalid-text' : '' ">
                    At least one lowercase letter</li>
                  <li [ngClass]="passwordForm.controls['password'].hasError('hasUpper')  ? 'invalid-text' : '' ">
                    At least one uppercase letter</li>
                  <li [ngClass]="passwordForm.controls['password'].hasError('hasSpecial')  ? 'invalid-text' : '' ">
                    At least one special character</li>
                </ul>
              </div>
              <div class="row" style="justify-content: flex-end;">
                <button
                  [disabled]="passwordMismatch || !passwordForm.valid || passwordForm.get('password').value == null"
                  class="btn btn-lg btn-primary" (click)="createPassword()">Save</button>
              </div>
            </div>
          </div>
        </app-om-card>
      </div>
    </div>
  </div>
</div>
