import {Component} from '@angular/core';

@Component({
  selector: 'app-page-header',
  styles: [],
  template: `
  <header class="cf-page-header">
    <h1 class="cf-page-header-text"><ng-content select="[title]"></ng-content></h1>
    <div class="cf-page-header-toolbar"><ng-content select="[toolbar]"></ng-content></div>
  </header>
  `
})
export class PageHeaderComponent {
  constructor() {}
}
