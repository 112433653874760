import { Component } from "@angular/core";

@Component({
    selector:"app-audit-history-header",
    template:`
    <div class="audit-header">
        <ng-content></ng-content>
    </div>  `
})

export class AuditHistoryHeaderComponent {
}