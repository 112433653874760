import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Report,
  ReportSetting,
  ReportSettingColumn,
  UpdateFiltersEvents,
  UserListForReports,
} from '@models/report.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public needFetchReportSettings = false;
  public reportFilterGroups: { name: string; ref: number; filters: any[]; columns: ReportSettingColumn[] }[] = [];
  public reportSortColumnGroups: { name: string; ref: number; sortEnabled: boolean; columns: any[] }[] = [];

  constructor(private httpClient: HttpClient) {}

  public updateFilters(event: UpdateFiltersEvents, type: number): void {
    let filterGroupIndex = this.reportFilterGroups.findIndex(cg => cg.ref === type);

    if (filterGroupIndex > -1) {
      this.reportFilterGroups[filterGroupIndex].filters = event.filterList;

      if (event.needMakeOtherTabsFalse) {
        this.reportFilterGroups.forEach(fg => {
          if (fg.ref !== type && fg.filters.length > 0) {
            fg.filters.forEach(f => {
              f.enabled = false;
            });
          }
        });
      }
    }
  }

  public updateFilterEnabled(type: number, filterIndex: number, checked: boolean, needMakeOtherTabsFalse: boolean) {
    let filterGroupIndex = this.reportFilterGroups.findIndex(cg => cg.ref === type);
    if (filterGroupIndex > -1) {
      this.reportFilterGroups[filterGroupIndex].filters[filterIndex].enabled = checked;

      if (needMakeOtherTabsFalse) {
        this.reportFilterGroups.forEach(fg => {
          if (fg.ref !== type && fg.filters.length > 0) {
            fg.filters.forEach(f => {
              f.enabled = false;
            });
          }
        });
      }
    }
  }

  public buildHumanReadableDateString(valueString: string): string {
    const components = valueString.replace('CALC=', '').split(',');
    const mode = parseInt(components[0]);
    const scope = parseInt(components[1]);
    const modifier = parseInt(components[2]);
    const modifier2 = parseInt(components[3]);

    const weekdayMap = {
      '0': 'SUNDAY',
      '1': 'MONDAY',
      '2': 'TUESDAY',
      '3': 'WEDNESDAY',
      '4': 'THURSDAY',
      '5': 'FRIDAY',
      '6': 'SATURDAY',
    };

    if (mode == 0) {
      if (scope == 0) {
        return 'DAY OF REPORT DATE';
      }

      if (scope == 1) {
        return `${modifier2} DAY(S) AFTER REPORT DATE`;
      }

      if (scope == 2) {
        return `${modifier2} DAY(S) BEFORE REPORT DATE`;
      }
    } else if (mode == 1) {
      if (scope == 0) {
        return `${weekdayMap[modifier2.toString()]} OF REPORT WEEK`;
      }

      if (scope == 1) {
        return `${weekdayMap[modifier2.toString()]} ${modifier} WEEK(S) AFTER REPORT DATE`;
      }

      if (scope == 2) {
        return `${weekdayMap[modifier2.toString()]} ${modifier} WEEK(S) BEFORE REPORT DATE`;
      }
    } else if (mode == 2) {
      if (scope == 0) {
        return `DAY ${modifier2} OF REPORT MONTH`;
      }

      if (scope == 1) {
        return `${modifier} MONTH(S) AFTER REPORT DATE ON DAY ${modifier2}`;
      }

      if (scope == 2) {
        return `${modifier} MONTH(S) BEFORE REPORT DATE ON DAY ${modifier2}`;
      }
    }

    return '';
  }

  public getReports(): Observable<Report[]> {
    return this.httpClient
      .get<Report[]>(`${environment.apiBaseURL}report/getReports`)
      .pipe(map(rs => rs.map(r => new Report(r))));
  }

  public getReport(id: string): Observable<Report> {
    return this.httpClient.get<Report>(`${environment.apiBaseURL}report/getReport/${id}`).pipe(map(r => new Report(r)));
  }

  public saveReport(report: Report): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiBaseURL}report/saveReport`, report);
  }

  public getReportSettings(reportType: number): Observable<ReportSetting[]> {
    const params = new HttpParams().set('reportType', reportType);
    return this.httpClient
      .get<ReportSetting[]>(`${environment.apiBaseURL}report/getReportSettings`, { params: params })
      .pipe(map(rs => rs.map(r => new ReportSetting(r))));
  }

  public downloadReport(reportID: string): any {
    return this.httpClient.get(`${environment.apiBaseURL}report/downloadReport/${reportID}`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public deleteReport(reportID: string | number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiBaseURL}report/deleteReport/${reportID}`);
  }

  public getUserListForReports(): Observable<UserListForReports[]> {
    return this.httpClient.get<UserListForReports[]>(`${environment.apiBaseURL}user/getUserEmails`);
  }
}
