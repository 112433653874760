import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessRulesComponent } from '@modules/customer-network/views/business-rules/business-rules.component';
import { RoutingGuideAdminComponent } from '@modules/customer-network/views/routing-guide-admin/routing-guide-admin.component';
import { UserPasswordComponent } from '@modules/user/views/user-password-creation/password-creation.component';
import { ForbiddenPage } from '@pages/forbidden/forbidden-page.component';
import { NotFoundPage } from '@pages/not-found/not-found-page.component';
import { UnauthorizedPage } from '@pages/unauthorized/unauthorized-page.component';
import { NetworkAdminGuard } from './core/guards/network-admin.guard';
import { OmsFullGuard } from './core/guards/oms-full.guard';
import { OmsLiteGuard } from './core/guards/oms-lite.guard';
import { InviteGuard } from './core/guards/invite.guard';
import { AppLayoutComponent } from './layouts/app/app-layout.component';
import { AuthGuard } from './modules/auth/helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: 'bookings',
        canActivate: [OmsFullGuard],
        loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule),
      },
      {
        path: 'purchase-orders',
        loadChildren: () => import('./modules/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
      },
      {
        path: 'purchase-orders-assignment',
        canActivate: [OmsLiteGuard],
        loadChildren: () =>
          import('./modules/purchase-order/assignment/po-assignment.module').then(m => m.PoAssignmentModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'customer-networks',
        canActivate: [NetworkAdminGuard],
        loadChildren: () =>
          import('./modules/customer-network/customer-network.module').then(m => m.CustomerNetworkModule),
      },
      {
        path: 'business-rules',
        pathMatch: 'full',
        component: BusinessRulesComponent,
      },
      {
        path: 'routing-guide-admin',
        pathMatch: 'full',
        component: RoutingGuideAdminComponent,
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'shipments',
        canActivate: [OmsFullGuard],
        loadChildren: () => import('./modules/shipment/shipment.module').then(m => m.ShipmentModule),
      },
      {
        path: 'containers',
        canActivate: [OmsFullGuard],
        loadChildren: () => import('./modules/container/container.module').then(m => m.ContainerModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule),
      },
      {
        path: 'reports',
        canActivate: [OmsFullGuard],
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'create-password/:inviteToken',
        canActivate: [InviteGuard],
        component: UserPasswordComponent,
      },
      {
        path: 'forbidden',
        component: ForbiddenPage,
      },
      {
        path: 'unauthorized',
        component: UnauthorizedPage,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
