<div id="filters-component">
  <div @slideInOut *ngIf="newFilter" [formGroup]="filterForm" class="pt-4 newFilter">
    <div class="row justify-content-center">
      <!-- Field Selector -->
      <mat-form-field appearance="outline">
        <mat-label>Data Field</mat-label>
        <mat-select (openedChange)="selectInputFilter.setFocus($event)" disableOptionCentering panelClass="fixed-select-panel" formControlName="dataField" (selectionChange)="dataFieldChanged()">
          <app-select-filterable #selectInputFilter (returnFilter)="filterData = $event" [options]="filterFields.fieldList" [displayLabel]="'text'"></app-select-filterable>
          <mat-option *ngFor="let field of filterData" [value]="field">{{ field.text }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <!-- Condition Selector -->
      <mat-form-field appearance="outline">
        <mat-label>Condition</mat-label>
        <mat-select (openedChange)="selectInputFilter2nd.setFocus($event)" disableOptionCentering panelClass="fixed-select-panel" formControlName="condition" (selectionChange)="conditionChanged()">
          <app-select-filterable #selectInputFilter2nd (returnFilter)="filterCondition = $event" [options]="f.dataField.value?.conditions" [displayLabel]="'text'"></app-select-filterable>
          <mat-option *ngFor="let condition of filterCondition" [value]="condition">{{ condition?.text }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <!-- Value 1 Input/Selector -->
      <mat-form-field appearance="outline">
        <mat-label>Value{{ f.condition.value?.input2 ? " 1" : "" }}</mat-label>
        <mat-select *ngIf="f.dataField.value?.conditionType === 'boolean' || f.condition.value?.input1value === true" formControlName="value" disableOptionCentering panelClass="fixed-select-panel">
          <mat-option value="True">True</mat-option>
          <mat-option value="False">False</mat-option>
        </mat-select>
        <input
          *ngIf="f.dataField.value?.conditionType !== 'boolean' && f.condition.value?.input1value && f.condition.value?.input1value !== true"
          matInput
          formControlName="value"
          [type]="f.dataField.value?.conditionType"
        />
        <input
          *ngIf="f.dataField.value?.conditionType !== 'boolean' && !f.condition.value?.input1value"
          matInput
          [pattern]="f.dataField.value?.conditionType === 'number' ? '[0-9]+' : ''"
          formControlName="value"
          [type]="f.dataField.value?.conditionType"
        />
      </mat-form-field>
      <!-- Value 2 Input/Selector -->
      <mat-form-field appearance="outline" [hidden]="!f.condition.value?.input2 && !f.dataField.value?.conditionUnits">
        <mat-label>Value 2</mat-label>
        <mat-select *ngIf="f.dataField.value?.conditionUnits" formControlName="value2" disableOptionCentering panelClass="fixed-select-panel">
          <mat-option *ngFor="let unit of f.dataField.value.conditionUnits" [value]="unit">{{ unit }}</mat-option>
        </mat-select>
        <input
          *ngIf="!f.dataField.value?.conditionUnits && f.condition?.value?.text === 'Days Range'"
          matInput
          placeholder="Days Range; EX: +20 OR -20"
          pattern="(\+|-){1}(\d){1,3}"
          maxlength="4"
          formControlName="value2"
          [type]="'text'"
        />
        <input
          *ngIf="!f.dataField.value?.conditionUnits && f.condition?.value?.text !== 'Days Range'"
          matInput
          [pattern]="f.dataField.value?.conditionType === 'number' ? '[0-9]+' : ''"
          formControlName="value2"
          [type]="f.dataField.value?.conditionType"
        />
      </mat-form-field>
    </div>
    <div class="row justify-content-around">
      <button mat-raised-button id="cancel-new-filter-button" (click)="cancelNewFilter()">Cancel New Filter</button>
      <button
        mat-raised-button
        id="apply-new-filter-button"
        (click)="addFilter()"
        [disabled]="!filterForm.valid || (f.condition.value?.input2 && !f.value2.value)"
      >
        Add Filter
      </button>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
  </div>
  <div class="row">
    <div class="col pl-0">
      <h5>Current {{ this.filterType | titlecase }} Filters</h5>
    </div>
    <div class="col" align="right">
      <button [disabled]="newFilter" mat-raised-button id="add-new-filter-button" (click)="newFilter = true">Add New Filter</button>
    </div>
  </div>
  <div class="row header-row">
    <div class="col-10 pl-0">
      <div class="row">
        <div class="col-4">Data Field</div>
        <div class="col-3">Condition</div>
        <div class="col-3">Value(s)</div>
        <div class="col-2" align="center">Enabled</div>
      </div>
    </div>
    <div class="col-2" align="center">Delete</div>
  </div>
  <div *ngFor="let filter of filters">
    <mat-card class="my-2 p-1 mat-elevation-z3">
      <div class="row filter-row">
        <div class="col-10 my-auto">
          <div class="row">
            <div class="col-4 pl-0 dataField" [matTooltip]="getFieldName(filter.dataField)" matTooltipClass="largerTooltip">
              <span>{{ getFieldName(filter.dataField) }}</span>
            </div>
            <div class="col-3">
              {{ filter.condition }}
            </div>
            <div class="col-3">
              {{ filter.value.replace("*", "").replace("*", "").replace("~", "")
              }}{{ filter.value2 ? "\n" + filter.value2.replace("*", "").replace("*", "").replace("~", "") : "" }}
            </div>
            <div class="col-2" align="center">
              <ui-switch
                id="enabled-switch"
                (changeEvent)="filter.selected = !filter.selected"
                [checked]="filter.selected"
                uncheckedLabel="Disabled"
                checkedLabel="Enabled"
                switchColor="white"
              ></ui-switch>
            </div>
          </div>
        </div>
        <div class="col-2 my-auto" align="center">
          <mat-icon class="deleteFilterIcon" (click)="removeFilter(filter)">highlight_off</mat-icon>
        </div>
      </div></mat-card
    >
  </div>
  <br /><mat-divider></mat-divider><br />
  <div class="row justify-content-center filter-margin-bottom">
    <button mat-raised-button id="apply-selected-filters-button" (click)="applyFilters()">
      Apply Changes to Filters
    </button>
  </div>
</div>
