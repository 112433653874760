import {Component, Input} from '@angular/core';
import { Location } from '@angular/common';
import {Router} from "@angular/router";


@Component({
  selector: 'app-back-button',
  template: `
    <mat-icon style="cursor:pointer;" (click)="goBack()" aria-hidden="false" aria-label="Back to list" fontIcon="arrow_back_ios" class="ml-1"></mat-icon>
  `,
})
export class BackButtonComponent {
  @Input() backUrl: string = '';
  constructor(
    private location: Location,
    private router: Router
  ) {
  }

  goBack(): void {
    if(this.backUrl !== '') {
      this.router.navigate([this.backUrl]);
      return
    }
    this.location.back()
  }
}
