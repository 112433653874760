import {Component, Inject, NgModule} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-options-group',
  templateUrl: './options-group.component.html',
  styleUrls: ['./options-group.component.scss'],
})
export class OptionsGroupComponent {
  public tabIndex = 0;
  public pageType = 'container';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog) {
    this.tabIndex = data.tabIndex;
    this.pageType = data.pageType;
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }
}
