<app-om-dialog [title]="'Routing Guide'" [showFooter]="true" (closeDialog)="cancel()" [loading]="loading">
  <div class="routing-editor-wrapper">
    <div class="re-content">
      <app-loading-indicator *ngIf="loading; else content"></app-loading-indicator>
      <ng-template #content>
        <form [formGroup]="form">
          <div class="row">
            <div class="form-field col-4">
              <app-unloco
                label="POL"
                formControlName="pol"
                [required]="true"
                (unlocoSelected)="selectPOL($event)"
              ></app-unloco>
            </div>
            <div class="form-field col-4">
              <app-unloco
                label="POD"
                formControlName="pod"
                [required]="true"
                (unlocoSelected)="selectPOD($event)"
              ></app-unloco>
            </div>
          </div>
          <div class="row">
            <div class="form-field col-4">
              <div class="row">
                <label class="detail-field-title">Supplier</label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <mat-select disableOptionCentering panelClass="fixed-select-panel" formControlName="supplier" [compareWith]="compareFn" required (openedChange)="selectInputFilter.setFocus($event)">
                    <app-select-filterable #selectInputFilter [options]="suppliers" (returnFilter)="supplierFilter =$event" [displayLabel]="'name'"></app-select-filterable>
                    <mat-option [value]="null">---</mat-option>
                    <mat-option *ngFor="let org of supplierFilter" [value]="org">
                      {{ org.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-field col-4">
              <div class="row">
                <label class="detail-field-title">Forwarder</label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <mat-select disableOptionCentering panelClass="fixed-select-panel" formControlName="forwarder" [compareWith]="compareFn" required (openedChange)="selectInputFilter2nd.setFocus($event)">
                    <app-select-filterable #selectInputFilter2nd [options]="forwarders" (returnFilter)="forwarderFilter =$event" [displayLabel]="'name'"></app-select-filterable>
                    <mat-option [value]="null">---</mat-option>
                    <mat-option *ngFor="let org of forwarderFilter" [value]="org">
                      {{ org.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-field col-4">
              <div class="row">
                <label class="detail-field-title">Contact</label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <mat-select disableOptionCentering panelClass="fixed-select-panel" formControlName="contactIds" multiple [compareWith]="compareContactFn" required (openedChange)="selectInputFilter3rd.setFocus($event)">
                    <app-select-filterable #selectInputFilter3rd [options]="showContacts" (returnFilter)="contactFilter =$event" [displayLabel]="'name'"></app-select-filterable>
                    <mat-option *ngFor="let contact of contactFilter" [value]="contact.id">
                      {{ contact.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
  <ng-container footer>
    <button mat-raised-button color="primary" (click)="cancel()" [disabled]="loading" class="destructive">
      CANCEL
      <mat-icon>do_not_disturb_on</mat-icon>
    </button>
    <button mat-raised-button color="primary" [disabled]="loading" (click)="save()" class="constructive">
      SAVE
      <mat-icon>save</mat-icon>
    </button>
  </ng-container>
</app-om-dialog>
