<div class="justify-content-start nav-row">
  <div class="pointer menu" [ngClass]="{ 'side-closed': !navOpened, selected: currentParentMenu == menuItem.labelName }" (click)="showSubChildrenMenu()">
    <mat-icon [svgIcon]="menuItem.imageName" class="menu-image"></mat-icon>
    <div class="name" [@textDisplay] *ngIf="navOpened">{{ menuItem.labelName }}</div>
    <div class="menu-arrow" [@rotatedState]="state" *ngIf="navOpened && menuItem.showArrow">
      <mat-icon class="menu-image">expand_more</mat-icon>
    </div>
  </div>
  <div [@slideUpDown] class="sub-menu" *ngIf="alwaysShowChildrenMenu || (menuItem.childrenMenu?.length && showChildrenMenu == menuItem.labelName)">
    <ng-container *ngFor="let child of menuItem.childrenMenu">
      <div
        class="sub-menu-item"
        *ngIf="child.enabled"
        [ngClass]="{ 'sub-menu-item-closed': !navOpened, 'sub-menu-item-selected': currentChildMenu == child.labelName }"
        (click)="setCurrentChildMenu(child.labelName, child.externalLink)"
      >
        <div class="sub-menu-item-name" *ngIf="!child.externalLink && navOpened" [@textDisplay] [routerLink]="['/' + child.routeAddress]">
          {{ navOpened ? child.labelName : "" }}
        </div>
        <div class="sub-menu-item-name" *ngIf="child.externalLink && navOpened" [@textDisplay] (click)="openExternalLink(child.routeAddress)">
          {{ navOpened ? child.labelName : "" }}
          <div class="sub-menu-icon" [@textDisplay] *ngIf="child.externalLink && navOpened">
            <mat-icon>launch</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
