import {
  DGWeightUnitDropDown,
  HeightUnitDropDown,
  LengthUnitDropDown,
  QuantityUnitDropDown,
  VolumeUnitDropDown,
  WeightUnitDropDown,
  WidthUnitDropDown,
} from '@models/product.model';
import { PurchaseOrderLine } from '@models/purchase-order-line.model';

export function getPoLineUnitText(line: PurchaseOrderLine) {
  line.weightUnitText = line.weightUnit ? WeightUnitDropDown.find(d => d.value === line.weightUnit).symbol : '';
  line.volumeUnitText = line.volumeUnit ? VolumeUnitDropDown.find(d => d.value === line.volumeUnit).symbol : '';
  line.heightUnitText = line.heightUnit ? HeightUnitDropDown.find(d => d.value === line.heightUnit).symbol : '';
  line.widthUnitText = line.widthUnit ? WidthUnitDropDown.find(d => d.value === line.widthUnit).symbol : '';
  line.lengthUnitText = line.lengthUnit ? LengthUnitDropDown.find(d => d.value === line.lengthUnit).symbol : '';
  line.quantityUnitText = line.quantityUnit ? QuantityUnitDropDown.find(d => d.value === line.quantityUnit).text : '';
  line.dgUwText = line.isDg && line.dgUw ? DGWeightUnitDropDown.find(d => d.value === line.dgUw).symbol : '';
  return line;
}
