import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Column, PreferencesService} from '../../service/preferences.service';

@Component({
  selector: 'app-filter-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent {
  public masterToggle = false;
  constructor(
    public preferencesService: PreferencesService,
    public router: Router
  ) {
    this.masterToggle =
      this.preferencesService.getColumnPreferences().length ===
      this.preferencesService.getColumnPreferences(true).length;
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.preferencesService.getColumnPreferences(), event.previousIndex, event.currentIndex);
    this.preferencesService.saveColumnPreferences();
  }

  public toggleColumn(column: Column, index: number): void {
    column.enabled = !column.enabled;
    if (!column.enabled) {
      moveItemInArray(this.preferencesService.getColumnPreferences(), index, this.preferencesService.getColumnPreferences().length - 1);
    } else {
      const preIndex = this.preferencesService.getColumnPreferences().indexOf(column);
      const curIndex = this.preferencesService.getColumnPreferences(true).length - 1;
      moveItemInArray(this.preferencesService.getColumnPreferences(), preIndex, curIndex);
    }
    this.preferencesService.saveColumnPreferences();
    this.masterToggle =
      this.preferencesService.getColumnPreferences().length ===
      this.preferencesService.getColumnPreferences(true).length;
  }

  public toggleAllColumns(bool: boolean): void {
    this.masterToggle = bool;
    this.preferencesService.getColumnPreferences().filter(column => column.enabled !== bool).forEach(column => {
      column.enabled = bool;
    });
    this.preferencesService.saveColumnPreferences();
  }

  public resetColumns(): void {
    this.preferencesService.overrideColumnsChanges();
  }
}
