import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(true);
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    private lastValue = false;

    constructor() {}

    public setDoneLoading(loadingTf: boolean): void {
        if (this.lastValue && this.lastValue === loadingTf) {
            return;
        }

        this.lastValue = loadingTf;
        this.isDoneLoadingSubject$.next(loadingTf);
    }
}