<h2 mat-dialog-title class="title">{{ title }}</h2>
<mat-dialog-content>
  <div class="content">
    <div *ngIf="title !== 'booked refs'; else bookedRefs">
      <div *ngFor="let item of dataList" class="content-item">
        <a
          href="javascript:void(0)"
          (click)="onExternalHyperlinkClick($event, dataConfig.url, item[dataConfig.idKey])"
          *ngIf="dataConfig.url"
        >
          {{ dataConfig.textKey ? item[dataConfig.textKey] : item }}
        </a>
        <div *ngIf="!dataConfig.url">{{ dataConfig.textKey ? item[dataConfig.textKey] : item }}</div>
      </div>
    </div>
    <ng-template #bookedRefs>
      <div class="booked-header">
        <div class="booked-column">Booking #</div>
        <div class="booked-column">Committed Quantity</div>
      </div>
      <div *ngFor="let item of dataList" class="content-item">
        <div class="booked-column">
          <a
            href="javascript:void(0)"
            (click)="onExternalHyperlinkClick($event, dataConfig.url, item[dataConfig.idKey])"
          >
            {{ dataConfig.textKey ? item[dataConfig.textKey] : item }}
          </a>
        </div>
        <div class="booked-column">
          {{ item.quantity }}
        </div>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-bottom: 0; padding-bottom: 0">
  <button mat-raised-button color="primary" (click)="close()" class="constructive">Close</button>
</mat-dialog-actions>
