import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Job } from '@models/job.model';
import { PurchaseOrder } from '@models/purchase-order.model';
import { PurchaseOrderLineSelectionService } from '@services/purchase-order-line-selection.service';
import { PurchaseOrderService } from '@services/purchaseOrder.service';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { OmTableComponent } from '../../../../shared/components/om-table/om-table.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { getPoLineUnitText } from '../../../../shared/utils/po-lines-unit';
import { e, identity, boolean } from 'mathjs';
import { BookingApiService } from '@services/booking-api.service';
import { DialogService } from '@services/dialog.service';

@Component({
  styleUrls: ['./job-purchase-order-editor.component.scss'],
  template: `
    <app-om-dialog [title]="'Edit Assigned Purchase Order'" [showFooter]="true" (closeDialog)="cancel()">
      <app-loading-indicator *ngIf="loading; else selector"></app-loading-indicator>
      <ng-template #selector>
        <div class="dialog-content">
          <mat-tab-group (selectedTabChange)="onTabChanged($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">check_box</mat-icon>
                ASSIGNED
                <div
                  class="tab-badge"
                  [matBadge]="purchaseOrders.length"
                  matBadgeColor="accent"
                  matBadgeOverlap="false"
                ></div>
              </ng-template>
              <div>
                <app-om-table
                  #selectedPoTable
                  tableKey="job-selected-po-table"
                  [dataSource]="showPurchaseOrders"
                  [templateRef]="selectedNestedTableTemplate"
                  [allowRowExpansion]="true"
                  [allowMultipleRowExpansion]="true"
                  [whiteBackground]="true"
                  [defaultExpanded]="true"
                  [headerOnTop]="true"
                  [containerHeight]="dialogService.dialogHeight - 80"
                  [dataLength]="selectedDataLength"
                  [hidePaginator]="selectedHidePaginator"
                  [pageIndex]="selectedPageNumber"
                  [pageSize]="selectedPageSize"
                  (pageChanged)="changeSelectedPage($event)"
                >
                  <ng-container tableToolbar>
                    <div class="table-toolbar">
                      <div class="filter-box">
                        <div class="filter-search">
                          <app-om-table-search-input
                            [for]="selectedPoTable"
                            [requestSearch]="true"
                            [searchInput]="selectedKeyWord"
                            (inputChanged)="changeSelectedSearch($event)"
                          ></app-om-table-search-input>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #selectedNestedTableTemplate let-element="element">
                    <app-om-table
                      #selectedPoLineTable
                      tableKey="job-selected-po-line-table"
                      [dataSource]="element.poLines"
                      [nested]="true"
                      [allowRowExpansion]="false"
                      [childTable]="true"
                    >
                      <app-om-table-custom-cells-wrapper customCells>
                        <ng-container matColumnDef="bookingQuantity">
                          <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">Booking Qty</th>
                          <td mat-cell *matCellDef="let nestedElement">
                            <ng-container *ngIf="editCache[nestedElement.rowId].edit">
                              <div class="order-div">
                                <input
                                  type="number"
                                  [(ngModel)]="editCache[nestedElement.rowId].data.bookingQuantity"
                                  min="1"
                                  [max]="nestedElement.orderQuantity - nestedElement.committedQuantity"
                                />
                                / {{ nestedElement.orderQuantity - nestedElement.committedQuantity }}
                                <mat-icon class="pointer" color="warn" (click)="cancelRowEdit(nestedElement.rowId)">
                                  close
                                </mat-icon>
                                <mat-icon class="pointer" color="primary" (click)="saveRowEdit(nestedElement.rowId)">
                                  check
                                </mat-icon>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!editCache[nestedElement.rowId].edit">
                              <div class="row order-div">
                                <span class="col">{{ nestedElement.bookingQuantity }}</span>
                                <mat-icon
                                  class="col pointer"
                                  (click)="startRowEdit(nestedElement.rowId)"
                                  color="primary"
                                >
                                  edit
                                </mat-icon>
                              </div>
                            </ng-container>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="actions" stickyEnd>
                          <th mat-header-cell *matHeaderCellDef class="om-table-header external-header"></th>
                          <td mat-cell *matCellDef="let nestedElement" class="external-td">
                            <span class="material-icons-outlined">
                              <mat-icon
                                class="pointer"
                                color="warn"
                                [ngClass]="{ 'remove-disabled': nestedElement.removeDisabled }"
                                (click)="removePurchaseOrderLine($event, nestedElement)"
                              >
                                remove_circle
                              </mat-icon>
                            </span>
                            <span *ngIf="nestedElement.isValidateError">
                              <app-error-info-icon [message]="nestedElement.isValidateMsg"></app-error-info-icon>
                            </span>
                          </td>
                        </ng-container>
                      </app-om-table-custom-cells-wrapper>
                    </app-om-table>
                  </ng-template>
                </app-om-table>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="header-icon">filter_none</mat-icon>
                UNASSIGNED
                <div
                  class="tab-badge"
                  [matBadge]="assignablePurchaseOrders.length"
                  matBadgeColor="accent"
                  matBadgeOverlap="false"
                ></div>
              </ng-template>
              <div>
                <app-om-table
                  #selectablePoTable
                  tableKey="job-po-selector-table"
                  [dataSource]="showAssignablePurchaseOrders"
                  [templateRef]="nestedTableTemplate"
                  [allowRowExpansion]="true"
                  [allowMultipleRowExpansion]="true"
                  [whiteBackground]="true"
                  (selectedElement)="selectWholePurchaseOrder($event)"
                  [defaultExpanded]="true"
                  [headerOnTop]="true"
                  [containerHeight]="dialogService.dialogHeight - 80"
                  [dataLength]="selectableDataLength"
                  [hidePaginator]="selectableHidePaginator"
                  [pageIndex]="selectablePageNumber"
                  [pageSize]="selectablePageSize"
                  (pageChanged)="changeSelectablePage($event)"
                >
                  <ng-container tableToolbar>
                    <div class="table-toolbar">
                      <div class="filter-box">
                        <div class="filter-search">
                          <app-om-table-search-input
                            [for]="selectablePoTable"
                            [requestSearch]="true"
                            [searchInput]="selectableKeyWord"
                            (inputChanged)="changeSelectableSearch($event)"
                          ></app-om-table-search-input>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #nestedTableTemplate let-element="element">
                    <app-om-table
                      #selectablePoLineTable
                      tableKey="job-po-line-selector-table"
                      [dataSource]="element.poLines"
                      [nested]="true"
                      [allowRowExpansion]="false"
                      [tableHeight]="'350px'"
                      [childTable]="true"
                    >
                      <app-om-table-custom-cells-wrapper customCells>
                        <ng-container matColumnDef="actions" stickyEnd>
                          <th mat-header-cell *matHeaderCellDef class="om-table-header external-header"></th>
                          <td mat-cell *matCellDef="let nestedElement" class="external-td">
                            <div class="actions-cell-content-wrapper">
                              <span
                                class="material-icons-outlined pointer"
                                (click)="selectPurchaseOrderLine($event, nestedElement)"
                              >
                                <mat-icon color="primary">add_circle</mat-icon>
                              </span>
                            </div>
                          </td>
                        </ng-container>
                      </app-om-table-custom-cells-wrapper>
                    </app-om-table>
                  </ng-template>
                </app-om-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
      <ng-container footer>
        <button mat-raised-button color="primary" class="destructive" [disabled]="btnLoading" (click)="cancel()">
          Discard Changes
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="send" [disabled]="btnLoading" (click)="confirm()">
          Confirm
          <mat-icon>check</mat-icon>
        </button>
      </ng-container>
    </app-om-dialog>
  `,
})
export class JobPurchaseOrderEditorComponent {
  public loading: boolean = false;
  public purchaseOrders: PurchaseOrder[] = [];
  public assignablePurchaseOrders: PurchaseOrder[] = [];

  public showPurchaseOrders: PurchaseOrder[] = [];
  public showAssignablePurchaseOrders: PurchaseOrder[] = [];
  public quantityThreshold: number;

  public editCache: { [key: string]: { edit: boolean; data: any } } = {};

  public selectedPageNumber = 1;
  public selectedPageSize = 10;
  public selectedKeyWord = '';
  public selectedDataLength = 0;
  public selectedHidePaginator = false;

  public selectablePageNumber = 1;
  public selectablePageSize = 10;
  public selectableKeyWord = '';
  public selectableDataLength = 0;
  public selectableHidePaginator = false;
  public isEditBooking: boolean = false;
  public btnLoading: boolean = false;
  public needValidateBookedPurchaseOrder: boolean = false;

  @ViewChild(OmTableComponent) private omTableComponent: OmTableComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { job: Job },
    public dialogService: DialogService,
    private dialogRef: MatDialogRef<JobPurchaseOrderEditorComponent>,
    private purchaseOrderService: PurchaseOrderService,
    private purchaseOrderLineSelectionService: PurchaseOrderLineSelectionService,
    private userNetworkService: UserNetworkApiService,
    private bookingApi: BookingApiService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    const booking = this.data.job;
    if (booking) {
      this.loading = true;
      this.purchaseOrderService.getBookingOrderLineSelectorData(booking.id).subscribe(
        data => {
          this.purchaseOrders = data.purchaseOrders;
          this.assignablePurchaseOrders = data.assignablePurchaseOrders;

          // select the po lines that were already part of the booking
          const [selectedPurchaseOrders, assignablePurchaseOrders] =
            this.purchaseOrderLineSelectionService.initialPurchaseOrder(
              booking.bookedPurchaseOrders,
              this.purchaseOrders,
              this.assignablePurchaseOrders
            );
          this.purchaseOrders = selectedPurchaseOrders;
          this.assignablePurchaseOrders = assignablePurchaseOrders;

          this.assignablePurchaseOrders.forEach((item: any) => {
            item.isExpanded = true;
            item.poLines.forEach((item: any) => {
              item.rowId = uuidv4();
              getPoLineUnitText(item);
            });
          });

          // add them to the edit cache
          this.purchaseOrders.forEach((item: any) => {
            item.isExpanded = true;
            item.poLines.forEach((item: any) => {
              item.rowId = uuidv4();
              getPoLineUnitText(item);
              this.editCache[item.rowId] = {
                edit: false,
                data: { ...item },
              };
            });
          });

          this.getSelectablePurchaseOrder();
          this.getSelectedPurchaseOrder();
          this.loading = false;
        },
        error => {
          this.toastr.error(error);
          this.dialogRef.close();
        }
      );
      if (booking.shipper) {
        this.userNetworkService.getBusinessRules(booking.shipper.id).subscribe(res => {
          if (res) {
            this.quantityThreshold = res.quantityShip;
          }
        });
      }
    }
  }

  public onTabChanged(event: MatTabChangeEvent) {
    this.omTableComponent.refresh();
  }

  public selectPurchaseOrderLine(event: any, element: any) {
    event.stopImmediatePropagation();

    this.addToEditCache(element);
    const [selectedPurchaseOrders, assignablePurchaseOrders] =
      this.purchaseOrderLineSelectionService.selectPurchaseOrderLine(
        this.purchaseOrders,
        this.assignablePurchaseOrders,
        element
      );
    this.purchaseOrders = selectedPurchaseOrders;
    this.assignablePurchaseOrders = assignablePurchaseOrders;
    this.selectedHidePaginator = true;
    this.selectableHidePaginator = true;
    this.getSelectablePurchaseOrder();
    this.selectedKeyWord = '';
    this.getSelectedPurchaseOrder();
    const seaPOs = this.purchaseOrders.filter(p => p.transportMode === 'SEA');
    const airPOs = this.purchaseOrders.filter(p => p.transportMode === 'AIR');
    if (seaPOs.length > 0 && airPOs.length > 0) {
      this.toastr.warning('You have selected multiple transport mode of purchase orders');
    }
  }

  public selectWholePurchaseOrder(data: { event: any; element: any }) {
    if (data.element.poLines.length > 0) {
      const polines = data.element.poLines.slice();
      polines.forEach(item => {
        this.selectPurchaseOrderLine(data.event, item);
      });
    }
  }

  public removePurchaseOrderLine(event: any, element: any) {
    event.stopImmediatePropagation();
    if (element.removeDisabled) {
      return;
    }

    this.removeFromEditCache(element.rowId);
    const [assignablePurchaseOrders, selectedPurchaseOrders] =
      this.purchaseOrderLineSelectionService.removePurchaseOrderLine(
        this.assignablePurchaseOrders,
        this.purchaseOrders,
        element
      );
    this.purchaseOrders = selectedPurchaseOrders;
    this.assignablePurchaseOrders = assignablePurchaseOrders;
    this.selectableKeyWord = '';
    this.selectedHidePaginator = true;
    this.selectableHidePaginator = true;
    this.getSelectablePurchaseOrder();
    this.getSelectedPurchaseOrder();
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public async confirm(): Promise<void> {
    this.btnLoading = true;
    const keys = Object.keys(this.editCache);
    let isExistEdit = false;
    keys.forEach(k => {
      if (this.editCache[k].edit) isExistEdit = true;
    });

    if (isExistEdit) {
      const dialogEditRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: 'There are unsaved booking qty, continue or not?',
      });

      dialogEditRef.afterClosed().subscribe(async (confirmed: boolean) => {
        if (confirmed) {
          this.validateBookByDate();
        } else {
          this.btnLoading = false;
        }
      });
    } else {
      this.validateBookByDate();
    }
  }

  public startRowEdit(rowId: string): void {
    this.editCache[rowId].edit = true;
  }

  public cancelRowEdit(rowId: string): void {
    this.editCache[rowId].edit = false;
  }

  public saveRowEdit(rowId: string): void {
    const newQuantity = this.editCache[rowId].data.bookingQuantity;
    const poId = this.editCache[rowId].data.purchaseOrderId;
    const poIndex = this.purchaseOrders.findIndex((item: any) => item.id === poId);
    const polIndex = this.purchaseOrders[poIndex].poLines.findIndex((item: any) => item.rowId === rowId);
    Object.assign(this.purchaseOrders[poIndex].poLines[polIndex], this.editCache[rowId].data);
    this.editCache[rowId].edit = false;
  }

  private addToEditCache(purchaseOrderLine: any): void {
    purchaseOrderLine.bookingQuantity = purchaseOrderLine.balanceQuantity;
    this.editCache[purchaseOrderLine.rowId] = {
      edit: false,
      data: { ...purchaseOrderLine },
    };
  }

  private removeFromEditCache(rowId: string): void {
    delete this.editCache[rowId];
  }

  public changeSelectedSearch(event: any) {
    this.selectedHidePaginator = true;
    this.selectedPageNumber = 1;
    this.selectedKeyWord = event;
    this.getSelectedPurchaseOrder();
  }

  public changeSelectedPage(page: any) {
    this.selectedPageNumber = page.pageIndex;
    this.selectedPageSize = page.pageSize;
    this.getSelectedPurchaseOrder();
  }

  public getSelectedPurchaseOrder() {
    let start = 0;
    let end = 0;
    start = (this.selectedPageNumber - 1) * this.selectedPageSize;
    end = this.selectedPageNumber * this.selectedPageSize;
    this.selectedDataLength = this.purchaseOrders.length;
    if (end > this.selectedDataLength) {
      end = this.selectedDataLength;
    }

    const tmp = this.purchaseOrders.filter(po =>
      po.poNumber.toLowerCase().includes(this.selectedKeyWord.toLowerCase())
    );

    this.showPurchaseOrders = tmp.slice(start, end);
    this.selectedHidePaginator = false;
  }

  public changeSelectableSearch(event: any) {
    this.selectableHidePaginator = true;
    this.selectablePageNumber = 1;
    this.selectableKeyWord = event;
    this.getSelectablePurchaseOrder();
  }

  public changeSelectablePage(page: any) {
    this.selectablePageNumber = page.pageIndex;
    this.selectablePageSize = page.pageSize;
    this.getSelectablePurchaseOrder();
  }

  public getSelectablePurchaseOrder() {
    let start = 0;
    let end = 0;
    start = (this.selectablePageNumber - 1) * this.selectablePageSize;
    end = this.selectablePageNumber * this.selectablePageSize;
    this.selectableDataLength = this.assignablePurchaseOrders.length;
    if (end > this.selectableDataLength) {
      end = this.selectableDataLength;
    }
    const tmp = this.assignablePurchaseOrders.filter(po =>
      po.poNumber.toLowerCase().includes(this.selectableKeyWord.toLowerCase())
    );
    this.showAssignablePurchaseOrders = tmp.slice(start, end);
    this.selectableHidePaginator = false;
  }

  private async validateBookByDate() {
    const posWithPastBookByDate = this.purchaseOrders
      .filter(po => po.bookByDate != null && new Date(po.bookByDate).getTime() < new Date().getTime())
      .map(po => po.poNumber);

    if (posWithPastBookByDate.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data:
          'The Book-By Dates for the following POs have already passed: \n \n ' +
          posWithPastBookByDate.toString() +
          '\n' +
          '\n Are you sure you want to add them?',
      });

      dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
        if (confirmed) {
          const val = await this.validateBookedPurchaseOrder();
          this.btnLoading = false;
          if (val) {
            this.dialogRef.close(this.purchaseOrders);
          }
        }
      });
    } else {
      const val = await this.validateBookedPurchaseOrder();
      this.btnLoading = false;
      if (val) {
        this.dialogRef.close(this.purchaseOrders);
      }
    }
  }

  private async validateBookedPurchaseOrder(): Promise<boolean> {
    if (this.needValidateBookedPurchaseOrder) {
      const poQtyArr = [];
      this.purchaseOrders.forEach(po => {
        po.poLines.forEach(l => {
          l.isValidateError = false;
          if (l.id) {
            poQtyArr.push({
              purchaseOrderLineId: l.id,
              quantity: l.bookingQuantity,
            });
          }
        });
      });
      if (poQtyArr.length > 0) {
        const res = await this.bookingApi.validateBookedPurchaseOrder(this.data.job.id, poQtyArr);
        if (!res || res.length == 0) {
          return true;
        } else {
          res.forEach(r => {
            const po = this.purchaseOrders.find(po => po.id === r.poId) as any;
            po.isExpanded = true;
            const pol = po.poLines.find(l => l.id === r.poLineId);
            pol.isValidateError = true;
            pol.isValidateMsg = r.message;
          });
          this.toastr.warning('Validation failed');
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
