import { Environment } from './environment.model';

export const environment = new Environment({
  production: true,
  apiBaseURL: 'https://oms-dev.clearfreight.com/api/',
  clearViewApi: 'https://clearview-dev.clearfreight.com/api/api/',
  clearViewUrl: 'https://clearview-dev.clearfreight.com/',
  home: 'https://oms-dev.clearfreight.com/',
  issuerUri: 'https://sso-dev.clearfreight.com',
  clientId: 'order_management_system_spa',
  responseType: 'code',
  scope: 'IdentityServerApi offline_access',
  azureBlobConfig: {
    azureBlobUrl: 'https://clrfwebservicestorage.blob.core.windows.net',
    containerShipmentDocName: 'clearview-dev-shipmentdocument',
  },
});
