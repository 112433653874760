

<div [formGroup]="passwordForm">      
        <div class="col" style="display: flex; white-space: nowrap;">
           <div class="col-5 vertical-align-label">
             <mat-label>New Password</mat-label>
           </div>
           <mat-form-field appearance="outline">
             <input type = 'password' matInput formControlName="password" required>
           </mat-form-field>
       </div>
 
       <div class="col mt-4" style="display: flex; white-space: nowrap;">
           <div class="col-5 vertical-align-label">
             <mat-label>Confirm Password</mat-label>
           </div>
           <mat-form-field appearance="outline">
             <input type = 'password' matInput formControlName="confirmPassword" required>
           </mat-form-field>
       </div>
 </div>
 
 
   