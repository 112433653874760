<div>
  <div class="title-text">
    {{ title }}
  </div>
  <div class="col-3">
    <div class="logo-container row justify-content-center">
      <img class="logo" [src]="logoUrl" />
    </div>
  </div>
  <div class="col-3 row justify-content-center mt-3">
    <button mat-raised-button color="primary" (click)="openUploadIconDialog($event)" [disabled]="loading">
      Upload {{ title }}
      <mat-icon>upload</mat-icon>
    </button>
  </div>
  <div class="col-3 row justify-content-center mt-3">
    <p class="prompt-text">Recommend to upload a square image, best dimensions is 200*200</p>
  </div>
</div>
