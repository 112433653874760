export class Report {
  public id: string; // If !ID, new report; else, existing
  public networkId: number;
  public reportName: string;
  public deliverEmpty: boolean; //Deliver if empty report
  public reportType: number;
  public scheduled: boolean;
  public definition: ReportDataDefinition;

  constructor(init: any = {}) {
    this.id = init.id ? init.id : null;
    this.networkId = init.networkId ? init.networkId : null;
    this.reportName = init.reportName ? init.reportName : '';
    this.deliverEmpty = init?.deliverEmpty ? init.deliverEmpty : false;
    this.reportType = init.reportType ? init.reportType : null;
    this.scheduled = init?.scheduled ? init.scheduled : false;
    if (init?.definition) this.definition = new ReportDataDefinition(init.definition);
  }
}

export class ReportDataDefinition {
  public date: Date;
  public frequency: number; // Daily, Weekly, etc
  public selectedWeekdays: string[];
  public time: string; // 5PM, 9AM ...
  public timezone: any; // UTC, EST
  public cron: string;
  public recipients: string[];
  public columnSettings: ReportDataColumns[];
  public filterSettings: ReportDataFilters[];

  constructor(init: any = {}) {
    this.date = init.date ? init.date : null;
    this.frequency = init.frequency;
    this.selectedWeekdays = init.selectedWeekdays;
    this.time = init.time;
    this.timezone = init.timezone;
    this.cron = init.cron;
    this.recipients = init.recipients;
    this.columnSettings = init.columnSettings?.map(c => new ReportDataColumns(c)) || [];
    this.filterSettings = init.filterSettings?.map(f => new ReportDataFilters(f)) || [];
  }
}

export class ReportDataColumns {
  public category: number;
  public columns: ColumnInfo[];
  public sortColumns: ColumnSortInfo[];

  constructor(init: any = {}) {
    this.category = init.category;
    this.columns = init.columns?.map(c => new ColumnInfo(c)) || [];
    this.sortColumns = init.sortColumns?.map(s => new ColumnSortInfo(s)) || [];
  }
}

export class ColumnInfo {
  public name: string;
  public order: number;

  constructor(init: any = {}) {
    this.name = init.name;
    this.order = init.order;
  }
}

export class ColumnSortInfo {
  public name: string;
  public order: number;
  public sortByAsc: boolean;

  constructor(init: any = {}) {
    this.name = init.name;
    this.order = init.order;
    this.sortByAsc = init.sortByAsc;
  }
}

export class ReportDataFilters {
  public category: number;
  public filters: ReportFilter[];

  constructor(init: any = {}) {
    this.category = init.category;
    this.filters = (init.filters || init.filters)?.map(f => new ReportFilter(f)) || [];
  }
}

export class ReportSetting {
  public category: ReportCategory;
  public title: string;
  public sortEnabled: boolean;
  public columns: ReportSettingColumn[];

  constructor(init: any = {}) {
    if (init?.category) this.category = init.category;
    if (init?.title) this.title = init.title;
    this.sortEnabled = init.sortEnabled;
    this.columns = init.columns?.map(c => new ReportSettingColumn(c)) || [];
  }
}

export class ReportSettingColumn {
  public name: string;
  public title: string;
  public dataType: ReportColumnDataType;
  public isFilterable: boolean;
  public options: ReportSettingColumnOptions[];
  public enabled: boolean;
  public sortEnabled: boolean;
  public sortByAsc: boolean;
  public conditions?: ReportFieldCondition[];

  constructor(init: any = {}) {
    if (init?.name) this.name = init.name;
    if (init?.title) this.title = init.title;
    if (init?.dataType !== undefined && init?.dataType !== null) this.dataType = init.dataType;
    this.isFilterable = init?.isFilterable ? init.isFilterable : false;
    this.enabled = init?.enabled ? init.enabled : false;
    this.sortEnabled = init?.sortEnabled ? init.sortEnabled : false;
    this.sortByAsc = init?.sortByAsc ? init.sortByAsc : false;
    this.conditions = init?.conditions ? init.conditions : [];

    this.options = init.options?.map(o => new ReportSettingColumnOptions(o)) || [];
  }
}

export class ReportSettingColumnOptions {
  public key: string;
  public value: string;

  constructor(init: any = {}) {
    if (init?.key) this.key = init.key;
    if (init?.value) this.value = init.value;
  }
}

export class ReportFilter {
  public id?: number;
  public enabled?: boolean;
  public title?: string;
  public field: string;
  public condition: string;
  public value: string;
  public value2: string;
  public values?: string[];
  public valuesStr?: string;

  constructor(init?: any) {
    Object.assign(this, init);
  }
}

/**
 * @prop {string} text - Text to be displayed in the dropdown selector
 * @prop {string} shortCode - Short code used in elastic queries
 * @prop {boolean} input1value - Used to override dropdown with given value
 * @prop {boolean} input2 - Enables/disables second value field
 * @prop {boolean} enableCalculation - show the calculation option (needed for dates that can be null or eeeempty).
 */
export class ReportFieldCondition {
  public text: string;
  public shortCode: string;
  public input1value?: string | boolean | number;
  public input1: boolean;
  public input2: boolean;
  public numberOnly?: boolean;
  public dateOnly?: boolean;
}

/**
 * @prop {string} stringConditions - Static property used to fill condition dropdown for string comparisons
 * @prop {string} nonStringConditions - Static property used to fill condition dropdown for numerical & date comparisons
 * @prop {boolean} booleanConditions - Static property used to fill condition dropdown for boolean comparisons
 */
export class ReportFilterField {
  public static readonly stringConditions: ReportFieldCondition[] = [
    {
      text: 'Is empty or null',
      shortCode: 'em',
      input1value: true,
      input1: true,
      input2: false,
    },
    {
      text: 'Is not empty or null',
      shortCode: 'nem',
      input1value: true,
      input1: true,
      input2: false,
    },
    {
      text: 'Contains',
      shortCode: 'cn',
      input1: true,
      input2: false,
    },
    {
      text: 'Does Not Contain',
      shortCode: 'ncn',
      input1: true,
      input2: false,
    },
    {
      text: 'Starts with',
      shortCode: 'sqs',
      input1: true,
      input2: false,
    },
    {
      text: 'Ends with',
      shortCode: 'eqs',
      input1: true,
      input2: false,
    },
    {
      text: 'Is exactly',
      shortCode: 'ie',
      input1: true,
      input2: false,
    },
    {
      text: 'Fuzzy',
      shortCode: 'fqs',
      input1: true,
      input2: false,
    },
  ];

  public static readonly nonStringConditions: ReportFieldCondition[] = [
    { text: 'Is equal to', shortCode: 'eq', input1: true, input2: false },
    { text: 'Is not equal to', shortCode: 'neq', input1: true, input2: false },
    { text: 'Is greater than', shortCode: 'gt', input1: true, input2: false },
    { text: 'Is greater than or equal to', shortCode: 'gte', input1: true, input2: false },
    { text: 'Is less than', shortCode: 'lt', input1: true, input2: false },
    { text: 'Is less than or equal to', shortCode: 'lte', input1: true, input2: false },
    { text: 'Is between', shortCode: 'bt', input1: true, input2: true },
    { text: 'Is not between', shortCode: 'nbt', input1: true, input2: true },
    { text: 'Is empty or null', shortCode: 'em', input1value: true, input1: true, input2: false },
    { text: 'Is not empty or null', shortCode: 'nem', input1value: true, input1: true, input2: false },
  ];

  public static readonly booleanConditions: ReportFieldCondition[] = [
    { text: 'Is', shortCode: 'is', input1: true, input2: false },
  ];

  public static readonly dropdownConditions: ReportFieldCondition[] = [
    { text: 'Contains', shortCode: 'cn', input1: true, input2: false },
    { text: 'Does Not Contain', shortCode: 'ncn', input1: true, input2: false },
  ];

  public fieldList: ReportSettingColumn[];

  public static formatDate(date: Date): string {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }
}

export class ReportTemplate {
  public id: string;
  public reportName: string;
  public deliverEmpty: boolean;
  public lastReportDate: string;
  public cron: string;
  public reportType: string;
  public nextReport: string;
  public reportFileType: string;
  public scheduled: boolean;

  constructor(init?: Partial<ReportTemplate>) {
    Object.assign(this, init);
    if (!this.id) this.id = this.reportName;
    //  if(init.reportType) this.reportType = (<any[]>init.reportType).map(rt => typeof rt === 'number' ? reportTypeMap[rt] : rt);
  }
}

export const reportTypeMap = {
  0: 'Sea',
  1: 'Air',
  2: 'Container',
};
export class Column {
  public name: string;
  public enabled = true;
}

export class UserListForReports {
  public firstName: string;
  public lastName: string;
  public email: string;

  constructor(init?: Partial<UserListForReports>) {
    Object.assign(this, init);
  }
}

export enum ReportType {
  PurchaseOrders = 1,
  Bookings = 2,
  Products = 3,
  Shipments = 4,
  ASN = 5,
}

export enum ReportTypeDescription {
  PurchaseOrders = 'Purchase Orders',
  Bookings = 'Bookings',
  Products = 'Products',
  Shipments = 'Shipments',
  ASN = 'ASN',
}

export enum Frequency {
  Once = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Quarterly = 4,
  Yearly = 5,
}

export enum ReportCategory {
  None = 0,
  PurchaseOrder = 1,
  PurchaseOrderLine = 2,
  Booking = 3,
  Shipment = 4,
}

export enum ReportColumnDataType {
  String = 0,
  Int = 1,
  Decimal = 2,
  Boolean = 3,
  Date = 4,
  Dropdown = 5,
  ValueWithUnit = 6,
}

export function getFilterColumns(columns: ReportSettingColumn[]): ReportSettingColumn[] {
  const newColumns = columns.filter(c => c.isFilterable);
  newColumns.forEach(field => {
    switch (field.dataType) {
      case ReportColumnDataType.Date:
        field.conditions = ReportFilterField.nonStringConditions.filter(fc => !fc.numberOnly);
        break;
      case ReportColumnDataType.Int:
      case ReportColumnDataType.Decimal:
      case ReportColumnDataType.ValueWithUnit:
        field.conditions = ReportFilterField.nonStringConditions.filter(fc => !fc.dateOnly);
        break;
      case ReportColumnDataType.Boolean:
        field.conditions = ReportFilterField.booleanConditions;
        break;
      case ReportColumnDataType.Dropdown:
        field.conditions = ReportFilterField.dropdownConditions;
        break;
      case ReportColumnDataType.String:
      default:
        field.conditions = ReportFilterField.stringConditions;
        break;
    }
  });
  return newColumns;
}

export function getInputTypeFromModel(dataType: number, conditionType: string): string {
  let type = '';
  switch (dataType) {
    case ReportColumnDataType.Int:
    case ReportColumnDataType.Decimal:
    case ReportColumnDataType.ValueWithUnit:
      type = 'number';
      if (conditionType === 'em' || conditionType === 'nem') {
        type = 'text';
      }
      break;
    case ReportColumnDataType.Boolean:
    case ReportColumnDataType.Dropdown:
    case ReportColumnDataType.Date:
    case ReportColumnDataType.String:
      type = 'text';
      break;
    default:
      type = 'text';
      break;
  }
  return type;
}

export function getFrequencyTypeName(type: number): string {
  let name = '';
  switch (type) {
    case Frequency.Once:
      name = 'Once';
      break;
    case Frequency.Daily:
      name = 'Daily';
      break;
    case Frequency.Weekly:
      name = 'Weekly';
      break;
    case Frequency.Monthly:
      name = 'Monthly';
      break;
    case Frequency.Quarterly:
      name = 'Quarterly';
      break;
    case Frequency.Yearly:
      name = 'Yearly';
      break;
    default:
      name = 'Unknown';
      break;
  }
  return name;
}

export function getConditionTextByShortCode(shortcode: string): string {
  let text = '';
  switch (shortcode) {
    case 'em':
      text = 'Is empty or null';
      break;
    case 'nem':
      text = 'Is not empty or null';
      break;
    case 'cn':
      text = 'Contains';
      break;
    case 'ncn':
      text = 'Does Not Contain';
      break;
    case 'sqs':
      text = 'Starts with';
      break;
    case 'eqs':
      text = 'Ends with';
      break;
    case 'fqs':
      text = 'Fuzzy';
      break;
    case 'ie':
      text = 'Is exactly';
      break;
    case 'eq':
      text = 'Is equal to';
      break;
    case 'neq':
      text = 'Is not equal to';
      break;
    case 'gt':
      text = 'Is greater than';
      break;
    case 'gte':
      text = 'Is greater than or equal to';
      break;
    case 'lt':
      text = 'Is less than';
      break;
    case 'lte':
      text = 'Is less than or equal to';
      break;
    case 'bt':
      text = 'Is between';
      break;
    case 'nbt':
      text = 'Is not between';
      break;
    case 'is':
      text = 'Is';
      break;
    default:
      text = 'Unknown';
      break;
  }

  return text;
}

export interface UpdateFiltersEvents {
  filterList: ReportFilter[];
  needMakeOtherTabsFalse: boolean;
}
