import * as moment from 'moment';
import { HttpRequest } from '@angular/common/http';

export function dateFormat(req: HttpRequest<any>): HttpRequest<any> {
  if (req.body) {
    for (let key in req.body) {
      if (req.body[key] instanceof Date && key.substring(key.length - 5) === 'Value') {
        req.body[key.substr(0, key.length - 5)] = moment(req.body[key]).format('l HH:mm');
      }
    }
  }
  return req;
}

export function formatHistoryValue(value: string) {
  if (value && value !== 'null') {
    value = value.replaceAll('"', '');
    if (value) {
      let result = moment(value).format('l HH:mm');
      return `\"${result}(UTC)\"`;
    }
  }
  return value;
}
