import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Alert } from "@models/alert.model";
import { AlertService } from "@services/alert.service";
import { PurchaseOrderService } from "@services/purchaseOrder.service";
import {formatHistoryValue} from "src/app/shared/utils/date-format";

@Component({
	selector: 'app-om-po-query-alert',
	template: `
        <app-om-alert [alert]="alert">
            <div class="row">
                <div class="col alert-link-col">
                    Purchase Order #:
                    <a routerLink="/app/purchase-orders/{{alert.key}}" class="alert-link-text">{{alert.displayName}}</a>
                </div>
            </div>
            <ng-container *ngFor="let change of alert.content.changes">
                <span class="row ml-3" [innerHtml]="displayMessage(change)"></span>
            </ng-container>
            <div class="row">
                <div class="col-md-6 offset-md-8 text-center">
                    <button mat-raised-button color="primary" (click)="approveChanges(alert.key, alert.id)" class="constructive">APPROVE</button>
                </div>
            </div>
        </app-om-alert>
    `,
	styleUrls: ['./po-query-alert.component.scss'],
})
export class PoQueryAlertComponent implements OnInit {
	@Input() alert: Alert;
    @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

	constructor(public purchaseOrderService: PurchaseOrderService, public alertService: AlertService) { }

	ngOnInit() {

	}

	displayMessage(change: any): string {
		if (change) {
            let oldValue = change.oldValue;
            let newValue = change.newValue;
            if (change.valueType.includes("time")) {
                oldValue = formatHistoryValue(change.oldValue);
                newValue = formatHistoryValue(change.newValue);
            }
            return change.prefix?.bold()+"&nbsp;|&nbsp;"+change.columnName?.bold()+"&nbsp; changed from &nbsp;"+
            oldValue?.bold()+"&nbsp; to &nbsp;"+newValue?.bold();
		} 
		return '';
	}

    approveChanges(purchaseOrderId: number, alertId: number): void {
        this.purchaseOrderService.consigneeApprove(purchaseOrderId).subscribe(_ => {
            this.alertService.markAlert(alertId);
        })
    }
}
