import {Component, Input, OnInit} from '@angular/core';
import {PurchaseOrderService} from "@services/purchaseOrder.service";
import {BookingApiService} from "@services/booking-api.service";
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';
import {formatHistoryValue} from "src/app/shared/utils/date-format";

@Component({
  selector: 'app-om-audit-history',
  styleUrls: ['./audit-history.component.scss'],
  template: `
    <app-om-card>
      <app-om-card-content>
        <div style="max-height: 450px; overflow-y: auto">
          <div *ngFor="let messageHeader of groupedMessages; index as i">
            <app-audit-history-header (click)="messageHeader.show = !messageHeader.show">
              <span class="title"> {{ booking ? "Booking" : "PO" }} {{messageHeader.isCreated ? "Created": "Revised"}} </span>
              <div class="row">
                <span class="title-item">{{ messageHeader.userFullName }}</span>
                <span class="title-item">{{ messageHeader.timestamp | date: 'HH:mm M/d/yyyy'}}</span>
                <span>( {{messageHeader.data.length}} )</span>
                <div class="icon-wrap" [ngClass]="{'flip': messageHeader.show}">
                  <mat-icon class="dropdown-arrow" svgIcon="down-arrow"></mat-icon>
                </div>
              </div>
            </app-audit-history-header>
            <div *ngFor="let message of messageHeader.data; index as j">
              <app-audit-history-content *ngIf="messageHeader.show">
                <span style="margin-left: 20px" [innerHtml]="displayMessage(messageHeader,j)"></span>
              </app-audit-history-content>
            </div>
          </div>
          <div *ngIf="auditMessages && auditMessages.length < 1" class="audit-message">
            <p>No history to show</p>
          </div>
          </div>
      </app-om-card-content>
    </app-om-card>
  `
})
export class AuditHistoryComponent implements OnInit {
  @Input() primaryKey: number;
  @Input() booking: boolean = false;
  groupedMessages: any[] = [];

  auditMessages: any[] = [];

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private bookingService: BookingApiService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer )
  {
    this.matIconRegistry.addSvgIcon("down-arrow",this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svgs/keyboard_arrow_down.svg"));
  }

  ngOnInit() {
    if (this.booking) {
      this.bookingService.bookingHistory(this.primaryKey).subscribe(res => {
        this.auditMessages = res;
        this.groupMessages();
      });
    } else {
      this.purchaseOrderService.getPurchaseOrderHistory(this.primaryKey).subscribe(res => {
        this.auditMessages = res;
        this.groupMessages();
      });
    }
  }

  displayMessage(message: any, index : number): string{
    if (message) {
      let oldValue = message.data[index].oldValue;
      let newValue = message.data[index].newValue;
      if (message.data[index].valueType.includes("time")) {
        oldValue = formatHistoryValue(message.data[index].oldValue);
        newValue = formatHistoryValue(message.data[index].newValue);
      }
      if (oldValue === "null") {
        if (message.data[index].columnName === "DELETE DATE") {
          return message.data[index].prefix?.bold() + " deleted.";          
        }
        return message.data[index].prefix?.bold()+" | "+message.data[index].columnName?.bold()+" added: ".italics()+
        newValue?.bold()
      }
      return message.data[index].prefix?.bold()+" | "+message.data[index].columnName?.bold()+" field changed from ".italics()+
      oldValue?.bold()+" to ".italics()+newValue?.bold();
    }
    return '';
  }

  groupMessages(){
    var showAmount = 0;
    this.auditMessages.forEach((message: any)=>{
      var key = message.timestamp + "-" + message.isCreated.toString();

      if(!this[key] ){
        this[key] = {
          timestamp: message.timestamp,
          userFullName: message.userFullName,
          isCreated: message.isCreated,
          show: showAmount < 2? true : false,
          data: []
        };
        showAmount++;
        this.groupedMessages.push(this[key]);
      }
      this[key].data.push({
        oldValue: message.oldValue,
        newValue: message.newValue,
        columnName: message.columnName,
        prefix: message.prefix,
        valueType: message.valueType
      });

    });
  }
}
