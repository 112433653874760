import { Component, Input, OnInit, Self } from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, NgControl } from "@angular/forms";
import { Observable } from "rxjs";
import { CWOrg } from "@models/cw-org.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { MatOption } from "@angular/material/core";

@Component({
  selector: 'app-om-organization-selector',
  styles: [''],
  template: `
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>{{label}}</mat-label>
      <input type="text"
            [placeholder]="placeholderLabel"
            matInput
            [formControl]="filterControl"
            [matAutocomplete]="cwOrgAuto"
            (blur)="onTouched()">
      <mat-autocomplete #cwOrgAuto="matAutocomplete"
                        [panelWidth]="'max-content'"
                        [displayWith]="displayFn"
                        (optionSelected)="handleSelection($event.option)">
        <mat-option *ngFor="let option of filteredCWOrgOptions$ | async" [value]="option">
          {{option.name}} ({{option.code}})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class OrganizationSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() public label = '';
  @Input() public placeholderLabel = '';
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public onChange: Function;
  public onTouched: Function;
  public disabled: boolean;

  public filteredCWOrgOptions$: Observable<CWOrg[]>;

  public value: any;

  constructor(@Self() public controlDir: NgControl, private http: HttpClient) {
    controlDir.valueAccessor = this;
  }

  ngOnInit() {
    const control = this.controlDir.control;
    let validators = control.validator ? [control.validator] : null;
    control.setValidators(validators);
    control.updateValueAndValidity();

    this.filteredCWOrgOptions$ = this.filterControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((filter) => (!!filter && filter.length > 2)),
      switchMap(filter => this.getCWOrganizations(filter))
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string | any): void {
    if (typeof value == "string") {
      this.getCWOrgByCode(value).subscribe(org => {
        if (org) {
          this.value = org;
          this.onChange(this.value);
          this.filterControl.setValue(this.value);
        } else {
          this.value = null;
        }
      }, () => {
        this.value = null;
      });
    } else {
      this.value = value;
      this.filterControl.setValue(this.value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.filterControl.disable();
    } else {
      this.filterControl.enable();
    }
  }

  public displayFn(value: string | any): string {
    if (typeof value == "string") {
      return value;
    } else {
      return (!value || !value.name) ? null : value.name;
    }
  }

  private getCWOrganizations(filter: string): Observable<CWOrg[]> {
    return this.http
      .get<CWOrg[]>(`${environment.apiBaseURL}Organization?filter=${filter}`)
      .pipe(map((organizations: any[]) => organizations.map(org => new CWOrg(org))));
  }

  private getCWOrgByCode(code: string): Observable<CWOrg> {
    return this.http
      .get<CWOrg>(`${environment.apiBaseURL}Organization/${code}`)
      .pipe(map((org: any) => new CWOrg(org)));
  }

  public handleSelection(event: MatOption) {
    const value = event.value;
    this.value = value;
    this.onChange(value);
  }
}
