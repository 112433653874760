import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Column } from './column.model';
import { UpdateColumns } from './column-settings.actions';

export class ColumnStateModel {
  public value: ColumnValueStateModel;
}

export class ColumnValueStateModel {
  public columns: Column[];
  public version: string;
}

export const DEFAULT_INVOICE = {
  columns: [
    { accessor: 'orderRef', name: 'Order Ref', enabled: true },
    { accessor: 'productCode', name: 'Product Code', enabled: true },
    { accessor: 'lineNumber', name: 'Line #', enabled: true },
    { accessor: 'description', name: 'Description', enabled: true },
    { accessor: 'tariff', name: 'Tariff', enabled: true },
    { accessor: 'invoiceQuantity', name: 'Invoice QTY', enabled: true },
    { accessor: 'customsQty', name: 'Customs QTY', enabled: true },
    { accessor: 'linePrice', name: 'Line Price', enabled: true },
    { accessor: 'duty', name: 'Duty', enabled: true },
    { accessor: 'provDuty', name: 'Prov Duty', enabled: true },
    { accessor: 'mpf', name: 'MPF', enabled: true },
    { accessor: 'hmf', name: 'HMF', enabled: true },
    { accessor: 'invoiceNumber', name: 'INVOICE #', enabled: false },
    { accessor: 'importer', name: 'Importer', enabled: false },
    { accessor: 'supplier', name: 'Supplier', enabled: false },
    { accessor: 'houseBill', name: 'HBL', enabled: false },
    { accessor: 'shipment', name: 'Shipment', enabled: false },
    { accessor: 'vessel', name: 'Vessel', enabled: false },
    { accessor: 'voyage', name: 'Voyage', enabled: false },
    { accessor: 'declarationRef', name: 'Declaration Ref', enabled: false },
    { accessor: 'scac', name: 'SCAC', enabled: false },
    { accessor: 'masterBill', name: 'MBL', enabled: false },
    { accessor: 'weight', name: 'Weight', enabled: false },
    { accessor: 'volume', name: 'Volume', enabled: false },
    { accessor: 'submittedDate', name: 'Submitted Date', enabled: false },
    { accessor: 'incoterm', name: 'INCO Term', enabled: false },
    { accessor: 'consolidation', name: 'Consolidation', enabled: false },
    { accessor: 'transportMode', name: 'Transport Mode', enabled: false },
    { accessor: 'entryNumber', name: 'Entry Number', enabled: false },
    { accessor: 'add', name: 'ADD', enabled: false },
    { accessor: 'addNumber', name: 'ADD Number', enabled: false },
    { accessor: 'addRate', name: 'ADD Rate', enabled: false },
    { accessor: 'cvd', name: 'CVD', enabled: false },
    { accessor: 'cvdNumber', name: 'CVD Number', enabled: false },
    { accessor: 'cvdRate', name: 'CVD Rate', enabled: false },
  ],
};

@State<ColumnStateModel>({
  name: 'InvoiceColumnsState',
  defaults: {
    value: {
      columns: DEFAULT_INVOICE.columns,
      version: InvoiceColumnsState.version,
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class InvoiceColumnsState {
  public static readonly valuekey = 'InvoiceColumnsState';
  public static readonly version = '20240820182157';
  constructor() {}

  @Selector()
  public static getColumnPreferences(state: ColumnStateModel): ColumnValueStateModel {
    return state.value;
  }

  @Action(UpdateColumns)
  updateColumns(ctx: StateContext<ColumnStateModel>, action: UpdateColumns) {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      ...action.payload,
    });
  }
}
