import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-not-found-page',
    styles: [''],
    template: `
    <app-static-page-layout>
        <app-om-card>
            <app-om-card-header>
                <ng-container title>404 Not Found</ng-container>
            </app-om-card-header>
            <app-om-card-content>
                <p>We're sorry we could not find the page you are looking for.</p>
                <button mat-raised-button color="primary" (click)="returnHome()"><mat-icon>home</mat-icon> Return Home</button>
            </app-om-card-content>
        </app-om-card>
    </app-static-page-layout>
    `
})
export class NotFoundPage {
    constructor(private router: Router) {}

    public returnHome(): void {
      this.router.navigateByUrl("app");
    }
}