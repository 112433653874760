// This model maps to the DataAccess.DbCWOrganization 
// on the API.
export class CWOrg {
  public name: string;
  public code: string;
  public cwId: string;

  constructor(init?: Partial<CWOrg>) {
    if (init?.name) this.name = init.name;
    if (init?.code) this.code = init.code;
    if (init?.cwId) this.cwId = init.cwId;
  }
}
