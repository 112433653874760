<form [formGroup]="form" class="edit-section" style="padding-left: 15px">
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Transport Mode</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="autoApprovedTransportMode" disableOptionCentering panelClass="fixed-select-panel" (selectionChange)="onTransportModeSelect($event)">
            <mat-option [value]="autoApprovedTransportMode.None">None</mat-option>
            <mat-option [value]="autoApprovedTransportMode.All">All</mat-option>
            <mat-option [value]="autoApprovedTransportMode.AIR">Air</mat-option>
            <mat-option [value]="autoApprovedTransportMode.SEA">Sea</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2" *ngIf="showContainerizedType">
      <div class="row">
        <label class="detail-field-title">Is Containerized Type</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="autoApprovedContainerizedType" disableOptionCentering panelClass="fixed-select-panel">
            <mat-option [value]="autoApprovedContainerizedType.None">None</mat-option>
            <mat-option [value]="autoApprovedContainerizedType.All">All</mat-option>
            <mat-option [value]="autoApprovedContainerizedType.Yes">Yes</mat-option>
            <mat-option [value]="autoApprovedContainerizedType.No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
