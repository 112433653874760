export interface ISaveFileData {
  parent: number;
  parentId: number;
  fileId: number;
  documentType: string;
}

export class DocumentDownloadRequest {
  public documentList: DocumentList[];

  constructor(init?: Partial<DocumentDownloadRequest>) {
    Object.assign(this, init);
  }
}

export class DocumentList {
  public id: number;
  public DBPartition: number;
  public type: string;
  public name: string;
}

export enum DocumentParent {
  None = 0,
  PurchaseOrder = 1,
  Booking = 2,
}

export enum SyncStatus {
  InProgress = 0,
  Success = 1,
  Failure = 2,
  Done = 3,
}

export enum InvoiceFileStatus {
  New = 0,
  InProgress = 1,
  Failure = 2,
  Done = 3,
}

export enum DocumentSource {
  Default = 0,
  InvoiceFile = 1,
  CargoWise = 2,
}

export interface DocumentFile {
  id?: number;
  fileId: number;
  fileName: string;
  userId: number;
  userName: string;
  receivedDate: string;
  syncStatus: SyncStatus;
  source: number;
  errorLog: string;
  canDownload: boolean;
  cwDocumentId: number;
  invoiceFileStatus: InvoiceFileStatus;
  fileStorageType: number;
  documentType: string;
  url: string;
  dbPartition: number;
  isSystemGenerated: boolean;
  selected?: boolean;
}

export class ShipmentDocument {
  id?: string;
  name: string;
  type: string;
  receivedDate: string;
  isSystemGenerated: boolean;
  userId: string;
  userName: string;
  fileId: number;
  syncStatus: SyncStatus;
  errorLog: string;
  canDownload: boolean;
  selected?: boolean;
  dbPartition?: number;

  constructor(init?: Partial<ShipmentDocument>) {
    Object.assign(this, init);
  }
}

export interface DocumentUrl {
  fileId: number;
  url: string;
}

export class ShipmentDocumentDownloadRequest {
  public documentList: ShipmentDocumentList[];

  constructor(init?: Partial<ShipmentDocumentDownloadRequest>) {
    Object.assign(this, init);
  }
}

export class ShipmentDocumentList {
  public id: string;
  public DBPartition: number;
  public type: string;
  public name: string;
}

export interface ISaveShipmentFileData {
  orgId: string;
  shipmentId: string;
  shipmentNumber: string;
  name: string;
  originalName?: string;
  size?: number;
  fileStorageType: number;
  description?: string;
  documentType: string;
}
