import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocumentDownloadRequest,
  DocumentFile,
  DocumentUrl,
  ISaveFileData,
  ISaveShipmentFileData,
  ShipmentDocument,
  ShipmentDocumentDownloadRequest,
} from '@models/document.model';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  public get getHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
  }

  public isUploadingDoc: boolean = false;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  /**
   * save document information to backend
   */
  public saveFilesStorage(data: ISaveFileData[]): Observable<number> {
    const requestURL = `${environment.apiBaseURL}document/saveDocuments`;
    const res = this.http.post<number>(requestURL, data, { headers: this.getHeaders });
    return res;
  }

  public syncFile (data: DocumentFile): Observable<number> {
    const requestURL = `${environment.apiBaseURL}Document/${data.id}/syncEAdaptor?source=${data.source}`;
    const res = this.http.get<number>(requestURL, { headers: this.getHeaders });
    return res;
  }

  public getDocumentsList(parent: number, parentId: number): Observable<DocumentFile[]> {
    const requestURL = `${environment.apiBaseURL}document/getDocuments/${parent}/${parentId}`;
    const res = this.http.get<DocumentFile[]>(requestURL, { headers: this.getHeaders });
    return res;
  }

  /**
   * download cargowise document api
   */
  public getDocuments(request: DocumentDownloadRequest): any {
    return this.http.post(`${environment.apiBaseURL}ClearView/getDocuments/`, request, { responseType: 'arraybuffer' });
  }

  /**
   * save shipment document information to backend
   */
  public saveShipmentFileStorage(data: ISaveShipmentFileData): Observable<number> {
    const requestURL = `${environment.apiBaseURL}ClearView/saveDocument`;
    const res = this.http.post<number>(requestURL, data, { headers: this.getHeaders });
    return res;
  }

  /**
   * download cargowise shipment document api
   */
  public getShipmentDocuments(request: ShipmentDocumentDownloadRequest): any {
    return this.http.post(`${environment.apiBaseURL}ClearView/getDocuments/`, request, { responseType: 'arraybuffer' });
  }

  /**
   * get shipment document list
   */
  public getShipmentDocument(shipmentId: string): Observable<ShipmentDocument[]> {
    return this.http.get<ShipmentDocument[]>(
      `${environment.apiBaseURL}ClearView/getShipmentDocuments?shipmentId=${shipmentId}`
    );
  }

  public getFileUrls(fileIds: number[]): Observable<DocumentUrl[]> {
    const data = {
      fileIds,
    };
    return this.http.post<DocumentUrl[]>(`${environment.apiBaseURL}ClearView/getFileUrls`, data);
  }
}
