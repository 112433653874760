import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-forbidden-page',
    styles: [''],
    template: `
    <app-static-page-layout>
        <app-om-card>
            <app-om-card-header>
                <ng-container title>403 Forbidden</ng-container>
            </app-om-card-header>
            <app-om-card-content>
                <p>It appears you do not have permission to view this page. Please contact your administrator if you think this is a mistake.</p>
                <button mat-raised-button color="primary" (click)="returnHome()"><mat-icon>home</mat-icon> Return Home</button>
            </app-om-card-content>
        </app-om-card>
    </app-static-page-layout>
    `
})
export class ForbiddenPage {
    constructor(private router: Router) {}

    public returnHome(): void {
      this.router.navigateByUrl("app");
    }
}