import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/modules/auth/services/user.service';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { ListFilter } from '@models/filter';
import { PurchaseOrderService } from '@services/purchaseOrder.service';
import { GetPurchaseOrders, PurchaseOrder} from '@models/purchase-order.model';
import * as FileSaver from 'file-saver';
import {PageOrgMode, PurchaseOrderStatus} from '@models/helpers';
import {getDownloadFileName} from 'src/app/shared/utils/download';

@Injectable({providedIn: 'root'})
export class PurchaseOrderBrowserService {
  protected _tableLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get tableLoading$(): Observable<boolean> {
    return this._tableLoading.asObservable();
  }

  public poTable = []
  public supplierDefault = []
  public purchaseOrders: any = {
    [PurchaseOrderStatus.AwaitingClient]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.AwaitingSupplier]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.Rejected]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.Accepted]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.Cancelled]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.Booked]: {
      result: [],
      page: 1
    },
    [PurchaseOrderStatus.ForwarderAssigned]: {
      result: [],
      page: 1
    }
  };
  public statusCounts: any = {};
  public pageNumber = 1;
  public pageSize = 10;
  public sortField = '';
  public sortByDescending = false;
  public currentPoStatus = null;
  public keyWord = '';
  public dataLength = 0;
  public hidePaginator = false;
  public downloadLoading = false;
  public refreshSub: Subscription = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private purchaseOrderService: PurchaseOrderService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
  }

  resetDefault(): void {
   this.supplierDefault = []
  }

  refreshTableData(): void {
    if (!this._tableLoading.getValue()) this._tableLoading.next(true);
    if (this.refreshSub) this.refreshSub.unsubscribe();
    this.poTable = []
    const params = this.getParams(false);
    if (this.pageNumber)
      if (this.purchaseOrders[this.currentPoStatus.toString()].page !== this.pageNumber || this.purchaseOrders[this.currentPoStatus.toString()].pageSize !== this.pageSize) {
        this.purchaseOrders[this.currentPoStatus.toString()].results = []
      } else {
        this.poTable = this.purchaseOrders[this.currentPoStatus.toString()].result;
        this.dataLength = this.purchaseOrders[this.currentPoStatus.toString()].dataLength;
        this._tableLoading.next(false);
        this.hidePaginator = false;
        return
      }
    if (this.userService.orgMode === PageOrgMode.Lite) {
      this.refreshSub = forkJoin({
        poRes: this.purchaseOrderService.getPurchaseOrders(params).pipe(take(1)),
      }).subscribe(
        ({poRes}) => {
          poRes.results.forEach(item => {
            item = new PurchaseOrder(item);
          });
          poRes.results.forEach((i: any) => {
            i.isExpanded = false;
          });
          this.initCache(poRes)
          this.dataLength = poRes.recordCount;
          this.poTable = poRes.results;
          this._tableLoading.next(false);
          this.hidePaginator = false;
        },
        _ => {
          this._tableLoading.next(false);
          this.hidePaginator = false;
        }
      );
    } else {
      this.refreshSub = forkJoin({
        poRes: this.purchaseOrderService.getPurchaseOrders(params).pipe(take(1)),
        statusCounts: this.purchaseOrderService.getPurchaseOrderStatusCount(this.keyWord).pipe(take(1)),
      }).subscribe(
        ({poRes, statusCounts}) => {
          this.statusCounts = statusCounts;
          poRes.results.forEach(item => {
            item = new PurchaseOrder(item);
          });
          poRes.results.forEach((i: any) => {
            i.isExpanded = false;
          });
          this.initCache(poRes)
          this.dataLength = poRes.recordCount;
          this.poTable = poRes.results;
          this._tableLoading.next(false);
          this.hidePaginator = false;
        },
        _ => {
          this._tableLoading.next(false);
          this.hidePaginator = false;
        }
      );
    }
  }

  initCache(poRes: GetPurchaseOrders) {
    this.purchaseOrders[this.currentPoStatus.toString()].result = poRes.results;
    this.purchaseOrders[this.currentPoStatus.toString()].page = this.pageNumber;
    this.purchaseOrders[this.currentPoStatus.toString()].pageSize = this.pageSize;
    this.purchaseOrders[this.currentPoStatus.toString()].dataLength = poRes.recordCount;
  }

  public initialData(): void {
    this.purchaseOrders = {
      [PurchaseOrderStatus.AwaitingClient]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.AwaitingSupplier]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.Rejected]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.Accepted]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.Cancelled]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.Booked]: {
        result: [],
        page: 1
      },
      [PurchaseOrderStatus.ForwarderAssigned]: {
        result: [],
        page: 1
      }
    };
  }

  public downloadPurchaseOrders(event: any, bookingId: number = 0): void {
    const params = this.getParams(false, bookingId);
    params.columnConfigs = event;
    this.downloadLoading = true;
    this.purchaseOrderService.downloadPurchaseOrders(params).subscribe(
      res => {
        let filename = getDownloadFileName('purchaseOrders.xlsx', res);
        this.downloadLoading = false;
        FileSaver.saveAs(new Blob([res.body], { type: res.type }), filename);
      },
      err => {
        this.downloadLoading = false;
      }
    );
  }

  private getParams(ignoreKeyWord, bookingId: number = 0) {
    const data = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortField: this.sortField,
      sortByDescending: this.sortByDescending,
      filter: {},
    };
    if (!ignoreKeyWord) {
      data['keyWord'] = this.keyWord;
    }
    if (bookingId) {
      data['filter']['bookingId'] = bookingId;
    } else {
      data['filter']['poStatus'] = this.currentPoStatus;
    }
    return new ListFilter(data);
  }
}
