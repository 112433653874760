import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

// tslint:disable: no-switch-case-fall-through


export class Column {
  public name: string;
  public enabled = true;

  constructor(init?: Partial<Column>) {
    Object.assign(this, init);
  }
}

export class ColumnPreferences {
  public container: Column[];
  public airShipment: Column[];
  public seaShipment: Column[];
  public roaShipment: Column[];
  public raiShipment: Column[];
  public couShipment: Column[];
  public versionKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  public imports = true;
  public columnPreferences: ColumnPreferences;
  public activeFreightPageLimit = 25;
  public versionKey = '20250317724';

  constructor(private router: Router) {
    const storedColPref = JSON.parse(
      localStorage.getItem('columnPreferences')
    );

    const columnPreferences = this.generateNewColumnPreferences('all');

    if (storedColPref && storedColPref.versionKey === this.versionKey) {
      const prefKeys = Object.keys(columnPreferences);
      prefKeys.forEach(key => {
        const enabledArr = [];
        const disabledArr = [];

        if(storedColPref[key] && storedColPref[key].forEach){
          storedColPref[key].forEach(col => {
            if(columnPreferences[key]){
            const foundIndex = columnPreferences[key].findIndex(c => c.name === col.name);
              if (foundIndex > -1) {
                if (col.enabled) {
                  enabledArr.push(col);
                } else {
                  disabledArr.push(col);
                }
              }
            }
          });
        }

        if(columnPreferences[key] && columnPreferences[key].forEach){
          columnPreferences[key].forEach(col => {
            if(storedColPref[key]){
              const foundIndex = storedColPref[key].findIndex(c => c.name === col.name);
              if (foundIndex === -1) {
                if (col.enabled) {
                  enabledArr.push(col);
                } else {
                  disabledArr.push(col);
                }
              }
            }
          });
        }
        columnPreferences[key] = enabledArr;
        columnPreferences[key].push(...disabledArr);
      });

      if(!columnPreferences.roaShipment || columnPreferences.roaShipment.length === 0){
        columnPreferences.roaShipment = this.generateNewColumnPreferences('all').roaShipment;
      }

      if(!columnPreferences.raiShipment || columnPreferences.raiShipment.length === 0){
        columnPreferences.raiShipment = this.generateNewColumnPreferences('all').raiShipment;
      }
      columnPreferences.versionKey = storedColPref.versionKey;
    } else {
      columnPreferences.versionKey = this.versionKey;
    }

    this.columnPreferences = columnPreferences;

    this.saveColumnPreferences();
  }

  public overrideColumnsChanges(): void {
    const columnPreferences = this.generateNewColumnPreferences('all');

    if (this.router.url.includes('container')) {
      this.columnPreferences.container = columnPreferences.container;
    } else if (this.router.url.includes('air')) {
      this.columnPreferences.airShipment = columnPreferences.airShipment;
    } else if (this.router.url.includes('sea')) {
      this.columnPreferences.seaShipment = columnPreferences.seaShipment;
    } else if (this.router.url.includes('roa')) {
      this.columnPreferences.roaShipment = columnPreferences.roaShipment;
    } else if (this.router.url.includes('rai')) {
      this.columnPreferences.raiShipment = columnPreferences.raiShipment;
    } else if (this.router.url.includes('cou')) {
      this.columnPreferences.couShipment = columnPreferences.couShipment;
    }

    this.saveColumnPreferences();
  }

  public getColumnPreferences(filter?: boolean): Column[] {
    let returnArray: Column[] = [];
    if (this.router.url.includes('container')) {
      returnArray = this.columnPreferences.container;
    } else if (this.router.url.includes('air')) {
      returnArray = this.columnPreferences.airShipment;
    } else if (this.router.url.includes('sea')) {
      returnArray = this.columnPreferences.seaShipment;
    } else if (this.router.url.includes('roa')) {
      returnArray = this.columnPreferences.roaShipment;
    } else if (this.router.url.includes('rai')) {
      returnArray = this.columnPreferences.raiShipment;
    } else if (this.router.url.includes('cou')) {
      returnArray = this.columnPreferences.couShipment;
    }

    if (filter != null) {
      return returnArray.filter(column => column.enabled === filter);
    } else {
      return returnArray;
    }
  }

  public getColumnNamePreferences(filter?: boolean): string[] {
    const isSea = this.router.url.includes('sea');
    const isAir = this.router.url.includes('air');
    const isRoa = this.router.url.includes('roa');
    const isRai = this.router.url.includes('rai');
    const isCou = this.router.url.includes('cou');
    const isContainer = this.router.url.includes('container');
    const returnArray: string[] = [];

    if (isSea || isAir || isRoa || isRai || isCou){
      if (filter){
        returnArray.push('Order Refs');
        returnArray.push('Priority');
      }
      if (isSea) {
        if (filter) {
          this.columnPreferences.seaShipment.filter(column => column.enabled === filter).forEach(item => {
            returnArray.push(item.name);
          });
        } else {
          this.columnPreferences.seaShipment.forEach(item => {
            returnArray.push(item.name);
          });
        }
      } else if (isAir) {
        if (filter) {
          this.columnPreferences.airShipment.filter(column => column.enabled === filter).forEach(item => {
            returnArray.push(item.name);
          });
        } else {
          this.columnPreferences.airShipment.forEach(item => {
            returnArray.push(item.name);
          });
        }
      } else if (isRoa) {
        if (filter) {
          this.columnPreferences.roaShipment.filter(column => column.enabled === filter).forEach(item => {
            returnArray.push(item.name);
          });
        } else {
          this.columnPreferences.roaShipment.forEach(item => {
            returnArray.push(item.name);
          });
        }
      } else if (isRai) {
        if (filter) {
          this.columnPreferences.raiShipment.filter(column => column.enabled === filter).forEach(item => {
            returnArray.push(item.name);
          });
        } else {
          this.columnPreferences.raiShipment.forEach(item => {
            returnArray.push(item.name);
          });
        }
      } else if (isCou) {
        if (filter) {
          this.columnPreferences.couShipment.filter(column => column.enabled === filter).forEach(item => {
            returnArray.push(item.name);
          });
        } else {
          this.columnPreferences.couShipment.forEach(item => {
            returnArray.push(item.name);
          });
        }
      }
    } else if (isContainer) {
      if (filter) {
        returnArray.push('Order Refs');
        this.columnPreferences.container.filter(column => column.enabled === filter).forEach(item => {
          returnArray.push(item.name);
        });
      } else {
        this.columnPreferences.container.forEach(item => {
          returnArray.push(item.name);
        });
      }
    }
    return returnArray;
  }

  public saveColumnPreferences(): void {
    localStorage.setItem(
      'columnPreferences',
      JSON.stringify(this.columnPreferences)
    );
  }

  public generateNewColumnPreferences(type: 'all' | 'container' | 'airShipment' | 'seaShipment' | 'roaShipment' | 'raiShipment' | 'couShipment', setTrue?: boolean): ColumnPreferences {
    if (setTrue === undefined) {
      setTrue = true;
    }
    const returnObject = new ColumnPreferences();
    switch (type) {
      case 'container':
      case 'all':
        returnObject.container = this.getContainerColumns(setTrue);
      case 'seaShipment':
      case 'all':
        returnObject.seaShipment = this.getShipmentColumns(setTrue);
      case 'airShipment':
      case 'all':
        returnObject.airShipment = this.getShipmentColumns(setTrue);
      case 'roaShipment':
      case 'all':
        returnObject.roaShipment = this.getShipmentColumns(setTrue);
      case 'raiShipment':
      case 'all':
        returnObject.raiShipment = this.getShipmentColumns(setTrue);
      case 'couShipment':
      case 'all':
        returnObject.couShipment = this.getShipmentColumns(setTrue);
    }
    return returnObject;
  }

  private getContainerColumns(setTrue?: boolean): Array<Column> {
    return [
      new Column({name: 'Container Number', enabled: setTrue}),
      new Column({name: 'Status', enabled: setTrue}),
      new Column({name: 'Shipper', enabled: setTrue}),
      new Column({name: 'Consignee', enabled: setTrue}),
      new Column({name: 'ETD', enabled: setTrue}),
      new Column({name: 'Origin/Dest', enabled: setTrue}),
      new Column({name: 'ETA', enabled: setTrue}),
      new Column({name: 'Master Bill', enabled: setTrue}),
      new Column({name: 'House Bill', enabled: setTrue}),
      new Column({name: 'Shipment Number', enabled: setTrue}),
      new Column({name: 'Shippers Ref', enabled: setTrue}),
      new Column({name: 'Container Type', enabled: setTrue}),
      new Column({name: 'Total Packages', enabled: setTrue}),
      new Column({name: 'Total Volume', enabled: setTrue}),
      new Column({name: 'Total Weight', enabled: setTrue}),
      new Column({name: 'Vessel', enabled: setTrue}),
      new Column({name: 'Origin Gate In Date', enabled: setTrue}),
      new Column({name: 'Origin Estimated Departure', enabled: setTrue}),
      new Column({name: 'Onboard', enabled: setTrue}),
      new Column({name: 'Origin Actual Departure', enabled: setTrue}),
      //new Column({ name: 'Estimated Full Delivery', enabled: setTrue }),
      new Column({name: 'Wharf Gate Out', enabled: setTrue}),
      new Column({name: 'Actual Full Delivery', enabled: setTrue}),
      new Column({name: 'Empty Return Date', enabled: setTrue}),
    ];
  }

  private getShipmentColumns(setTrue?: boolean): Array<Column> {
    return [
      new Column({name: 'Status', enabled: setTrue}),
      new Column({name: 'ETD', enabled: setTrue}),
      new Column({name: 'Origin/Dest', enabled: setTrue}),
      new Column({name: 'ETA', enabled: setTrue}),
      new Column({name: 'Container Number', enabled: setTrue}),
      new Column({name: 'House Bill', enabled: setTrue}),
      new Column({name: 'Shipment Number', enabled: setTrue}),
      new Column({name: 'Shippers Ref', enabled: setTrue}),
      new Column({name: 'Shipper', enabled: setTrue}),
      new Column({name: 'Consignee', enabled: false}),
      new Column({name: 'B/L No.', enabled: false}),
      new Column({name: 'Origin ETD', enabled: false}),
      new Column({name: 'Origin ATD', enabled: false}),
      new Column({name: 'Total Packages', enabled: false}),
      new Column({name: 'Total Volume', enabled: false}),
      new Column({name: 'Total Weight', enabled: false}),
      new Column({name: 'Estimated Full Delivery', enabled: false}),
      new Column({name: 'Actual Full Delivery', enabled: false}),
      new Column({name: 'Chargeable', enabled: false}),
      new Column({name: 'Vessel/Flight', enabled: false})
    ];
  }
}
