import { Component, Input } from '@angular/core';
import { Alert } from '@models/alert.model';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-om-alert',
  styleUrls: ['./alert.component.scss'],
  template: `
    <mat-card class="container p-0 pt-1 inset-mat-card mb-2">
      <div class="row pt-2 alert-box-title-row">
        <div class="col">
          <span class="red-dot"></span>
          <span class="ml-3 alert-box-title-text">{{ alert.metadata.header }}</span>
        </div>
        <div class="col text-right">
          <span class="alert-box-date">{{ alert.createDate }}</span>
          <span
            [matMenuTriggerFor]="menu"
            class="material-icons menu-icon"
            [ngClass]="menuOpen ? 'menu-icon-active' : 'menu-icon-inactive'"
            (menuOpened)="menuOpen = true"
            (menuClosed)="menuOpen = false"
          >
            more_horiz
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="markAlert()">Mark as read</button>
          </mat-menu>
        </div>
      </div>
      <div class="alert-content">
        <ng-content></ng-content>
      </div>
    </mat-card>
  `,
})
export class AlertComponent {
  @Input() alert: Alert;

  menuOpen: boolean = false;

  constructor(public alertService: AlertService) {}

  markAlert() {
    this.alertService.markAlert(this.alert.id);
  }
}
