import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule } from '@modules/auth/auth.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { TablePreferenceState } from './state/om-table.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AppLayoutComponent } from './layouts/app/app-layout.component';
import { StaticPageLayout } from './layouts/static-page/static-page-layout.component';
import { SharedModule } from './shared/shared.module';
import { NotFoundPage } from '@pages/not-found/not-found-page.component';
import { ForbiddenPage } from '@pages/forbidden/forbidden-page.component';
import { UnauthorizedPage } from '@pages/unauthorized/unauthorized-page.component';
import { MatMenuModule } from '@angular/material/menu';
import { FilterPreferencesState } from '@modules/shipment/components/options-panel/state/filter-preferences.state';
import { ShipmentState } from '@modules/shipment/state/shipments/shipment/shipment.state';
import { InvoiceColumnsState } from '@modules/shipment/state/column-setting/column-settings.state';
import { PacklineColumnState } from '@modules/shipment/state/packlines/packlines.state';
import { PurchaseOrderColumnsState } from '@modules/shipment/state/purchase-order-column-setting/column-settings.state';
import { PoLinesColumnsState } from '@modules/shipment/state/purchase-order-column-setting/po-lines-column-settings.state';
import { ShipmentActionsHandler } from '@modules/shipment/state/shipments/actions/shipment.actions.handler';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReportState } from '@modules/report/states/report.state';

registerLocaleData(en);

export function noop() {
  return function () {};
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, StaticPageLayout, NotFoundPage, ForbiddenPage, UnauthorizedPage],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule.forRoot(),
    FormsModule,
    NgxsModule.forRoot([
      TablePreferenceState,
      FilterPreferencesState,
      ReportState,
      ShipmentState,
      InvoiceColumnsState,
      PacklineColumnState,
      PurchaseOrderColumnsState,
      PoLinesColumnsState,
    ]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        //'tablePreferences',
        TablePreferenceState.valuekey,
        'filterPreferences',
        'shipment',
        InvoiceColumnsState.valuekey,
        'PacklineColumnState',
        'PurchaseOrderColumnsState',
        'PoLinesColumnsState',
      ],
    }),
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APP_INITIALIZER,
      useFactory: noop,
      deps: [ShipmentActionsHandler],
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, autoFocus: false } as MatDialogConfig,
    },
  ],
})
export class AppModule {}
