// tslint:disable: variable-name
export class Palette {
  // Static Properties
  public static readonly UN: Palette = {
    fullLogoSrc: 'assets/images/full-logo-un.png',
    logoSrc: 'assets/images/logo-un.png',
    type: 'UN',
    cf_primary1: '#004bff',
    cf_primary2: '#0077e7',
    cf_primary3: '#00c3ff',
    cf_primary4: '#00e7e7',
    cf_primary5: '#00feb7',

    cf_status_red: '#f44336',
    cf_status_amber: '#ff9800',
    cf_status_green: '#4caf50',

    cf_secondary_blue1: '#4b7894',
    cf_secondary_blue2: '#5588a8',
    cf_secondary_blue3: '#7ab7d3',
    cf_secondary_blue4: '#a9dff2',
    cf_secondary_blue5: '#daf4fA',

    cf_secondary_grey1: '#6c6c6c',
    cf_secondary_grey2: '#909090',
    cf_secondary_grey3: '#b1b1b1',
    cf_secondary_grey4: '#d3d3d3',
    cf_secondary_grey5: '#ebebeb',
    cf_secondary_grey6: '#f5f5f5',
    cf_secondary_grey7: '#fafafa',

    cf_button_outline_grey: '#707070',
    cf_button_outline_teal: '#00e7e7',

    cf_nav_bar_blue1: '#137ec7',
    cf_nav_bar_blue2: '#3683e3',
    cf_nav_bar_blue3: '#44aeef',

    cf_active_freight_blue1: '#1b203c',

    cf_sea_port: '#62b3a6',
    cf_sea_port_light: '#92e3d6',
    cf_air_port: '#228386',
    cf_air_port_light: '#52b3b6',

    cf_white: '#ffffff',
    cf_black: '#000000',
    dashboard_palette: [
      '#078cff',
      '#046cff',
      '#004bff',
      '#0031ad',
      '#012172',
      '#001038',
      '#400F03',
      '#801F06',
      '#FF7D00',
      '#E85000',
      '#FF3F0D',
    ],
  };

  public static readonly CL: Palette = {
    fullLogoSrc: 'assets/images/full-logo-cl.png',
    logoSrc: 'assets/images/logo-cl.png',
    type: 'CL',
    cf_primary1: '#004bff',
    cf_primary2: '#0077e7',
    cf_primary3: '#00c3ff',
    cf_primary4: '#00e7e7',
    cf_primary5: '#00feb7',

    cf_status_red: '#fc5361',
    cf_status_amber: '#f7c04b',
    cf_status_green: '#03e58e',

    cf_secondary_blue1: '#4b7894',
    cf_secondary_blue2: '#5588a8',
    cf_secondary_blue3: '#7ab7d3',
    cf_secondary_blue4: '#a9dff2',
    cf_secondary_blue5: '#daf4fA',

    cf_secondary_grey1: '#6c6c6c',
    cf_secondary_grey2: '#909090',
    cf_secondary_grey3: '#b1b1b1',
    cf_secondary_grey4: '#d3d3d3',
    cf_secondary_grey5: '#ebebeb',
    cf_secondary_grey6: '#f5f5f5',
    cf_secondary_grey7: '#fafafa',

    cf_button_outline_grey: '#707070',
    cf_button_outline_teal: '#00e7e7',

    cf_nav_bar_blue1: '#137ec7',
    cf_nav_bar_blue2: '#3683e3',
    cf_nav_bar_blue3: '#44aeef',

    cf_active_freight_blue1: '#1b203c',

    cf_sea_port: '#62b3a6',
    cf_sea_port_light: '#92e3d6',
    cf_air_port: '#228386',
    cf_air_port_light: '#52b3b6',

    cf_white: '#ffffff',
    cf_black: '#000000',
    dashboard_palette: [
      '#001038',
      '#012172',
      '#0031ad',
      '#004bff',
      '#078cff',
      '#7bbffa',
      '#888d9e',
      '#b3bacb',
      '#8992a7',
      '#595e6a',
    ],
  };

  public static readonly CN: Palette = {
    fullLogoSrc: 'assets/images/full-logo-cn.png',
    logoSrc: 'assets/images/logo-cn.png',
    type: 'CN',
    cf_primary1: '#004bff',
    cf_primary2: '#0077e7',
    cf_primary3: '#00c3ff',
    cf_primary4: '#00e7e7',
    cf_primary5: '#00feb7',

    cf_status_red: '#fc5361',
    cf_status_amber: '#f7c04b',
    cf_status_green: '#03e58e',

    cf_secondary_blue1: '#4b7894',
    cf_secondary_blue2: '#5588a8',
    cf_secondary_blue3: '#7ab7d3',
    cf_secondary_blue4: '#a9dff2',
    cf_secondary_blue5: '#daf4fA',

    cf_secondary_grey1: '#6c6c6c',
    cf_secondary_grey2: '#909090',
    cf_secondary_grey3: '#b1b1b1',
    cf_secondary_grey4: '#d3d3d3',
    cf_secondary_grey5: '#ebebeb',
    cf_secondary_grey6: '#f5f5f5',
    cf_secondary_grey7: '#fafafa',

    cf_button_outline_grey: '#707070',
    cf_button_outline_teal: '#00e7e7',

    cf_nav_bar_blue1: '#137ec7',
    cf_nav_bar_blue2: '#3683e3',
    cf_nav_bar_blue3: '#44aeef',

    cf_active_freight_blue1: '#1b203c',

    cf_sea_port: '#62b3a6',
    cf_sea_port_light: '#92e3d6',
    cf_air_port: '#228386',
    cf_air_port_light: '#52b3b6',

    cf_white: '#ffffff',
    cf_black: '#000000',
    dashboard_palette: [
      '#001038',
      '#012172',
      '#0031ad',
      '#004bff',
      '#078cff',
      '#7bbffa',
      '#888d9e',
      '#b3bacb',
      '#8992a7',
      '#595e6a',
    ],
  };

  public static readonly NL: Palette = {
    fullLogoSrc: 'assets/images/full-logo-nl.png',
    logoSrc: 'assets/images/logo-nl.png',
    type: 'NL',
    cf_primary1: '#004bff',
    cf_primary2: '#0077e7',
    cf_primary3: '#00c3ff',
    cf_primary4: '#00e7e7',
    cf_primary5: '#00feb7',

    cf_status_red: '#fc5361',
    cf_status_amber: '#f7c04b',
    cf_status_green: '#03e58e',

    cf_secondary_blue1: '#4b7894',
    cf_secondary_blue2: '#5588a8',
    cf_secondary_blue3: '#7ab7d3',
    cf_secondary_blue4: '#a9dff2',
    cf_secondary_blue5: '#daf4fA',

    cf_secondary_grey1: '#6c6c6c',
    cf_secondary_grey2: '#909090',
    cf_secondary_grey3: '#b1b1b1',
    cf_secondary_grey4: '#d3d3d3',
    cf_secondary_grey5: '#ebebeb',
    cf_secondary_grey6: '#f5f5f5',
    cf_secondary_grey7: '#fafafa',

    cf_button_outline_grey: '#707070',
    cf_button_outline_teal: '#00e7e7',

    cf_nav_bar_blue1: '#137ec7',
    cf_nav_bar_blue2: '#3683e3',
    cf_nav_bar_blue3: '#44aeef',

    cf_active_freight_blue1: '#1b203c',

    cf_sea_port: '#62b3a6',
    cf_sea_port_light: '#92e3d6',
    cf_air_port: '#228386',
    cf_air_port_light: '#52b3b6',

    cf_white: '#ffffff',
    cf_black: '#000000',
    dashboard_palette: [
      '#001038',
      '#012172',
      '#0031ad',
      '#004bff',
      '#078cff',
      '#7bbffa',
      '#888d9e',
      '#b3bacb',
      '#8992a7',
      '#595e6a',
    ],
  };

  public static readonly BLACKOUT: Palette = {
    fullLogoSrc: 'assets/images/full-logo-un.png',
    logoSrc: 'assets/images/logo-un.png',
    type: 'UN',
    cf_primary1: '#000000',
    cf_primary2: '#000000',
    cf_primary3: '#000000',
    cf_primary4: '#000000',
    cf_primary5: '#000000',

    cf_status_red: '#000000',
    cf_status_amber: '#000000',
    cf_status_green: '#000000',

    cf_secondary_blue1: '#000000',
    cf_secondary_blue2: '#000000',
    cf_secondary_blue3: '#000000',
    cf_secondary_blue4: '#000000',
    cf_secondary_blue5: '#000000',

    cf_secondary_grey1: '#000000',
    cf_secondary_grey2: '#000000',
    cf_secondary_grey3: '#000000',
    cf_secondary_grey4: '#000000',
    cf_secondary_grey5: '#000000',
    cf_secondary_grey6: '#000000',
    cf_secondary_grey7: '#000000',

    cf_button_outline_grey: '#000000',
    cf_button_outline_teal: '#000000',

    cf_nav_bar_blue1: '#000000',
    cf_nav_bar_blue2: '#000000',
    cf_nav_bar_blue3: '#000000',

    cf_active_freight_blue1: '#000000',

    cf_sea_port: '#000000',
    cf_sea_port_light: '#000000',
    cf_air_port: '#000000',
    cf_air_port_light: '#000000',

    cf_white: '#000000',
    cf_black: '#000000',
    dashboard_palette: [
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
    ],
  };

  public static readonly WHITEOUT: Palette = {
    fullLogoSrc: 'assets/images/full-logo-un.png',
    logoSrc: 'assets/images/logo-un.png',
    type: 'UN',
    cf_primary1: '#ffffff',
    cf_primary2: '#ffffff',
    cf_primary3: '#ffffff',
    cf_primary4: '#ffffff',
    cf_primary5: '#ffffff',

    cf_status_red: '#ffffff',
    cf_status_amber: '#ffffff',
    cf_status_green: '#ffffff',

    cf_secondary_blue1: '#ffffff',
    cf_secondary_blue2: '#ffffff',
    cf_secondary_blue3: '#ffffff',
    cf_secondary_blue4: '#ffffff',
    cf_secondary_blue5: '#ffffff',

    cf_secondary_grey1: '#ffffff',
    cf_secondary_grey2: '#ffffff',
    cf_secondary_grey3: '#ffffff',
    cf_secondary_grey4: '#ffffff',
    cf_secondary_grey5: '#ffffff',
    cf_secondary_grey6: '#ffffff',
    cf_secondary_grey7: '#ffffff',

    cf_button_outline_grey: '#ffffff',
    cf_button_outline_teal: '#ffffff',

    cf_nav_bar_blue1: '#ffffff',
    cf_nav_bar_blue2: '#ffffff',
    cf_nav_bar_blue3: '#ffffff',

    cf_active_freight_blue1: '#ffffff',

    cf_sea_port: '#ffffff',
    cf_sea_port_light: '#ffffff',
    cf_air_port: '#ffffff',
    cf_air_port_light: '#ffffff',

    cf_white: '#ffffff',
    cf_black: '#ffffff',
    dashboard_palette: [
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
    ],
  };

  public static readonly UGLY: Palette = {
    fullLogoSrc: 'assets/images/full-logo-un.png',
    logoSrc: 'assets/images/logo-un.png',
    type: 'UGLY',
    cf_primary1: Palette.generateRandomRGBString(),
    cf_primary2: Palette.generateRandomRGBString(),
    cf_primary3: Palette.generateRandomRGBString(),
    cf_primary4: Palette.generateRandomRGBString(),
    cf_primary5: Palette.generateRandomRGBString(),

    cf_status_red: Palette.generateRandomRGBString(),
    cf_status_amber: Palette.generateRandomRGBString(),
    cf_status_green: Palette.generateRandomRGBString(),

    cf_secondary_blue1: Palette.generateRandomRGBString(),
    cf_secondary_blue2: Palette.generateRandomRGBString(),
    cf_secondary_blue3: Palette.generateRandomRGBString(),
    cf_secondary_blue4: Palette.generateRandomRGBString(),
    cf_secondary_blue5: Palette.generateRandomRGBString(),

    cf_secondary_grey1: Palette.generateRandomRGBString(),
    cf_secondary_grey2: Palette.generateRandomRGBString(),
    cf_secondary_grey3: Palette.generateRandomRGBString(),
    cf_secondary_grey4: Palette.generateRandomRGBString(),
    cf_secondary_grey5: Palette.generateRandomRGBString(),
    cf_secondary_grey6: Palette.generateRandomRGBString(),
    cf_secondary_grey7: Palette.generateRandomRGBString(),

    cf_button_outline_grey: Palette.generateRandomRGBString(),
    cf_button_outline_teal: Palette.generateRandomRGBString(),

    cf_nav_bar_blue1: Palette.generateRandomRGBString(),
    cf_nav_bar_blue2: Palette.generateRandomRGBString(),
    cf_nav_bar_blue3: Palette.generateRandomRGBString(),

    cf_active_freight_blue1: Palette.generateRandomRGBString(),

    cf_sea_port: Palette.generateRandomRGBString(),
    cf_sea_port_light: Palette.generateRandomRGBString(),
    cf_air_port: Palette.generateRandomRGBString(),
    cf_air_port_light: Palette.generateRandomRGBString(),

    cf_white: Palette.generateRandomRGBString(),
    cf_black: Palette.generateRandomRGBString(),
    dashboard_palette: [
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
      Palette.generateRandomRGBString(),
    ],
  };

  public fullLogoSrc: string;
  public logoSrc: string;
  public type: string;
  public cf_primary1: string;
  public cf_primary2: string;
  public cf_primary3: string;
  public cf_primary4: string;
  public cf_primary5: string;

  public cf_status_red: string;
  public cf_status_amber: string;
  public cf_status_green: string;

  public cf_secondary_blue1: string;
  public cf_secondary_blue2: string;
  public cf_secondary_blue3: string;
  public cf_secondary_blue4: string;
  public cf_secondary_blue5: string;

  public cf_secondary_grey1: string;
  public cf_secondary_grey2: string;
  public cf_secondary_grey3: string;
  public cf_secondary_grey4: string;
  public cf_secondary_grey5: string;
  public cf_secondary_grey6: string;
  public cf_secondary_grey7: string;

  public cf_button_outline_grey: string;
  public cf_button_outline_teal: string;

  public cf_nav_bar_blue1: string;
  public cf_nav_bar_blue2: string;
  public cf_nav_bar_blue3: string;

  public cf_active_freight_blue1: string;

  public cf_sea_port: string;
  public cf_sea_port_light: string;
  public cf_air_port: string;
  public cf_air_port_light: string;

  public cf_white: string;
  public cf_black: string;

  public dashboard_palette: string[];

  constructor(countryCode: string) {
    let palette: Palette;
    switch (countryCode?.toUpperCase()) {
      case 'CN': // China
        palette = Palette.CN;
        break;
      case 'NL': // Netherlands
        palette = Palette.NL;
        break;
      case 'CL': // Chile
        palette = Palette.CL;
        break;
      case 'BLACKOUT': // Used for testing
        palette = Palette.BLACKOUT;
        break;
      case 'WHITEOUT': // Used for testing
        palette = Palette.WHITEOUT;
        break;
      case 'UGLY': // EGG
        palette = Palette.UGLY;
        break;
      default:
        // Universal Standard
        palette = Palette.UN;
        break;
    }
    Object.assign(this, palette);
    Object.keys(palette).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key.replace(/_/g, '-')}`,
        palette[key]
      );
    });
  }

  public static generateRandomRGBString(): string {
    return `rgb(${Math.round(Math.random() * 255)},${Math.round(
      Math.random() * 255
    )},${Math.round(Math.random() * 255)})`;
  }
}
