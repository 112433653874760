import { Injectable } from "@angular/core";
import { Contact } from "@models/contact.model";
import { Organization } from "@models/organization.model";
import { RoutingGuide } from "@models/routing-guide.model";
import { Location } from "@models/location.model";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { UserNetworkApiService } from "./user-network-api.service";

@Injectable({
  providedIn: 'root'
})
export class OmCacheService {
  private _customer: BehaviorSubject<Organization> = new BehaviorSubject(null);
  get customer$(): Observable<Organization> {
    return this._customer.asObservable();
  }
  get customer(): Organization {
    return this._customer.value;
  }

  private _consigneeOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get consigneeOrgs$(): Observable<Organization[]> {
    return this._consigneeOrgs.asObservable();
  }
  get consigneeOrgs(): Organization[] {
    return this._consigneeOrgs.value;
  }

  private _shipperOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get shipperOrgs$(): Observable<Organization[]> {
    return this._shipperOrgs.asObservable();
  }
  get shipperOrgs(): Organization[] {
    return this._shipperOrgs.value;
  }

  private _supplierOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get supplierOrgs$(): Observable<Organization[]> {
    return this._supplierOrgs.asObservable();
  }
  get supplierOrgs(): Organization[] {
    return this._supplierOrgs.value;
  }

  private _manufacturerOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get manufacturerOrgs$(): Observable<Organization[]> {
    return this._manufacturerOrgs.asObservable();
  }
  get manufacturerOrgs(): Organization[] {
    return this._manufacturerOrgs.value;
  }

  private _forwarderOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get forwarderOrgs$(): Observable<Organization[]> {
    return this._forwarderOrgs.asObservable();
  }
  get forwarderOrgs(): Organization[] {
    return this._forwarderOrgs.value;
  }

  private _customerLocations: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  get customerLocations$(): Observable<Location[]> {
    return this._customerLocations.asObservable();
  }
  get customerLocations(): Location[] {
    return this._customerLocations.value;
  }

  private _consigneeLocations: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  get consigneeLocations$(): Observable<Location[]> {
    return this._consigneeLocations.asObservable();
  }
  get consigneeLocations(): Location[] {
    return this._customerLocations.value;
  }

  private _supplierLocations: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  get supplierLocations$(): Observable<Location[]> {
    return this._supplierLocations.asObservable();
  }
  get shipperLocations(): Location[] {
    return this._supplierLocations.value;
  }

  private _routingGuides: BehaviorSubject<RoutingGuide[]> = new BehaviorSubject<RoutingGuide[]>([]);
  get routingGuides$(): Observable<RoutingGuide[]> {
    return this._routingGuides.asObservable();
  }
  get routingGuides(): RoutingGuide[] {
    return this._routingGuides.value;
  }

  private _networkContacts: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);
  get networkContacts$(): Observable<Contact[]> {
    return this._networkContacts.asObservable();
  }
  get networkContacts(): Contact[] {
    return this._networkContacts.value;
  }

  private _buyerOrgs: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
  get buyerOrgs$(): Observable<Organization[]> {
    return this._buyerOrgs.asObservable();
  }
  get buyerOrgs(): Organization[] {
    return this._buyerOrgs.value;
  }

  constructor(private uns: UserNetworkApiService) {}
  public buildCacheForCurrentNetwork() {
    this._consigneeOrgs.next([]);
    this._supplierOrgs.next([]);
    this._manufacturerOrgs.next([]);
    this._forwarderOrgs.next([]);
    this._routingGuides.next([]);
    this._buyerOrgs.next([]);

    forkJoin({
      network: this.uns.getFullNetwork(),
      contacts: this.uns.getContactsInCurrentNetwork()
    }).subscribe(({network, contacts}) => {
      const consignees = network.organizations.filter(o => o.type == 'Consignee');
      const shippers = network.organizations.filter(o => (o.type == 'Shipper' || o.type == 'Supplier'));
      const suppliers = network.organizations.filter(o => o.type == 'Supplier');
      const manufacturers = network.organizations.filter(o => o.type == 'Manufacturer');
      const forwarders = network.organizations.filter(o => o.type == 'Forwarder');

      this._customer.next(network.customer);
      this._consigneeOrgs.next(consignees);
      this._shipperOrgs.next(shippers);
      this._supplierOrgs.next(suppliers);
      // TODO: Ask if this is correct or should orgs that are both suppliers and manufacturers 
      // be set up that way in CW - similar to orgs that are consignees and suppliers.
      // 2022-9-28: need to change it to be manufacturers and remove the suppliers until clarify it in CargoWise
      this._manufacturerOrgs.next([...manufacturers, ...suppliers.slice(0)]);
      this._forwarderOrgs.next(forwarders);
      this._routingGuides.next(network.routingGuides);
      this._networkContacts.next(contacts);
      this._buyerOrgs.next([network.customer]);
      
    }, _ => {

    });
  }
}