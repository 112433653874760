import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BlobServiceClient, AnonymousCredential, newPipeline, BlobUploadCommonResponse } from '@azure/storage-blob';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebResourceLike } from '@azure/core-http';
import { UUID } from 'angular2-uuid';

export interface IUploadResult {
  status: number;
  request: WebResourceLike;
  fileName: string;
  fileId: number;
}

export interface IUploadStorageResult {
  response: Promise<BlobUploadCommonResponse>;
}

export interface IFileSASResult {
  containerName: string;
  fileName: string;
  fileId: number;
  originalName: string;
  sharedAccessSignature: string;
}

export enum FileStorageType {
  None = 0,
  Invoice = 1,
  PoDocument = 2,
  BookingDocument = 3,
  OldDocument = 4,
  Logo = 5,
}

export enum CVFileStorageType {
  None = 0,
  ShipmentDocument = 1,
}

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  public get getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
  }

  constructor(private http: HttpClient) {}

  public async checkFileExists(blobName: string, containerName: string, sasToken: string): Promise<any> {
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 4 }, // Retry options
      userAgentOptions: { userAgentPrefix: 'AdvancedSample V1.0.0' }, // Customized telemetry string
      keepAliveOptions: {
        // Keep alive is enabled by default, disable keep alive by setting false
        enable: false,
      },
    });

    const blobServiceClient = new BlobServiceClient(
      `${environment.azureBlobConfig.azureBlobUrl}?${sasToken}`,
      pipeline
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobClient = containerClient.getBlobClient(blobName);
    const exists = await blobClient.exists();
    return exists;
  }

  public async uploadFileToStorage(
    fileIndex: number,
    currentFile: File,
    fileSASInfo: IFileSASResult,
    progressCallback: (fileIndex: number, fileSize: number, progressNum: number) => any
  ): Promise<IUploadStorageResult> {
    const sasToken = fileSASInfo.sharedAccessSignature;
    const containerName = fileSASInfo.containerName;
    const fileName = fileSASInfo.fileName;
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 4 }, // Retry options
      userAgentOptions: { userAgentPrefix: 'AdvancedSample V1.0.0' }, // Customized telemetry string
      keepAliveOptions: {
        // Keep alive is enabled by default, disable keep alive by setting false
        enable: false,
      },
    });

    const blobServiceClient = new BlobServiceClient(
      `${environment.azureBlobConfig.azureBlobUrl}?${sasToken}`,
      pipeline
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);

    const client = containerClient.getBlockBlobClient(fileName);
    return {
      response: client.uploadBrowserData(currentFile, {
        // blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: ev => {
          progressCallback(fileIndex, currentFile.size, ev.loadedBytes);
        },
        blobHTTPHeaders: { blobContentType: currentFile.type },
      }),
    };
  }

  public async uploadToStorage(
    currentFile: File,
    containerName: string,
    fileStorageType = CVFileStorageType.ShipmentDocument,
    progressCallback: (fileSize: number, progressNum: number) => any
  ): Promise<IUploadResult> {
    const fileExtension = currentFile.name.substr(currentFile.name.lastIndexOf('.'));
    const fileName = UUID.UUID() + fileExtension;

    let result = await this.getCVFileUploadSAS(fileStorageType, fileName);
    const fileId = result.fileId;
    const sasToken = result.sharedAccessSignature;
    const pipeline = newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 4 }, // Retry options
      userAgentOptions: { userAgentPrefix: 'AdvancedSample V1.0.0' }, // Customized telemetry string
      keepAliveOptions: {
        // Keep alive is enabled by default, disable keep alive by setting false
        enable: false,
      },
    });

    const blobServiceClient = new BlobServiceClient(
      `${environment.azureBlobConfig.azureBlobUrl}?${sasToken}`,
      pipeline
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);

    const client = containerClient.getBlockBlobClient(fileName);
    const response = await client.uploadBrowserData(currentFile, {
      // blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20, // 20 concurrency
      onProgress: ev => {
        progressCallback(currentFile.size, ev.loadedBytes);
      },
      blobHTTPHeaders: { blobContentType: currentFile.type },
    });

    return {
      status: response._response.status,
      request: response._response.request,
      fileName,
      fileId,
    };
  }

  public getSharedAccessSignature(
    fileStorageType = FileStorageType.PoDocument,
    blobName: string,
    isReadOnly = false
  ): any {
    const requestURL = `${environment.apiBaseURL}document/getSharedAccessSignature?fileStorageType=${fileStorageType}&blobName=${blobName}&isReadOnly=${isReadOnly}`;
    const res = this.http.get(requestURL, { headers: this.getHeaders, responseType: 'text' }).toPromise();
    return res;
  }

  public getCVFileUploadSAS(fileStorageType = CVFileStorageType.ShipmentDocument, blobName: string): any {
    const requestURL = `${environment.apiBaseURL}ClearView/getFileUploadSAS?fileStorageType=${fileStorageType}&fileName=${blobName}`;
    const res = this.http.get(requestURL, { headers: this.getHeaders }).toPromise();
    return res;
  }

  public getFileUploadSAS(fileStorageType = FileStorageType.PoDocument, originalName: string, size: number): any {
    const requestURL = `${environment.apiBaseURL}document/saveFileUploadData`;
    const res = this.http
      .post(
        requestURL,
        {
          FileStorageType: fileStorageType,
          OriginalName: originalName,
          Size: size,
        },
        { headers: this.getHeaders }
      )
      .toPromise();
    return res;
  }

  public getFilesUploadSAS(
    arr: { fileStorageType: FileStorageType.PoDocument; originalName: string; size: number }[]
  ): Promise<IFileSASResult[]> {
    const requestURL = `${environment.apiBaseURL}document/saveFileUploadDatas`;
    const res = this.http.post<IFileSASResult[]>(requestURL, arr, { headers: this.getHeaders }).toPromise();
    return res;
  }

  public getFilesSAS(fileIds: number[], isReadOnly: boolean = false): any {
    const requestURL = `${environment.apiBaseURL}document/getFilesSAS`;
    const res = this.http.post(
      requestURL,
      {
        fileIds: fileIds,
        isReadOnly: isReadOnly,
      },
      { headers: this.getHeaders }
    );
    return res;
  }

  public getNamesByUrl(url: string) {
    const arr = url.split('/');
    return {
      containerName: arr[arr.length - 2],
      fileName: arr[arr.length - 1],
    };
  }
}
