import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Organization } from '@models/organization.model';
import { FilterDelegate, OrganizationFilter } from '@models/filter';

@Component({
  selector: 'app-org-slicer',
  templateUrl: './org-slicer.component.html',
  styleUrls: ['./org-slicer.component.scss']
})
export class OrgSlicerComponent implements OnInit {
  @Input()
  set for(value: FilterDelegate) {
    if (value) {
      this.delegate = value;
    }
  };
  get for(): FilterDelegate {
    return this.delegate;
  }
  private delegate: FilterDelegate;

  @Input() 
  orgType: 'consignee' | 'shipper';

  @Input()
  set orgs(val: Organization[]) {
    this._orgs = (val) ? val : [];
  }
  get orgs(): Organization[] {
    return this._orgs;
  }
  private _orgs: Organization[] = [];

  @Output()
  selectedOrg = new EventEmitter<Organization | undefined>();

  @HostListener('click', ['$event'])
  onClickProp(e: Event) {
    e.stopImmediatePropagation();
  }

  ngOnInit(): void {
    if (!this.for) throw new Error('Org slicer must have a filter delegate attached.');
    if (!this.orgType) throw new Error('Org slice must have an organization type.');
  }

  public selectOrg(org?: Organization) {
    this.selectedOrg.next(org);
    if (org) {
      this.delegate.registerFilter(this.orgType, new OrganizationFilter([this.orgType], org));
    } else {
      this.delegate.unregisterFilter(this.orgType);
    }
  }

  public reset(): void {
	  this.selectOrg();
  }
}