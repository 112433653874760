<div>
  <div class="col remark-div" [ngStyle]="{ 'min-height': minHeight ? minHeight : '350px' }">
    <div *ngIf="remarks.length > 0" class="mt-2">
      <div class="remark-container" *ngFor="let r of remarks">
        <div>
          <div class="remark-message">{{ r.message }}</div>
        </div>
        <div class="remark-info">
          <div class="d-flex remark-detail">
            <div class="d-flex" style="padding-right: 5px">
              <small>{{ r.userFirstName }} {{ r.userLastName }}</small>
            </div>
            <div class="d-flex">
              <small>{{ r.date | date : 'HH:mm M/d/yyyy' }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="remarks.length === 0">
      <div class="col remark-container no-data-div">
        <div style="text-align: center">No data available.</div>
      </div>
    </div>
  </div>
  <div class="col remark-input">
    <!--<mat-form-field appearance='outline'>-->
    <!--<input-->
    <!--placeholder="Add your remarks here..."-->
    <!--matInput-->
    <!--[(ngModel)]="text"-->
    <!--(keydown.enter)="addRemark(text)"-->
    <!--&gt;-->
    <!--<button mat-button matSuffix mat-icon-button [disabled]="text.length === 0" (click)="addRemark(text)">-->
    <!--<mat-icon [ngClass]="{'send-icon' : text.length > 0}">send</mat-icon>-->
    <!--</button>-->
    <!--</mat-form-field>-->
    <mat-form-field floatLabel="never">
      <textarea placeholder="Add your remarks here..." matInput [(ngModel)]="text" rows="3"></textarea>
      <button mat-button matSuffix mat-icon-button [disabled]="text.length === 0 || disable" (click)="addRemark(text)">
        <mat-icon [ngClass]="{ 'send-icon': text.length > 0 && !disable }">send</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
