import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-om-confirm-dialog',
  template: `
    <h2 mat-dialog-title>Confirm</h2>
    <mat-dialog-content>
      <p class="respect-breaks">{{ prompt }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="margin-bottom: 0; padding-bottom: 0;">
      <button mat-raised-button color="primary" class="destructive" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" class="constructive" (click)="onYesClick()">Yes</button>
    </mat-dialog-actions>
  `,
})
export class ConfirmDialogComponent {
  public prompt: string;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    this.prompt = data;
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
