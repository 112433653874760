import { Pipe, PipeTransform } from '@angular/core';
import { BOOKING_STATUS, BookingStatusDescription } from '@models/helpers';

@Pipe({
  name: 'bookingStatus',
})
export class BookingStatusPipe implements PipeTransform {
  transform(value: any) {
    let status = 'TBD';
    switch (value) {
      case BOOKING_STATUS.Accepted: {
        status = BookingStatusDescription.Accepted;
        break;
      }
      case BOOKING_STATUS.AwaitingClient: {
        status = BookingStatusDescription.AwaitingClient;
        break;
      }
      case BOOKING_STATUS.AwaitingSupplier: {
        status = BookingStatusDescription.AwaitingSupplier;
        break;
      }
      case BOOKING_STATUS.Unsent: {
        status = BookingStatusDescription.Unsent;
        break;
      }
      case BOOKING_STATUS.Packed: {
        status = BookingStatusDescription.Packed;
        break;
      }
      case BOOKING_STATUS.Cancelled: {
        status = BookingStatusDescription.Cancelled;
        break;
      }
    }
    return status;
  }
}
