import { UserOrg } from '@models/organization.model';

export class SsoUser {
  id: string;
  name: string;
  email: string;
  username: string;

  constructor(id?: string, name?: string, email?: string, username?: string) {
    this.id = id ? id : '';
    this.name = name ? name : '';
    this.email = email ? email : '';
    this.username = username ? username : '';
  }
}

export class User {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
  username: string;
  phoneNumber: string;
  isOmsAdmin: boolean;
  currentNetworkId: number;
  orgs: UserOrg[];
  externalId: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
export interface IUserInfo {
  user: User;
  orgs: UserOrg[];
}
