<span mat-dialog-title class="cf-header-text">Manufacturer Change</span>
<mat-dialog-content>
  <div>
    <p>Are you sure you want to change manufacturers? Doing so will remove all existing purchase order lines.</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="cancelManufacturerChange()" class="secondary">Cancel</button>
  <button mat-raised-button color="primary" (click)="confirmManufacturerChange()">Change Manufacturer</button>
</mat-dialog-actions>
