import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Booking } from '@models/booking.model';
import { PurchaseOrderBrowserService } from '@modules/purchase-order/services/purchase-order-browser.service';

@Component({
  selector: 'app-om-job-purchase-orders',
  styles: [''],
  template: `
    <app-om-table
      #poTable
      tableKey="job-po-table"
      [dataSource]="job.bookedPurchaseOrders"
      [templateRef]="nestedListTableTemplate"
      [allowRowExpansion]="true"
      [whiteBackground]="true"
      [headerOnTop]="true"
      [containerHeight]="containerHeight - 40"
      [defaultExpanded]="true"
      [hasExport]="true"
      (export)="export($event)"
    >
      <ng-container tableToolbar>
        <div class="table-toolbar table-toolbar-small">
          <div class="filter-box"></div>
          <div class="column-customizer-box">
            <app-om-table-column-customizer
              [tableKey]="'job-po-table'"
              label="Edit PO Columns"
            ></app-om-table-column-customizer>
            <div class="column-box-ml">
              <app-om-table-column-customizer
                [tableKey]="'job-display-po-line-table'"
                label="Edit PO Line Columns"
              ></app-om-table-column-customizer>
            </div>
            <div class="column-box-ml">
              <button
                *ngIf="canEdit"
                (click)="togglePurchaseOrderEditor.emit()"
                mat-raised-button
                color="primary"
                class="edit-btn"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #nestedListTableTemplate let-element="element">
        <app-om-table
          #poLineTable
          tableKey="job-display-po-line-table"
          [dataSource]="element.poLines"
          [nested]="true"
          [allowRowExpansion]="false"
          [childTable]="true"
        >
          <app-om-table-custom-cells-wrapper customCells>
            <ng-container matColumnDef="bookingQuantityDisplay">
              <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">Booking Qty</th>
              <td mat-cell *matCellDef="let polineElement">
                <ng-container>
                  <div class="row order-div">
                    <span class="col">{{ polineElement.bookingQuantity }}</span>
                  </div>
                </ng-container>
              </td>
            </ng-container>
          </app-om-table-custom-cells-wrapper>
        </app-om-table>
      </ng-template>
    </app-om-table>
  `,
})
export class JobPurchaseOrdersComponent {
  @Input() job: Booking;
  @Input() canEdit: boolean = false;
  @Input() containerHeight: number;
  @Output() togglePurchaseOrderEditor: EventEmitter<void> = new EventEmitter<void>();

  constructor(public pbs: PurchaseOrderBrowserService) {}

  public export(event: any) {
    this.pbs.downloadPurchaseOrders(event, this.job.id);
  }
}
