export enum AutoApprovedTransportMode {
  None = 0,
  All = 1,
  AIR = 2,
  SEA = 3,
}

export enum AutoApprovedContainerizedType {
  None = 0,
  All = 1,
  Yes = 2,
  No = 3,
}
