import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Unloco } from '@models/unloco.model';
import { UnlocoApiService } from '@services/unloco-api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-unloco',
  templateUrl: './unloco.component.html',
  styleUrls: ['./unloco.component.scss']
})
export class UnlocoComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {
  @Input() label = '';
  @Input() required = false;
  @Input() extendedWidth = false;
  @Input() marked = false;
  @ViewChild(MatAutocompleteTrigger) trigger: any;

  @Output() unlocoSelected: EventEmitter<Unloco> = new EventEmitter<Unloco>();

  searchControl = new UntypedFormControl();
  selectedLocode = "";
  unlocos: Unloco[] = [];

  private onChange = (_: string) => { };
  private onTouched = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private service: UnlocoApiService
  ) {
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500)
    ).subscribe(newValue => {
      if (newValue !== '' && typeof newValue === 'string') {  // on input
        this.selectLocode('');  // clear out selected locode while we find one that matches the current input.
        this.filterUnlocos(newValue)

      } else if (newValue) { // on selected
        this.unlocoSelected.emit((newValue as Unloco));
        this.selectLocode((newValue as Unloco).locode);

      } else { // on input cleared
        this.unlocos = [];
        this.selectLocode('');
      }
    });
  }

  ngAfterViewInit(): void {
    // handles case when the user leaves the input (blur) but the input still
    // has text in it. we check if the text matches any options and select it , else
    // we clear the input.
    (this.trigger as MatAutocompleteTrigger).panelClosingActions.subscribe(e => {
      let currentValue = this.searchControl.value;
      // currentValue would be an object if an option was selected. we check this instead of
      // selectedLocode for the times that the selectedLocode doesn't get cleared out in time
      // before this function is called.
      if (!(e && e.source) && currentValue && typeof currentValue === 'string') {  
        this.service.getUnlocoCodes(currentValue).subscribe(codes => {
          this.unlocos = codes;
          var currentValueUpper = currentValue.toUpperCase();  
          const matchingUnloco = this.unlocos.find(u => u.locode === currentValueUpper || u.name.toUpperCase() === currentValueUpper);
          if (matchingUnloco) {
            this.searchControl.setValue(matchingUnloco);
            this.selectedLocode = matchingUnloco.locode;
          } 
          else {
            this.searchControl.setValue(null);
            this.selectedLocode = ""; 
          }
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['marked'] && changes['marked'].firstChange === false) {
      this.marked = changes['marked'].currentValue;
      if (this.marked) {
        this.searchControl.markAsTouched();
      }
    }
  }

  filterUnlocos(value: string, emitEvent: boolean = true) {
    if (value) {
      this.service.getUnlocoCodes(value).subscribe(codes => {
        this.unlocos = codes;
        var currentInput = value.toUpperCase();  
        const matchingUnloco = this.unlocos.find(u => u.locode === currentInput || u.name.toUpperCase() === currentInput);
        if (matchingUnloco) {
          this.searchControl.setValue(matchingUnloco, {emitEvent: emitEvent});
          this.selectedLocode = matchingUnloco.locode;
        } 
      });
    }
  }

  selectLocode(value: string): void {
    this.selectedLocode = value;
    this.onChange(value);
    this.onTouched();
  }

  writeValue(locode: string): void {
    if (!locode) {
      this.searchControl.setValue(null, {emitEvent: false});
      this.selectedLocode = null;
      this.unlocos = [];
    } else {
      this.filterUnlocos(locode, false);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    return null;
  }

  displayFn(unloco?: Unloco): string {
    return unloco ? `(${unloco.locode}) ${unloco.name}` : "";
  }

  public clearField(event: any): void {
    event.stopImmediatePropagation();
    this.ngControl.reset(null);
    this.unlocoSelected.emit(null);
  }
}
