import { boolean } from 'mathjs';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from '@models/equipment.model';
import { ToastrService } from 'ngx-toastr';
import { ContainerService } from '@services/container.service';
import { ContainerType } from '@models/container.model';
import { MatSelectChange } from '@angular/material/select';
import { Booking } from '@models/booking.model';
import { BookingApiService } from '@services/booking-api.service';
import { checkDuplicateProperty } from 'src/app/shared/utils/array-utils';

@Component({
  styleUrls: ['./job-equipment-editor.component.scss'],
  template: `
    <app-om-dialog [title]="'EDIT EQUIPMENT'" [showFooter]="true" (closeDialog)="cancel()">
      <app-loading-indicator *ngIf="loading; else editor"></app-loading-indicator>
      <ng-template #editor>
        <div>
          <app-om-table
            tableKey="job-equipment-editor-table"
            [dataSource]="equipment"
            [allowRowExpansion]="false"
            [whiteBackground]="true"
            [equipmentTable]="true"
          >
            <app-om-table-custom-cells-wrapper customCells>
              <ng-container matColumnDef="equipmentType">
                <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">TYPE</th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" class="equiment-type-td">
                  <ng-container>
                    <mat-form-field appearance="outline">
                      <mat-select
                        [(ngModel)]="element.containerTypeId"
                        (selectionChange)="selectedEquipment($event, i)"
                      >
                        <mat-option *ngFor="let type of containerTypes" [value]="type.id">
                          {{ type.code }} - {{ type.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">QUANTITY</th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" class="equiment-quantity-td">
                  <ng-container>
                    <mat-form-field appearance="outline">
                      <input matInput type="number" [(ngModel)]="element.quantity" min="1" />
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">Operation</th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" class="equiment-operation-td">
                  <mat-icon
                    color="warn"
                    [ngClass]="{ 'remove-disabled': element.removeDisabled }"
                    (click)="removeEquipment($event, i)"
                  >
                    delete
                  </mat-icon>
                  <span *ngIf="element.isValidateError">
                    <app-error-info-icon [message]="element.isValidateMsg"></app-error-info-icon>
                  </span>
                </td>
              </ng-container>
            </app-om-table-custom-cells-wrapper>
          </app-om-table>
          <button mat-raised-button color="primary" class="send" (click)="addEquipment()">
            Add
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </ng-template>
      <ng-container footer>
        <button mat-raised-button color="primary" class="destructive" [disabled]="btnLoading" (click)="cancel()">
          Discard Changes
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="send" [disabled]="btnLoading" (click)="confirm()">
          <div class="icon-container d-flex flex-row align-items-center" [class.download-spinner]="btnLoading">
          Confirm
          <mat-icon>check</mat-icon>
          </div>
        </button>
      </ng-container>
    </app-om-dialog>
  `,
})
export class JobEquipmentEditorComponent {
  public loading: boolean = false;
  public equipment: Equipment[] = [];
  public equipmentForm: UntypedFormGroup;

  public containerTypes: ContainerType[] = [];
  public btnLoading: boolean = false;
  public needValidateBookedEquipment: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { job: Booking },
    private dialogRef: MatDialogRef<JobEquipmentEditorComponent>,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private containerService: ContainerService,
    private bookingApi: BookingApiService
  ) {}

  ngOnInit() {
    // Get container types
    this.containerService.getContainerTypes().subscribe(res => {
      this.containerTypes = res;
    });

    this.equipment = this.data.job.bookedEquipments.slice(0);

    this.equipmentForm = this.fb.group({
      type: [null, Validators.required],
      quantity: [null, Validators.required],
    });

    if (this.equipment.length < 1) {
      this.addEquipment();
    }
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public async confirm(): Promise<void> {
    event.stopImmediatePropagation();
    let msg = '';
    const dupRes = checkDuplicateProperty(this.equipment, 'equipmentType');
    if (dupRes) {
      msg = 'Container type is duplicated';
    }
    if (!msg) {
      this.equipment.forEach(item => {
        if (item.equipmentType == '') {
          msg = 'Please select equipment type';
          return false;
        }
        if (item.quantity <= 0 || item.quantity == undefined) {
          msg = 'Quantity must be greater than 0';
          return false;
        }
      });
    }

    if (msg) {
      this.toastr.warning(msg);
      return;
    } else {
      this.btnLoading = true;
      const val = await this.validateBookedEquipment();
      this.btnLoading = false;
      if (val) {
        this.dialogRef.close(this.equipment);
      }
    }
  }

  public addEquipment() {
    const equipmentItem = {
      equipmentType: '',
      description: '',
      quantity: 0,
      containerTypeId: 0,
      id: 0,
      isValidateError: false,
      isValidateMsg: '',
      removeDisabled: false,
    };
    this.equipment = [...this.equipment, equipmentItem];
  }

  public removeEquipment(event: any, index: number): void {
    event.stopImmediatePropagation();
    if (this.equipment[index].removeDisabled) return;

    this.equipment.splice(index, 1);

    // Table uses on push change detection strategy so we must create a new
    // array reference for the changes to propagate
    this.equipment = this.equipment.slice(0);
  }

  public selectedEquipment(event: MatSelectChange, index: number): void {
    const ctIndex = this.containerTypes.findIndex((item: any) => item.id === event.value);
    this.equipment[index].equipmentType = this.containerTypes[ctIndex].code;
    this.equipment[index].description = this.containerTypes[ctIndex].description;
  }

  public compareEquipmentFn(optionA: any, optionB: any): boolean {
    if (optionA && optionB) {
      return optionA.id == optionB.id;
    } else {
      return false;
    }
  }

  private async validateBookedEquipment(): Promise<boolean> {
    if (this.needValidateBookedEquipment) {
      const eQtyArr = [];
      this.equipment.forEach(e => {
        e.isValidateError = false;
        eQtyArr.push({
          containerTypeId: e.containerTypeId,
          quantity: e.quantity,
        });
      });
      if (eQtyArr.length > 0) {
        const res = await this.bookingApi.validateBookedEquipment(this.data.job.id, eQtyArr);
        if (!res || res.length == 0) {
          return true;
        } else {
          res.forEach(r => {
            const equip = this.equipment.find(e => e.containerTypeId === r.containerTypeId) as any;
            equip.isValidateError = true;
            equip.isValidateMsg = r.message;
          });
          this.toastr.warning('Validation failed');
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
