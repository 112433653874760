export function checkDuplicateProperty(array, propertyName): boolean {
  const values = {};
  for (let i = 0; i < array.length; i++) {
    const propertyValue = array[i][propertyName];
    if (propertyValue in values) {
      return true;
    }
    values[propertyValue] = true;
  }
  return false;
}
