import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BlobStorageService, FileStorageType } from '@services/blob-storage.service';
import { SettingService } from '@services/setting.service';

interface UploadItem {
  url: string;
  id: number;
  name: string;
  originalName: string;
  size: number;
  fileStorageType: number;
  description?: string;
}

@Component({
  selector: 'app-icon-upload-dialog',
  templateUrl: './icon-upload-dialog.component.html',
  styleUrls: ['./icon-upload-dialog.component.scss'],
})
export class IconUploadDialogComponent implements OnInit {
  public orgId: number;
  public progressValue = 0;
  public isUploadingDoc = false;
  public docItem: UploadItem = {
    url: '',
    id: 0,
    name: '',
    originalName: '',
    size: 0,
    fileStorageType: FileStorageType.Logo,
  };

  @ViewChild('contentDoc') public contentDoc: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<IconUploadDialogComponent>,
    private toastr: ToastrService,
    private blobStorageService: BlobStorageService,
    private settingService: SettingService
  ) {}

  public ngOnInit(): void {}

  public async fileChanged(event: Event): Promise<void> {
    if (event) {
      const target = event.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      await this.docUpload(file);
    }

    return;
  }

  // From drag and drop
  public async onDropSuccess(event): Promise<void> {
    event.preventDefault(event);
    const file: File = (event.dataTransfer.files as FileList)[0];
    await this.docUpload(file);
    return;
  }

  public onDragOver(event): void {
    event.preventDefault();
  }

  public async docUpload(file: File): Promise<boolean | void> {
    try {
      if (this.isUploadingDoc) {
        this.toastr.error('Please wait for the other image to finish uploading');
        this.contentDoc.nativeElement.value = '';
        return false;
      }

      this.progressValue = 0;

      if (file == null) {
        this.toastr.error(`Please select image`);
        return false;
      }

      if (file.size > 1 * 1024 * 1024) {
        this.toastr.error(`Document size maximum 1M`);
        return false;
      }

      this.toastr.info(`Uploading image...`);
      this.isUploadingDoc = true;
      this.docItem.name = file.name;
      this.docItem.originalName = file.name;
      this.docItem.size = file.size;

      const filesParams = [];

      filesParams.push({
        originalName: file.name,
        fileStorageType: this.docItem.fileStorageType,
        size: file.size,
      });

      const fileRes = await this.blobStorageService.getFilesUploadSAS(filesParams);

      const currentFileSASInfo = fileRes[0];

      console.log('fileRes', fileRes);

      // upload to Azure blob storage
      const uploadRes = await this.blobStorageService.uploadFileToStorage(
        0,
        file,
        currentFileSASInfo,
        this.handleUploadProgress.bind(this)
      );
      uploadRes.response.then(res => {
        if (res._response.status !== 201) {
          this.toastr.error(`Failed to upload document to Azure storage, please try again`);
          this.isUploadingDoc = false;
        } else {
          this.docItem.name = currentFileSASInfo.fileName;
          this.docItem.url = res._response.request.url;
          this.docItem.id = currentFileSASInfo.fileId;
          this.isUploadingDoc = false;
        }
      });
    } catch (error) {
      this.toastr.error(`Failed to upload image, please try again`);
      this.isUploadingDoc = false;

      this.docItem.url = '';
      this.docItem.name = '';
      this.contentDoc.nativeElement.value = '';
      this.docItem.originalName = '';

      return false;
    }

    return;
  }

  public handleUploadProgress(fileSize: number, progressNum: number): void {
    this.progressValue = Math.floor((progressNum / (fileSize + 1000)) * 100);
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public getFileType(name: string): string {
    const lookup = name.match(/\.\w*/g);
    if (lookup) {
      return lookup[lookup.length - 1]?.substring(1).toLowerCase();
    } else {
      return 'file';
    }
  }

  public saveDoc(): void {
    this.isUploadingDoc = true;

    const fileData = {
      organizationId: this.orgId,
      logoFileId: this.docItem.id,
    };

    this.settingService.saveOrganizationSetting(fileData).subscribe(
      res => {
        this.toastr.success(`Save Logo Successfully`);
        this.dialogRef.close({
          fileUrl: this.docItem.url,
        });
      },
      err => {
        this.toastr.error(`Save Logo Failed`);
      }
    );
  }
}
