import { Container } from '@modules/shipment/models/container.model';

export class SelectShipment {
  public static readonly type = '[Shipment] Select';
  constructor(public id: string) {}
}

export class PreviousShipment {
  public static readonly type = '[Shipment] Previous';
  constructor() {}
}

export class NextShipment {
  public static readonly type = '[Shipment] Next';
  constructor() {}
}

export class SetAirShipmentList {
  public static readonly type = '[Shipment] SetAirList';
  constructor(public payload: any[]) {}
}

export class SetSeaShipmentList {
  public static readonly type = '[Shipment] SetSeaList';
  constructor(public payload: any[]) {}
}

export class SetRoaShipmentList {
  public static readonly type = '[Shipment] SetRoaList';
  constructor(public payload: any[]) {}
}

export class SetRaiShipmentList {
  public static readonly type = '[Shipment] SetRaiList';
  constructor(public payload: any[]) {}
}

export class SetContainerShipmentList {
  public static readonly type = '[Shipment] SetContainerList';
  constructor(public payload: Container[]) {}
}

export class ResetShipmentState {
  public static readonly type = '[Shipment] Reset';
  constructor() {}
}
