import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { TablePreferenceState } from 'src/app/state/om-table.state';
import { MoveColumn, ToggleTableColumn } from '../../../state/om-table.actions';

@Component({
  selector: 'app-om-table-column-customizer',
  styleUrls: ['./om-table-column-customizer.component.scss'],
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column align-items-end">
        <button class="table-action-btn" mat-raised-button [matMenuTriggerFor]="columnMenu">
          <div class="column-customizer-content">
            <div style="padding-top: 4px">{{ label }}</div>
            <mat-icon>view_column</mat-icon>
          </div>
        </button>
        <mat-menu #columnMenu="matMenu" yPosition="above" class="column-menu-panel">
          <div (click)="$event.stopPropagation()" *ngIf="tableConfig">
            <div class="custom-popover-header">Click and drag to reorder columns</div>
            <div id="column-selection-panel" cdkDropList (cdkDropListDropped)="drop($event)" class="ml-auto">
              <div
                *ngFor="let column of renderColumns; let i = index"
                cdkDrag
                [cdkDragDisabled]="column.onTop || column.onBottom"
                class="d-flex justify-content-between"
                [ngClass]="{ draggable: !column.onTop && !column.onBottom }"
              >
                <div>
                  {{ column.name?.replace('_', ' ') | titlecase }}
                </div>
                <mat-slide-toggle
                  [checked]="column.enabled"
                  [disabled]="column.alwaysShow"
                  (change)="toggleColumn(column)"
                  class="ml-3"
                ></mat-slide-toggle>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  `,
})
export class OmTableColumnCustomizerComponent implements OnInit, OnDestroy {
  @Input() tableKey: string = '';
  @Input() label: string = 'EDIT COLUMNS';
  public tableConfig: { columns: any[]; renderColumns: any[]; displayColumns: any[] } = {
    columns: [],
    renderColumns: [],
    displayColumns: [],
  };
  public topColumns: any[];
  public bottomColumns: any[];
  public renderColumns: any[] = [];
  public hasArrow: boolean = false;

  private tablePreferencesSubscription: Subscription;

  constructor(private store: Store, private tablePreferenceState: TablePreferenceState) {}

  ngOnInit() {
    // this.store
    //   .select(state => state.tablePreferences[this.tableKey])
    //   .subscribe(tableConfig => {
    //     if (tableConfig) this.tableConfig = tableConfig;
    //     if (tableConfig?.renderColumns) {
    //       this.renderColumns = this.tableConfig.renderColumns.slice(0);
    //       console.log('renderColumns', this.renderColumns);
    //       this.hasArrow = this.renderColumns.filter(c => c.type === 'arrow').length > 0 ? true : false;
    //       this.renderColumns = this.renderColumns.filter(c => c.type !== 'arrow');
    //     }
    //     this.topColumns = this.tableConfig?.renderColumns?.filter(c => c.onTop);
    //     this.bottomColumns = this.tableConfig?.renderColumns?.filter(c => c.onBottom);
    //   });

    this.tablePreferencesSubscription = this.store.subscribe(stateSub => {
      if (stateSub && stateSub.tablePreferences && stateSub.tablePreferences[this.tableKey]) {
        this.tableConfig = stateSub.tablePreferences[this.tableKey];
        if (this.tableConfig?.renderColumns) {
          this.renderColumns = this.tableConfig.renderColumns.slice(0);
          this.hasArrow = this.renderColumns.filter(c => c.type === 'arrow').length > 0 ? true : false;
          this.renderColumns = this.renderColumns.filter(c => c.type !== 'arrow');
        }
        this.topColumns = this.tableConfig?.renderColumns?.filter(c => c.onTop);
        this.bottomColumns = this.tableConfig?.renderColumns?.filter(c => c.onBottom);
      }
    });
  }

  public toggleColumn(column: any): void {
    this.store.dispatch(new ToggleTableColumn(this.tableKey, column.key));
  }

  public drop(event: CdkDragDrop<any[]>): void {
    if (this.hasArrow) {
      event.currentIndex = event.currentIndex + 1;
      event.previousIndex = event.previousIndex + 1;
    }
    this.store.dispatch(new MoveColumn(this.tableKey, event));
  }

  ngOnDestroy() {
    if (this.tablePreferencesSubscription) {
      this.tablePreferencesSubscription.unsubscribe();
    }
  }
}
