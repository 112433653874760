import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import {
  Filter,
  FieldCondition,
  FilterField,
  ShipmentFilterField,
  ContainerFilterField,
  Field
} from '../../model/filter.model';
import * as moment from 'moment';
import { Store } from '@ngxs/store';

import { IPreferenceSet } from '../../state/filter-preferences.state';
import { Observable, Subject } from 'rxjs';
import { UpdateFilters } from '../../state/filter-preferences.actions';
import { takeUntil } from 'rxjs/operators';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import {utilDeepClone} from "../../../utils";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate('100ms ease-in-out', style({ opacity: 0 })), animate(250, style({ height: 0 }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        group([animate(250, style({ height: '*' })), animate('100ms 150ms ease-in-out', style({ opacity: 1 }))]),
      ]),
    ]),
  ],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Output() public filtersUpdated = new EventEmitter<Filter[]>();
  @Output() public close = new EventEmitter<Filter[]>();
  public filters: Filter[];
  public filterForm: UntypedFormGroup;
  public get f(): { [key: string]: AbstractControl } {
    return this.filterForm.controls;
  }
  public dataFieldOptions: string[] = [];
  public conditionOptions: string[] = [];
  public valueOptions: string[] = [];
  public filterFields: FilterField;
  public newFilter = false;
  public filterType: string;

  public preferences$: Observable<IPreferenceSet>;
  public preferences: IPreferenceSet;
  private unsubscribe$ = new Subject<void>();

  public filterData: Field[];
  public filterCondition: [];

  constructor(private fb: UntypedFormBuilder,
              private store: Store) {}

  public ngOnInit(): void {
    const urlPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    switch (urlPath) {
      case 'containers':
        this.preferences$ = this.store.select(state => state.filterPreferences.container);
        break;
      case 'air-shipments':
        this.preferences$ = this.store.select(state => state.filterPreferences.air);
        break;
      case 'sea-shipments':
        this.preferences$ = this.store.select(state => state.filterPreferences.sea);
        break;
      case 'roa-shipments':
        this.preferences$ = this.store.select(state => state.filterPreferences.roa);
        break;
      case 'rai-shipments':
        this.preferences$ = this.store.select(state => state.filterPreferences.rai);
        break;
      case 'cou-shipments':
        this.preferences$ = this.store.select(state => state.filterPreferences.cou);
        break;
    }

    this.preferences$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(preferences => {
        if (preferences) {
          this.preferences = utilDeepClone(preferences);
          this.filters = utilDeepClone(preferences.filters);
          this.filterType = utilDeepClone(preferences.ref);

          if (preferences.ref === 'air' || preferences.ref === 'sea' || preferences.ref === 'roa' || preferences.ref === 'rai' || preferences.ref === 'cou') {
            this.filterFields = new ShipmentFilterField();
          } else if (this.filterType === 'container') {
            this.filterFields = new ContainerFilterField();
          }
        }
      }
    );

    this.filterForm = this.fb.group({
      dataField: new UntypedFormControl(null, [Validators.required]),
      condition: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
      value: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
      value2: new UntypedFormControl(null, [Validators.nullValidator]),
    });
    this.filterData = this.filterFields.fieldList;
  }

  public getFieldName(field: string): string {
    const curField = this.filterData.find(item => item.dbColumn === field);
    if (curField) {
      return curField.text;
    }
    return field;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addFilter(): void {
    let value1Override;
    let value2Override;
    if (this.f.condition.value.text === 'Days Range') {
      const dayRange = Number.parseInt(this.f.value2.value, 10);
      value2Override = moment().add(dayRange, 'days').format('YYYY-MM-DD');
      if (dayRange < 0) {
        value1Override = value2Override;
        value2Override = this.f.value.value;
      }
    }

    const filter = new Filter({
      dataField: this.f.dataField.value.text,
      condition: this.f.condition.value.text,
      value: value1Override ? value1Override : this.f.value.value,
      value2: value2Override ? value2Override : this.f.value2.value,
      selected: true,
      id: this.filters.length > 0 ? this.filters[this.filters.length - 1]?.id + 1 : 0,
    });

    this.filterForm.reset();
    this.f.condition.disable();
    this.f.value.disable();

    this.filters.push(filter);
  }

  public removeFilter(filter: any): void {
    this.filters = this.filters.filter((f) => f.id != filter.id);
  }

  public cancelNewFilter(): void {
    this.filterForm.reset();
    this.newFilter = false;
  }

  public applyFilters(): void {
    this.store.dispatch(new UpdateFilters({ ref: this.preferences.ref, filters: this.filters }));
    this.close.emit();
  }

  public dataFieldChanged(): void {
    if (!this.f.dataField.value) {
      return;
    }
    this.f.condition.reset();
    this.f.condition.enable();
    this.f.value.reset();
    this.f.value.disable();
    this.f.value2.reset();
    this.filterCondition = this.f.dataField.value?.conditions;
  }

  public conditionChanged(): void {
    if (!this.f.dataField.value) {
      return;
    }
    this.f.value.reset();
    this.f.value.enable();
    this.f.value2.reset();
    this.f.value2.enable();
    const condition: FieldCondition = this.f.condition.value;
    if (condition.input1value) {
      if (condition.text === 'Days Range') {
        this.f.value.setValue(condition.input1value);
        this.f.value.disable();
      } else {
        const value = condition.input1value.toString();
        this.f.value.setValue(value[0]?.toUpperCase() + value.slice(1));
        this.f.value.disable();
      }
    }
  }
}
