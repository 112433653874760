import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {Filter} from '../model/filter.model';
import {AddFilter, RemoveFilter, UpdateFilters, UpdateSorting} from './filter-preferences.actions';

export interface IPreferenceSet {
  filters: Filter[];
  sort: { sortByField: string, sortByAscending: boolean };
  ref: string;
  defaultSort: { sortByField: string, sortByAscending: boolean };
}

export class FilterPreferencesStateModel {
  public air: IPreferenceSet;
  public sea: IPreferenceSet;
  public roa: IPreferenceSet;
  public rai: IPreferenceSet;
  public cou: IPreferenceSet;
  public container: IPreferenceSet;
}

@State<FilterPreferencesStateModel>({
  name: 'filterPreferences',
  defaults: {
    air: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'air'
    } as IPreferenceSet,
    sea: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'sea'
    } as IPreferenceSet,
    container: {
      filters: [],
      sort: {
        sortByField: 'shipmentLegs.etd',
        sortByAscending: false
      },
      defaultSort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'container'
    } as IPreferenceSet,
    roa: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'roa'
    } as IPreferenceSet,
    rai: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'rai'
    } as IPreferenceSet,
    cou: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedArrival',
        sortByAscending: false
      },
      ref: 'cou'
    } as IPreferenceSet
  }
})
@Injectable({
  providedIn: 'root'
})
export class FilterPreferencesState {
  constructor() {
    const filterPreferences = JSON.parse(localStorage.getItem('filterPreferences')) || {};

    if (!filterPreferences.air || !filterPreferences.air.defaultSort) {
      filterPreferences.air = {
        filters: [],
        sort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        ref: 'air'
      } as IPreferenceSet;
    }

    if (!filterPreferences.sea || !filterPreferences.sea.defaultSort) {
      filterPreferences.sea = {
        filters: [],
        sort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        ref: 'sea'
      } as IPreferenceSet;
    }

    if (!filterPreferences.container || !filterPreferences.container.defaultSort) {
      filterPreferences.container = {
        filters: [],
        sort: {
          sortByField: 'estimatedTimeOfArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'estimatedTimeOfArrival',
          sortByAscending: false
        },
        ref: 'container'
      } as IPreferenceSet;
    }

    if (!filterPreferences.roa || !filterPreferences.roa.defaultSort) {
      filterPreferences.roa = {
        filters: [],
        sort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        ref: 'roa'
      } as IPreferenceSet;
    }

    if (!filterPreferences.rai || !filterPreferences.rai.defaultSort) {
      filterPreferences.rai = {
        filters: [],
        sort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        ref: 'rai'
      } as IPreferenceSet;
    }

    if (!filterPreferences.cou || !filterPreferences.cou.defaultSort) {
      filterPreferences.cou = {
        filters: [],
        sort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        defaultSort: {
          sortByField: 'status.estimatedArrival',
          sortByAscending: false
        },
        ref: 'cou'
      } as IPreferenceSet;
    }

    localStorage.setItem('filterPreferences', JSON.stringify(filterPreferences));
  }

  @Action(UpdateFilters)
  updateFilters(ctx: StateContext<FilterPreferencesStateModel>, action: UpdateFilters) {
    const state = {...ctx.getState()};
    ctx.setState({
      ...state,
      [action.payload.ref]: {...state[action.payload.ref], filters: action.payload.filters}
    });
  }

  @Action(AddFilter)
  addFilter(ctx: StateContext<FilterPreferencesStateModel>, action: AddFilter) {
    const state = {...ctx.getState()};
    const filters = state[action.payload.ref].filters;
    filters.push(action.payload.filter)
    ctx.setState({
      ...state,
      [action.payload.ref]: {...state[action.payload.ref], filters: filters}
    });
  }

  @Action(RemoveFilter)
  removeFilter(ctx: StateContext<FilterPreferencesStateModel>, action: RemoveFilter) {
    const state = {...ctx.getState()};
    const filters = state[action.payload.ref].filters.filter(f => (f.id != action.payload.filterID));
    ctx.setState({
      ...state,
      [action.payload.ref]: {...state[action.payload.ref], filters: filters}
    });
  }

  @Action(UpdateSorting)
  updateSorting(ctx: StateContext<FilterPreferencesStateModel>, action: UpdateSorting) {
    const state = {...ctx.getState()};
    ctx.setState({
      ...state,
      [action.payload.ref]: {...state[action.payload.ref], sort: action.payload.sort}
    });
  }
}
