import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
    selector:'oms-user-password-form',
    templateUrl:'./password-creation-form.component.html',
    styleUrls:['./password-creation-form.component.scss']
})

export class UserPasswordFormComponent {
    @Input() passwordForm: UntypedFormGroup;
}