<div>
  <div class="row">
    <label class="detail-field-title">{{label}}</label>
  </div>
  <div class="row detail-field-content">
    <mat-form-field [ngClass]="{'extended-width': extendedWidth}" appearance="outline">
      <input type="text" placeholder="Enter Name or Code..." aria-label="Unloco" matInput [formControl]="searchControl"
             [required]="required" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let unloco of unlocos" [value]="unloco">
          ({{unloco.locode}}) {{unloco.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-icon *ngIf="ngControl.value" matSuffix class="icon-action-red" (click)="clearField($event)">delete</mat-icon>
    </mat-form-field>
  </div>
</div>
