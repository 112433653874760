import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { UserService } from "@modules/auth/services/user.service";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class InviteGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, private toastr: ToastrService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const inviteToken = route.params.inviteToken;
    return this.userService.getUserInviteStatus(inviteToken).pipe(map((status) => {
      if (status !== "VALID") {
        this.toastr.error(status);
        return this.router.parseUrl('/unauthorized');
      } 
      return true;
    }));
  }
}