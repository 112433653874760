import {Component, Input} from "@angular/core";
import {Booking} from "@models/booking.model";
import {BookingSyncStatus} from "@models/helpers";
import {UserService} from "@modules/auth/services/user.service";

@Component({
  selector: 'app-om-sync-status',
  styleUrls: ['./sync-status.scss'],
  templateUrl: './sync-status.html',
})

export class SyncStatus {
  private _job: any;
  private StatusEnum = BookingSyncStatus
  @Input() public statusKey = 0

  @Input() set job(value: Booking) {
    this._job = value;
  }

  get jobData(): any {
    return this._job;
  }

  public getUserIsAdmin(): boolean {
    return this.userService.userValue.isOmsAdmin;
  }

  public getIcon(): string {
    const syncResult = this.jobData?.syncTaskResults
    if(syncResult && syncResult.length > 0) {
      const singleItem = syncResult.find((item: any) => item.type === this.statusKey)
      if(singleItem) {
        switch (singleItem.status) {
          case BookingSyncStatus.InProgress:
            return 'refresh';
          case BookingSyncStatus.Success:
            return 'check_circle';
          case BookingSyncStatus.Failure:
            return 'error';
          case BookingSyncStatus.Done:
            return 'block';
          case BookingSyncStatus.Skip:
            return 'next_plan';
          case BookingSyncStatus.Delay:
            return 'access_time';
          default:
            return ''
        }
      }
      return ''
    }
    return ''
  }

  public getMsg(): string {
    const syncResult = this.jobData?.syncTaskResults
    if(syncResult && syncResult.length > 0) {
      const singleItem = syncResult.find((item: any) => item.type === this.statusKey)
      if(singleItem) {
        switch (singleItem.status) {
          case BookingSyncStatus.InProgress:
            return 'Synchronization in progress';
          case BookingSyncStatus.Success:
            return 'Synchronization successfully';
          case BookingSyncStatus.Failure:
            return singleItem.errorLog || 'Synchronization failed';
          case BookingSyncStatus.Done:
            return 'Synchronization done';
          case BookingSyncStatus.Skip:
            return 'Synchronization skipped';
          case BookingSyncStatus.Delay:
            return singleItem.errorLog || 'Synchronization delayed';
          default:
            return ''
        }
      }
      return ''
    }
    return ''
  }

  constructor(public userService: UserService) {}
}
