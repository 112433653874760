import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: ` <div class="row center">
    <mat-spinner></mat-spinner>
  </div>`,
  styles: [
    `
      .center {
        justify-content: center;
        margin-top: 15vh;
        margin-bottom: 15vh;
      }

      ::ng-deep .mat-progress-spinner circle,
      .mat-spinner circle {
        stroke: #0077e7;
      }
    `,
  ],
})
export class LoadingIndicatorComponent  {}
