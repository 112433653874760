import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { OptionsGroupComponent } from './options-group/options-group.component';
import { IPreferenceSet } from './state/filter-preferences.state';
import {SearchService} from "@common-lib/services/search.service";

@Component({
  selector: 'app-table-filter-panel',
  templateUrl: './table-column-filter.html',
  styleUrls: ['./table-column-filter.scss']
})
export class TableColumnFilter implements OnInit, OnDestroy {
  downloading = false;
  public preferences$: Observable<IPreferenceSet>;
  public filterCount: number =  null;
  public preferences: IPreferenceSet;
  public pageType =  'container';
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    const urlPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    switch (urlPath) {
      case 'containers':
        this.pageType =  'container';
        this.preferences$ = this.store.select(state => state.filterPreferences.container);
        break;
      case 'air-shipments':
        this.pageType =  'air';
        this.preferences$ = this.store.select(state => state.filterPreferences.air);
        break;
      case 'sea-shipments':
        this.pageType =  'sea';
        this.preferences$ = this.store.select(state => state.filterPreferences.sea);
        break;
      case 'roa-shipments':
        this.pageType =  'roa';
        this.preferences$ = this.store.select(state => state.filterPreferences.roa);
        break;
      case 'rai-shipments':
        this.pageType =  'rai';
        this.preferences$ = this.store.select(state => state.filterPreferences.rai);
        break;
      case 'cou-shipments':
        this.pageType =  'cou';
        this.preferences$ = this.store.select(state => state.filterPreferences.cou);
        break;
    }

    this.preferences$.pipe(takeUntil(this.unsubscribe$)).subscribe(preferences => {
      this.filterCount = (preferences.filters.length) ? preferences.filters.length : null;
      this.preferences = preferences;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openPanel(tabIndex: number): void {
    this.dialog.open(OptionsGroupComponent, {
      panelClass: 'my-centered-dialog',
      disableClose: false,
      height: 'calc(100vh - 95px)',
      width: '800px',
      position: { right: '0px', top: '95px' },
      data: { tabIndex, preferences: this.preferences$, pageType: this.pageType},
      maxHeight: '100vh',
    });
  }

  public download() {
    this.downloading = true;
    let datasetName = '';

    if (window.location.pathname.includes("containers")) datasetName = "Containers";
    else if (window.location.pathname.includes("air-shipments")) datasetName = "AirShipments";
    else if (window.location.pathname.includes("sea-shipments")) datasetName = "SeaShipments";
    else if (window.location.pathname.includes("roa-shipments")) datasetName = "RoaShipments";
    else if (window.location.pathname.includes( "rai-shipments")) datasetName = "RaiShipments";
    else if (window.location.pathname.includes("cou-shipments")) datasetName = "CouShipments";
    else return;

    this.searchService.downloadDataset(datasetName, this.preferences).pipe(first()).subscribe(() => this.downloading = false);
  }

}
