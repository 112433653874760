import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Filter } from '../../../models/filter.model';
import { AddFilter, RemoveFilter, UpdateFilters, UpdateSorting } from './filter-preferences.actions';

export interface IPreferenceSet {
  filters: Filter[];
  sort: { sortByField: string; sortByAscending: boolean };
  ref: string;
}

export class FilterPreferencesStateModel {
  public air: IPreferenceSet;
  public sea: IPreferenceSet;
  public roa: IPreferenceSet;
  public rai: IPreferenceSet;
  public container: IPreferenceSet;
}

@State<FilterPreferencesStateModel>({
  name: 'filterPreferences',
  defaults: {
    air: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedDelivery',
        sortByAscending: false,
      },
      ref: 'air',
    } as IPreferenceSet,
    sea: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedDelivery',
        sortByAscending: false,
      },
      ref: 'sea',
    } as IPreferenceSet,
    roa: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedDelivery',
        sortByAscending: false,
      },
      ref: 'roa',
    } as IPreferenceSet,
    rai: {
      filters: [],
      sort: {
        sortByField: 'status.estimatedDelivery',
        sortByAscending: false,
      },
      ref: 'rai',
    } as IPreferenceSet,
    container: {
      filters: [],
      sort: {
        sortByField: 'shipmentLegs.etd',
        sortByAscending: false,
      },
      ref: 'container',
    } as IPreferenceSet,
  },
})
@Injectable({
  providedIn: 'root',
})
export class FilterPreferencesState {
  constructor() {}

  @Action(UpdateFilters)
  updateFilters(ctx: StateContext<FilterPreferencesStateModel>, action: UpdateFilters) {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      [action.payload.ref]: { ...state[action.payload.ref], filters: action.payload.filters },
    });
  }

  @Action(AddFilter)
  addFilter(ctx: StateContext<FilterPreferencesStateModel>, action: AddFilter) {
    const state = { ...ctx.getState() };
    const filters = state[action.payload.ref].filters;
    filters.push(action.payload.filter);
    ctx.setState({
      ...state,
      [action.payload.ref]: { ...state[action.payload.ref], filters: filters },
    });
  }

  @Action(RemoveFilter)
  removeFilter(ctx: StateContext<FilterPreferencesStateModel>, action: RemoveFilter) {
    const state = { ...ctx.getState() };
    const filters = state[action.payload.ref].filters.filter(f => f.id != action.payload.filterID);
    ctx.setState({
      ...state,
      [action.payload.ref]: { ...state[action.payload.ref], filters: filters },
    });
  }

  @Action(UpdateSorting)
  updateSorting(ctx: StateContext<FilterPreferencesStateModel>, action: UpdateSorting) {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      [action.payload.ref]: { ...state[action.payload.ref], sort: action.payload.sort },
    });
  }
}
