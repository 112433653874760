import { Component } from '@angular/core';

@Component({
    selector: 'app-om-card-header',
    styles: [''],
    template: `
    <div class="om-card-header">
        <span class="cf-header-text"><ng-content select="[title]"></ng-content></span>
        <ng-content></ng-content>
    </div>
    `
})
export class OMCardHeaderComponent {
    constructor() {}
}
