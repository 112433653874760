import { Injectable } from '@angular/core';
import { PurchaseOrder } from '@models/purchase-order.model';
import { PurchaseOrderLine } from '@models/purchase-order-line.model';

@Injectable()
export class PurchaseOrderLineSelectionService {
  constructor() {}

  public movePurchaseOrderLine(to: any[], from: any[], purchaseOrderLine: any): [any[], any[]] {
    to = to.slice(0);
    from = from.slice(0);

    const toIndex = to.findIndex(po => po.id === purchaseOrderLine.purchaseOrderId);
    const fromIndex = from.findIndex(po => po.id === purchaseOrderLine.purchaseOrderId);

    const fromPo = from[fromIndex];

    // If po header already exists in destination array add purchase order line.
    if (toIndex > -1) {
      const toPo = to[toIndex];
      toPo.isExpanded = true;
      const poLines = toPo.poLines.slice(0); // for on-push detection
      poLines.push(Object.assign({}, purchaseOrderLine));
      toPo.poLines = poLines;
    } else if (from[fromIndex]) {
      // If po header does not exist in destination array add po header with order line.
      const po = Object.assign({}, from[fromIndex]);
      po.isExpanded = fromPo.isExpanded;
      po.poLines = [];
      po.poLines.push(Object.assign({}, purchaseOrderLine));
      to.unshift(po);
    }
    if (fromPo) {
      // Remove purchase order line from origin array.
      const fromPoLineIndex = fromPo.poLines.findIndex(pol => pol.id === purchaseOrderLine.id);
      fromPo.poLines.splice(fromPoLineIndex, 1);
      fromPo.poLines = fromPo.poLines.slice(0); // for on-push detection

      // If there are no more purchase order lines within origin purchase order header
      // remove the purchase order header from the origin array.
      if (fromPo.poLines.length < 1) from.splice(fromIndex, 1);
    }

    return [to, from];
  }

  public selectPurchaseOrderLine(to: PurchaseOrder[], from: PurchaseOrder[], purchaseOrderLine: any): [any[], any[]] {
    if (from.length > 0) {
      (from[from.findIndex(po => po.id === purchaseOrderLine.purchaseOrderId)] as any).isExpanded = true;
    }
    return this.movePurchaseOrderLine(to, from, purchaseOrderLine);
  }

  public removePurchaseOrderLine(to: PurchaseOrder[], from: PurchaseOrder[], purchaseOrderLine: any): [any[], any[]] {
    (from[from.findIndex(po => po.id === purchaseOrderLine.purchaseOrderId)] as any).isExpanded = true;
    return this.movePurchaseOrderLine(to, from, purchaseOrderLine);
  }

  public initialPurchaseOrder(current: PurchaseOrder[], to: PurchaseOrder[], from: PurchaseOrder[]): [any[], any[]] {
    const total = from.slice(0);
    /**
     * get all POs (from + to)
     */
    to.forEach(po => {
      po.poLines.forEach((line: any) => {
        const poIndex = total.findIndex(p => p.id === po.id);
        if (poIndex > -1) {
          const curPo = total[poIndex];
          const poLines = curPo.poLines.slice(0);
          const polineIndex = poLines.findIndex(l => l.id === line.id);
          if (polineIndex < 0) {
            // not exist
            poLines.push(Object.assign({}, line));
          }
          curPo.poLines = poLines;
        } else {
          const newPO = Object.assign({}, po);
          po.poLines = [];
          po.poLines.push(Object.assign({}, line));
          total.push(po);
        }
      });
    });

    current.forEach(po => {
      po.poLines.forEach((line: any) => {
        const totalIndex = total.findIndex(po => po.id === line.purchaseOrderId);
        if (totalIndex > -1) {
          const totalPo = total[totalIndex];
          // Remove purchase order line from origin array.
          const totalPoLineIndex = totalPo.poLines.findIndex(pol => pol.id === line.id);
          totalPo.poLines.splice(totalPoLineIndex, 1);
          totalPo.poLines = totalPo.poLines.slice(0); // for on-push detection

          // If there are no more purchase order lines within origin purchase order header
          // remove the purchase order header from the origin array.
          if (totalPo.poLines.length < 1) total.splice(totalIndex, 1);
        }
      });
    });

    return [current, total];
  }
}
