import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchSubject = new BehaviorSubject<string>('');
  public searchObservable = this.searchSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private httpClient: HttpClient) {}

  public search(searchTerm: string): Observable<any> {
    searchTerm = searchTerm.replace(/\ /g, '*');
    return this.httpClient.get<any>(`${environment.apiBaseURL}Booking/SystemWideSearch?keyword=${searchTerm}`);
  }

  public searchLite(searchTerm: string): Observable<any> {
    searchTerm = searchTerm.replace(/\ /g, '*');
    return this.httpClient.get<any>(`${environment.apiBaseURL}PurchaseOrder/lite/systemWideSearch?keyword=${searchTerm}`);
  }
}
