import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateColumns, UpdatePoLineColumns } from './column-settings.actions';
import { PoColumn } from './column.model';

export class PoLinesColumnStateModel {
  public columns: PoColumn[];
}

export const DEFAULT_PO_LINE = {
  columns: [
    new PoColumn('lineNumber'),
    new PoColumn('skuItemNumber'),
    new PoColumn('description'),
    new PoColumn('productDescription'),
    new PoColumn('lineType'),
    new PoColumn('bookingQuantity'),
    new PoColumn('orderQuantity'),
    new PoColumn('receivedQuantity'),
    new PoColumn('shippedQuantity'),
    new PoColumn('balanceQuantity'),
    new PoColumn('quantityUnit'),
    new PoColumn('itemPrice').setType('currency'),
    new PoColumn('linePrice').setType('currency'),
    new PoColumn('unitCost').setType('currency'),
    new PoColumn('origin'),
    new PoColumn('manufacturer.name', 'Manufacturer'),
    new PoColumn('manufacturerLocation.name', 'Manufacturer Address'),
    new PoColumn('weight').setUnitAccessor('weightUnit'),
    new PoColumn('height').setUnitAccessor('heightUnit'),
    new PoColumn('length').setUnitAccessor('lengthUnit'),
    new PoColumn('width').setUnitAccessor('widthUnit'),
    new PoColumn('volume').setUnitAccessor('volumeUnit'),
    new PoColumn('size'),
    new PoColumn('color'),
    new PoColumn('dgClass'),
    new PoColumn('dgWeight'),
    new PoColumn('dgUw'),
    new PoColumn('htsCode'),
    new PoColumn('duty'),
    // new PoColumn('incoterm'),
    // new PoColumn('confirmDate').setType('date'),
    // new PoColumn('confirmNumber'),
    // new PoColumn('requiredInStoreDate').setType('date'),
    new PoColumn('specialInstructions'),
  ],
};

@State<PoLinesColumnStateModel>({
  name: 'PoLinesColumnsState',
  defaults: {
    columns: DEFAULT_PO_LINE.columns,
  },
})
@Injectable({
  providedIn: 'root',
})
export class PoLinesColumnsState {
  constructor() {}

  @Selector()
  public static getColumnPreferences(state: PoLinesColumnStateModel): PoColumn[] {
    let tableColumns = [];
    if (state.columns) {
      // Remove columns in the saved state that are no longer in the DEFAULT columns
      for (let i = state.columns.length - 1; i >= 0; --i) {
        const found = DEFAULT_PO_LINE.columns.findIndex(c => c.accessor === state.columns[i].accessor) > -1;
        if (!found) {
          state.columns.splice(i, 1);
        }
      }

      tableColumns = state.columns;
      DEFAULT_PO_LINE.columns.forEach(col => {
        // Add any new DEFAULT columns that were not already in the saved state.
        const found = state.columns.findIndex(c => c.accessor === col.accessor) > -1;
        if (!found) {
          tableColumns.push(col);
        }
      });
      state.columns = tableColumns;
    }
    return state.columns;
  }

  @Action(UpdatePoLineColumns)
  public updateColumns(ctx: StateContext<PoLinesColumnStateModel>, action: UpdateColumns): void {
    const state = { ...ctx.getState() };
    ctx.setState({
      ...state,
      ...action.payload,
    });
  }
}
