import { ShipmentDocument, SyncStatus } from '@models/document.model';

export class Shipment {
  public id: string;
  public localOwnerID: string;
  public orgID?: number;
  public shippersRef: string;
  public details: ShipmentDetails;
  public status: ShipmentStatus;
  public addressing: ShipmentAddressing;
  public packLines: ShipmentPackLine[];
  public shipmentLegs: ShipmentLeg[];
  public consolidations: ShipmentConsolidation[];
  public documents: ShipmentDocument[];
  public events: AirShipmentEvent[];
  public orderReferences: string[];
  public milestones: ShipmentMilestone[];
  public declarationDetails: ShipmentDeclarationDetails[];
  public declarationMessages: ShipmentDeclarationMessages[];
  public pga: PGA[];
  public isImport: boolean;
  public isExport: boolean;
  public flight?: Flight;
  public shipmentNotes: Note[];
  public noteCount: number;
  public vesselPositionData: any;
  public orderRefTooltip?: string[];
  public shippersRefArr: string[];
  public orderRefHighlight?: boolean;
  public declarationAndPGAMsges: CustomsMessage[];

  constructor(init?: Partial<Shipment>) {
    Object.assign(this, init);
    if (!this.shipmentLegs) {
      this.shipmentLegs = [];
    }
    if (!this.documents) {
      this.documents = [];
    }
    if (!this.events) {
      this.events = [];
    }
    if (!this.packLines) {
      this.packLines = [];
    }
    if (!this.orderReferences) {
      this.orderReferences = [];
    }
    if (!this.milestones) {
      this.milestones = [];
    }
    if (!this.declarationMessages) {
      this.declarationMessages = [];
    }
    if (!this.pga) {
      this.pga = [];
    }
    if (!this.consolidations) {
      this.consolidations = [];
    }
    if (this.details.transportType === 'SEA' && !this.details.isDeclaration) {
      this.details.chargeableUnit = this.details.volumeUnit;
    } else {
      this.details.chargeableUnit = this.details.weightUnit;
    }

    if (!this.shippersRef) {
      this.shippersRefArr = [];
    } else {
      const arr = this.shippersRef.split(',');
      this.shippersRefArr = arr.filter(r => r !== '');
    }

    if (!this.declarationDetails[0]?.declarationIsExport) {
      this.declarationAndPGAMsges = this.GetDeclarationAndPGAMessages();
    }

  }

  get currentLeg(): ShipmentLeg {
    return this.shipmentLegs.find(leg => leg.currentLeg);
  }

  public GetDeclarationAndPGAMessages(): CustomsMessage[] {
    const declarationMessages = this.declarationMessages.map(d => {
      const message = new CustomsMessage();
      message.title = d.message;
      message.message = d.message;
      message.postDate = new Date(Date.parse(d.postDate));
      message.type = 'CUS';
      return message;
    });
    const pgaMessages = this.pga.map(p => {
      const message = new CustomsMessage();
      message.title = p.pgAstatus;
      message.message = p.agency;
      message.postDate = new Date(p.pgaDate);
      message.type = 'PGA';
      return message;
    });
    const messages = [...declarationMessages, ...pgaMessages]?.sort(
      (a, b) => a.postDate.valueOf() - b.postDate.valueOf()
    );
    return messages;
  }

  public getAllMileStones(): ShipmentMilestone[] {
    const milestones: ShipmentMilestone[] = [];
    const containers = this.getRelatedContainers();

    const mileStonePickup = new ShipmentMilestone({
      mileEventDescription: 'Pick Up From Supplier',
    });
    const mileStoneArrival = new ShipmentMilestone({
      mileEventDescription: 'Arrival at Destination',
    });
    const mileStoneDeparture = new ShipmentMilestone({
      mileEventDescription: 'Departure From Origin',
    });
    const mileStoneDelivered = new ShipmentMilestone({
      mileEventDescription: 'Delivered to Consignee',
    });

    if (
      this.status?.pickupCompleted != null ||
      (containers.length > 0 && containers.filter(c => c.isPickedUp === true).length === containers.length)
    ) {
      mileStonePickup.completed = true;
      if (this.status?.pickupCompleted != null) {
        mileStonePickup.mileActualDate = this.status.pickupCompleted;
      }
    } else {
      mileStonePickup.completed = false;
    }
    if (this.status?.actualDeparture) {
      mileStoneDeparture.mileActualDate = this.status.actualDeparture;
      mileStoneDeparture.completed = true;
      mileStonePickup.completed = true;
    } else {
      mileStoneDeparture.mileEstimatedDate = this.status.estimatedDeparture;
      mileStoneDeparture.completed = false;
    }
    if (this.shipmentLegs?.sort((a, b) => a.legOrder - b.legOrder)[this.shipmentLegs?.length - 1]?.ata != null) {
      mileStoneArrival.mileActualDate = this.status.actualArrival;
      mileStoneArrival.completed = true;
      mileStonePickup.completed = true;
      mileStoneDeparture.completed = true;
    } else {
      mileStoneArrival.mileEstimatedDate = this.status.estimatedArrival;
      mileStoneArrival.completed = false;
    }
    if (
      this.status?.actualDelivery ||
      (this.status?.currentFriendly === 'Returned Empty' && this.details?.packingMode === 'FCL')
    ) {
      mileStoneDelivered.mileActualDate = this.status.actualDelivery;
      mileStoneDelivered.completed = true;
      mileStoneArrival.completed = true;
      mileStoneDeparture.completed = true;
      mileStonePickup.completed = true;
    } else {
      mileStoneDelivered.completed = false;
      mileStoneDelivered.mileEstimatedDate = this.status.estimatedDelivery;
    }

    if (
      containers.length > 0 &&
      containers.filter(c => c.returnInGate != null).length === containers.length &&
      this.details?.packingMode === 'FCL'
    ) {
      mileStoneDelivered.completed = true;
      mileStoneArrival.completed = true;
      mileStoneDeparture.completed = true;
      mileStonePickup.completed = true;
    }

    if (this.shipmentLegs?.length > 0 && this.shipmentLegs?.filter(l => l.atd != null).length > 0) {
      mileStoneDeparture.completed = true;
      mileStonePickup.completed = true;
    }

    milestones.push(mileStonePickup);
    milestones.push(mileStoneDeparture);
    milestones.push(mileStoneArrival);
    milestones.push(mileStoneDelivered);
    return milestones;
  }

  public getRelatedContainers(): ShipmentConsolidationContainer[] {
    if (this.consolidations[0]?.containers?.length > 0) {
      return this.consolidations[0]?.containers.filter(container => container.number || container.number === '');
    } else {
      return [];
    }
  }
}

class ShipmentDetails {
  public isShipment: boolean;
  public isDeclaration?: boolean;
  public shipmentNumber: string;
  public houseBill: string;
  public transportType: string;
  public serviceLevel: string;
  public releaseType: string;
  public releaseTypeFriendly: string;
  public packingMode: string;
  public packingModeFriendly: string;
  public goodsCurrency: string;
  public goodsDescription: string;
  public goodsValue: number;
  public insuranceCurrency: string;
  public insuranceValue: number;
  public isImport: boolean;
  public isExport: boolean;
  public chargeable: number; // DB Table: JobShipment, Field: JS_ActualChargeable, CW1 Field: Basic Registration -> Details -> Chargeable
  public chargeableUnit: string; // If TransportType is SEA then VolumeUnit, Otherwise WeightUnit
  public totalWeight: number;
  public weightUnit: string;
  public totalVolume: number;
  public volumeUnit: string;
  public totalPack: number; // DB Table: JobShipment, Field: JS_OuterPacks, CW1 Field:  Basic Registration -> Details -> Packs
  public incoTerms: string;
  public incoTermsFriendly: string;
  public additionalTerms: string;
  public fclAvailable: Date;
  public originPort: Port;
  public destinationPort: Port;
  public vessel: string;

  constructor(init?: Partial<ShipmentDetails>) {
    Object.assign(this, init);
  }
}

export interface Port {
  code: string;
  countryCode: string;
  name: string;
  state?: string;
  stateCode?: string;
  latitude?: number;
  longitude?: number;
}

class ShipmentStatus {
  public actualArrival: string; // DB Table: JobDocsAndCartage, Field: JP_DeliveryCartageCompleted, CW1 Field:  Delivery -> Delivery Details -> Actual Delivery
  public actualDeparture: string;
  public actualDelivery: string; // DB Table: JobDocsAndCartage, Field: JP_DeliveryCartageCompleted, CW1 Field:  Delivery -> Delivery Details -> Actual Delivery
  public pickupCompleted: string;
  public pickupAdvised: string;
  public currentFriendly: string;
  public currentTransport: string;
  public customs: string;
  public delivery: string;
  public deliveryAdvised: string;
  public estimatedArrival: string; // DB Table: JobShipment, Field: JS_E_ARV, CW1 Field:  Basic Registration -> Details -> ETA
  public estimatedDelivery: string; // DB Table: JobDocsAndCartage, Field: JP_EstimatedDelivery, CW1 Field:  Delivery -> Delivery Details -> Estimated Delivery
  public estimatedDeparture: string; // DB Table: JobShipment, Field: JS_E_DEP, CW1 Field:  Basic Registration -> Details -> ETD
  public isPriority: boolean;
  public oblTlx: string;
}

export class ShipmentAddressing {
  public consignee: string;
  public consigneeOrgPk?: string;
  public consignor: string; // DB Table: OrgHeader, Field: OH_FullName
  public consignorOrgPk?: string;
  public shipper: string; // DB Table: OrgHeader, Field: OH_FullName (same as Consignor)
  public shipperOrgPk?: string;
  public delivery?: string;
  public deliveryOrgPk?: string;
  public pickup?: string;
  public pickupOrgPk?: string;
  public importer?: string;
  public importerOrgPk?: string;
  public consigneeAddress: ShipmentAddress;
  public consignorAddress: ShipmentAddress;
  public deliveryAddress: ShipmentAddress;
  public importerAddress?: ShipmentAddress;
  public pickupAddress: ShipmentAddress;
  public notifyPartyAddress: ShipmentAddress;
}

export class ShipmentAddress {
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public stateName: string;
  public postalCode: string;
  public countryCode: string;
  public countryName: string;
}

export class PacklineColumn {
  public name: string;
  public enabled: boolean;
}

export class ShipmentPackLine {
  public static readonly PacklineColumns: PacklineColumn[] = [
    {
      name: 'Description',
      enabled: true,
    },
    {
      name: 'Packs',
      enabled: true,
    },
    {
      name: 'Weight',
      enabled: true,
    },
    {
      name: 'Volume',
      enabled: true,
    },
    {
      name: 'Length',
      enabled: true,
    },
    {
      name: 'Width',
      enabled: true,
    },
    {
      name: 'Height',
      enabled: true,
    },
    {
      name: 'Pack Type',
      enabled: true,
    },
    {
      name: 'Container Number',
      enabled: true,
    },
    {
      name: 'Shipment Number',
      enabled: true,
    },
    {
      name: 'House Bill',
      enabled: true,
    },
    {
      name: 'Commodity Code',
      enabled: true,
    },
    {
      name: 'Item Number',
      enabled: true,
    },
    {
      name: 'Line Price',
      enabled: true,
    },
    {
      name: 'Ref Number',
      enabled: true,
    },
  ];

  public id: string;
  public container: string;
  public containerNumber: string;
  public shipment: string;
  public weight: number; // DB Table: JobPackLines, Field: JL_ActualWeight, CW1 Field:  Packing -> Packs -> Weight
  public weightUnit: string;
  public volume: number;
  public volumeUnit: string;
  public packCount: number;
  public itemNumber: number;
  public endItemNumber: number;
  public linePrice: number;
  public commodityCode: string;
  public commodityCodeFriendly: string;
  public refNumber: string;
  public description: string;
  public packTypeCode: string;
  public packTypeFriendly: string;
  public containers: ShipmentPackLineContainer[];
  public length: number;
  public width: number;
  public height: number;
}

export class ShipmentPackLineContainer {
  public id: string;
  public number: string;
}

export class ShipmentLeg {
  public legId: string;
  public consolidationId: string;
  public currentLeg: boolean;
  public isDeliveryLeg: boolean;
  public legOrder: number;
  public consolidationStatus: string;
  public consolidationImportExport: string;
  public isExport: boolean;
  public isImport: boolean;
  public etd: string;
  public eta: string;
  public transportMode: string;
  public transportType: string;
  public vessel: string;
  public voyageflight: string;
  public loadport: string;
  public loadportname: string;
  public loadportcountry: string;
  public dischargeport: string;
  public dischargeportname: string;
  public dischargeportcountry: string;
  public dischargeportlatitude: number;
  public dischargeportlongitude: number;
  public rvVesselType: string;
  public rvCode: string;
  public rvLloydsNumber: string;
  public atd: string;
  public ata: string;
  public loadportlatitude: number;
  public loadportLongitude: number;
  public sta: string;
  public std: string;
  public isDelivered: boolean;
  public isBooking: boolean;
}

export class ShippingStop {
  public LocationName: string;
  public LocationCountry: string;
  public StopType: string;
  public IsFirst: boolean;
  public IsLast: boolean;
  public IsComplete: boolean;
  public IsCurrent: boolean;
  public IsActive: boolean;
  public IsFuture: boolean;
  public Etd: string;
  public Eta: string;
  public Atd: string;
  public Ata: string;
}

export class ShipmentConsolidation {
  public number: string;
  public masterBill: string;
  public containers: ShipmentConsolidationContainer[];
  public shipments: ShipmentConsolidationShipment[];
}

export class ShipmentConsolidationContainer {
  public id: string;
  public mode: string;
  public modeFriendly: string;
  public number: string;
  public weight: number;
  public weightUnit: string;
  public consolidationStatus: string;
  public isPickedUp: boolean;
  public returnInGate: string;
}

export class ShipmentConsolidationShipment {
  public id: string;
  public number: string;
  public houseBill: string;
  public weight: number;
  public weightUnit: string;
  public packCount: number; // DB Table: JobShipment, Field: JS_OuterPacks
  public noteCount: number;
}

export class AirShipmentEvent {
  public description: string;
  public eventTime: string;
  public eventType: string;
  public unlocos: string[];

  constructor(init?: Partial<ShipmentDocument>) {
    Object.assign(this, init);
  }
}

export class ShipmentMilestone {
  public mileEventCode: string;
  public mileEventDescription: string;
  public srcRecord?: string;
  public mileCondTrig?: string;
  public mileActualDate?: string;
  public mileEstimatedDate?: string;
  public completed: boolean;

  constructor(init?: Partial<ShipmentMilestone>) {
    Object.assign(this, init);
  }
}

export class ShipmentDeclarationDetails {
  public dutyFees: number;
  public customsEntryDate: Date;
  public customsEntryNumber: string;
  public itnNumber: string;
  public ownerRef: string;
  public location: string;
  public declarationIsExport: boolean;
  public invoices: Invoice[];
  public invoiceLines: InvoiceLine[];
  public isf: ISF[];
  public statementNumber: string;
  public statementStatus: string;
  public paymentStatus: string;
  public statementPaymentDate: Date;
  public statementDueDate: Date;
  public statementPrintDate: Date;
  public statementCheckNo: string;
  public totalProvDuty: number;
  public totalDuty: number;
  public totalMpf: number;
  public totalHmf: number;
  public totalOther: number;
  public goodsDescription: string;
}

export class Invoice {
  public invoiceNumber: string;
  public supplier: string;
  public importer: string;
  public invoiceTerms: string;
  public totalAmount: string;
  public currency: string;
  public invoiceDate: Date;
  public lines: InvoiceLine[];
}

export class InvoiceLine {
  public lineNumber: string;
  public importer: string;
  public supplier: string;
  public houseBill: string;
  public shipment: string;
  public vessel: string;
  public voyage: string;
  public declarationReference: string;
  public scac: string;
  public masterBill: string;
  public customsQtyUnit: string;
  public customsQty: string;
  public ProductCode: string;
  public product: string;
  public orderReference: string;
  public submittedDate: string;
  public incoTerm: string;
  public consolidation: string;
  public transportMode: string;
  public entryNum: string;
  public provDuty: string;
  public tariff: string;
  public invoiceQty: string;
  public invoiceNumber: string;
  public linePrice: string;
  public description: string;
  public weight: string;
  public weightUnit: string;
  public volume: string;
  public volumeUnit: string;
  public orderNumber: string;
  public duty: string;
  public enteredValue: string;
  public section301DutyAmount: string;
  public mpf: string;
  public hmf: string;
  public addApplicable: boolean;
  public addCaseNumber: string;
  public addDespositRate: string;
  public cvdApplicable: boolean;
  public cvdCaseNumber: string;
  public cvdDepositRate: string;
}

export class ISF {
  public shipmentHouseBill: string;
  public declarationHouseBill: string;
  public isfNumber: string;
  public bookingParty: string;
  public shipToParty: string;
  public placeOfDelivery: string;
  public portOfUnload: string;
  public status: string;
  public commodity: string;
}
export class ShipmentDeclarationMessages {
  public id: string;
  public code: string;
  public message: string;
  public postDate: string;
}

class CustomsMessage {
  public postDate: Date;
  public title: string;
  public message: string;
  public type: string;
}

class PGA {
  public 'pk': string;
  public 'agency': string;
  public 'agencyCode': string;
  public 'pgAstatus': string;
  public 'pgaDate': string;
  public 'pkStatus': string;
}

export class Flight {
  public currentPort: string;
  public currentFlight: string;
  public previousPort: string;
  public previousPortCountry: string;
  public nextPort: string;
  public nextPortCountry: string;
  public latitude: number;
  public longitude: number;
  public bearing: string;
  public altitude: string;
  public speed: string;
  public kmRemaining: string;

  public onBoardShipments: Shipment[];

  constructor(init?: Partial<Flight>) {
    Object.assign(this, init);
  }
}

export class Note {
  public description: string;
  public note: string;
}

export const pickupDates = [
  { Accessor: 'TerminalOrDepotReceival', FieldName: 'Terminal Earliest Receiving' },
  { Accessor: 'TerminalOrDepotCutOff', FieldName: 'Terminal Receiving Cutoff' },
  { Accessor: 'DocCutOff', FieldName: 'Export Docs Due' },
  { Accessor: 'PickupRequiredFrom', FieldName: 'Pickup Required From' },
  { Accessor: 'PickupRequiredBy', FieldName: 'Pickup Required By' },
  { Accessor: 'PickupAdvised', FieldName: 'Pickup Requested' },
  { Accessor: 'EstimatedPickup', FieldName: 'Estimated Pickup' },
  { Accessor: 'PickupCompleted', FieldName: 'Actual Pickup' },
];

export const deliveryDates = [
  { Accessor: 'AvailableDate', FieldName: 'Available Date' },
  { Accessor: 'Storage', FieldName: 'Storage Start' },
  { Accessor: 'DeliveryRequiredFrom', FieldName: 'Delivery Required From' },
  { Accessor: 'DeliveryRequiredBy', FieldName: 'Delivery Required By' },
  { Accessor: 'DeliveryAdvised', FieldName: 'Delivery Requested' },
  { Accessor: 'EstimatedDelivery', FieldName: 'Estimated Delivery' },
  { Accessor: 'ActualDelivery', FieldName: 'Actual Delivery' },
];

export class DateField {
  public FieldName: string;
  public FieldValue: Date;
}

export class Geometry {
  public type: string;
  public coordinates = [];

  constructor(init?: Partial<Geometry>) {
    Object.assign(this, init);
  }
}
