String.prototype.replaceAll = replaceAll;

interface String {
  replaceAll: typeof replaceAll;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(find, replace) : string {
  let str = this;
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}