<div class="time-zone form-field">
  <!--<mat-form-field appearance="outline" floatLabel="always">-->
    <!--<mat-label>Current Time Zone</mat-label>-->
    <!--<mat-select-->
      <!--[value]="currentTimeZone"-->
      <!--[disabled]="true"-->
      <!--(selectionChange)="onTimeZoneSelected($event)"-->
    <!--&gt;-->
      <!--<mat-option [value]="currentTimeZone">-->
        <!--{{ currentTimeZone }}-->
      <!--</mat-option>-->
    <!--</mat-select>-->
  <!--</mat-form-field>-->

  <mat-label>Display Timezone</mat-label>
  <mat-label>{{ currentTimeZone }}</mat-label>
</div>
