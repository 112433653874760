import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlertComponent } from './components/alert.component';
import { AlertsComponent } from './views/alerts.component';
import { PoQueryAlertComponent } from './views/po-query-alert/po-query-alert.component';

@NgModule({
  imports: [CommonModule, SharedModule, MatCardModule, MatMenuModule, MatButtonModule, RouterModule, MatIconModule],
  declarations: [AlertsComponent, AlertComponent, PoQueryAlertComponent],
})
export class AlertModule {}
