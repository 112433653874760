export function checkDuplicateProperty(array, propertyName): boolean {
  const values = {};
  for (let i = 0; i < array.length; i++) {
    const propertyValue = array[i][propertyName];
    if (propertyValue in values) {
      return true;
    }
    values[propertyValue] = true;
  }
  return false;
}

export function deepClone(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepClone(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepClone(obj[key]);
      return newObj;
    }, {});
  }
}
