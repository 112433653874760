import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, iif, Observable, of, defer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ImpersonationRequest } from 'src/app/modules/auth/models/impersonation-request.model';
import { ImpersonationResponse } from 'src/app/modules/auth/models/impersonation-response.model';
import { OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { LoadingService } from 'src/app/modules/auth/services/loading.service';
import { authCodeFlowConfig } from 'src/app/modules/auth/auth.config';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isImpersonatingSubject = new BehaviorSubject<boolean>(false);
  public isImpersonating$ = this.isImpersonatingSubject.asObservable();

  private storedAdminAccessToken = 'stored_admin_access_token';
  private storedAdminIdToken = 'stored_admin_id_token';

  get authenticated(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  constructor(
    private oauthService: OAuthService,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocument();

    this.userService.user$.subscribe(user => {
      if (Object.keys(user).length && this.userService.currentOrg) {
        this.loadingService.setDoneLoading(true);
        const accessToSite =
          this.userService.currentNetwork.customerEnabled || this.userService.currentNetwork.customerLiteEnabled;
        if (!accessToSite) {
          console.error("Customer doesn't have access");
          this.logout();
        }
      }
    });

    // UNCOMMENT TO SUBSCRIBE TO ALL OAUTH EVENTS AND ERRORS FOR DEV/DEBUG PURPOSES
    // this.oauthService.events.subscribe(event => {
    //     if (event instanceof OAuthErrorEvent) {
    //         console.error('OAuthErrorEvent Object:', event);
    //     } else {
    //         console.warn('OAuthEvent Object:', event);
    //     }
    // });

    this.oauthService.events
      .pipe(filter(e => ['discovery_document_loaded'].includes(e.type)))
      .subscribe(e => this.discoveryDocumentedLoaded(e));

    this.oauthService.events.pipe(filter((e: OAuthEvent) => ['token_received'].includes(e.type))).subscribe(_ => {
      if (this.authenticated) {
        // if (localStorage.getItem('isImpersonating')) {
        //   this.setIsImpersonating();
        // }
        this.removeAdminTokens(); // Remove old tokens if any
        this.oauthService.setupAutomaticSilentRefresh({}, 'access_token', false);
        this.oauthService.loadUserProfile().then(_ => {
          this.setIsAuthenticated();
        });
      }
    });

    this.oauthService.events.pipe(filter((e: OAuthEvent) => ['session_terminated'].includes(e.type))).subscribe(_ => {
      this.logout();
    });
  }

  public checkAuthForRoute(route: string): Observable<boolean> {
    if (this.authenticated) {
      if (Object.keys(this.userService.userValue).length) {
        return of(true);
      } else {
        return from(this.userService.initializeUser(this.oauthService.getIdentityClaims()));
      }
    } else {
      return this.trySilentLogin(route);
    }
  }

  // TRYS TO LOGIN IN THE USER SILENTLY
  // REDIRECTS USER TO LOGIN PAGE IF SILENT LOGIN FAILS
  public trySilentLogin(targetUrl?: string): Observable<boolean> {
    // CONFIGURE THE OAUTH SERVICE
    this.oauthService.configure(authCodeFlowConfig);

    // STORE CURRENT URL TO REDIRECT TO AFTER A SUCCESSFUL LOGIN.
    // INGORE SSO REDIRECT URLS.
    if (!targetUrl.includes('IdentityServerApi')) sessionStorage.setItem('recentUrl', targetUrl || '');

    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      map(_ => {
        return this.oauthService.hasValidIdToken() && this.oauthService.hasValidIdToken();
      }),
      switchMap(validTokens =>
        iif(
          () => validTokens,
          defer(() => from(this.userService.initializeUser(this.oauthService.getIdentityClaims()))),
          defer(() => of(false))
        )
      ),
      tap(success => {
        // ON REDIRECT BACK FROM THE SSO NAVIGATE BACK TO THE
        // THE LAST URL THE USER VISITED.
        if (success) {
          const recentUrl = sessionStorage.getItem('recentUrl');
          if (recentUrl && recentUrl != targetUrl) {
            this.router.navigateByUrl(sessionStorage.getItem('recentUrl'));
          }
        }
      })
    );
  }

  public logout(): void {
    this.removeAdminTokens();
    sessionStorage.removeItem('recentUrl');
    localStorage.removeItem('networkId');
    localStorage.removeItem('orgId');
    localStorage.removeItem('orgType');
    if (localStorage.getItem('isImpersonating')) {
      localStorage.removeItem('isImpersonating');
    }
    this.oauthService.logOut();
  }

  public requestImpersonation(userId: string): void {
    this.httpClient
      .post<ImpersonationResponse>(`${environment.issuerUri}/Impersonate/User`, new ImpersonationRequest(userId))
      .subscribe(
        result => {
          if (!result || !result.accessToken || !result.idToken) {
            console.error('Error: No tokens found.');
            return;
          }

          try {
            this.storeAdminTokens(result.accessToken, result.idToken);
          } catch (error) {
            console.error('Error: Received tokens, but unable to store them and begin impersonation.', error);
            this.logout();
          }
        },
        error => {
          console.error('Error: Unable to impersonate user.', error);
        }
      );
  }

  public stopImpersonating(): void {
    this.toastr.info('Loading user information', 'Ending impersonation...');
    console.info('Ending impersonation... Loading original user information.');

    const adminAccessToken = localStorage.getItem(this.storedAdminAccessToken);
    const adminIdToken = localStorage.getItem(this.storedAdminIdToken);

    if (!adminAccessToken || !adminIdToken) {
      throw new Error('No access token or id token found.');
    }

    localStorage.setItem('access_token', adminAccessToken);
    localStorage.setItem('id_token', adminIdToken);

    this.removeAdminTokens();
    this.reloadUserProfileUnsafely();
  }

  private setIsAuthenticated(): void {
    if (this.authenticated) {
      this.setIsImpersonating();
    }
  }

  private setIsImpersonating(): void {
    if (localStorage.getItem(this.storedAdminAccessToken) && localStorage.getItem(this.storedAdminIdToken)) {
      this.oauthService.skipSubjectCheck = true;
      this.isImpersonatingSubject.next(true);
    } else {
      this.oauthService.skipSubjectCheck = false;
      this.isImpersonatingSubject.next(false);
    }
    this.userService.initializeUser(this.oauthService.getIdentityClaims()).then(
      () => {},
      () => {
        setTimeout(() => {
          this.logout();
        }, 3000);
      }
    );
  }

  private storeAdminTokens(impersonatedAccessToken: string, impersonatedIdToken: string): void {
    const adminAccessToken = localStorage.getItem('access_token');
    const adminIdToken = localStorage.getItem('id_token');

    if (!adminAccessToken || !adminIdToken) {
      throw new Error('No access/id token found.');
    }

    localStorage.setItem(this.storedAdminAccessToken, adminAccessToken);
    localStorage.setItem(this.storedAdminIdToken, adminIdToken);
    localStorage.setItem('access_token', impersonatedAccessToken);
    localStorage.setItem('id_token', impersonatedIdToken);
    localStorage.setItem('isImpersonating', 'true');

    this.reloadUserProfileUnsafely();
  }

  private reloadUserProfileUnsafely(): void {
    this.oauthService.skipSubjectCheck = true;
    this.oauthService.loadUserProfile().then(
      _ => {
        console.info('Success... User information loaded!');
        this.setIsImpersonating();
        this.router.navigateByUrl('app');
      },
      error => {
        console.error('Error getting impersonated user profile:', error);
        this.stopImpersonating();
      }
    );
  }

  private removeAdminTokens(): void {
    localStorage.removeItem(this.storedAdminAccessToken);
    localStorage.removeItem(this.storedAdminIdToken);
  }

  private discoveryDocumentedLoaded(e): void {
    if (!e.info) {
      return;
    }
    this.setIsAuthenticated();
  }
}
