import { DocumentService } from '@services/document.service';
import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlobStorageService } from '@services/blob-storage.service';
import { ToastrService } from 'ngx-toastr';
import { Type } from '../../constants';
import { ISaveFileData } from '@models/document.model';

interface UploadItem {
  url: string;
  documentType: string;
  id: number;
  name: string;
  originalName: string;
  size: number;
  fileStorageType: number;
  description?: string;
  inProgress: number;
  uploadAzureStorageSuccess: boolean;
}

@Component({
  selector: 'app-file-selector',
  styleUrls: ['./file-selector.component.scss'],
  template: `
    <app-om-dialog
      [title]="'File Selector'"
      [showFooter]="true"
      (closeDialog)="cancel()"
      [loading]="documentService.isUploadingDoc"
    >
      <app-om-card>
        <app-om-card-content>
          <div class="row">
            <div class="col upload-box" fileDragArea (filesSelected)="detectFiles($event)">
              <div style="margin-top:55px">
                <input type="file" id="fileElem" #fileElem multiple (change)="detectInputFiles($event)" />
                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <label for="fileElem" style="cursor: pointer;">Browse for more files</label>
              </div>
            </div>
            <div *ngIf="readableFileList.length != 0" class="col files-list">
              <div class="single-file" *ngFor="let file of readableFileList; let i = index">
                <div class="single-file-content">
                  <div class="file-icon" style="width: 50px">
                    <mat-icon class="document mt-2">description</mat-icon>
                  </div>
                  <div class="info">
                    <h4 class="name">
                      {{ file?.name }}
                    </h4>
                  </div>
                  <mat-select
                    *ngIf="data.fileTypeOptions.length > 0"
                    [(value)]="docItems[i].documentType"
                    [compareWith]="compareWith"
                    [disabled]="documentService.isUploadingDoc || !initialStatus"
                  >
                    <mat-select-trigger>{{ docItems[i].documentType }}</mat-select-trigger>
                    <mat-option *ngFor="let option of data.fileTypeOptions" [value]="option.code">
                      {{ option.code }} - {{ option.description }}
                    </mat-option>
                  </mat-select>
                  <div class="delete">
                    <mat-icon
                      class="material-icons document-success"
                      *ngIf="!documentService.isUploadingDoc && docItems[i].uploadAzureStorageSuccess && !initialStatus"
                    >
                      check_circle
                    </mat-icon>
                    <mat-icon
                      class="material-icons document-failed"
                      *ngIf="
                        !documentService.isUploadingDoc && !docItems[i].uploadAzureStorageSuccess && !initialStatus
                      "
                    >
                      cancel
                    </mat-icon>
                    <mat-icon
                      class="material-icons document"
                      (click)="deleteFile(i)"
                      *ngIf="!documentService.isUploadingDoc && initialStatus"
                    >
                      delete
                    </mat-icon>
                  </div>
                </div>
                <div *ngIf="documentService.isUploadingDoc">
                  <mat-progress-bar mode="determinate" [value]="docItems[i].inProgress"></mat-progress-bar>
                </div>
              </div>
            </div>
          </div>
        </app-om-card-content>
      </app-om-card>
      <ng-container footer>
        <button
          mat-raised-button
          color="secondary"
          class="mr-2"
          (click)="cancel()"
          [disabled]="documentService.isUploadingDoc"
        >
          Close
        </button>
        <button
          mat-raised-button
          color="primary"
          class="mr-4"
          (click)="upload()"
          [disabled]="!readyForUpload || documentService.isUploadingDoc"
        >
          Upload
        </button>
      </ng-container>
    </app-om-dialog>
  `,
})
export class FileSelectorComponent {
  private _files: FileList;
  @ViewChild('fileElem') el: ElementRef;

  set files(value: FileList) {
    if (value === null || value === undefined) {
      this._files = {} as FileList;
      this.readableFileList = [];
      this.docItems = [];
      this.readyForUpload = false;
      this.initialStatus = true;
    } else {
      this._files = value;
      this.docItems = [];
      this.readableFileList = Array.from(value);
      this.readableFileList.forEach(f => {
        this.docItems.push({
          url: '',
          documentType: this.data.fileTypeOptions[0].code,
          id: 0,
          name: f.name,
          originalName: f.name,
          size: f.size,
          fileStorageType: this.data.fileStorageType,
          inProgress: 0,
          uploadAzureStorageSuccess: false,
        });
      });
      this.readyForUpload = true;
      this.initialStatus = true;
    }
  }
  get files(): FileList {
    return this._files;
  }

  public readyForUpload: boolean = false;

  // FileList's cannot be iterated over so we need to convert to an array for reading
  // in the template
  public readableFileList: File[] = [];
  public docItems: UploadItem[] = [];
  public filesData: ISaveFileData[] = [];
  public initialStatus: boolean = true;
  public successUploaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      uploadURL: string;
      fileTypeOptions: Type[];
      parent: number;
      parentId: number;
      fileStorageType: number;
    },
    public dialogRef: MatDialogRef<FileSelectorComponent>,
    private toastr: ToastrService,
    private blobStorageService: BlobStorageService,
    public documentService: DocumentService
  ) {}

  public async upload(): Promise<boolean | void> {
    if (this.documentService.isUploadingDoc) {
      this.toastr.error('Please wait for the other document to finish uploading');
      return false;
    }

    this.toastr.info(`Uploading file...`);
    this.documentService.isUploadingDoc = true;
    this.initialStatus = false;
    this.filesData = [];
    let downloadFinished = 0;
    this.readyForUpload = false;

    const filesParams = [];

    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      filesParams.push({
        originalName: file.name,
        fileStorageType: this.data.fileStorageType,
        size: file.size,
      });
    }

    const fileRes = await this.blobStorageService.getFilesUploadSAS(filesParams);

    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      const currentFileSASInfo = fileRes.find(f => f.originalName === file.name);

      // upload to Azure blob storage
      const uploadRes = await this.blobStorageService.uploadFileToStorage(
        i,
        file,
        currentFileSASInfo,
        this.handleUploadProgress.bind(this)
      );
      uploadRes.response.then(res => {
        if (res._response.status !== 201) {
          this.toastr.error(`Failed to upload document to Azure storage, please try again`);
          // this.documentService.isUploadingDoc = false;
          this.docItems[i].uploadAzureStorageSuccess = false;
        } else {
          this.docItems[i].name = currentFileSASInfo.fileName;
          this.docItems[i].url = res._response.request.url;
          this.docItems[i].id = currentFileSASInfo.fileId;
          this.docItems[i].uploadAzureStorageSuccess = true;

          const fileData = {
            parent: this.data.parent,
            parentId: this.data.parentId,
            fileId: this.docItems[i].id,
            documentType: this.docItems[i].documentType,
          };
          this.filesData.push(fileData);
        }

        downloadFinished++;
        this.docItems[i].inProgress = 100;

        if (downloadFinished === this.files.length) {
          this.documentService.saveFilesStorage(this.filesData).subscribe(
            res => {
              this.documentService.isUploadingDoc = false;
              this.successUploaded = true;
              this.toastr.success(`Save Documents Successfully`);
            },
            err => {
              this.docItems.forEach(item => {
                item.uploadAzureStorageSuccess = false;
              });
              this.toastr.error(`Save Documents Failed`);
              this.documentService.isUploadingDoc = false;
            }
          );
        }
      });
    }
  }

  public handleUploadProgress(fileIndex: number, fileSize: number, progressNum: number): void {
    this.docItems[fileIndex].inProgress = Math.floor((progressNum / (fileSize + 1000)) * 100);
  }

  public cancel(): void {
    this.dialogRef.close(this.successUploaded);
  }

  public openSystemFileDialog(): void {
    this.el.nativeElement.click();
  }

  public detectFiles(files: FileList): void {
    this.files = files;
  }

  public detectInputFiles(event: any): void {
    this.files = event.target.files;
  }

  public deleteFile(index: number) {
    var file = Array.from(this.files);
    file.splice(index, 1);
    this.files = file as unknown as FileList;
  }

  public compareWith(type1: Type, type2: Type): boolean {
    if (!type1 || !type2) return false;
    return type1.code === type2.code;
  }
}
