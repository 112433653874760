import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IRefsListData {
  title: string;
  list: any;
  config: IRefsListConfig;
}

export interface IRefsListConfig {
  url: string;
  idKey: string;
  textKey: string;
}

@Component({
  selector: 'app-refs-list-dialog',
  templateUrl: './refs-list-dialog.component.html',
  styleUrls: ['./refs-list-dialog.component.scss'],
})
export class RefsListDialogComponent implements OnInit {
  public title: string;
  public dataList: any;
  public dataConfig: IRefsListConfig;
  constructor(
    public dialogRef: MatDialogRef<RefsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRefsListData
  ) {
    this.title = data.title;
    this.dataList = data.list;
    this.dataConfig = data.config;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  public onExternalHyperlinkClick(event: any, url: any, primaryKeyAccessor: any): void {
    event.stopImmediatePropagation();
    window.open(url(primaryKeyAccessor), '_blank');
  }
}
