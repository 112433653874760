import { PurchaseOrderLine, SavePurchaseOrderLine } from './purchase-order-line.model';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { PurchaseOrderStatus } from '@models/helpers';

export class PurchaseOrderBasic {
  public id: number;
  public poNumber: string;
  public poStatus: PurchaseOrderStatus;
  public poDate: Date;
  public poType: string;
  public altPoNumber: string;
  public customerPoNumber: string;
  public isPriority: boolean;
  public revisedCargoReadyDate: Date;
  public actualCargoReadyDate: Date;

  public requiredInStoreDate: string;
  public requiredInStoreDateValue: Date;
  public expectedCargoReadyDate: string;
  public expectedCargoReadyDateValue: Date;
  public inDistCenterDate: string;
  public inDistCenterDateValue: Date;
  public firstShipDate: string;
  public firstShipDateValue: Date;
  public lastShipDate: string;
  public lastShipDateValue: Date;
  public bookByDate: string;
  public bookByDateValue: Date;

  public customerStartShipDate: Date;
  public customerCancelShipDate: Date;
  public originCountry: string;
  public pickupLocation: Location;
  public shipToLocation: Location;
  public transportMode: string;
  public polCode: string;
  public podCode: string;
  public incoterm: string;
  public htsCode: string;
  public supplier: Organization;
  public customer: Organization;
  public buyingAgent: Organization;
  public consignee: Organization;
  public manufacturer: Organization;
  public rejectionCode: string;
  public rejectionMessage: string;
  public lastUpdateUserId: number;
  public containerNumber: string;
  public shipmentNumber: string;
  public skuItemNumbers: string;

  constructor(init?: Partial<PurchaseOrderBasic>) {
    if (init?.id) this.id = init.id;
    if (init?.poNumber) this.poNumber = init.poNumber;
    if (init?.poStatus || init?.poStatus === PurchaseOrderStatus.AwaitingClient) this.poStatus = init.poStatus;
    if (init?.poDate) this.poDate = init.poDate;
    if (init?.poType) this.poType = init.poType;
    if (init?.altPoNumber) this.altPoNumber = init.altPoNumber;
    if (init?.customerPoNumber) this.customerPoNumber = init.customerPoNumber;
    if (init?.isPriority) this.isPriority = init.isPriority;
    if (init?.revisedCargoReadyDate) this.revisedCargoReadyDate = init.revisedCargoReadyDate;
    if (init?.actualCargoReadyDate) this.actualCargoReadyDate = init.actualCargoReadyDate;

    if (init?.requiredInStoreDateValue) {
      this.requiredInStoreDateValue = init.requiredInStoreDateValue;
    } else if (init?.requiredInStoreDate) {
      this.requiredInStoreDate = init.requiredInStoreDate;
      this.requiredInStoreDateValue = new Date(init.requiredInStoreDate);
    }
    if (init?.expectedCargoReadyDateValue) {
      this.expectedCargoReadyDateValue = init.expectedCargoReadyDateValue;
    } else if (init?.expectedCargoReadyDate) {
      this.expectedCargoReadyDate = init.expectedCargoReadyDate;
      this.expectedCargoReadyDateValue = new Date(init.expectedCargoReadyDate);
    }
    if (init?.inDistCenterDateValue) {
      this.inDistCenterDateValue = init.inDistCenterDateValue;
    } else if (init?.inDistCenterDate) {
      this.inDistCenterDate = init.inDistCenterDate;
      this.inDistCenterDateValue = new Date(init.inDistCenterDate);
    }
    if (init?.firstShipDateValue) {
      this.firstShipDateValue = init.firstShipDateValue;
    } else if (init?.firstShipDate) {
      this.firstShipDate = init.firstShipDate;
      this.firstShipDateValue = new Date(init.firstShipDate);
    }
    if (init?.lastShipDateValue) {
      this.lastShipDateValue = init.lastShipDateValue;
    } else if (init?.lastShipDate) {
      this.lastShipDate = init.lastShipDate;
      this.lastShipDateValue = new Date(init.lastShipDate);
    }
    if (init?.bookByDateValue) {
      this.bookByDateValue = init.bookByDateValue;
    } else if (init?.bookByDate) {
      this.bookByDate = init.bookByDate;
      this.bookByDateValue = new Date(init.bookByDate);
    }

    if (init?.customerStartShipDate) this.customerStartShipDate = init.customerStartShipDate;
    if (init?.customerCancelShipDate) this.customerCancelShipDate = init.customerCancelShipDate;
    if (init?.originCountry) this.originCountry = init.originCountry;
    if (init?.pickupLocation) this.pickupLocation = init.pickupLocation;
    if (init?.shipToLocation) this.shipToLocation = init.shipToLocation;
    if (init?.transportMode) this.transportMode = init.transportMode;
    if (init?.polCode) this.polCode = init.polCode;
    if (init?.podCode) this.podCode = init.podCode;
    if (init?.incoterm) this.incoterm = init.incoterm;
    if (init?.htsCode) this.htsCode = init.htsCode;
    if (init?.supplier) this.supplier = init.supplier;
    if (init?.customer) this.customer = init.customer;
    if (init?.buyingAgent) this.buyingAgent = init.buyingAgent;
    if (init?.consignee) this.consignee = init.consignee;
    if (init?.manufacturer) this.manufacturer = init.manufacturer;
    if (init?.rejectionCode) this.rejectionCode = init.rejectionCode;
    if (init?.rejectionMessage) this.rejectionMessage = init.rejectionMessage;
    if (init?.lastUpdateUserId) this.lastUpdateUserId = init.lastUpdateUserId;
    if (init?.containerNumber) this.containerNumber = init.containerNumber;
    if (init?.shipmentNumber) this.shipmentNumber = init.shipmentNumber;
    if (init?.skuItemNumbers) this.skuItemNumbers = init.skuItemNumbers;
  }
}

// This model maps to the Domain.PurchaseOrder
// on the API.
export class PurchaseOrder extends PurchaseOrderBasic {
  public poLines: PurchaseOrderLine[];
  public relatedBookings: { key: number; value: string }[];
  public canEdit: boolean;
  public poLiteStatus: string;

  constructor(init?: Partial<PurchaseOrder>) {
    super(init);
    this.poLines = init?.poLines ? init.poLines : [];
    this.relatedBookings = init?.relatedBookings ? init.relatedBookings : [];
    if (init?.canEdit) this.canEdit = init.canEdit;
    if (init?.poLiteStatus) this.poLiteStatus = init.poLiteStatus;
  }
}

export class SavePurchaseOrder extends PurchaseOrderBasic {
  public poLines: SavePurchaseOrderLine[];

  constructor(init?: Partial<SavePurchaseOrder>) {
    super(init);
    this.poLines = init?.poLines ? init.poLines : [];
  }
}

export class GetPurchaseOrders {
  results: PurchaseOrder[];
  pageNumber: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
  additionalResult: any;

  constructor(init?: Partial<GetPurchaseOrders>) {
    if (init?.results) this.results = init.results;
    if (init?.pageNumber) this.pageNumber = init.pageNumber;
    if (init?.pageCount) this.pageCount = init.pageCount;
    if (init?.pageSize) this.pageSize = init.pageSize;
    if (init?.recordCount) this.recordCount = init.recordCount;
    if (init?.additionalResult) this.additionalResult = init.additionalResult;
  }
}
