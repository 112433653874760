import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Booking } from '@models/booking.model';

@Component({
  selector: 'app-om-job-equipment',
  styles: [''],
  template: `
    <app-om-table
      tableKey="job-equipment-table"
      [dataSource]="job.bookedEquipments"
      [allowRowExpansion]="false"
      [whiteBackground]="true"
      [tableWidth]="'350px'"
      [equipmentTable]="true"
    >
      <ng-container tableToolbar>
        <div class="table-toolbar table-toolbar-small" *ngIf="canEdit">
          <div class="filter-box"></div>
          <div class="column-customizer-box">
            <button
              *ngIf="canEdit"
              (click)="toggleEquipmentEditor.emit()"
              mat-raised-button
              color="primary"
              class="edit-btn"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </app-om-table>
  `,
})
export class JobEquipmentComponent {
  @Input() job: Booking;
  @Input() canEdit: boolean = false;
  @Output() toggleEquipmentEditor: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
