<div *ngIf="!outline">
  <div class="row">
    <label class="detail-field-title">{{ label }}</label>
  </div>
  <div class="row detail-field-content">
    <mat-form-field [ngClass]="{ 'extended-width': extendedWidth }" appearance="outline">
      <input
        type="text"
        placeholder="Enter Name or Code..."
        aria-label="UnlocoCountry"
        matInput
        [formControl]="searchControl"
        [required]="required"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let country of countries" [value]="country">
          ({{ country.code }}) {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-icon *ngIf="ngControl.value" matSuffix class="icon-action-red" (click)="clearField($event)">delete</mat-icon>
    </mat-form-field>
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="outline">
  <mat-form-field class="outline-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      type="text"
      placeholder="Enter Name or Code..."
      aria-label="UnlocoCountry"
      matInput
      [formControl]="searchControl"
      [required]="required"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let country of countries" [value]="country">
        ({{ country.code }}) {{ country.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="ngControl.value" matSuffix class="icon-action-red" (click)="clearField($event)">delete</mat-icon>
  </mat-form-field>
</div>
