import { Pipe, PipeTransform } from "@angular/core";
import { Constants } from "../constants";

@Pipe({
    name: 'cwDocument'
})

export class CwDocumentPipe implements PipeTransform {
    transform(documentCode: string){
        if (!documentCode) return '';
        const docType = Constants.documents.find(d => d.code === documentCode);
        return docType ? docType.description : documentCode;
    }
}