import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network, UserOrg } from '@models/organization.model';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePasswordRequest } from '../models/change-password-request.model';
import { SsoUser, User, IUserInfo } from '../models/user.model';
import { OrgModeText, PageOrgMode, UserMode } from '@models/helpers';
import { OmCacheService } from '@services/om-cache.service';
import { ToastrService } from 'ngx-toastr';
import { ShipmentAddressing } from '@modules/shipment/models/cw-shipment.model';
import { GeoData, IPData } from '@modules/shipment/models/ip-data.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentSsoUser = new SsoUser();

  private _user = new BehaviorSubject<User>(new User());
  public user$ = this._user.asObservable();
  public userCountryCode: string = null;

  public get userValue(): User {
    return this._user.value;
  }

  private _orgMode: PageOrgMode;
  get orgMode(): PageOrgMode {
    return this._orgMode;
  }

  private _userMode: UserMode;
  get userMode(): UserMode {
    return this._userMode;
  }

  private _currentOrg: UserOrg;
  get currentOrg(): UserOrg {
    return this._currentOrg;
  }

  private _currentNetwork: Network;
  get currentNetwork(): Network {
    return this._currentNetwork;
  }

  private _selectedOrg = new ReplaySubject<UserOrg>();
  public selectedOrg$ = this._selectedOrg.asObservable();
  public orgSubscribe;

  public currentTimeZone: string = null;

  constructor(private http: HttpClient, private omCache: OmCacheService, private toastr: ToastrService) {}

  public get getCurrentSsoUser(): SsoUser {
    return this.currentSsoUser;
  }

  public initializeUser(claims: any): Promise<boolean> {
    this.currentSsoUser = new SsoUser(claims.sub, claims.name, claims.preferred_username, claims.preferred_username);
    return new Promise<boolean>((resolveFn, rejectFn) => {
      this.getCurrentUserInfo().subscribe(
        res => {
          const user = res.user;
          user.initials = this.extractUserInitials(user);

          const sessionNetworkId = parseInt(localStorage.getItem('networkId'));
          if (sessionNetworkId) user.currentNetworkId = sessionNetworkId;

          const customerCodes = res.orgs.map(o => o.network.customerCode);
          this.getCustomerPermissions(customerCodes).subscribe(
            perm => {
              let orgs = res.orgs.map(o => {
                const orgSitePermission = perm.find(r => r.cwCode == o.network.customerCode);
                o.network.customerEnabled = orgSitePermission ? orgSitePermission.enableOrderManagement : false;
                o.network.customerLiteEnabled = orgSitePermission ? orgSitePermission.enableOrderManagementLite : false;
                return o;
              });
              user.orgs = orgs.filter(o => o.network.customerEnabled || o.network.customerLiteEnabled);
              if (user.orgs.length == 0) {
                this.toastr.error("You don't have the permission to access the organization for OMS.");
                rejectFn(false);
                return;
              }
              let currentNetwork = null;
              if (!user.currentNetworkId) {
                user.currentNetworkId = user.orgs[0].network.id;
                currentNetwork = user.orgs[0].network;
              } else {
                let currentOrg = orgs.find(o => o.network.id == user.currentNetworkId);
                if (!currentOrg) {
                  user.currentNetworkId = user.orgs[0].network.id;
                  currentNetwork = user.orgs[0].network;
                } else {
                  currentNetwork = currentOrg.network;
                }
              }

              const sessionOrgId = parseInt(localStorage.getItem('orgId'));
              const sessionOrgType = localStorage.getItem('orgType');
              let currentUserOrganization = null;
              if (sessionOrgId) {
                currentUserOrganization = orgs.find(o => o.id == sessionOrgId && o.type == sessionOrgType);
              }
              if (!currentUserOrganization) {
                currentUserOrganization = orgs.find(o => o.network.id == user.currentNetworkId);
              }
              // SET CURRENT Network
              this._currentNetwork = currentNetwork;
              // SET CURRENT ORG
              this._currentOrg = currentUserOrganization;
              this._selectedOrg.next(currentUserOrganization);

              this.setUserMode(user);
              this.setOrgMode();

              this._user.next(user);
              resolveFn(true);
            },
            error => {
              rejectFn(false);
            }
          );
        },
        error => {
          rejectFn(false);
        }
      );
    });
  }

  public refreshUser() {
    this.getCurrentUserInfo().subscribe(res => {
      const user = this.userValue;
      user.firstName = res.user.firstName;
      user.lastName = res.user.lastName;
      user.email = res.user.email;
      user.username = res.user.username;
      user.phoneNumber = res.user.phoneNumber;
      user.initials = this.extractUserInitials(user);
      this._user.next(user);
    });
  }

  public setUserNetwork(network: Network) {
    this._currentNetwork = network;
    const user = Object.assign({}, this.userValue);
    const sessionOrgId = parseInt(localStorage.getItem('orgId'));
    const sessionOrgType = localStorage.getItem('orgType');
    let networkOrgs = user.orgs.filter(o => o.network.id == network.id);

    if (user.isOmsAdmin) {
      networkOrgs = networkOrgs.filter(o => o.type === 'Client' || o.type === 'Supplier');
    }

    localStorage.setItem('networkId', network.id.toString());
    this._user.next({ ...user, currentNetworkId: network.id });

    // lite version: default selection is client
    if (sessionOrgId && !network.customerLiteEnabled) {
      let currentUserOrganization = networkOrgs.find(o => o.id == sessionOrgId && o.type == sessionOrgType);
      if (!currentUserOrganization) {
        const clientOrg = networkOrgs.filter(o => o.type === OrgModeText.Client);
        if (clientOrg.length > 0) {
          currentUserOrganization = clientOrg[0];
        } else {
          currentUserOrganization = networkOrgs[0];
        }
      }
      this.setCurrentOrg(currentUserOrganization);
    } else {
      const clientOrg = networkOrgs.filter(o => o.type === OrgModeText.Client);
      if (clientOrg.length > 0) {
        this.setCurrentOrg(clientOrg[0]);
      } else {
        this.setCurrentOrg(networkOrgs[0]);
      }
    }

    this.setUserMode(user);
    this._user.next({ ...user, currentNetworkId: network.id });

    if (network.customerLegacyId) {
      this.http
        .post<any>(`${environment.clearViewApi}User/SetUserLastOrg?lastOrg=${network.customerLegacyId}`, {})
        .subscribe(() => {});
    }

    this.omCache.buildCacheForCurrentNetwork();
  }

  private setUserMode(user: User) {
    if (user.isOmsAdmin) this._userMode = UserMode.OmsAdmin;
    else if (this._currentOrg.isOrganizationAdmin) this._userMode = UserMode.OrgAdmin;
    else if (this._currentOrg.isNetworkAdmin) this._userMode = UserMode.NetworkAdmin;
    else if (this._currentOrg.isClearfreight) this._userMode = UserMode.Clearfreight;
    else this._userMode = UserMode.User;
  }

  public setCurrentOrg(userOrg: UserOrg) {
    this._currentOrg = userOrg;
    this._selectedOrg.next(userOrg);
    const user = Object.assign({}, this.userValue);
    this.setOrgMode();
    this._user.next({ ...user });
    localStorage.setItem('orgId', userOrg.id.toString());
    localStorage.setItem('orgType', userOrg.type);
  }

  private setOrgMode() {
    // SET CURRENT ORGANIZATION TYPE
    if (this.currentNetwork.customerLiteEnabled) this._orgMode = PageOrgMode.Lite;
    else if (this._currentOrg.type == 'Supplier' || this._currentOrg.type == 'Manufacturer')
      this._orgMode = PageOrgMode.Supplier;
    else if (this._currentOrg.type == 'Consignee' || this._currentOrg.type == 'Client')
      this._orgMode = PageOrgMode.Client;
    else console.warn("No Type Set For Current User's Organization");
  }

  public getCurrentUserInfo(): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${environment.apiBaseURL}User/${this.currentSsoUser.id}`);
  }

  public getCustomerPermissions(customerCodes: string[]): Observable<any> {
    return this.http.post<any>(`${environment.clearViewApi}pg/cworgsitepermissions`, customerCodes);
  }

  public updateUserPassword(request: ChangePasswordRequest): Observable<void> {
    return this.http.post<void>(`${environment.issuerUri}/User/ChangePassword`, request);
  }

  public getAllUsers(): Observable<SsoUser[]> {
    return this.http.get<any[]>(environment.issuerUri + '/User/AllUsers');
  }

  public getAllOmsUsers(): Observable<User[]> {
    return this.http.get<any[]>(environment.apiBaseURL + '/User/admin/allUsers');
  }

  public saveProfile(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseURL}User/SaveProfile/`, payload);
  }

  public confirmAccount(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseURL}User/Confirm/`, payload);
  }

  public getUserInviteStatus(inviteToken: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseURL}User/inviteStatus?token=${inviteToken}`);
  }

  public genetateApiAccessKey(orgId: number): Observable<string> {
    return this.http.get(`${environment.apiBaseURL}/User/admin/generateApiAccessKey?orgId=${orgId}`, {
      responseType: 'text',
    });
  }

  private extractUserInitials(user: User): string {
    let initials = '';
    if (user.firstName.length) initials = initials + user.firstName[0];
    if (user.lastName.length) initials = initials + user.lastName[0];
    return initials.toUpperCase();
  }

  public userDocPermitted(addressing: ShipmentAddressing, docType: string): boolean {
    // if (this.userValue.siteAdministrator || this.currentUserOrg?.isAdmin || this.userValue.clearFreightUsers) {
    //   return true;
    // } else {
    //   const lookupOrg = this.getLookupOrg(addressing);
    //   if (lookupOrg) {
    //     const documentPermission = lookupOrg.permissions?.documentPermissions.find((d) => d.cwCode == docType);
    //     if (lookupOrg.permissions?.isAdmin || documentPermission.permitted) return true;
    //   }
    //   return false;
    // }

    return true;
  }

  public getLookupOrg(addressing: ShipmentAddressing): UserOrg {
    return this.userValue?.orgs?.find(
      org =>
        org.id === Number(addressing.consigneeOrgPk) ||
        org.id === Number(addressing.consignorOrgPk) ||
        org.id === Number(addressing.shipperOrgPk) ||
        org.id === Number(addressing.importerOrgPk) ||
        org.id === Number(addressing.pickupOrgPk) ||
        org.id === Number(addressing.deliveryOrgPk)
    );
  }

  public async prepUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userCountryCode = localStorage.getItem('ucc');
      if (this.userCountryCode) {
        resolve();
      } else {
        this.getUserIP(resolve).subscribe();
      }
    });
  }

  private getUserIP(resolver: VoidFunction): Observable<void> {
    return this.http.get<IPData>(`https://api.ipify.org/?format=json`).pipe(
      map(ipData => {
        if (ipData.ip.match('[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}').length === 0) {
          this.userCountryCode = null;
          resolver();
        }
        this.getUserCountryCode(ipData.ip).subscribe(
          res => {
            if (res.countryCode.length > 2) {
              this.userCountryCode = null;
            } else {
              this.userCountryCode = res.countryCode;
              localStorage.setItem('ucc', this.userCountryCode);
            }
            resolver();
          },
          err => {
            this.userCountryCode = null;
            resolver();
          }
        );
      })
    );
  }

  private getUserCountryCode(ip: string): Observable<GeoData> {
    return this.http.get<GeoData>(`${environment.apiBaseURL}ClearView/getCountryCode/${ip}`);
  }
}
