import { Component } from "@angular/core";

@Component({
  selector: 'app-om-card-footer',
  styles: [''],
  template: `
  <div class="om-card-footer">
    <ng-content></ng-content>
  </div>
  `
})
export class OmCardFooterComponent {
  constructor() {}
}
