import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/*
  This pipe bypasses the default Angular2 'unsafe:' prefix added to the front of links that
  use protocols which are not whitelisted by default in Angular2.
  In paticular this is used to santaize our 'edient:' links which link to and open a corresponding file in CW1.
*/

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
