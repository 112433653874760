import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListFilter } from '@models/filter';
import { GetProducts, Product } from '@models/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProductApiService {
  constructor(private httpClient: HttpClient) {}

  public save(product: Product): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}product`, product);
  }

  public update(product: Product): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}product`, product);
  }

  public delete(product: Product): Observable<any> {
    return this.httpClient.delete(`${environment.apiBaseURL}product/${product.id}`);
  }

  public product(productId: number): Observable<any> {
    return this.httpClient.get<Product>(`${environment.apiBaseURL}product/${productId}`);
  }

  public products(supplierId: number): Observable<Product[]> {
    return this.httpClient
      .get<Product[]>(`${environment.apiBaseURL}product/list`)
      .pipe(map(po => po.map(p => new Product(p))));
  }

  public getProducts(params: ListFilter): Observable<GetProducts> {
    return this.httpClient.post<GetProducts>(`${environment.apiBaseURL}product/getProducts`, params);
  }

  public downloadTemplate(): any {
    return this.httpClient.get(`${environment.apiBaseURL}product/downloadTemplate/`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public downloadProducts(): any {
    return this.httpClient.get(`${environment.apiBaseURL}product/downloadProducts/`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public downloadProductsReport(params: ListFilter): any {
    return this.httpClient.post(`${environment.apiBaseURL}product/downloadProductsReport/`, params, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public uploadProducts(fileToUpload: File): any {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient.post(`${environment.apiBaseURL}product/uploadProducts/`, formData, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
}
