import { PoColumn } from './column.model'

export class UpdateColumns {
  public static readonly type = '[PurchaseOrders] Change Column settings';

  constructor(public payload: { columns: PoColumn[] }) {}
}

export class UpdatePoLineColumns {
  public static readonly type = '[PurchaseOrderLines] Change Column settings';

  constructor(public payload: { columns: PoColumn[] }) {}
}
