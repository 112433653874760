import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { Alert } from '@models/alert.model';
import { AlertGroupMetadata, AlertService, AlertGroup } from '@services/alert.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RefsListDialogComponent } from 'src/app/shared/components/refs-list-dialog/refs-list-dialog.component';

@Component({
  selector: 'app-om-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    trigger('fadeInOutTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AlertsComponent implements OnInit, OnDestroy {
  currentAlertGroup: AlertGroup;
  metadata: AlertGroupMetadata;
  alerts: Alert[];
  private _routerSub: Subscription;

  constructor(public alertService: AlertService, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this._routerSub = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(_ => {
      this.alertService.closeAlerts();
    });

    this.currentAlertGroup = this.alertService.currentAlertGroup;

    this.metadata = this.alertService.getMetadata(this.currentAlertGroup);
    this.alertService.alerts$.subscribe(alerts => {
      this.alerts = alerts.filter(a => a.metadata.group === this.currentAlertGroup);
    });
  }

  ngOnDestroy(): void {
    this._routerSub.unsubscribe();
  }

  public openPoRefDialog(event: any, element: any): void {
    event.stopImmediatePropagation();
    const dialogRef = this.dialog.open(RefsListDialogComponent, {
      disableClose: true,
      width: '300px',
      maxHeight: '95vh',
      data: {
        title: 'PO Refs',
        list: element,
        config: {
          url: (primaryKey: number | string) => `/app/purchase-orders/${primaryKey}`,
          idKey: 'key',
          textKey: 'value',
        },
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
