import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationSetting } from '@models/notificationSetting.model';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationApiService {
  constructor( private httpClient: HttpClient) { }

  public getNotificationSettings(): Observable<NotificationSetting[]> {
    return this.httpClient.get<NotificationSetting[]>(`${environment.apiBaseURL}notification/settings`);
  }

  public updateNotificationSetting(notificationSettings: NotificationSetting[]){
    return this.httpClient.post<NotificationSetting[]>(`${environment.apiBaseURL}notification/settings`, notificationSettings);
  }
}
