import { Filter } from '../model/filter.model';

export class UpdateFilters {
    public static readonly type = '[Preferences] Update Filters';

    constructor(public payload: { ref: string, filters: Filter[] }) {}
}

export class AddFilter {
    public static readonly type = '[Preferences] Add Filter';

    constructor(public payload: { ref: string, filter: Filter }) {}
}

export class RemoveFilter {
    public static readonly type = '[Preferences] Remove Filter';

    constructor(public payload: { ref: string, filterID:  number }) {}
}

export class UpdateSorting {
    public static readonly type = '[Preferences] Update Sorting';

    constructor(public payload: { ref: string, sort: { sortByField: string, sortByAscending: boolean } }) {}
}
