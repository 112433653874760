import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { IAsnTriggerDefinition, AsnTriggerType } from '@models/supplier-business-rule-set.model';

@Component({
  selector: 'app-network-asn-trigger-setting',
  templateUrl: './network-asn-trigger-setting.component.html',
  styleUrls: ['./network-asn-trigger-setting.component.scss'],
})
export class NetworkAsnTriggerSettingComponent implements OnInit {
  @Input() orgId: number;
  @Input() asnTriggerDefinition: IAsnTriggerDefinition;
  @Output() refreshAsnTrigger: EventEmitter<IAsnTriggerDefinition> = new EventEmitter<IAsnTriggerDefinition>();

  public asnTriggerType = AsnTriggerType;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('emailInput') public emailInput: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit(): void {}

  toggleAsnTriggerEnabled(type: string, event: boolean) {
    if (type === 'Containerized') {
      this.asnTriggerDefinition.hasContainerizedTrigger = event;
      if (event) {
        this.asnTriggerDefinition.containerizedTrigger = this.asnTriggerType.ContainersSealed;
      } else {
        this.asnTriggerDefinition.containerizedTrigger = this.asnTriggerType.None;
      }
    } else if (type === 'NonContainerized') {
      this.asnTriggerDefinition.hasNonContainerizedTrigger = event;
      if (event) {
        this.asnTriggerDefinition.nonContainerizedTrigger = this.asnTriggerType.AcutalDepartureDate;
      } else {
        this.asnTriggerDefinition.nonContainerizedTrigger = this.asnTriggerType.None;
      }
    }

    if (!this.asnTriggerDefinition.hasContainerizedTrigger && !this.asnTriggerDefinition.hasNonContainerizedTrigger) {
      this.asnTriggerDefinition.recipientSetting.otherRecipients = [];
      this.asnTriggerDefinition.recipientSetting.sendToClient = false;
      this.asnTriggerDefinition.recipientSetting.sendToSupplier = false;
    }

    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  hasContainerizedTriggerChanged(event: MatRadioChange) {
    this.asnTriggerDefinition.containerizedTrigger = event.value;
    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  hasNonContainerizedTriggerChanged(event: MatRadioChange) {
    this.asnTriggerDefinition.nonContainerizedTrigger = event.value;
    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  public onDeliverEmptyReportChange(event: MatRadioChange): void {
    this.asnTriggerDefinition.deliverEmpty = event.value;
    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  recipientCheckboxChanged(type: string, event: MatCheckboxChange) {
    if (type === 'client') {
      this.asnTriggerDefinition.recipientSetting.sendToClient = event.checked;
    } else if (type === 'supplier') {
      this.asnTriggerDefinition.recipientSetting.sendToSupplier = event.checked;
    }

    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  public addChip(event: any): void {
    const otherEmails: string[] = this.asnTriggerDefinition.recipientSetting.otherRecipients
      ? this.asnTriggerDefinition.recipientSetting.otherRecipients
      : [];
    if (event.target.value.trim() !== '') {
      otherEmails.push(event.target.value.trim());
      this.emailInput.nativeElement.value = '';
    } else {
      return;
    }
    this.asnTriggerDefinition.recipientSetting.otherRecipients = otherEmails;
    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  public removeChip(email: string): void {
    const otherEmails: string[] = this.asnTriggerDefinition.recipientSetting.otherRecipients;
    const index = otherEmails.indexOf(email);
    if (index >= 0) {
      otherEmails.splice(index, 1);
    }
    this.asnTriggerDefinition.recipientSetting.otherRecipients = otherEmails;
    this.refreshAsnTrigger.emit(this.asnTriggerDefinition);
  }

  public changeEmailText(event: string): void {}
}
