import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/modules/auth/services/user.service';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { ListFilter } from '@models/filter';
import { PurchaseOrderService } from '@services/purchaseOrder.service';
import { PurchaseOrder } from '@models/purchase-order.model';
import * as FileSaver from 'file-saver';
import { PageOrgMode } from '@models/helpers';
import { getDownloadFileName } from 'src/app/shared/utils/download';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderBrowserService {
  protected _tableLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get tableLoading$(): Observable<boolean> {
    return this._tableLoading.asObservable();
  }

  public purchaseOrders: any[] = [];
  public statusCounts: any = {};
  public pageNumber = 1;
  public pageSize = 10;
  public sortField = '';
  public sortByDescending = false;
  public currentPoStatus = 0;
  public keyWord = '';
  public dataLength = 0;
  public hidePaginator = false;
  public downloadLoading = false;
  public refreshSub: Subscription = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private purchaseOrderService: PurchaseOrderService,
    private toastr: ToastrService,
    private userService: UserService
  ) {}

  refreshTableData(): void {
    if (!this._tableLoading.getValue()) this._tableLoading.next(true);
    if (this.refreshSub) this.refreshSub.unsubscribe();
    this.purchaseOrders = [];
    const params = this.getParams(false);
    if (this.userService.orgMode === PageOrgMode.Lite) {
      this.refreshSub = forkJoin({
        poRes: this.purchaseOrderService.getPurchaseOrders(params).pipe(take(1)),
      }).subscribe(
        ({ poRes }) => {
          poRes.results.forEach(item => {
            item = new PurchaseOrder(item);
          });
          poRes.results.forEach((i: any) => {
            i.isExpanded = false;
          });
          this.dataLength = poRes.recordCount;
          this.purchaseOrders = poRes.results;
          this._tableLoading.next(false);
          this.hidePaginator = false;
        },
        _ => {
          this._tableLoading.next(false);
          this.hidePaginator = false;
        }
      );
    } else {
      this.refreshSub = forkJoin({
        poRes: this.purchaseOrderService.getPurchaseOrders(params).pipe(take(1)),
        statusCounts: this.purchaseOrderService.getPurchaseOrderStatusCount(this.keyWord).pipe(take(1)),
      }).subscribe(
        ({ poRes, statusCounts }) => {
          this.statusCounts = statusCounts;
          poRes.results.forEach(item => {
            item = new PurchaseOrder(item);
          });
          poRes.results.forEach((i: any) => {
            i.isExpanded = false;
          });
          this.dataLength = poRes.recordCount;
          this.purchaseOrders = poRes.results;
          this._tableLoading.next(false);
          this.hidePaginator = false;
        },
        _ => {
          this._tableLoading.next(false);
          this.hidePaginator = false;
        }
      );
    }
  }

  public initialData(): void {
    const params = this.getParams(true);
    forkJoin({
      poRes: this.purchaseOrderService.getPurchaseOrders(params).pipe(take(1)),
      statusCounts: this.purchaseOrderService.getPurchaseOrderStatusCount(this.keyWord).pipe(take(1)),
    }).subscribe(
      ({ poRes, statusCounts }) => {
        this.statusCounts = statusCounts;
        poRes.results.forEach(item => {
          item = new PurchaseOrder(item);
        });
        poRes.results.forEach((i: any) => {
          i.isExpanded = false;
        });
        this.dataLength = poRes.recordCount;
        this.purchaseOrders = poRes.results;
        // this._loading.next(false);
      },
      _ => {
        // this._loading.next(false);
      }
    );
  }

  public downloadPurchaseOrders(event: any, bookingId: number = 0): void {
    const params = this.getParams(false, bookingId);
    params.columnConfigs = event;
    this.downloadLoading = true;
    this.purchaseOrderService.downloadPurchaseOrders(params).subscribe(
      res => {
        let filename = getDownloadFileName('purchaseOrders.xlsx', res);
        this.downloadLoading = false;
        FileSaver.saveAs(new Blob([res.body], { type: res.type }), filename);
      },
      err => {
        this.downloadLoading = false;
      }
    );
  }

  private getParams(ignoreKeyWord, bookingId: number = 0) {
    const data = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortField: this.sortField,
      sortByDescending: this.sortByDescending,
      filter: {},
    };
    if (!ignoreKeyWord) {
      data['keyWord'] = this.keyWord;
    }
    if (bookingId) {
      data['filter']['bookingId'] = bookingId;
    } else {
      data['filter']['poStatus'] = this.currentPoStatus;
    }
    return new ListFilter(data);
  }
}
