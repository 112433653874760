import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  styles: [''],
  template: `
  <span mat-dialog-title class="cf-header-text">{{action}} {{name}}</span>
  <mat-dialog-content class="center-align-dialog-content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 form-field">
          <mat-form-field appearance="outline" floatLabel="always" class="extended-width">
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="confirmBtnDisabled" (click)="confirm()">Confirm</button>
  </mat-dialog-actions>
`
})
export class JobCommentsComponent implements OnInit {
  @Input() isPurchaseOrder: Boolean = false;
  @Input() isReactivate: Boolean = false;
  public form: UntypedFormGroup;

  public name: string;
  public action: string;

  constructor(private dialogRef: MatDialogRef<JobCommentsComponent>,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private dialog: MatDialog) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      comments: [null, Validators.required],
    });
    this.name = this.isPurchaseOrder ? "Purchase Order" : "Booking";
    this.action = this.isReactivate ? "Reactivate" : "Cancel";
  }

  confirm(): void {
    let obj = {
      comments: this.form.getRawValue().comments
    };

    this.dialogRef.close(obj);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  get confirmBtnDisabled(): boolean {
    if (this.form.getRawValue().comments == null ||
      this.form.getRawValue().comments == '') {
      return true
    } else {
      return false
    }
  }
}
