import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { UserService } from "@modules/auth/services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class OmsAdminGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const isOmsAdmin = this.userService.userValue.isOmsAdmin;

    if (!isOmsAdmin) {  
      return this.router.parseUrl('/unauthorized');
    }

    return true;
  }
}