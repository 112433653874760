import { Component, OnInit } from '@angular/core';
import {MatSelectChange} from "@angular/material/select";
import {UserService} from "@modules/auth/services/user.service";

@Component({
  selector: 'app-time-zone-selector',
  templateUrl: './time-zone-selector.component.html',
  styleUrls: ['./time-zone-selector.component.scss']
})
export class TimeZoneSelectorComponent implements OnInit {

  public currentTimeZone: string = '';

  constructor(public userService: UserService) {
    this.currentTimeZone = userService.currentTimeZone;
  }

  ngOnInit(): void {
  }

  public onTimeZoneSelected(event: MatSelectChange) {}

}
