import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ColumnsComponent} from './table-column-filter/options-group/columns/columns.component';
import {FiltersComponent} from './table-column-filter/options-group/filters/filters.component';
import { TableColumnFilter} from './table-column-filter/table-column-filter';
import {CommonModule, NgForOf, NgIf, TitleCasePipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTabsModule} from '@angular/material/tabs';
import {OptionsGroupComponent} from './table-column-filter/options-group/options-group.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TableHeaderToolsComponent} from './table-header-tools/table-header-tools.component';
import {SelectFilterComponent} from './select-filter/select-filter.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {UiSwitchModule} from 'ngx-ui-switch';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TablePaginator} from './paginator/table-paginator.component';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {LoadingIndicatorComponent} from "./loading-indicator/loading-indicator.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {BackButtonComponent} from "./back-button/back-button.component";
import {NavigatorComponent} from "./navigator/navigator.component";



@NgModule({
  imports: [TitleCasePipe, MatIconModule, MatBadgeModule, MatTabsModule, MatCardModule, MatDividerModule, MatSlideToggleModule, MatFormFieldModule, MatSelectModule, MatMenuModule, MatInputModule, MatButtonModule, MatBadgeModule, FormsModule, ReactiveFormsModule, MatDialogModule, CommonModule, DragDropModule, UiSwitchModule, MatTooltipModule, NzPaginationModule, MatProgressSpinnerModule],
  declarations: [ColumnsComponent, FiltersComponent, TableColumnFilter, OptionsGroupComponent, TableHeaderToolsComponent, SelectFilterComponent, TablePaginator, LoadingIndicatorComponent, BackButtonComponent, NavigatorComponent],
  exports: [ColumnsComponent, FiltersComponent, TitleCasePipe, TableColumnFilter, OptionsGroupComponent, TableHeaderToolsComponent, SelectFilterComponent, TablePaginator, LoadingIndicatorComponent, BackButtonComponent, NavigatorComponent],
  providers: [TitleCasePipe]
})

export class CommonLibModule {}
