import { DatePipe } from '@angular/common';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import fs from 'file-saver';
import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import { ITableColumnConfig } from 'src/app/shared/components/om-table/table-configurations';
import { TableCellPipe } from 'src/app/shared/pipes/table-cell.pipe';
import { NestedTableExportConfig } from 'src/app/shared/components/om-table/om-table-export.component';
import { BASE64_LOGO } from 'src/assets/images/base64Logo';

@Injectable()
export class ExcelService {
  constructor(
    private datePipe: DatePipe,
    private tableCellPipe: TableCellPipe
  ) {}

  exportTable( cols: ITableColumnConfig[], data: any[], datasetName: string, nestedExportConfig: NestedTableExportConfig = null) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(datasetName, {
      pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
    });

    const columnNames = cols.map((c) => c.name);
    const nestedColumnNames = nestedExportConfig?.columns.map((c) => c.name);
    nestedColumnNames?.splice(0, 0, ' '); // empty first column as offset for nested rows

    let rowIndex = 1;

    data.forEach((d, index) => {
      if (nestedExportConfig || index === 0) {
        const headerRow = worksheet.insertRow(rowIndex, columnNames);
        headerRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0080ff' },
          };
          cell.font = { color:  {argb: 'ffffff'}}
        });
        rowIndex++;
      }
      const rowValues = [];
      cols.forEach((c) => {
        rowValues.push(this.tableCellPipe.transform(d, c));
      });
      worksheet.insertRow(rowIndex++, rowValues);

      if (nestedExportConfig) {
        rowIndex++;
        const nestedHeaderRow = worksheet.insertRow(rowIndex, nestedColumnNames);
        nestedHeaderRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber > 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'a9a9a9' },
            };
            cell.font = { color:  {argb: 'ffffff'}}
          }
        });
        rowIndex++;

        d[nestedExportConfig.accessor].forEach((nd) => {
          const nestedRowValues = [' '];
          nestedExportConfig.columns.forEach((c) => {
            nestedRowValues.push(this.tableCellPipe.transform(nd, c));
          });
          worksheet.insertRow(rowIndex++, nestedRowValues);
        });
        rowIndex++;
      }
    });

    worksheet.columns.forEach((column) => {
      var dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        dataMax = cell.value ? cell.value.toString().length : 0;
      });
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    this.addLogo(workbook, worksheet);

    return this.saveAs(workbook, datasetName);
  }

  private addLogo(workBook: Workbook, worksheet: Worksheet) {
    const imageId2 = workBook.addImage({
      base64: BASE64_LOGO,
      extension: 'png',
    });

    const logoRow = worksheet.insertRow(1, []);
    logoRow.height = 60;
    worksheet.mergeCells(1, 1, 1, worksheet.getRow(2).cellCount);
    worksheet.addImage(imageId2, {
      tl: { col: 0, row: 0 },
      ext: { width: 162, height: 85 },
    });
  }

  private saveAs(workbook: Workbook, reportName) {
    const now =
      this.datePipe.transform(new Date(), 'yyyy-MM-dd-HH:mm:ss') ||
      new Date().toLocaleString();

    return from(workbook.xlsx.writeBuffer()).pipe(
      tap((data) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return fs.saveAs(blob, `${reportName}-${now}.xlsx`);
      })
    );
  }
}
