import { Pipe, PipeTransform } from "@angular/core";
import { Contact } from "@models/contact.model";

@Pipe({
    name: 'contact'
})

export class ContactPipe implements PipeTransform {
    transform(contact: Contact, format?: "full" | "code"){
        if (!contact) return '';
        if (format === 'full') {
            return  (contact.name ? (contact.name + '\n') : '') + 
                    (contact.email ? (contact.email + '\n') : '') + 
                    (contact.phone ? (contact.phone + ', ') : '')
        } else {
            return contact.name;
        }
    }
}