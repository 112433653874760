import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Booking, GetBookings, SaveBooking } from '@models/booking.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BookingStatusUpdateRecord } from '@models/bookingStatusUpdateRecord.model';
import { ListFilter } from '@models/filter';

@Injectable()
export class BookingApiService {
  constructor(private httpClient: HttpClient) {}

  public booking(bookingId: number): Observable<any> {
    return this.httpClient.get<Booking>(`${environment.apiBaseURL}Booking/${bookingId}`);
  }

  public bookings(): Observable<Booking[]> {
    return this.httpClient
      .get<Booking[]>(`${environment.apiBaseURL}Booking/`)
      .pipe(map(po => po.map(p => new Booking(p))));
  }

  public getBookings(params: ListFilter): Observable<GetBookings> {
    return this.httpClient.post<GetBookings>(`${environment.apiBaseURL}Booking/getBookings`, params);
  }

  public bookingHistory(bookingId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiBaseURL}history/Booking/${bookingId}`);
  }

  public create(booking: SaveBooking): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking`, booking);
  }

  public consigneeUpdate(booking: SaveBooking): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${booking.id}/consigneeUpdate`, booking);
  }

  public supplierApprove(bookingId: number, multiTransportModeConfirm: boolean = false): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/supplierApprove`, {
      multiTransportModeConfirm: multiTransportModeConfirm,
    });
  }

  public consigneeApprove(bookingId: number): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/consigneeApprove`, {});
  }

  public clientDeny(bookingId: number, record: BookingStatusUpdateRecord): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/clientDeny`, record);
  }

  public delete(bookingId: number) {
    return this.httpClient.delete(`${environment.apiBaseURL}Booking/${bookingId}`);
  }

  public cancel(bookingId: number, comments: string): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/cancel`, { comments: comments });
  }

  public getBookingStatusCount(keyWord: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiBaseURL}Booking/statusCount?keyWord=${keyWord}`);
  }

  public reactivate(bookingId: number, comments: string): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/reactivate`, { comments: comments });
  }

  public downloadBookings(params: ListFilter): any {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/downloadBookings/`, params, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public generateCommercialInvoicePDF(bookingId: number): any {
    return this.httpClient.get(`${environment.apiBaseURL}Booking/${bookingId}/generateCommercialInvoice`);
  }

  public generatePackingListPDF(bookingId: number): any {
    return this.httpClient.get(`${environment.apiBaseURL}Booking/${bookingId}/generatePackingList`);
  }

  public checkCanGenerateInvoice(bookingId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiBaseURL}Booking/${bookingId}/checkCanGenerateInvoice`);
  }

  public bookingPickup(bookingId: number, cargoPickedUpDateValue: Date): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}Booking/${bookingId}/pickup`, {
      cargoPickedUpDateValue: cargoPickedUpDateValue,
    });
  }

  public markBookingPriority(id: number, isPriority: boolean): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiBaseURL}Booking/${id}/updatePriority?isPriority=${isPriority}`,
      {}
    );
  }

  public validateBookedPurchaseOrder(bookingId: number, data: any): any {
    return this.httpClient
      .post(`${environment.apiBaseURL}Booking/${bookingId}/validateBookedPurchaseOrder`, data)
      .toPromise();
  }

  public validateBookedEquipment(bookingId: number, data: any): any {
    return this.httpClient
      .post(`${environment.apiBaseURL}Booking/${bookingId}/validateBookedEquipment`, data)
      .toPromise();
  }

  public downloadBookingXml(bookingId: number): any {
    return this.httpClient
      .get(`${environment.apiBaseURL}Booking/${bookingId}/downloadBookingXml`, {
        responseType: 'arraybuffer',
        observe: 'response',
      });
  }

  public downloadContainersXml(bookingId: number): any {
    return this.httpClient
      .get(`${environment.apiBaseURL}Booking/${bookingId}/downloadContainersXml`, {
        responseType: 'arraybuffer',
        observe: 'response',
      });
  }

  public downloadCommercialInvoiceXml(bookingId: number): any {
    return this.httpClient
      .get(`${environment.apiBaseURL}Booking/${bookingId}/downloadCommercialInvoiceXml`, {
        responseType: 'arraybuffer',
        observe: 'response',
      });
  }

  private syncEAdaptor(bookingId: number, type: number): any {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Booking/${bookingId}/syncEAdaptor?type=${type}`);
  }

  public syncBookingXml(bookingId: number): any {
    return this.syncEAdaptor(bookingId, 1);
  }

  public syncContainersXml(bookingId: number): any {
    return this.syncEAdaptor(bookingId, 3);
  }

  public syncCommercialInvoiceXml(bookingId: number): any {
    return this.syncEAdaptor(bookingId, 4);
  }
}
