import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import {environment} from "../../../../environments/environment";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService, private injector: Injector) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((req) => {
                if (!req.ok) {
                    switch (req.status) {
                        case 400:
                            this.toastr.warning(
                                req.error,
                                'Error',
                                { timeOut: 10000 }
                            );  
                            return throwError(req);
                        case 401:
                            this.toastr.warning(
                                'User authentication expired, please log back in.',
                                'User Unauthenticated',
                                { timeOut: 10000 }
                            );
                            this.injector.get(AuthService).logout();
                            return next.handle(req);
                        case 403:
                            if (req.url.indexOf(environment.clearViewApi) == -1) {
                              this.toastr.warning(
                                'User requested a forbidden action, please log back in to verify user account and permissions.',
                                'Forbidden Request Detected',
                                { timeOut: 10000 }
                              );

                              this.injector.get(AuthService).logout();
                            }
                            return next.handle(req);
                        case 404:
                            return throwError(req);
                        case 500:
                            if (req?.error?.Message) {
                                var message = req.error.Message as string;
                            } else {
                                var message = 'A 500 error was encountered, please report this event to your Admin.';
                            }
                            this.toastr.warning(
                                message,
                                'Server Error',
                                { timeOut: 5000 }
                            );                            
                            return throwError(req);
                        case 503:
                            this.toastr.warning(
                                'A 503 error was encountered, please report this event to your Admin.',
                                'Service Unavailable',
                                { timeOut: 5000 }
                            );                            
                            return next.handle(req);
                        default:
                            console.error(`Unexpected error code received: ${req.status}`);
                            return next.handle(req);
                    }
                }
                return next.handle(req);
            })
        );
    }
}
