import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RequestInterceptor } from '@modules/auth/helpers/request.interceptor';
import { BookingApiService } from '@services/booking-api.service';
import { LocationApiService } from '@services/location-api.service';
import { PurchaseOrderService } from '@services/purchaseOrder.service';
import { PurchaseOrderCacheService } from '@services/purchaseOrder-cache.service';
import { RemarkApiService } from '@services/remark-api.service';
import { UnlocoApiService } from '@services/unloco-api.service';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from '@modules/alert/alert.module';
import { AlertService } from '@services/alert.service';
import { OmsAdminGuard } from './guards/oms-admin.guard';
import { OrgAdminGuard } from './guards/org-admin.guard';
import { OmsLiteGuard } from './guards/oms-lite.guard';
import { OmsFullGuard } from './guards/oms-full.guard';
import { ProductApiService } from '@services/product-api.service';
import { PurchaseOrderLineSelectionService } from '@services/purchase-order-line-selection.service';
import { NetworkAdminGuard } from './guards/network-admin.guard';
import { ShipmentApiService } from '@services/shipment-api.service';
import { ExcelService } from '@services/excel.service';
import { InviteGuard } from './guards/invite.guard';
import { ColorService } from '@modules/auth/services/color.service';
import { NotificationApiService } from '@services/notification-api.service';
import { OmCacheService } from '@services/om-cache.service';
import { NetworkDenyReasonApiService } from '@services/network-deny-reason-api.service';
import { ReportService } from '@services/report.service';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      easing: 'ease-in',
      easeTime: 500,
      tapToDismiss: true,
      closeButton: true,
    }),
  ],
  providers: [
    AlertService,
    BookingApiService,
    ExcelService,
    LocationApiService,
    NotificationApiService,
    OmCacheService,
    ProductApiService,
    PurchaseOrderLineSelectionService,
    PurchaseOrderService,
    PurchaseOrderCacheService,
    RemarkApiService,
    ShipmentApiService,
    UnlocoApiService,
    UserNetworkApiService,
    NetworkDenyReasonApiService,
    OmsAdminGuard,
    OrgAdminGuard,
    NetworkAdminGuard,
    OmsLiteGuard,
    OmsFullGuard,
    InviteGuard,
    PurchaseOrderLineSelectionService,
    ShipmentApiService,
    ExcelService,
    ColorService,
    ReportService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
})
export class CoreModule {}
