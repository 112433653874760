import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { UserNetworkApiService } from '@services/user-network-api.service';

@Component({
  selector: 'app-om-purchase-order-defaults',
  styleUrls: [],
  templateUrl: './purchase-order-defaults.component.html',
})
export class PurchaseOrderDefaultsComponent implements OnInit, OnChanges {
  incoterms = Constants.incoterms.map((item: any) => {
    return {
      ...item,
      fullText: item.code + ' - ' + item.description
    }
  });
  @Input() supplierCode: string;
  @Input() manufacturers: Organization[];
  @Input() consignees: Organization[];
  @Input() pickupLocations: Location[] = [];
  @Input() shipToLocations: Location[] = [];
  @Input() form: UntypedFormGroup;
  public manufactureFilter = []
  public consigneesFilter = []
  public pickupLocationsFilter = []
  public shipToLocationsFilter = []
  public incotermFilter = this.incoterms

  constructor(private userNetworkService: UserNetworkApiService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.supplierCode) {
      this.userNetworkService.getCWLocationsForOrg(this.supplierCode).subscribe(locations => {
        this.pickupLocations = locations;
        this.pickupLocationsFilter = locations;
      });
    }
    if (changes.manufacturers) {
      this.manufactureFilter = changes.manufacturers.currentValue
    }
    if (changes.consignees) {
      this.consigneesFilter = changes.consignees.currentValue
    }
  }

  ngOnInit() {
    this.form.controls.consigneeOrgCode.valueChanges.subscribe(orgCode => {
      if (orgCode) {
        this.userNetworkService.getCWLocationsForOrg(orgCode).subscribe(locations => {
          this.shipToLocations = locations;
          this.shipToLocationsFilter = locations;
          if (!this.form.controls.consigneeOrgCode.pristine) {
            this.form.controls.shipToLocationCode.setValue(this.shipToLocations[0].code);
          }
        });
      } else {
        this.form.controls.shipToLocationCode.setValue(null);
      }
    });
  }
}
