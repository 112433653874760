import { SavePurchaseOrder } from './../models/purchase-order.model';
import { Injectable } from '@angular/core';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { PurchaseOrderService } from '@services/purchaseOrder.service';
import { UserNetworkApiService } from './user-network-api.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PurchaseOrder } from '@models/purchase-order.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { PageOrgMode } from '@models/helpers';
import { ToastrService } from 'ngx-toastr';
import { ManufacturerChangeComponent } from '@modules/purchase-order/oms-lite/views/purchase-order/manufacturer-change/manufacturer-change.component';
import { PURCHASE_ORDER_STATUS } from '@models/helpers';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { JobCommentsComponent } from '@modules/job/components/dialogs/job-comments.component';
import { PoRejectPromptComponent } from '@modules/purchase-order/components/po-reject-prompt/po-reject-prompt.component';
import { getPoLineUnitText } from '../../shared/utils/po-lines-unit';
import { SavePurchaseOrderLine } from '@models/purchase-order-line.model';
import { LocationApiService } from './location-api.service';
import { UserService } from '@modules/auth/services/user.service';
import { CreateBookingEditorComponent } from '@modules/booking/components/create-booking-editor.compnent';
import {LocationPipe} from "../../shared/pipes/location.pipe";

@Injectable()
export class PurchaseOrderCacheService {
  public purchaseOrder: PurchaseOrder;
  public purchaseOrderDetailsForm: UntypedFormGroup;
  public manufacturerSelected: boolean = false;
  public orgMode: PageOrgMode;

  public loading: boolean = false;

  public suppliers: Organization[];
  public consignees: Organization[];
  public manufacturers: Organization[];
  public pickupLocations: Location[] = [];
  public shipToLocations: Location[] = [];
  public manufacturerAddresses: any[] = [];
  public manufacturerRelAddress: { [key: string]: { address: any[] } } = {};

  public manufacturerChangeDialogRef: MatDialogRef<ManufacturerChangeComponent>;
  public editCache: { [key: string]: { edit: boolean; data: any; confirmed: boolean } } = {};
  public editCacheList: any = [];
  public productCache: { [key: string]: any[] } = {};
  public hasProduct: { [key: string]: boolean } = {};

  public dataLoaded: Subject<boolean> = new Subject();
  public afterSaving: Subject<any> = new Subject();

  public dataLoadedEvent: Subscription;
  public afterSavingEvent: Subscription;

  private _previousManufacturerValue: any;
  private editing: boolean;
  public canConvertToBooking: boolean = false;

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private locationApiService: LocationApiService,
    private userNetworkService: UserNetworkApiService,
    private userService: UserService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private locationPipe: LocationPipe
  ) {}

  public loadDataById(purchaseOrderId: number, orgMode: PageOrgMode, editing: boolean) {
    if (!purchaseOrderId) {
      this.dataLoaded.next(false);
    } else {
      this.loading = true;
      const dataSubscribeSuccess = (res: PurchaseOrder) => {
        let po = res;
        this.loadData(po, orgMode, editing);
        this.loading = false;
      };
      const dataSubscribeFail = (error: any) => {
        this.dataLoaded.next(true);
      };
      if (orgMode == PageOrgMode.Lite) {
        this.purchaseOrderService
          .getLitePurchaseOrderById(purchaseOrderId)
          .subscribe(dataSubscribeSuccess, dataSubscribeFail);
      } else {
        this.purchaseOrderService
          .getPurchaseOrderById(purchaseOrderId)
          .subscribe(dataSubscribeSuccess, dataSubscribeFail);
      }
    }
  }

  public loadData(purchaseOrder: PurchaseOrder, orgMode: PageOrgMode, editing: boolean) {
    this.editing = editing;
    this.orgMode = orgMode;
    this.purchaseOrder = purchaseOrder;

    this.manufacturerSelected = false;
    this._previousManufacturerValue = null;
    this.editCache = {};
    this.editCacheList = [];
    this.purchaseOrder.poLines.forEach((item: any) => {
      item.rowId = uuidv4();
      getPoLineUnitText(item);
      this.editCache[item.rowId] = {
        edit: true,
        data: { ...item },
        confirmed: true,
      };
      this.editCacheList.push(item);
      if (this.editing) {
        const queryAddressCode = [];
        item.productManufacturers.forEach(pm => {
          if (!this.manufacturerRelAddress[pm.code]) {
            queryAddressCode.push(pm.code);
          }
        });

        if (queryAddressCode.length > 0) {
          this.getLocationsForOrgs(queryAddressCode);
        }
      }
    });
    // PO lines should default with 1st line added (with empty values)
    if (this.editCacheList.filter(x => x.skuItemNumber == '').length < 1) {
      this.addPurchaseOrderLine();
    }
    this.purchaseOrderDetailsForm = this.fb.group({
      id: null,
      poNumber: [null, Validators.required],
      poStatus: null,
      poDate: new Date(),
      customerPoNumber: null,
      isPriority: false,
      expectedCargoReadyDate: null,
      inDistCenterDate: null,
      firstShipDate: null,
      lastShipDate: null,
      bookByDate: null,
      requiredInStoreDate: null,
      expectedCargoReadyDateValue: null,
      inDistCenterDateValue: null,
      firstShipDateValue: null,
      lastShipDateValue: null,
      bookByDateValue: null,
      requiredInStoreDateValue: null,
      originCountry: null,
      pickupLocation: null,
      shipToLocation: null,
      polCode: null,
      podCode: null,
      incoterm: null,
      supplier: [null, Validators.required],
      buyer: null,
      customer: [{ value: null, disabled: true }],
      buyingAgent: null,
      consignee: null,
      manufacturer: null,
      transportMode: [null, Validators.required],
    });

    if (
      this.purchaseOrder.poStatus === PURCHASE_ORDER_STATUS.Accepted &&
      this.userService.orgMode === PageOrgMode.Supplier
    ) {
      this.canConvertToBooking = true;
    } else {
      this.canConvertToBooking = false;
    }

    this.configurePurchaseOrderForm(this.purchaseOrder, editing);

    this.dataLoaded.next(false);
  }

  private configurePurchaseOrderForm(purchaseOrder: PurchaseOrder, isEditing: boolean) {
    this.purchaseOrder = purchaseOrder;
    if (!isEditing) {
      this.purchaseOrderDetailsForm.get('isPriority').disable();
      this.purchaseOrderDetailsForm.get('isPriority').setValue(purchaseOrder?.isPriority);
    }

    this.userNetworkService.getOrgsInCurrentNetwork().subscribe(res => {
      this.suppliers = res.suppliers;
      this.consignees = res.consignees;
      this.manufacturers = res.manufacturers;
      this.purchaseOrderDetailsForm.get('customer').setValue(res.customer);
    });

    this.purchaseOrderDetailsForm.controls.supplier.valueChanges.subscribe(data => {
      if (data && data.code) {
        this.userNetworkService.getCWLocationsForOrg(data.code).subscribe((locations: Location[]) => {
          this.pickupLocations = locations.map((item: Location) => {
            return {
              ...item,
              fullLocation: this.locationPipe.transform(item, 'full')
            }
          });
          if (isEditing) {
            this.patchSupplierDefaults(data.id);
          }
        });
      } else this.pickupLocations = [];
    });

    this.purchaseOrderDetailsForm.controls.consignee.valueChanges.subscribe(data => {
      if (data && data.code) {
        this.userNetworkService.getCWLocationsForOrg(data.code).subscribe((locations: Location[]) => {
          this.shipToLocations = locations.map((item: Location) => {
            return {
              ...item,
              fullLocation: this.locationPipe.transform(item, 'full')
            }
          }); //possible map
        });
      } else this.shipToLocations = [];
    });

    this.purchaseOrderDetailsForm.controls.manufacturer.valueChanges.subscribe(data => {
      // Fetch locations for selected manufacturer.
      if (data && data.code) {
        this.userNetworkService.getCWLocationsForOrg(data.code).subscribe((locations: Location[]) => {
          this.manufacturerAddresses = locations; //possible map
        });
      }

      // If manufacturer is a new value and we have existing purchase order lines show warning prompt.
      if (this._previousManufacturerValue && this._previousManufacturerValue.code) {
        if (data && data.code && data.code !== this._previousManufacturerValue.code) {
          if (purchaseOrder.poLines.length) {
            this.showManufacturerChange();
          }
        }
      }
      this.manufacturerSelected = data !== null && data !== undefined;
      // Store the next manufacturer value for comparison on future change.
      this._previousManufacturerValue = data;
    });

    if (purchaseOrder) {
      this.purchaseOrderDetailsForm.patchValue(purchaseOrder);
    }
  }

  private patchSupplierDefaults(supplierId: number) {
    this.userNetworkService.getBusinessRules(supplierId).subscribe(res => {
      if (res) {
        if (!this.purchaseOrderDetailsForm.get('transportMode').value) {
          this.purchaseOrderDetailsForm.get('transportMode').setValue(res.poDefaults.transportMode);
        }
        if (!this.purchaseOrderDetailsForm.get('originCountry').value) {
          this.purchaseOrderDetailsForm.get('originCountry').setValue(res.poDefaults.originCountry);
        }
        if (!this.purchaseOrderDetailsForm.get('incoterm').value) {
          this.purchaseOrderDetailsForm.get('incoterm').setValue(res.poDefaults.incoterm);
        }
        if (!this.purchaseOrderDetailsForm.get('polCode').value) {
          this.purchaseOrderDetailsForm.get('polCode').setValue(res.poDefaults.pol);
        }
        if (!this.purchaseOrderDetailsForm.get('podCode').value) {
          this.purchaseOrderDetailsForm.get('podCode').setValue(res.poDefaults.pod);
        }
        if (!this.purchaseOrderDetailsForm.get('consignee').value) {
          const defaultConsignee = this.consignees.find(c => c.code == res.poDefaults.consigneeOrgCode);
          if (defaultConsignee) {
            this.purchaseOrderDetailsForm.get('consignee').setValue(defaultConsignee, { emitEvent: false });
            this.userNetworkService.getCWLocationsForOrg(defaultConsignee.code).subscribe(locations => {
              this.shipToLocations = locations.map((item: Location) => {
                return {
                  ...item,
                  fullLocation: this.locationPipe.transform(item, 'full')
                }
              });
              const defaultShipTo = this.shipToLocations.find(l => l.code == res.poDefaults.shipToLocationCode);
              if (defaultShipTo) this.purchaseOrderDetailsForm.get('shipToLocation').setValue(defaultShipTo);
            });
          }
        }
        if (!this.purchaseOrderDetailsForm.get('manufacturer').value) {
          const defaultManufacturer = this.manufacturers.find(m => m.code == res.poDefaults.manufacturerOrgCode);
          if (defaultManufacturer) this.purchaseOrderDetailsForm.get('manufacturer').setValue(defaultManufacturer);
        }
        if (!this.purchaseOrderDetailsForm.get('pickupLocation').value) {
          const defaultPickup = this.pickupLocations.find(l => l.code == res.poDefaults.pickupLocationCode);
          if (defaultPickup) this.purchaseOrderDetailsForm.get('pickupLocation').setValue(defaultPickup);
        }
      }
    });
  }

  public showManufacturerChange() {
    const dialogRef = this.dialog.open(ManufacturerChangeComponent, {
      width: '40vw',
      maxWidth: '800px',
      maxHeight: '600px',
    });

    const previousValue = this._previousManufacturerValue;
    dialogRef.componentInstance.onManufacturerChangeCancel.subscribe(() => {
      this._previousManufacturerValue = previousValue;
      this.purchaseOrderDetailsForm.controls.manufacturer.setValue(previousValue, {
        onlySelf: true,
        emitEvent: false,
      });
      dialogRef.close();
    });

    dialogRef.componentInstance.onManufacturerChangeConfirm.subscribe(() => {
      this.purchaseOrder.poLines = [];
      dialogRef.close();
    });
  }

  public clearManufacturerField() {
    if (this.purchaseOrder.poLines.length) {
      const dialogRef = this.dialog.open(ManufacturerChangeComponent, {
        width: '40vw',
        maxWidth: '800px',
        maxHeight: '600px',
      });

      const previousValue = this._previousManufacturerValue;

      dialogRef.componentInstance.onManufacturerChangeCancel.subscribe(() => {
        this._previousManufacturerValue = previousValue;
        this.purchaseOrderDetailsForm.controls.manufacturer.setValue(previousValue, {
          onlySelf: true,
          emitEvent: false,
        });
        dialogRef.close();
      });

      dialogRef.componentInstance.onManufacturerChangeConfirm.subscribe(() => {
        this.purchaseOrder.poLines = [];
        this.manufacturerSelected = false;
        this.purchaseOrderDetailsForm.controls.manufacturer.setValue(null, {
          onlySelf: true,
          emitEvent: false,
        });
        dialogRef.close();
      });
    }
  }

  public savePurchaseOrder(isApprove) {
    if (this.purchaseOrderDetailsForm.valid) {
      const formValue = this.purchaseOrderDetailsForm.getRawValue();

      let msg = '';
      const needDel = [];
      if (this.orgMode === PageOrgMode.Lite) {
        this.editCacheList.forEach((item, index) => {
          if (!item.skuItemNumber && !item.orderQuantity) {
            needDel.push(index);
          } else if (item.skuItemNumber && !item.orderQuantity) {
            msg = 'The quantity are required.';
          } else {
            if (this.hasProduct[item.rowId] && item.skuItemNumber) {
              let products = this.productCache[item.rowId];
              let index = products.findIndex(i => i.skuItemNumber == item.skuItemNumber);
              if (index < 0 || !item.productId) {
                msg = 'The Sku/Item # is invalid.';
              }
            }
          }
        });
      } else {
        this.editCacheList.forEach((item, index) => {
          if (!item.skuItemNumber && !item.orderQuantity) {
            needDel.push(index);
          } else if (!item.skuItemNumber || !item.orderQuantity) {
            msg = 'The Sku/Item # and quantity are required.';
          } else {
            if (this.hasProduct[item.rowId]) {
              let products = this.productCache[item.rowId];
              let index = products.findIndex(i => i.skuItemNumber == item.skuItemNumber);
              if (index < 0 || !item.productId) {
                msg = 'The Sku/Item # is invalid.';
              }
            }
          }
        });
      }

      if (needDel.length > 0) {
        for (let i = needDel.length - 1; i >= 0; i--) {
          this.editCacheList.splice(needDel[i], 1);
        }
      }
      this.updateLineNumbers();

      if (msg) {
        this.toastr.warning(msg);
        return false;
      } else {
        // save purchase order directly
        formValue.poLines = this.editCacheList.slice(0);
        this.savePurchaseOrderOp(isApprove, formValue);
      }
    } else {
      const fields = this.findInvalidControls();
      let detailError = false;
      const requiredFields = [
        'consignee',
        'manufacturer',
        'cargoReadyDateValue',
        'consigneeLocation',
        'shipperLocation',
        'customerLocation',
      ];
      fields.forEach(item => {
        this.purchaseOrderDetailsForm.controls[item].markAsTouched();

        if (requiredFields.indexOf(item) > -1) detailError = true;
      });

      this.toastr.warning('Please fill out all of the required fields.');
    }
  }

  public savePurchaseOrderOp(isApprove, formValue) {
    if (isApprove && this.orgMode !== PageOrgMode.Lite) {
      formValue.poStatus = PURCHASE_ORDER_STATUS.AwaitingSupplier;
    }
    if (!formValue.expectedCargoReadyDateValue) {
      formValue.expectedCargoReadyDate = '';
    }
    if (!formValue.inDistCenterDateValue) {
      formValue.inDistCenterDate = '';
    }
    if (!formValue.firstShipDateValue) {
      formValue.firstShipDate = '';
    }
    if (!formValue.lastShipDateValue) {
      formValue.lastShipDate = '';
    }
    if (!formValue.bookByDateValue) {
      formValue.bookByDate = '';
    }

    const postData = new SavePurchaseOrder(formValue);
    postData.poLines = [];
    formValue.poLines.forEach(item => {
      postData.poLines.push(new SavePurchaseOrderLine(item));
    });

    this.loading = true;
    if (this.orgMode === PageOrgMode.Lite || !this.purchaseOrder.id) {
      if (!this.purchaseOrder.id) {
        this.purchaseOrderService.createPurchaseOrder(postData).subscribe(
          res => {
            this.loading = false;
            this.afterSaving.next(res);
            //this.redirect.next(`/app/purchase-orders/${res}`);
          },
          _ => {
            this.loading = false;
            this.reloadPoFormDataForFailed();
          }
        );
      } else {
        this.purchaseOrderService.updatePurchaseOrder(postData).subscribe(
          res => {
            this.loading = false;
            this.afterSaving.next(res);
          },
          _ => {
            this.loading = false;
            this.reloadPoFormDataForFailed();
          }
        );
      }
    } else if (this.orgMode === PageOrgMode.Client) {
      this.purchaseOrderService.consigneeUpdate(this.purchaseOrder.id, postData).subscribe(
        res => {
          this.loading = false;
          this.afterSaving.next(res);
        },
        _ => {
          this.loading = false;
          this.reloadPoFormDataForFailed();
        }
      );
    } else if (this.orgMode === PageOrgMode.Supplier) {
    }
  }

  public reloadPoFormDataForFailed() {
    const customer = this.purchaseOrderDetailsForm.get('customer').value;
    this.purchaseOrderDetailsForm.get('customer').setValue(null);
    setTimeout(() => {
      this.purchaseOrderDetailsForm.get('customer').setValue(customer);
    }, 1);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.purchaseOrderDetailsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  public approvePurchaseOrder(): void {
    let msg = '';
    if (this.orgMode === PageOrgMode.Client) {
      msg = 'Are you sure you want to send this purchase order to supplier?';
    } else {
      msg = 'Are you sure you want to approve this purchase order?';
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: msg,
    });
    confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true;
        if (this.orgMode === PageOrgMode.Client) {
          this.purchaseOrderService.consigneeApprove(this.purchaseOrder.id).subscribe(
            () => {
              this.router.navigateByUrl(`/app/purchase-orders`);
            },
            _ => {
              this.loading = false;
            }
          );
        }

        if (this.orgMode === PageOrgMode.Supplier) {
          this.purchaseOrderService.supplierApprove(this.purchaseOrder.id).subscribe(
            () => {
              this.router.navigateByUrl(`/app/purchase-orders`);
            },
            _ => {
              this.loading = false;
            }
          );
        }
      }
    });
  }

  public rejectPurchaseOrder(): void {
    if (this.orgMode === PageOrgMode.Supplier) {
      let dialogRef = this.dialog.open(PoRejectPromptComponent, {
        width: '400px',
        maxWidth: '800px',
        maxHeight: '600px',
      });
      dialogRef.afterClosed().subscribe(rejectData => {
        if (rejectData == null) return;
        this.loading = true;
        this.purchaseOrderService.supplierReject(this.purchaseOrder.id, rejectData).subscribe(
          () => {
            this.router.navigateByUrl(`/app/purchase-orders`);
          },
          _ => {
            this.loading = false;
          }
        );
      });
    }
  }

  public deletePurchaseOrder(): void {
    if (this.orgMode === PageOrgMode.Client || this.orgMode === PageOrgMode.Lite) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to delete this purchase order?',
      });
      confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.loading = true;
          this.purchaseOrderService.deletePurchaseOrder(this.purchaseOrder.id).subscribe(
            () => {
              this.router.navigateByUrl(`/app/purchase-orders`);
            },
            _ => {
              this.loading = false;
            }
          );
        }
      });
    }
  }

  public cancelPurchaseOrderEdit() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to cancel changes?',
    });
    confirmDialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.purchaseOrder && this.purchaseOrder.id) {
          //this.redirect.next(`/app/purchase-orders/${this.purchaseOrder.id}`);
        } else {
          this.router.navigateByUrl(`/app/purchase-orders`);
        }
      }
    });
  }

  public cancelPurchaseOrder() {
    this.openCommentsDialog(false);
  }

  public reactivatePurchaseOrder() {
    this.openCommentsDialog(true);
  }

  private openCommentsDialog(isReactivate: boolean) {
    const dialogRef = this.dialog.open(JobCommentsComponent, {
      width: '40vw',
      maxWidth: '800px',
      maxHeight: '600px',
    });
    dialogRef.componentInstance.isPurchaseOrder = true;
    dialogRef.componentInstance.isReactivate = isReactivate;
    dialogRef.afterClosed().subscribe((jobData: any) => {
      if (jobData) {
        this.loading = true;
        if (isReactivate) {
          this.purchaseOrderService.reactivatePurchaseOrder(this.purchaseOrder.id, jobData.comments).subscribe(
            () => {
              this.router.navigateByUrl(`/app/purchase-orders`);
            },
            _ => {
              this.loading = false;
            }
          );
        } else {
          this.purchaseOrderService.cancelPurchaseOrder(this.purchaseOrder.id, jobData.comments).subscribe(
            () => {
              this.router.navigateByUrl(`/app/purchase-orders`);
            },
            _ => {
              this.loading = false;
            }
          );
        }
      }
    });
  }

  public addPurchaseOrderLine(): void {
    if (!this.editing) return;

    const pol = {
      skuItemNumber: '',
      lineNumber: this.editCacheList.length + 1,
      rowId: uuidv4(),
      manufacturer: null,
    };
    this.addToEditCache(pol);
  }

  public onRowRemove(rowId: string): void {
    if (!this.editing) return;
    this.removeFromEditCache(rowId);
  }

  public onRowProductSelected(values: any): void {
    if (!this.editing) return;

    // Get manufacturer addresses
    const queryAddressCode = [];
    values.product.manufacturers.forEach(pm => {
      if (!this.manufacturerRelAddress[pm.code]) {
        queryAddressCode.push(pm.code);
      }
    });

    if (queryAddressCode.length > 0) {
      this.getLocationsForOrgs(queryAddressCode);
    }
    let data = this.editCacheList.filter(x => x.rowId === values.rowId)[0];
    let product = values.product;
    data.productId = product.id;
    data.skuItemNumber = product.skuItemNumber;
    data.quantityUnit = product.quantityUnit;
    data.itemPrice = product.itemPrice;
    data.linePrice = product.linePrice;
    data.origin = product.origin;
    data.grossWeight = product.grossWeight;
    data.netWeight = product.netWeight;
    data.weightUnit = product.weightUnit;
    data.height = product.height;
    data.heightUnit = product.heightUnit;
    data.length = product.length;
    data.lengthUnit = product.lengthUnit;
    data.width = product.width;
    data.widthUnit = product.widthUnit;
    data.volume = product.volume;
    data.volumeUnit = product.volumeUnit;
    data.size = product.size;
    data.color = product.color;
    data.isDg = product.isDg;
    data.dgClass = product.dgClass;
    data.dgWeight = product.dgWeight;
    data.dgUw = product.dgUw;
    data.htsCode = product.htsCode;
    data.specialInstructions = product.specialInstructions;
    data.productDescription = product.description;
    data.manufacturer = product.manufacturers.length > 0 ? product.manufacturers[0] : null;
    data.manufacturerLocation = null;
    data.shippedQuantity = 0;
    data.productManufacturers = product.manufacturers;
    getPoLineUnitText(data);

    // Once done adding a line, create new row automatically (with empty values)
    if (this.editCacheList.filter(x => x.skuItemNumber == '').length < 1) {
      this.addPurchaseOrderLine();
    }
  }

  public handleLiteOrderQtyBlur() {
    // Once done adding a line, create new row automatically (with empty values)
    if (this.editCacheList.filter(x => !x.orderQuantity).length < 1) {
      this.addPurchaseOrderLine();
    }
  }

  // MANUFACTURER CHANGE DIALOG HANDLERS
  public cancelManufacturerChange(): void {
    this.purchaseOrderDetailsForm.controls.manufacturer.setValue(this._previousManufacturerValue, {
      onlySelf: true,
      emitEvent: false,
    });
    this.manufacturerChangeDialogRef.close();
  }

  public confirmManufacturerChange(): void {
    this.purchaseOrder.poLines = [];
    this.manufacturerChangeDialogRef.close();
  }

  public discardPoLinesChanges(): void {
    this.editCacheList = this.purchaseOrder.poLines;
  }

  public getLocationsForOrgs(queryAddressCode: string[]): void {
    this.locationApiService.getLocationsForOrgs(queryAddressCode).subscribe(res => {
      res.forEach(r => {
        if (!this.manufacturerRelAddress[r.orgCode]) {
          this.manufacturerRelAddress[r.orgCode] = {
            address: [r],
          };
        } else {
          if (this.manufacturerRelAddress[r.orgCode]?.address.findIndex(a => a.code === r.code) < 0) {
            this.manufacturerRelAddress[r.orgCode].address.push(r);
          }
        }
      });
    });
  }

  public openConvertToBookingDialog(purchaseOrder: PurchaseOrder) {
    if (this.userService.orgMode !== PageOrgMode.Supplier) return;

    const dialogRef = this.dialog.open(CreateBookingEditorComponent, {
      disableClose: true,
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
    const polines = purchaseOrder.poLines.filter(l => l.balanceQuantity > 0);
    const tmpPo = JSON.parse(JSON.stringify(purchaseOrder));
    tmpPo.poLines = polines;

    dialogRef.componentInstance.convertPo = tmpPo;
    dialogRef.afterClosed().subscribe((bookingId: any) => {
      if (bookingId) {
        this.router.navigateByUrl(`/app/bookings/${bookingId}`);
      }
    });
  }

  // HELPERS
  private addToEditCache(purchaseOrderLine: any): void {
    this.editCache[purchaseOrderLine.rowId] = {
      edit: true,
      data: { ...purchaseOrderLine },
      confirmed: false,
    };
    const tmpList = this.editCacheList.slice(0);
    tmpList.push(purchaseOrderLine);
    this.editCacheList = tmpList;
  }

  private removeFromEditCache(rowId: string): void {
    delete this.editCache[rowId];
    const index = this.editCacheList.findIndex(pol => pol.rowId === rowId);
    const tmpList = this.editCacheList.slice(0);
    tmpList.splice(index, 1);
    this.editCacheList = tmpList;
    this.updateLineNumbers();
  }

  private updateLineNumbers(): void {
    this.editCacheList.forEach((item, index) => {
      item.lineNumber = index + 1;
    });
  }
}
