import {Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output} from "@angular/core";

export interface IObservedSizes {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Directive({
  selector: '[appTransformObserver]',
  exportAs: 'appTransformObserver'
})
export class TransformObserverDirective implements OnDestroy {
  private _observer: ResizeObserver;
  public observedSizes: IObservedSizes = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
  @Output() sizesObserved: EventEmitter<IObservedSizes> = new EventEmitter<IObservedSizes>();

  constructor(private el: ElementRef, private zone: NgZone) {
    this._observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        const entry = entries[0];
        const sizes: IObservedSizes = {
          x: entry.contentRect.x,
          y: entry.contentRect.y,
          width: entry.contentRect.width,
          height: entry.contentRect.height,
          top: entry.contentRect.top,
          right: entry.contentRect.right,
          bottom: entry.contentRect.bottom,
          left: entry.contentRect.left
        }
        this.observedSizes = sizes;
        this.sizesObserved.emit(sizes);
      });
    });
    this._observer.observe(this.el.nativeElement);
  }

  public ngOnDestroy() {
    this._observer.unobserve(this.el.nativeElement);
  }
}