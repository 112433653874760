import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { BaseFilter, FilterDelegate } from '@models/filter';

export interface ITableFilter {
  name: string;
  value: BaseFilter;
}

const TABLE_FILTER_KEY = 'toggledFilter';

@Component({
  selector: 'app-om-table-filter-group',
  styleUrls: ['./om-table-filter-toggle-group.components.scss'],
  template: `
    <div class="toggle-group">
      <mat-button-toggle-group
        *ngIf="filters.length"
        class="mat-elevation-z2"
        name="filterGroup"
        aria-label="Filter Group"
        (change)="toggleFilter($event)"
      >
        <mat-button-toggle
          *ngFor="let filter of filters"
          [value]="filter"
          [checked]="selectedFilter !== null && filter.value.filterValues[0] === selectedFilter"
        >
          {{ filter.name }}
          <span
            *ngIf="showBadge"
            class="toggle-group-badge"
            matBadgeColor="accent"
            matBadgeOverlap="false"
            [matBadge]="statusCounts[filter.value.filterValues[0]] ? statusCounts[filter.value.filterValues[0]] : 0"
          ></span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  `,
})
export class OmTableFilterGroup implements OnInit, OnChanges {
  @Input()
  set filters(value: ITableFilter[]) {
    this._filters = value || [];
  }
  get filters(): ITableFilter[] {
    return this._filters;
  }
  private _filters: ITableFilter[] = [];

  @Input()
  set for(value: FilterDelegate) {
    if (value) {
      this._delegate = value;
    }
  }
  get for(): FilterDelegate {
    return this._delegate;
  }
  private _delegate: FilterDelegate;

  @Input()
  set statusCounts(value: any) {
    if (value) {
      this._counts = value;
    }
  }
  get statusCounts(): any {
    return this._counts;
  }
  private _counts: any = {};

  @Input() showBadge = false;
  @Input() selected = null;

  @Output() changeStatusTab: EventEmitter<number> = new EventEmitter<number>();

  public selectedFilter: any = null;

  constructor() {}

  ngOnInit(): void {
    if (!this._delegate) throw new Error('No filter delegate specified for filter group.');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selected?.currentValue !== null && changes?.selected?.currentValue !== undefined) {
      this.selectedFilter = changes.selected.currentValue;
    }
  }

  public toggleFilter(event: MatButtonToggleChange): void {
    this.changeStatusTab.emit(event.value.value.filterValues[0]);
    // if (this.selectedFilter) this._delegate.unregisterFilter(TABLE_FILTER_KEY);
    // const filter = event.value as ITableFilter;
    // this.selectedFilter = filter;

    // this._delegate.registerFilter(TABLE_FILTER_KEY, this.selectedFilter.value);
  }
}
