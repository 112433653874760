import { ISaveLogoData, OrganizationSetting } from './../models/setting.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private httpClient: HttpClient) {}

  public getOrganizationSetting(orgId: number): Observable<OrganizationSetting> {
    return this.httpClient.get<OrganizationSetting>(
      `${environment.apiBaseURL}organization/getOrganizationSetting?orgId=${orgId}`
    );
  }

  public saveOrganizationSetting(data: ISaveLogoData): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}organization/saveOrganizationSetting`, data);
  }
}
