import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DateRangeFilterRequest, FilterDelegate } from '@models/filter';
import { addDays, addQuarters, endOfMonth, endOfYear, lastDayOfQuarter, startOfMonth, startOfQuarter, startOfYear, subDays, subYears } from 'date-fns';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-date-range-slicer',
  templateUrl: './date-range-slicer.component.html',
  styleUrls: ['./date-range-slicer.component.scss']
})
export class DateRangeSlicerComponent implements OnInit, AfterViewInit {
  public static readonly rangeGroups = {
    primary: [
      {name: 'This Year', start: startOfYear(new Date()), end: endOfYear(new Date())},
      {name: 'This Month', start: startOfMonth(new Date()), end: endOfMonth(new Date())},
      {name: 'First Quarter', start: startOfYear(new Date()), end: lastDayOfQuarter(startOfYear(new Date()))},
      {name: 'Second Quarter', start: startOfQuarter(addQuarters(startOfYear(new Date()), 1)), end: lastDayOfQuarter(addQuarters(startOfYear(new Date()), 1))},
      {name: 'Third Quarter', start: startOfQuarter(addQuarters(startOfYear(new Date()), 2)), end: lastDayOfQuarter(addQuarters(startOfYear(new Date()), 2))},
      {name: 'Fourth Quarter', start: startOfQuarter(addQuarters(startOfYear(new Date()), 3)), end: lastDayOfQuarter(addQuarters(startOfYear(new Date()), 3))}
    ],
    alternate: [
      {name: '+/- 30 Days', start: subDays(new Date(), 30), end: addDays(new Date(), 30)},
      {name: 'Next 30 Days', start: new Date(), end: addDays(new Date(), 30)},
      {name: 'Next 90 Days', start: new Date(), end: addDays(new Date(), 90)},
      {name: 'This Year', start: startOfYear(new Date()), end: endOfYear(new Date())},
      {name: 'This Month', start: startOfMonth(new Date()), end: endOfMonth(new Date())}
    ]
  }

  minDate = subYears(new Date(), 1);

  public get ranges(): DateRange[] {
    return DateRangeSlicerComponent.rangeGroups[this.rangeGroup];
  } 

  @HostListener('click', ['$event'])
  onClickProp(e: Event) {
    e.stopImmediatePropagation();
  }

  private internalDatesChange = new EventEmitter<[Date, Date]>()

  @Output()
  datesChange = this.internalDatesChange.pipe(shareReplay(1));

  @Input()
  dates: [Date, Date] | [null, null] = [null, null];

  @Input() 
  rangeGroup: keyof (typeof DateRangeSlicerComponent)['rangeGroups'] = 'primary';

  @Input()
  set for(value: FilterDelegate) {
    if (value) {
      this.delegate = value;
      if (this.selectedRange) this.delegate.registerFilter('date-range', new DateRangeFilterRequest(['bookingDate'], this.selectedRange.start, this.selectedRange.end));
    }
  };

  get for(): FilterDelegate {
    return this.delegate;
  }
  private delegate: FilterDelegate;

  public DateTypes = Dates;

  public selectedRange?: DateRange;

  public selectRange(range: DateRange) {
    this.selectedRange = range;
    this.dates[0] = range.start
    this.dates[1] = range.end;
    this.internalDatesChange.emit(<[Date, Date]>this.dates);
    this.delegate.registerFilter('date-range', new DateRangeFilterRequest(['bookingDate'], this.selectedRange.start, this.selectedRange.end));
  }
  
  changeDate(index: Dates, date: Date) {
    this.dates[index] = date;
    const dates = [this.selectedRange.start, this.selectedRange.end];
    dates[index] = date;
    this.selectedRange = {name: 'custom', start: dates[0], end: dates[1]};
    this.internalDatesChange.emit(<[Date, Date]>this.dates);
    this.delegate.registerFilter('date-range', new DateRangeFilterRequest(['bookingDate'], this.selectedRange.start, this.selectedRange.end));
  }

  constructor(private cdRef: ChangeDetectorRef) {}
  
  ngOnInit(): void {
    if (!this.delegate) throw new Error("Date range slicer must have a filter delegate attached");
    if(this.dates[0] == null || this.dates[1] == null) this.selectRange(this.ranges[0]);
  }
  
  ngAfterViewInit() {
    if(this.dates[0] == null || this.dates[1] == null) this.selectRange(this.ranges[0]);
  }

  reset() {
	  this.selectRange(this.ranges[0])
  }

}

interface DateRange {
  name: string;
  start: Date;
  end: Date;
}

enum Dates {
  START,
  END
}