import { DataSource } from '@angular/cdk/table';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PreferencesService} from "../../../common-lib/table-column-filter/service/preferences.service";
import { ShipmentService } from './shipment.service';
import { Filter, FilteredDownloadRequest, FilteredRequest } from '@modules/shipment/models/filter.model';
import { FilterService } from '@modules/shipment/services/filter.service';
import { ContainerService } from './container.service';
import {deepClone} from "../../../shared/utils/array-utils";

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchSubject = new BehaviorSubject<string>('');
  public searchObservable = this.searchSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private httpClient: HttpClient,
    // private userService: UserService,
    private preferencesService: PreferencesService,
    private filterService: FilterService,
    private shipmentService: ShipmentService,
    private containerService: ContainerService,
    private toastr: ToastrService
  ) {
    // this.userService.orgUpdated.subscribe(() => {
    //   this.searchSubject.next('');
    // });
  }

  public downloadDataset(datasetName: string, preferences?: any) {
    let filters = deepClone(preferences.filters);
    let columns = this.preferencesService.getColumnPreferences(true).map(c => c.name);
    let sortByField = preferences.sort.sortByField;
    let sortAsc = preferences.sort.sortByAscending;
    let dispatchType = 'shipment';
    const defaultShipmentColumns = [
      'Order Refs',
      'Priority',
    ];

    const defaultContainerColumns = [
      'Order Refs'
    ];

    switch (datasetName) {
      case 'Containers':
        dispatchType = 'container';
        columns.unshift(...defaultContainerColumns);
        break;
      case 'AirShipments':
        filters.push(
          new Filter({
            selected: true,
            dataField: 'details.transportType',
            condition: 'eq',
            shortCode: 'eq',
            value: 'AIR',
            value2: '',
          })
        );
        columns.unshift(...defaultShipmentColumns);
        break;

      case 'SeaShipments':
        filters.push(
          new Filter({
            selected: true,
            dataField: 'details.transportType',
            condition: 'eq',
            shortCode: 'eq',
            value: 'SEA',
            value2: '',
          })
        );
        columns.unshift(...defaultShipmentColumns);
        break;
      case 'RoaShipments':
        filters.push(
          new Filter({
            selected: true,
            dataField: 'details.transportType',
            condition: 'eq',
            shortCode: 'eq',
            value: 'ROA',
            value2: '',
          })
        );
        columns.unshift(...defaultShipmentColumns);
        break;
      case 'RaiShipments':
        filters.push(
          new Filter({
            selected: true,
            dataField: 'details.transportType',
            condition: 'eq',
            shortCode: 'eq',
            value: 'RAI',
            value2: '',
          })
        );
        columns.unshift(...defaultShipmentColumns);
        break;
    }
    const filterRequest = new FilteredDownloadRequest({
      offset: 0,
      limit: 500,
      requestType: '',
      sort: sortByField,
      sortDesc: !sortAsc,
      filters: filters,
    });

    filterRequest.prepForDispatch(dispatchType);
    const payload = { Columns: columns, FilterRequest: filterRequest };
    return this.downloadDatasetAsExcel(datasetName, payload);
  }

  public downloadDatasetAsExcel(datasetName: string, payload) {
    this.toastr.info('Excel file will download in the background.', 'Downloading');
    let fileDownloadStatus = new Subject<boolean>();

    this.httpClient
      .post(`${environment.apiBaseURL}ClearView/${datasetName}/download`, payload, { responseType: 'arraybuffer' })
      .subscribe(
        (res: any) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([res], { type: res.type }));
          downloadLink.setAttribute('download', datasetName + '.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.toastr.success('Download completed.');
          fileDownloadStatus.next(true);
        },
        _ => {
          fileDownloadStatus.next(false);
          this.toastr.error('Please try again later.', 'Error downloading file.');
        }
      );
    return fileDownloadStatus;
  }
}
