import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {CdkPortal, DomPortalHost, DomPortalOutlet} from "@angular/cdk/portal";

@Component({
  selector: 'app-column-configuration-group',
  styles: [],
  template: `
  <ng-container *cdkPortal>
    <ng-content></ng-content>
  </ng-container>
  `
})
export class ColumnConfigurationGroupComponent implements AfterViewInit, OnDestroy {

  @ViewChild(CdkPortal)
  private portal: CdkPortal;

  private host: DomPortalOutlet;

  constructor(private cfr: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {}

  ngAfterViewInit() {
    this.host = new DomPortalOutlet(
      document.querySelector('#columnConfigurationGroup'),
      this.cfr,
      this.appRef,
      this.injector
    );

    this.host.attach(this.portal);
  }

  ngOnDestroy() {
    this.host.detach();
  }
}
