import { Component } from "@angular/core";

@Component({
    selector:"app-audit-history-content",
    template:`
    <div class="audit-content">
        <ng-content></ng-content>
    </div>`
})

export class AuditHistoryContentComponent {
}