import {Component, EventEmitter, Input, Output, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-select-filterable',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent{
  @ViewChild('inputDiv', {static: false}) private inputDiv: ElementRef;
  public onChange: (value: string) => void;
  @Input() public options: [];
  @Input() public displayLabel: string | null;
  @Output() public returnFilter = new EventEmitter<any[]>();

  public applyChange(e): void {
    const filter = this.options.filter((item: any) => {
      if (this.displayLabel && item[this.displayLabel]) {
        return item[this.displayLabel].toLowerCase().includes(e.target.value.toLowerCase());
      } else {
        return item.toLowerCase().includes(e.target.value.toLowerCase());
      }
    });
    this.returnFilter.emit(filter);
  }
  public setFocus(isOpen: boolean): void {
    if (isOpen) {
      this.inputDiv.nativeElement.focus();
    } else {
      this.inputDiv.nativeElement.value = '';
      this.returnFilter.emit(this.options);
    }
  }

}
