import { Pipe, PipeTransform } from '@angular/core';
import { PURCHASE_ORDER_STATUS, PurchaseOrderStatusDescription } from '@models/helpers';

@Pipe({
  name: 'poStatus',
})
export class PurchaseOrderStatusPipe implements PipeTransform {
  transform(value: any) {
    let status = '';
    if (typeof value === 'string') {
      status = value;
    } else {
      status = 'TBD';
    }

    switch (value) {
      case PURCHASE_ORDER_STATUS.Accepted: {
        status = PurchaseOrderStatusDescription.Accepted;
        break;
      }
      case PURCHASE_ORDER_STATUS.Booked: {
        status = PurchaseOrderStatusDescription.Booked;
        break;
      }
      case PURCHASE_ORDER_STATUS.AwaitingClient: {
        status = PurchaseOrderStatusDescription.AwaitingClient;
        break;
      }
      case PURCHASE_ORDER_STATUS.AwaitingSupplier: {
        status = PurchaseOrderStatusDescription.AwaitingSupplier;
        break;
      }
      case PURCHASE_ORDER_STATUS.Rejected: {
        status = PurchaseOrderStatusDescription.Rejected;
        break;
      }
      case PURCHASE_ORDER_STATUS.Cancelled: {
        status = PurchaseOrderStatusDescription.Cancelled;
        break;
      }
      case PURCHASE_ORDER_STATUS.ForwarderAssigned: {
        status = PurchaseOrderStatusDescription.ForwarderAssigned;
        break;
      }
    }
    return status;
  }
}
