import { Organization } from '@models/organization.model';
// This model maps to the Domain.Product
// on the API.
export class Product {
  public id: number;
  public skuItemNumber: string;
  public description: string;
  public quantityUnit: number;
  public quantityUnitText: string;
  public itemPrice: number;
  public linePrice: number;
  public unitCost: number;
  public origin: string;
  public manufacturerOrganizationIds: number[];
  public networkId: number;
  public grossWeight: number;
  public netWeight: number;
  public weightUnit: number;
  public weightUnitText: string;
  public height: number;
  public heightUnit: number;
  public heightUnitText: string;
  public length: number;
  public lengthUnit: number;
  public lengthUnitText: string;
  public width: number;
  public widthUnit: number;
  public widthUnitText: string;
  public volume: number;
  public volumeUnit: number;
  public volumeUnitText: string;
  public size: number;
  public color: string;
  public partAttribute1: string;
  public partAttribute2: string;
  public partAttribute3: string;
  public dgClass: string;
  public dgWeight: number;
  public dgUw: number;
  public dgUwText: string;
  public reqExWorks: string;
  public htsCode: string;
  public duty: number;
  public incoterm: string;
  public specialInstructions: string;
  public additionalInformation: string;
  public additionalTerms: string;
  public requiredInspection: boolean;
  public isDg: boolean;
  public manufacturers: Organization[];
  public manufacturersText: string;

  constructor(init?: Partial<Product>) {
    if (init?.id) this.id = init.id;
    if (init?.skuItemNumber) this.skuItemNumber = init.skuItemNumber;
    if (init?.description) this.description = init.description;
    if (init?.quantityUnit) this.quantityUnit = init.quantityUnit;
    if (init?.quantityUnitText) this.quantityUnitText = init.quantityUnitText;
    if (init?.itemPrice) this.itemPrice = init.itemPrice;
    if (init?.linePrice) this.linePrice = init.linePrice;
    if (init?.unitCost) this.unitCost = init.unitCost;
    if (init?.origin) this.origin = init.origin;
    if (init?.manufacturerOrganizationIds) this.manufacturerOrganizationIds = init.manufacturerOrganizationIds;
    if (init?.networkId) this.networkId = init.networkId;
    if (init?.grossWeight) this.grossWeight = init.grossWeight;
    if (init?.netWeight) this.netWeight = init.netWeight;
    if (init?.weightUnit) this.weightUnit = init.weightUnit;
    if (init?.weightUnitText) this.weightUnitText = init.weightUnitText;
    if (init?.height) this.height = init.height;
    if (init?.heightUnit) this.heightUnit = init.heightUnit;
    if (init?.heightUnitText) this.heightUnitText = init.heightUnitText;
    if (init?.length) this.length = init.length;
    if (init?.lengthUnit) this.lengthUnit = init.lengthUnit;
    if (init?.lengthUnitText) this.lengthUnitText = init.lengthUnitText;
    if (init?.width) this.width = init.width;
    if (init?.widthUnit) this.widthUnit = init.widthUnit;
    if (init?.widthUnitText) this.widthUnitText = init.widthUnitText;
    if (init?.volume) this.volume = init.volume;
    if (init?.volumeUnit) this.volumeUnit = init.volumeUnit;
    if (init?.volumeUnitText) this.volumeUnitText = init.volumeUnitText;
    if (init?.size) this.size = init.size;
    if (init?.color) this.color = init.color;
    if (init?.partAttribute1) this.partAttribute1 = init.partAttribute1;
    if (init?.partAttribute2) this.partAttribute2 = init.partAttribute2;
    if (init?.partAttribute3) this.partAttribute3 = init.partAttribute3;
    if (init?.dgClass) this.dgClass = init.dgClass;
    if (init?.dgWeight) this.dgWeight = init.dgWeight;
    if (init?.dgUw) this.dgUw = init.dgUw;
    if (init?.dgUwText) this.dgUwText = init.dgUwText;
    if (init?.reqExWorks) this.reqExWorks = init.reqExWorks;
    if (init?.htsCode) this.htsCode = init.htsCode;
    if (init?.duty) this.duty = init.duty;
    if (init?.incoterm) this.incoterm = init.incoterm;
    if (init?.specialInstructions) this.specialInstructions = init.specialInstructions;
    if (init?.additionalInformation) this.additionalInformation = init.additionalInformation;
    if (init?.additionalTerms) this.additionalTerms = init.additionalTerms;
    this.requiredInspection = init.requiredInspection ? init.requiredInspection : false;
    this.isDg = init.isDg ? init.isDg : false;
    if (init?.manufacturers) this.manufacturers = init.manufacturers;
  }
}

export class GetProducts {
  results: Product[];
  pageNumber: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
  additionalResult: any;

  constructor(init?: Partial<GetProducts>) {
    if (init?.results) this.results = init.results;
    if (init?.pageNumber) this.pageNumber = init.pageNumber;
    if (init?.pageCount) this.pageCount = init.pageCount;
    if (init?.pageSize) this.pageSize = init.pageSize;
    if (init?.recordCount) this.recordCount = init.recordCount;
    if (init?.additionalResult) this.additionalResult = init.additionalResult;
  }
}

interface UnitDropDown {
  value: number;
  text: string;
  symbol: string;
}

interface UnitText {
  value: number;
  text: string;
}

export enum WeightUnit {
  Kilogram = 1,
  Pound = 2,
}

export const WeightUnitText: UnitText[] = [
  { value: WeightUnit.Kilogram, text: 'kg' },
  { value: WeightUnit.Pound, text: 'lbs' },
];

export const DGWeightUnitDropDown: UnitDropDown[] = [
  { value: WeightUnit.Kilogram, text: 'Kilogram(kg)', symbol: 'kg' },
  { value: WeightUnit.Pound, text: 'Pound(lbs)', symbol: 'lbs' },
];

export const WeightUnitDropDown: UnitDropDown[] = [
  { value: WeightUnit.Kilogram, text: 'Kilogram(kg)', symbol: 'kg' },
  { value: WeightUnit.Pound, text: 'Pound(lbs)', symbol: 'lbs' },
];

export enum SizeUnit {
  Feet = 1,
  Inch = 2,
  Meter = 3,
  Centimeter = 4,
}

export const WidthUnitDropDown: UnitDropDown[] = [
  { value: SizeUnit.Feet, text: 'Feet(ft)', symbol: 'ft' },
  { value: SizeUnit.Inch, text: 'Inch(in)', symbol: 'in' },
  { value: SizeUnit.Meter, text: 'Meter(m)', symbol: 'm' },
  { value: SizeUnit.Centimeter, text: 'Centimeter(cm)', symbol: 'cm' },
];

export const HeightUnitDropDown: UnitDropDown[] = [
  { value: SizeUnit.Feet, text: 'Feet(ft)', symbol: 'ft' },
  { value: SizeUnit.Inch, text: 'Inch(in)', symbol: 'in' },
  { value: SizeUnit.Meter, text: 'Meter(m)', symbol: 'm' },
  { value: SizeUnit.Centimeter, text: 'Centimeter(cm)', symbol: 'cm' },
];

export const LengthUnitDropDown: UnitDropDown[] = [
  { value: SizeUnit.Feet, text: 'Feet(ft)', symbol: 'ft' },
  { value: SizeUnit.Inch, text: 'Inch(in)', symbol: 'in' },
  { value: SizeUnit.Meter, text: 'Meter(m)', symbol: 'm' },
  { value: SizeUnit.Centimeter, text: 'Centimeter(cm)', symbol: 'cm' },
];

export enum VolumeUnit {
  CubicMeter = 1,
  Litre = 2,
  CubicFeet = 3,
}

export const VolumnUnitText: UnitText[] = [
  { value: VolumeUnit.CubicMeter, text: 'm3' },
  { value: VolumeUnit.Litre, text: 'L' },
  { value: VolumeUnit.CubicFeet, text: 'cuft' },
];

export const VolumeUnitDropDown: UnitDropDown[] = [
  { value: VolumeUnit.CubicMeter, text: 'CubicMeter(m³)', symbol: 'm³' },
  { value: VolumeUnit.Litre, text: 'Litre(L)', symbol: 'L' },
  { value: VolumeUnit.CubicFeet, text: 'CubicFeet(ft³)', symbol: 'ft³' },
];

export enum QuantityUnit {
  Bottle = 1,
  Box = 2,
  Case = 3,
  Crate = 4,
  Carton = 5,
  Piece = 6,
  Package = 7,
  Dozen = 8,
  Drum = 9,
  Pallet = 10,
  Roll = 11,
  Set = 12,
  Sheet = 13,
  Spool = 14,
  Unit = 15,
  Each = 16
}

export const QuantityUnitDropDown: UnitDropDown[] = [
  { value: QuantityUnit.Bottle, text: 'Bottle', symbol: '' },
  { value: QuantityUnit.Box, text: 'Box', symbol: '' },
  { value: QuantityUnit.Case, text: 'Case', symbol: '' },
  { value: QuantityUnit.Crate, text: 'Crate', symbol: '' },
  { value: QuantityUnit.Carton, text: 'Carton', symbol: '' },
  { value: QuantityUnit.Piece, text: 'Piece', symbol: '' },
  { value: QuantityUnit.Package, text: 'Package', symbol: '' },
  { value: QuantityUnit.Dozen, text: 'Dozen', symbol: '' },
  { value: QuantityUnit.Drum, text: 'Drum', symbol: '' },
  { value: QuantityUnit.Pallet, text: 'Pallet', symbol: '' },
  { value: QuantityUnit.Roll, text: 'Roll', symbol: '' },
  { value: QuantityUnit.Set, text: 'Set', symbol: '' },
  { value: QuantityUnit.Sheet, text: 'Sheet', symbol: '' },
  { value: QuantityUnit.Spool, text: 'Spool', symbol: '' },
  { value: QuantityUnit.Unit, text: 'Unit', symbol: '' },
  { value: QuantityUnit.Each, text: 'Each', symbol: '' },
];
