import {Component, Input} from "@angular/core";

@Component({
    selector:"app-om-card-content",
    styles:[''],
    template:`
    <div class="om-card-content" [ngStyle]="{'height': (maxHeight) ? maxHeight : 'auto'}">
        <ng-content></ng-content>
    </div>
    `
})

export class OmCardContentComponent{
  @Input() maxHeight: string;
}
