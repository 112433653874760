import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Column,
  Frequency,
  Report,
  ReportCategory,
  ReportDataColumns,
  ReportDataDefinition,
  ReportDataFilters,
  ReportType,
} from '@models/report.model';
import { ReportService } from '@services/report.service';
import {
  CompleteReport,
  CreateReport,
  DeleteReport,
  GetReportById,
  GetReports,
  SaveReport,
  SetReportDataColumns,
  SetReportDataFilters,
  SetReportDate,
  SetReportFrequency,
  SetReportName,
  SetReportScheduled,
  SetReportTime,
  SetReportTimezone,
  SetReportType,
  SetDeliverEmptyReport,
  ToggleReportFrequencyWeekday,
  SetReportDataColumnSorts,
} from './report.actions';
import { UserService } from '@modules/auth/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

export class ReportStateModel {
  public report: Report;
  public reports: Report[];
}

export interface IReportBuilderData {
  report: Report;
  reportValid: boolean;
}

@State<ReportStateModel>({
  name: 'reportState',
  defaults: {
    report: null,
    reports: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ReportState {
  constructor(
    private reportService: ReportService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  @Selector()
  public static getReport(state: ReportStateModel): Report {
    return state.report;
  }

  @Selector()
  public static getReports(state: ReportStateModel): Report[] {
    return state.reports;
  }

  @Selector()
  public static getReportBuilderData(state: ReportStateModel): IReportBuilderData {
    return {
      report: state.report,
      reportValid: this.validateReport(state.report),
    };
  }

  @Action(GetReports)
  getReports(ctx: StateContext<GetReports>) {
    return this.reportService.getReports().pipe(
      tap(result => {
        const state = ctx.getState();
        ctx.setState({ ...state, reports: result });
      })
    );
  }

  @Action(GetReportById)
  getReport({ patchState }: StateContext<GetReports>, { reportID }: GetReportById) {
    return this.reportService.getReport(reportID).pipe(
      tap(result => {
        patchState({ report: result });
      })
    );
  }

  @Action(CreateReport)
  public createReport({ getState, setState }: StateContext<ReportStateModel>, {}: CreateReport): void {
    const state = getState();
    var newReport = new Report({
      id: null,
      networkId: this.userService.currentNetwork.id,
      reportName: '',
      deliverEmpty: false,
      reportType: 1,
      scheduled: false,
      definition: new ReportDataDefinition(),
    });
    setState({
      ...state,
      report: newReport,
    });
  }

  @Action(SaveReport)
  public saveReport({ getState, setState }: StateContext<ReportStateModel>, { payload }: SaveReport): void {
    const state = getState();
    setState({ ...state, report: payload });
  }

  @Action(CompleteReport)
  public completeReport(ctx: StateContext<ReportStateModel>, action: CompleteReport) {
    return this.reportService.saveReport(action.payload).pipe(
      tap(saveReportResult => {
        if (saveReportResult) {
          const state = ctx.getState();
          ctx.setState({ ...state, report: null });
          this.toastr.success('Save Report successfully');
          this.router.navigate(['/app/reports']);
        }
      })
    );
  }

  @Action(DeleteReport)
  public deleteReport({ getState, patchState }: StateContext<ReportStateModel>, { reportID }: DeleteReport) {
    return this.reportService.deleteReport(reportID).pipe(
      tap(result => {
        const state = getState();
        const reports = [...state.reports];
        reports.splice(
          reports.findIndex(s => s.id == reportID),
          1
        );
        patchState({ reports: reports });
      })
    );
  }

  @Action(SetReportName)
  public setReportName(ctx: StateContext<ReportStateModel>, action: SetReportName): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const reportName = action.name;
    report.reportName = reportName;
    ctx.patchState({ report: report });
  }
  @Action(SetDeliverEmptyReport)
  public setDeliverEmpty(ctx: StateContext<ReportStateModel>, action: SetDeliverEmptyReport): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    report.deliverEmpty = action.deliverEmpty;
    ctx.patchState({ report: report });
  }
  @Action(SetReportScheduled)
  public setReportScheduled(ctx: StateContext<ReportStateModel>, action: SetReportScheduled): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const scheduled = action.scheduled;

    // Reset scheduled fields
    if (report.scheduled !== scheduled) {
      report.definition.cron = null;
      report.definition.date = null;
      report.definition.frequency = null;
      report.definition.selectedWeekdays = null;
      report.definition.time = null;
      report.definition.timezone = null;
    }

    report.scheduled = scheduled;

    ctx.patchState({ report: report });
  }

  @Action(SetReportFrequency)
  public setReportFrequency(ctx: StateContext<ReportStateModel>, action: SetReportFrequency): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const frequency = action.frequency;

    // Reset scheduled fields
    if (report.definition.frequency !== frequency) {
      report.definition.cron = null;
      report.definition.selectedWeekdays = null;
    }

    report.definition.frequency = frequency;
    ctx.patchState({ report: report });
  }

  @Action(SetReportDate)
  public setReportDate(ctx: StateContext<ReportStateModel>, action: SetReportDate): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const reportDate = action.date;
    report.definition.date = reportDate;
    ctx.patchState({ report: report });
  }

  @Action(SetReportTime)
  public setReportTime(ctx: StateContext<ReportStateModel>, action: SetReportTime): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const reportTime = action.time;
    report.definition.time = reportTime;
    ctx.patchState({ report: report });
  }

  @Action(SetReportTimezone)
  public setReportTimezone(ctx: StateContext<ReportStateModel>, action: SetReportTimezone): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const reportTimezone = action.timezone;
    report.definition.timezone = reportTimezone;
    ctx.patchState({ report: report });
  }

  @Action(SetReportType)
  public setReportType(ctx: StateContext<ReportStateModel>, action: SetReportType): void {
    const state = ctx.getState();
    const reportType = action.type;

    const report = Object.assign({}, state.report);
    report.reportType = reportType;

    ctx.patchState({ report: report });
  }

  @Action(ToggleReportFrequencyWeekday)
  public toggleReportFrequencyWeekday(ctx: StateContext<ReportStateModel>, action: ToggleReportFrequencyWeekday): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const weekDays = action.weekdays;
    report.definition.selectedWeekdays = weekDays;
    ctx.patchState({ report: report });
  }

  @Action(SetReportDataColumns)
  public updateReportDataColumns(ctx: StateContext<ReportStateModel>, action: SetReportDataColumns): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const columnGroups = action.columnGroups.slice(0);
    const reportDataColumns: ReportDataColumns[] = [];
    columnGroups.forEach(cg => {
      // get sorts in current report
      const colSetting = report.definition.columnSettings.find(cs => cs.category === cg.ref);
      var columnSet = new ReportDataColumns();
      if (colSetting) {
        // remain exist sorts
        columnSet.sortColumns = colSetting.sortColumns;
      }
      columnSet.category = cg.ref;
      columnSet.columns = (<Column[]>cg.columns)
        .filter(t => t.enabled == true)
        .map((c, i) => {
          return { name: c.name, order: i };
        });
      reportDataColumns.push(columnSet);
    });

    // update sort columns
    this.reportService.reportSortColumnGroups.forEach(scg => {
      let columnGroup = columnGroups.find(cg => cg.ref === scg.ref);
      scg.columns.forEach(c => {
        const col = columnGroup.columns.find(t => t.name === c.name);
        c.enabled = col.enabled;
        if (c.sortEnabled && !col.enabled) {
          c.sortEnabled = false;
        }
      });
    });

    report.definition.columnSettings = reportDataColumns;
    ctx.patchState({ report: report });
  }

  @Action(SetReportDataColumnSorts)
  public updateReportDataColumnSorts(ctx: StateContext<ReportStateModel>, action: SetReportDataColumnSorts): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const columnSortGroups = action.columnSortGroups.slice(0);
    const reportDataColumns: ReportDataColumns[] = [];
    columnSortGroups.forEach(cg => {
      var columnSet = new ReportDataColumns();
      columnSet.category = cg.ref;
      const colSetting = report.definition.columnSettings.find(cs => cs.category === cg.ref);
      columnSet.columns = colSetting.columns;
      columnSet.sortColumns = cg.columns
        .filter(t => t.enabled == true && t.sortEnabled == true)
        .map((c, i) => {
          return { name: c.name, order: i, sortByAsc: c.sortByAsc };
        });
      reportDataColumns.push(columnSet);
    });

    report.definition.columnSettings = reportDataColumns;
    ctx.patchState({ report: report });
  }

  @Action(SetReportDataFilters)
  public updateReportDataFilters(ctx: StateContext<ReportStateModel>, action: SetReportDataFilters): void {
    const state = ctx.getState();
    const report = Object.assign({}, state.report);
    const filterGroups = action.filterGroups.slice(0);
    const reportDataFilters: ReportDataFilters[] = [];
    filterGroups.forEach(fg => {
      var columnSet = new ReportDataFilters({ category: fg.ref, filters: fg.filters });
      reportDataFilters.push(columnSet);
    });

    report.definition.filterSettings = reportDataFilters;
    ctx.patchState({ report: report });
  }

  private static validateReport(report: Report): boolean {
    if (report.reportName === null || report.reportName === undefined || report.reportName === '') return false;
    if (report.scheduled === null || report.scheduled === undefined) return false;
    if (report.reportType === null || report.reportType === undefined) return false;

    if (report.scheduled) {
      if (report.definition.frequency === null || report.definition.frequency === undefined) return false;
      if (report.definition.timezone === null || report.definition.timezone === undefined) return false;
      if (report.definition.time === null || report.definition.time === undefined || report.definition.time === '')
        return false;
      if (!report.definition.date) return false;
      if (report.definition.frequency == Frequency.Weekly) {
        if (
          report.definition.selectedWeekdays === null ||
          report.definition.selectedWeekdays === undefined ||
          report.definition.selectedWeekdays.length < 1
        )
          return false;
      }
    }

    return true;
  }
}
