import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@modules/auth/services/user.service';
import { Observable } from 'rxjs';

@Injectable()
export class OmsFullGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isOmsLiteNetwork = this.userService.currentNetwork.customerLiteEnabled;
    if (!isOmsLiteNetwork) {
      return true;
    }

    return this.router.parseUrl('/forbidden');
  }
}
