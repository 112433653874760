import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-om-info-dialog',
  template: `<h2 mat-dialog-title>{{ header }}</h2>
    <mat-dialog-content>
      <p class="respect-breaks">{{ prompt }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end"style="margin-bottom: 0; padding-bottom: 0;">
      <button mat-raised-button color="primary" (click)="close()" class="constructive">Ok</button>
    </mat-dialog-actions> `,
})
export class InfoDialogComponent {
  public header: string;
  public prompt: string;
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.prompt = data.prompt;
    this.header = data.header;
  }

  close() {
    this.dialogRef.close();
  }
}
