import { Unloco } from './unloco.model';

// This model maps to the Domain.ShipmentEvent
// on the API.
export class ShipmentEvent {
  public eventType: string;
  public eventTime: Date;
  public description: string;
  public unlocos: Unloco[];

  constructor(init?: Partial<ShipmentEvent>) {
    if (init?.eventType) this.eventType = init.eventType;
    if (init?.eventTime) this.eventTime = init.eventTime;
    if (init?.description) this.description = init.description;
    this.unlocos = init?.unlocos ? init.unlocos : [];
  }
}

export class ShippingStop {
  public locationName: string;
  public locationCountry: string;
  public stopType: string;
  public isFirst: boolean;
  public isLast: boolean;
  public isComplete: boolean;
  public isCurrent: boolean;
  public isActive: boolean;
  public isFuture: boolean;
  public etd: Date;
  public eta: Date;
  public atd: Date;
  public ata: Date;
}

export class TimeLineStep {
  public type: string;
  public title: string;
  public text?: string;
  public subtexts: string[];
  public icon?: string;
  public completed?: boolean;
  public stepDate: Date;
}

export const EventIconMap = new Map<string, string>([
  ['on_water', 'ocean-transportation'],
  ['sea', 'ocean-transportation'],
  ['departed', 'ocean-transportation'],
  ['departure', 'ocean-transportation'],
  ['booked', 'delivery-date'],
  ['on_rail', 'train'],
  ['rail', 'train'],
  ['rai', 'train'],
  ['in_gate', 'delivery-scale'],
  ['in_transit', 'air-transport'],
  ['discharge', 'container-hanging'],
  ['air', 'air-transport'],
  ['arrived', 'delivery-clipboard'],
  ['arrival', 'delivery-clipboard'],
  ['out_gate', 'piles-stored'],
  ['delivered', 'on-door-delivery'],
  ['empty_returned', 'opened-packaged'],
  ['loaded', 'shipping-and-delivery'],
  ['gate_in', 'box-on-delivery-scale'],
  ['freight_loaded', 'shipping-and-delivery'],
  ['freight_unloaded', 'container-hanging-1'],
  ['cargo_available', 'package'],
  ['container_ready', 'open-pack'],
  ['handed_over', 'delivery-in-hand'],
  ['dehire', 'package-on-trolley'],
  ['held', 'delivery-search'],
  ['gate_out', 'box-on-delivery-scale'],
  ['delivery_truck', 'delivery-truck'],
  ['roa', 'delivery-truck'],
  ['ports', 'port'],
  ['seaports', 'port'],
  ['airports', 'airport'],
  ['arrived_transship', 'storage'],
  ['rail_arrival', 'train'],
  ['rail_departure', 'train'],
]);
