import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LoadingService } from '@modules/auth/services/loading.service';
import { Subscription } from 'rxjs';
import * as momentTZ from 'moment-timezone';
import {UserService} from "@modules/auth/services/user.service";
import 'src/app/shared/utils/string-prototypes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public navigating: boolean = true;
  private _routerEventsSub: Subscription;
  private _navigationStartTimeout; // Used to prevent loading flicker for routes without resolvers attached.

  public loading: boolean = false;
  private _manualLoadingSub: Subscription;

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private userService: UserService
  ) {
    this.userService.currentTimeZone = momentTZ.tz.guess(true);
  }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon("search", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svgs/search.svg"));
    this.matIconRegistry.addSvgIcon("clear", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svgs/clear.svg"));
  
    this._routerEventsSub = this.router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
    });

    this._manualLoadingSub = this.loadingService.isDoneLoading$.subscribe(isDoneLoading => {
      this.loading = !isDoneLoading;
    });
  }

  ngOnDestroy(): void {
      if (this._routerEventsSub) this._routerEventsSub.unsubscribe();
      if (this._manualLoadingSub) this._manualLoadingSub.unsubscribe();
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      if (this._navigationStartTimeout) clearTimeout(this._navigationStartTimeout);
      this._navigationStartTimeout = setTimeout(() => {
        this.navigating = true;
      }, 250)
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
        if (this._navigationStartTimeout) clearTimeout(this._navigationStartTimeout);
        this.navigating = false;
    }
  }
}
