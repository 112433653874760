import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogHeight: number;

  get dialogHeight(): number {
    return this._dialogHeight;
  }

  set dialogHeight(value: number) {
    this._dialogHeight = value;
  }
}
