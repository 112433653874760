import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IApiAccessKey } from '@models/supplier-business-rule-set.model';
import { UserService } from '@modules/auth/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Address4 } from 'ip-address';

@Component({
  selector: 'app-network-access-key-setting',
  templateUrl: './network-access-key-setting.component.html',
  styleUrls: ['./network-access-key-setting.component.scss'],
})
export class NetworkAccessKeySettingComponent implements OnInit, OnChanges {
  @Input() orgId: number;
  @Input() apiAccessKeyInfo: IApiAccessKey;
  @Input() errorRestrictionsArr: string[] = [];
  @Output() refreshApiIpRestrictions: EventEmitter<IApiAccessKey> = new EventEmitter<IApiAccessKey>();
  public loading: boolean = false;
  public valid: boolean = true;
  public errorRestrictionsList = [];

  constructor(private userService: UserService, private toastr: ToastrService) {}

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorRestrictionsArr && !changes.errorRestrictionsArr.firstChange) {
      this.errorRestrictionsList = changes.errorRestrictionsArr.currentValue;
    }
  }

  generateAccessKey(event: any) {
    this.userService.genetateApiAccessKey(this.orgId).subscribe(res => {
      this.apiAccessKeyInfo.accessKey = res;
      this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
    });
  }

  toggleAccessKeyEnabled(event: boolean) {
    this.apiAccessKeyInfo.enabled = event;
    if (!event) {
      this.apiAccessKeyInfo.ipRestrictionEnabled = event;
    }
    this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
  }

  toggleIpRestrictionEnabled(event: boolean) {
    this.apiAccessKeyInfo.ipRestrictionEnabled = event;
    this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
  }

  public addRestriction() {
    const restrictionsItem = {
      startIp: '',
      endIp: '',
      id: 0,
    };
    this.valid = false;
    this.apiAccessKeyInfo.apiIpRestrictions = [...this.apiAccessKeyInfo.apiIpRestrictions, restrictionsItem];
    this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
  }

  public removeRestriction(event: any, index: number): void {
    event.stopImmediatePropagation();
    this.apiAccessKeyInfo.apiIpRestrictions.splice(index, 1);

    this.apiAccessKeyInfo.apiIpRestrictions = this.apiAccessKeyInfo.apiIpRestrictions.slice(0);
    this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
  }

  public restrictionChanged(event: any, type: 'start' | 'end', index: number) {
    if (!Address4.isValid(event?.target?.value)) {
      this.toastr.warning('Please input the correct ip address.');
      this.addErrorRestrictions(type + '-' + index);
    } else {
      this.removeErrorRestrictions(type + '-' + index);
    }
    if (type === 'start') {
      this.apiAccessKeyInfo.apiIpRestrictions[index].startIp = event?.target?.value;
    } else if (type === 'end') {
      this.apiAccessKeyInfo.apiIpRestrictions[index].endIp = event?.target?.value;
    }
    this.refreshApiIpRestrictions.emit(this.apiAccessKeyInfo);
  }

  public addErrorRestrictions(text: string) {
    if (this.errorRestrictionsList.indexOf(text) === -1) {
      this.errorRestrictionsList.push(text);
    }
  }

  public removeErrorRestrictions(text: string) {
    const index = this.errorRestrictionsList.indexOf(text);
    if (index !== -1) {
      this.errorRestrictionsList.splice(index, 1);
    }
  }
}
