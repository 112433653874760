import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";

@Component({
  selector: 'app-detail-navigator',
  templateUrl: './navigator.component.html'
})
export class NavigatorComponent implements OnInit {
  @Input() public dataSource: any[] = [];
  public canViewPrevious = true
  public canViewNext = true
  public shipmentIndex = 0
  @Input() idField: string = 'id';
  @Output() reloadData = new EventEmitter();
  @Input() curItem: any;
  @Input() loading: boolean = false;

  constructor(
    private router: Router,
  ) {
  }


  public ngOnInit(): void {
    const index = this.dataSource.findIndex(item => item[this.idField] === this.curItem);
    this.shipmentIndex = index + 1
    this.checkButton()
  }

  private checkButton(): void {
    this.canViewNext = false;
    this.canViewPrevious = this.shipmentIndex > 1;
    if(this.shipmentIndex< this.dataSource.length ) {
      this.canViewNext = true
    }
  }

  public viewPreviousShipment(): void {
    const urlArr = this.router.url.split('/');
    const newIndex = this.shipmentIndex - 2
    urlArr[urlArr.length - 1] = this.dataSource[newIndex][this.idField]
    const url = urlArr.join('/')
    this.router.navigate([url]);
    this.shipmentIndex = newIndex + 1
    this.checkButton()
    setTimeout(() => {
      this.reloadData.emit();
    });
  }

  public viewNextShipment(): void {
    const urlArr = this.router.url.split('/');
    urlArr[urlArr.length - 1] = this.dataSource[this.shipmentIndex][this.idField]
    const url = urlArr.join('/')
    this.router.navigate([url])
    this.shipmentIndex = this.shipmentIndex + 1
    this.checkButton()
    setTimeout(() => {
      this.reloadData.emit();
    });
  }

}
