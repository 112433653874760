import { Component, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UnlocoCountry } from '@models/unloco.model';
import { UnlocoApiService } from '@services/unloco-api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-unloco-country',
  templateUrl: './unloco-country.component.html',
  styleUrls: ['./unloco-country.component.scss'],
})
export class UnlocoCountryComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() required = false;
  @Input() extendedWidth = false;
  @Input() outline = false;
  @ViewChild(MatAutocompleteTrigger) trigger: any;

  searchControl = new UntypedFormControl();
  selectedCountry = '';
  countries: UnlocoCountry[] = [];

  private onChange = (_: string) => {};
  private onTouched = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private service: UnlocoApiService) {
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(newValue => {
      if (newValue !== '' && typeof newValue === 'string') {
        // on input
        this.selectCountry(''); // clear out selected country while we find one that matches the current input
        this.filterCountries(newValue);
      } else if (newValue) {
        // on selected
        this.selectCountry((newValue as UnlocoCountry).code);
      } else {
        // on input cleared
        this.countries = [];
        this.selectCountry('');
      }
    });
  }

  ngAfterViewInit(): void {
    // handles case when the user leaves the input (blur) but the input still
    // has text in it. we check if the text matches any options and select it , else
    // we clear the input.
    (this.trigger as MatAutocompleteTrigger).panelClosingActions.subscribe(e => {
      let currentValue = this.searchControl.value;
      // currentValue would be an object if an option was selected. we check this instead of
      // selectedCountry for the times that the selectedCountry doesn't get cleared out in time
      // before this function is called.
      if (!(e && e.source) && currentValue && typeof currentValue === 'string') {
        this.service.getUnlocoCountries(currentValue).subscribe(countries => {
          this.countries = countries;
          var currentValueUpper = currentValue.toUpperCase();
          const matchingCountry = this.countries.find(
            u => u.code === currentValueUpper || u.name.toUpperCase() === currentValueUpper
          );
          if (matchingCountry) {
            this.searchControl.setValue(matchingCountry);
            this.selectedCountry = matchingCountry.code;
          } else {
            this.searchControl.setValue(null);
            this.selectedCountry = '';
          }
        });
      }
    });
  }

  filterCountries(value: string, emitEvent: boolean = true): void {
    if (value) {
      this.service.getUnlocoCountries(value).subscribe(countries => {
        this.countries = countries;
        var currentInput = value.toUpperCase();
        const matchingCountry = countries.find(u => u.code === currentInput || u.name.toUpperCase() === currentInput);
        if (matchingCountry) {
          this.searchControl.setValue(matchingCountry, { emitEvent: emitEvent });
          this.selectedCountry = matchingCountry.code;
        }
      });
    }
  }

  selectCountry(value: string): void {
    this.selectedCountry = value;
    this.onChange(value);
    this.onTouched();
  }

  writeValue(code: string): void {
    if (!code) {
      this.searchControl.setValue(null, { emitEvent: false });
      this.selectedCountry = null;
      this.countries = [];
    } else {
      this.filterCountries(code, false);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    return null;
  }

  displayFn(country?: UnlocoCountry): string {
    return country ? `(${country.code}) ${country.name}` : '';
  }

  public clearField(event: any): void {
    event.stopImmediatePropagation();
    this.ngControl.reset(null);
  }
}
