import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Shipment } from "@models/shipment.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class ShipmentApiService {
  constructor(private http: HttpClient) { }

  getShipments(): Observable<Shipment[]> {
    return this.http.get<Shipment[]>(`${environment.apiBaseURL}Shipment`);
  }

  getShipment(bookingId: number): Observable<Shipment> {
    return this.http.get<Shipment>(`${environment.apiBaseURL}Shipment/${bookingId}`);
  }

  saveShipment(shipment: Shipment): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseURL}Shipment`, shipment);
  }
}