<div *ngIf="(selectedOrg | async) as org; else orgSelect" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="auto center">
  <span fxFlex="1 0 auto">{{org.name}}, {{org.code}}</span>
  <button fxFlex="0 0 auto" mat-icon-button (click)="reset()"><mat-icon>close</mat-icon></button>
</div>
<ng-template #orgSelect>
  <div fxLayout="column" id="port-list">
    <button mat-button disabled *ngIf="!orgs.length">No options found.</button>
    <button mat-button *ngFor="let org of orgs" (click)="selectOrg(org)">
      <span fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="auto center">
        <span>{{org.name}}, {{org.code}}</span>
      </span>
    </button>
  </div>
</ng-template>