import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter, FilterDelegate } from '@models/filter';

@Component({
  selector: 'app-om-table-search-input',
  styleUrls: ['./om-table-search-input.component.scss'],
  template: `
    <div class="search-div">
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput [(ngModel)]="searchInput" (input)="onInput()" type="text" aria-label="Search" />
      </mat-form-field>
    </div>
  `,
})
export class OmTableSearchInput {
  @Input() public searchInput = '';

  @Input()
  set for(value: FilterDelegate) {
    if (value) {
      this._delegate = value;
    }
  }
  get for(): FilterDelegate {
    return this._delegate;
  }
  private _delegate: FilterDelegate;

  @Input() requestSearch: boolean = false;
  @Output() inputChanged: EventEmitter<string> = new EventEmitter<string>();

  private _inputTimeout;

  constructor() {}

  onInput(): void {
    if (!this.for) return;

    clearTimeout(this._inputTimeout);
    this._inputTimeout = setTimeout(() => {
      if (this.requestSearch) {
        this.inputChanged.emit(this.searchInput);
      } else {
        const filter = new Filter(null, [this.searchInput]);
        this._delegate.registerFilter('search', filter);
      }
    }, 400);
  }
}
