import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoApprovedContainerizedType, AutoApprovedTransportMode } from '@models/customer-network.model';

@Component({
  selector: 'app-booking-auto-approve',
  templateUrl: './booking-auto-approve.component.html',
  styleUrls: ['./booking-auto-approve.component.scss'],
})
export class BookingAutoApproveComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  public showContainerizedType = false;
  public autoApprovedTransportMode = AutoApprovedTransportMode;
  public autoApprovedContainerizedType = AutoApprovedContainerizedType;
  constructor() {}

  ngOnInit(): void {
    if (
      this.form.get('autoApprovedTransportMode').value === this.autoApprovedTransportMode.All ||
      this.form.get('autoApprovedTransportMode').value === this.autoApprovedTransportMode.SEA
    ) {
      this.showContainerizedType = true;
    } else {
      this.showContainerizedType = false;
    }
  }

  onTransportModeSelect(event: any) {
    if (event.value === this.autoApprovedTransportMode.All || event.value === this.autoApprovedTransportMode.SEA) {
      this.showContainerizedType = true;
    } else {
      this.showContainerizedType = false;
    }
  }
}
