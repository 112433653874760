<app-page-header>
  <ng-container title>Routing Guides</ng-container>
  <ng-container toolbar>
    <button mat-raised-button color="primary" (click)="openEditor($event)" [disabled]="loading">
      Add Routing Guide
    </button>
  </ng-container>
</app-page-header>
<app-loading-indicator *ngIf="loading; else content"></app-loading-indicator>
<ng-template #content>
  <app-om-table
    tableKey="routing-guide-admin-table"
    [dataSource]="routingGuides"
    [allowRowExpansion]="false"
    [headerOnTop]="true"
    [containerHeight]="viewHeight - 80"
    [grayScrollCorner]="true"
  >
    <app-om-table-custom-cells-wrapper customCells>
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="om-table-header external-header"></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            aria-label="Show routing guide actions."
            [matMenuTriggerFor]="menu"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditor($event, element)">Edit Routing Guide</button>
            <button mat-menu-item (click)="deleteRoutingGuide($event, element.id)">Delete Routing Guide</button>
          </mat-menu>
        </td>
      </ng-container>
    </app-om-table-custom-cells-wrapper>
  </app-om-table>
</ng-template>
