import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ITableColumnConfig } from './table-configurations';

export interface ExportDelegate {
  exportAsExcel(datasetName: string, nestedConfig: NestedTableExportConfig): Observable<any>;
}

export interface NestedTableExportConfig {
  accessor: string;
  tableKey: string;
  columns?: ITableColumnConfig[];
}

@Component({
  selector: 'app-om-table-export',
  styleUrls: ['./om-table-export.component.scss'],
  template: `
    <button class="download-btn" mat-raised-button color="primary" (click)="export()" [disabled]="loading">
      <mat-icon [class.download-spinner]="loading">download</mat-icon>
    </button>
  `,
})
export class OmTableExport {
  @Input() datasetName: string = '';
  @Input() nestedTableKey: string;
  @Input() nestedAccessor: string;
  @Input() hasChildTableKey: boolean = false;

  @Input()
  set for(value: ExportDelegate) {
    if (value) {
      this._delegate = value;
    }
  }

  @Input() loading: boolean = false;

  private _delegate: ExportDelegate;

  constructor() {}

  export() {
    const config: NestedTableExportConfig = {
      accessor: this.nestedAccessor,
      tableKey: this.nestedTableKey,
    };
    if (this.hasChildTableKey) {
      this._delegate.exportAsExcel(this.datasetName, config);
    } else {
      this._delegate.exportAsExcel(this.datasetName, config).subscribe(_ => {});
    }
  }
}
