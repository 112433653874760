export interface INetworkBusinessRuleSet {
  closePoUntilFulfilled: boolean;
  apiAccessKey: IApiAccessKey;
  asnTriggerDefinition: IAsnTriggerDefinition;
}

export interface ISupplierBusinessRuleSet {
  networkOrganizationId?: number;
  quantityShip: number;
  bookAndCargoDatesDiff: number;
  containerFill: number;
  daysToBook: number;
  documentsAllowed: string[];
  poDefaults: IPoDefaults;
  bookingDefaults: IBookingDefaults;
  autoApprovedTransportMode: number;
  autoApprovedContainerizedType: number;
  canEditApprovedBooking: boolean;
}

export interface IPoDefaults {
  transportMode: string;
  manufacturerOrgCode: string;
  pickupLocationCode: string;
  incoterm: string;
  consigneeOrgCode: string;
  shipToLocationCode: string;
  originCountry: string;
  pol: string;
  pod: string;
}

export interface IBookingDefaults {
  consigneeOrgCode: string;
  manufacturerOrgCode: string;
  shipperLocationCode: string;
  customerLocationCode: string;
  consigneeLocationCode: string;
  incoterm: string;
  pickupLocationCode: string;
  shipToLocationCode: string;
  pol: string;
  pod: string;
  forwarderOrgCode: string;
  forwarderContactId: number;
}

export interface IApiAccessKey {
  organizationId: number;
  accessKey: string;
  enabled: boolean;
  ipRestrictionEnabled: boolean;
  apiIpRestrictions: IApiIpRestrictions[];
}

export interface IApiIpRestrictions {
  id?: number;
  startIp: string;
  endIp: string;
}

export interface IAsnTriggerDefinition {
  id?: number;
  organizationId: number;
  containerizedTrigger: AsnTriggerType;
  nonContainerizedTrigger: AsnTriggerType;
  hasContainerizedTrigger: boolean;
  hasNonContainerizedTrigger: boolean;
  deliverEmpty: boolean;
  recipientSetting: IRecipientSetting;
}

export interface IRecipientSetting {
  otherRecipients: string[];
  sendToClient: boolean;
  sendToSupplier: boolean;
}

export enum AsnTriggerType {
  None = 0,
  ContainersSealed = 1,
  PackingCompleted = 2,
  AcutalDepartureDate = 3,
  FreightLoaded = 4,
  CargoPickedUpDate = 5,
}
