import { Organization } from './organization.model';
// This model maps to the Domain.PurchaseOrderLine
// on the API.
export class PurchaseOrderLine {
  public id: number;
  public purchaseOrderId: number;
  public productId: number;
  public lineNumber: number;
  public subLineNumber: number;
  public partNumber: string;
  public skuItemNumber: string;
  public description: string;
  public productDescription: string;
  public lineType: string;
  public innerPacks: number;
  public totalInnerPacks: number;
  public outerPacks: number;
  public orderQuantity: number;
  public bookingQuantity: number;
  public balanceQuantity: number;
  public invoicedQuantity: number;
  public receivedQuantity: number;
  public shippedQuantity: number;
  public committedQuantity: number;
  public bookedQuanties: BookedQuanty[];
  public quantityUnit: number;
  public quantityUnitText: string;
  public itemPrice: number;
  public linePrice: number;
  public unitCost: number;
  public invoiceNumber: string;
  public origin: string;
  public manufacturer: Organization;
  public manufacturerLocation: Location;
  public grossWeight: number;
  public netWeight: number;
  public weightUnit: number;
  public weightUnitText: string;
  public height: number;
  public heightUnit: number;
  public heightUnitText: string;
  public length: number;
  public lengthUnit: number;
  public lengthUnitText: string;
  public width: number;
  public widthUnit: number;
  public widthUnitText: string;
  public volume: number;
  public volumeUnit: number;
  public volumeUnitText: string;
  public size: number;
  public color: string;
  public partAttribute1: string;
  public partAttribute2: string;
  public partAttribute3: string;
  public dgClass: string;
  public dgWeight: number;
  public dgUw: number;
  public dgUwText: string;
  public isDg: boolean;
  public reqExWorks: string;
  public htsCode: string;
  public duty: number;
  public incoterm: string;
  public confirmDate: Date;
  public confirmNumber: string;
  public cargoReadyDate: Date;
  public specialInstructions: string;
  public additionalInformation: string;
  public additionalTerms: string;
  public lastUpdateUserId: number;
  public productManufacturers: Organization[];

  // Assigned purchase order line fields
  public containerNumber: number;
  public shipmentNumber: number;

  // Booked purchase order line fields
  public bookingId: number;

  // validate in the assign po
  public isValidateError: boolean;
  public isValidateMsg: string;
  public removeDisabled: boolean;

  constructor(init?: Partial<PurchaseOrderLine>) {
    if (init?.id) this.id = init.id;
    if (init?.purchaseOrderId) this.purchaseOrderId = init.purchaseOrderId;
    if (init?.productId) this.productId = init.productId;
    if (init?.lineNumber) this.lineNumber = init.lineNumber;
    if (init?.subLineNumber) this.subLineNumber = init.subLineNumber;
    if (init?.partNumber) this.partNumber = init.partNumber;
    if (init?.skuItemNumber) this.skuItemNumber = init.skuItemNumber;
    if (init?.description) this.description = init.description;
    if (init?.productDescription) this.productDescription = init.productDescription;
    if (init?.lineType) this.lineType = init.lineType;
    if (init?.innerPacks) this.innerPacks = init.innerPacks;
    if (init?.totalInnerPacks) this.totalInnerPacks = init.totalInnerPacks;
    if (init?.outerPacks) this.outerPacks = init.outerPacks;
    if (init?.orderQuantity) this.orderQuantity = init.orderQuantity;
    if (init?.bookingQuantity) this.bookingQuantity = init.bookingQuantity;
    if (init?.balanceQuantity) this.balanceQuantity = init.balanceQuantity;
    if (init?.invoicedQuantity) this.invoicedQuantity = init.invoicedQuantity;
    if (init?.receivedQuantity) this.receivedQuantity = init.receivedQuantity;
    if (init?.shippedQuantity) this.shippedQuantity = init.shippedQuantity;
    if (init?.committedQuantity) this.committedQuantity = init.committedQuantity;
    if (init?.quantityUnit) this.quantityUnit = init.quantityUnit;
    if (init?.quantityUnitText) this.quantityUnitText = init.quantityUnitText;
    if (init?.itemPrice) this.itemPrice = init.itemPrice;
    if (init?.linePrice) this.linePrice = init.linePrice;
    if (init?.unitCost) this.unitCost = init.unitCost;
    if (init?.invoiceNumber) this.invoiceNumber = init.invoiceNumber;
    if (init?.origin) this.origin = init.origin;
    if (init?.manufacturer) this.manufacturer = init.manufacturer;
    if (init?.manufacturerLocation) this.manufacturerLocation = init.manufacturerLocation;
    if (init?.grossWeight) this.grossWeight = init.grossWeight;
    if (init?.netWeight) this.netWeight = init.netWeight;
    if (init?.weightUnit) this.weightUnit = init.weightUnit;
    if (init?.weightUnitText) this.weightUnitText = init.weightUnitText;
    if (init?.height) this.height = init.height;
    if (init?.heightUnit) this.heightUnit = init.heightUnit;
    if (init?.heightUnitText) this.heightUnitText = init.heightUnitText;
    if (init?.length) this.length = init.length;
    if (init?.lengthUnit) this.lengthUnit = init.lengthUnit;
    if (init?.lengthUnitText) this.lengthUnitText = init.lengthUnitText;
    if (init?.width) this.width = init.width;
    if (init?.widthUnit) this.widthUnit = init.widthUnit;
    if (init?.widthUnitText) this.widthUnitText = init.widthUnitText;
    if (init?.volume) this.volume = init.volume;
    if (init?.volumeUnit) this.volumeUnit = init.volumeUnit;
    if (init?.volumeUnitText) this.volumeUnitText = init.volumeUnitText;
    if (init?.size) this.size = init.size;
    if (init?.color) this.color = init.color;
    if (init?.partAttribute1) this.partAttribute1 = init.partAttribute1;
    if (init?.partAttribute2) this.partAttribute2 = init.partAttribute2;
    if (init?.partAttribute3) this.partAttribute3 = init.partAttribute3;
    if (init?.dgClass) this.dgClass = init.dgClass;
    if (init?.dgWeight) this.dgWeight = init.dgWeight;
    if (init?.dgUw) this.dgUw = init.dgUw;
    if (init?.dgUwText) this.dgUwText = init.dgUwText;
    this.isDg = init?.isDg ? init.isDg : false;
    if (init?.reqExWorks) this.reqExWorks = init.reqExWorks;
    if (init?.htsCode) this.htsCode = init.htsCode;
    if (init?.duty) this.duty = init.duty;
    if (init?.incoterm) this.incoterm = init.incoterm;
    if (init?.confirmDate) this.confirmDate = init.confirmDate;
    if (init?.confirmNumber) this.confirmNumber = init.confirmNumber;
    if (init?.specialInstructions) this.specialInstructions = init.specialInstructions;
    if (init?.additionalInformation) this.additionalInformation = init.additionalInformation;
    if (init?.additionalTerms) this.additionalTerms = init.additionalTerms;
    if (init?.lastUpdateUserId) this.lastUpdateUserId = init.lastUpdateUserId;
    this.productManufacturers = init?.productManufacturers ? init.productManufacturers : [];
    this.bookedQuanties = init?.bookedQuanties ? init.bookedQuanties : [];

    if (init?.containerNumber) this.containerNumber = init.containerNumber;
    if (init?.shipmentNumber) this.shipmentNumber = init.shipmentNumber;

    if (init?.bookingId) this.bookingId = init.bookingId;
    if (init?.isValidateError) this.isValidateError = init.isValidateError;
    if (init?.isValidateMsg) this.isValidateMsg = init.isValidateMsg;
    if (init?.removeDisabled) this.removeDisabled = init.removeDisabled;
  }
}

export class BookedQuanty {
  public id: number;
  public internalNumber: string;
  public cwNumber: string;
  public quantity: number;

  constructor(init?: Partial<BookedQuanty>) {
    if (init?.id) this.id = init.id;
    if (init?.internalNumber) this.internalNumber = init.internalNumber;
    if (init?.cwNumber) this.cwNumber = init.cwNumber;
    if (init?.quantity) this.quantity = init.quantity;
  }
}

export class BookedPurchaseOrderLine {
  public purchaseOrderLineId: number;
  public quantity: number;
}

export class SavePurchaseOrderLine {
  public id: number;
  public productId: number;
  public lineNumber: number;
  public partNumber: string;
  public skuItemNumber: string;
  public orderQuantity: number;
  public bookingQuantity: number;
  public balanceQuantity: number;
  public shippedQuantity: number;
  public committedQuantity: number;
  public quantityUnit: number;
  public itemPrice: number;
  public linePrice: number;
  public manufacturer: Organization;
  public manufacturerLocation: Location;
  public grossWeight: number;
  public netWeight: number;
  public weightUnit: number;
  public height: number;
  public heightUnit: number;
  public length: number;
  public lengthUnit: number;
  public width: number;
  public widthUnit: number;
  public volume: number;
  public volumeUnit: number;
  public size: number;
  public color: string;
  public dgClass: string;
  public dgWeight: number;
  public dgUw: number;
  public isDg: boolean;
  public htsCode: string;
  public specialInstructions: string;
  public productDescription: string;

  constructor(init?: Partial<SavePurchaseOrderLine>) {
    if (init?.id) this.id = init.id;
    this.isDg = init?.isDg ? init.isDg : false;
    if (init?.lineNumber) this.lineNumber = init.lineNumber;
    if (init?.orderQuantity) this.orderQuantity = init.orderQuantity;
    if (init?.balanceQuantity) this.balanceQuantity = init.balanceQuantity;
    if (init?.productId) this.productId = init.productId;
    if (init?.productId) {
      if (init?.partNumber) this.partNumber = init.partNumber;
      if (init?.skuItemNumber) this.skuItemNumber = init.skuItemNumber;
      if (init?.bookingQuantity) this.bookingQuantity = init.bookingQuantity;
      if (init?.shippedQuantity) this.shippedQuantity = init.shippedQuantity;
      if (init?.committedQuantity) this.committedQuantity = init.committedQuantity;
      if (init?.quantityUnit) this.quantityUnit = init.quantityUnit;
      if (init?.itemPrice) this.itemPrice = init.itemPrice;
      if (init?.linePrice) this.linePrice = init.linePrice;
      if (init?.manufacturer) this.manufacturer = init.manufacturer;
      if (init?.manufacturerLocation) this.manufacturerLocation = init.manufacturerLocation;
      if (init?.grossWeight) this.grossWeight = init.grossWeight;
      if (init?.netWeight) this.netWeight = init.netWeight;
      if (init?.weightUnit) this.weightUnit = init.weightUnit;
      if (init?.height) this.height = init.height;
      if (init?.heightUnit) this.heightUnit = init.heightUnit;
      if (init?.length) this.length = init.length;
      if (init?.lengthUnit) this.lengthUnit = init.lengthUnit;
      if (init?.width) this.width = init.width;
      if (init?.widthUnit) this.widthUnit = init.widthUnit;
      if (init?.volume) this.volume = init.volume;
      if (init?.volumeUnit) this.volumeUnit = init.volumeUnit;
      if (init?.size) this.size = init.size;
      if (init?.color) this.color = init.color;
      if (init?.dgClass) this.dgClass = init.dgClass;
      if (init?.dgWeight) this.dgWeight = init.dgWeight;
      if (init?.dgUw) this.dgUw = init.dgUw;
      if (init?.htsCode) this.htsCode = init.htsCode;
      if (init?.specialInstructions) this.specialInstructions = init.specialInstructions;
      if (init?.productDescription) this.productDescription = init.productDescription;
    }
  }
}
