const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
export class PoColumn {
  public accessor: string;
  public name: string;
  public enabled = true;
  public type = 'text';
  public unitAccessor: string;

  constructor(accessor: string, name?: string) {
    this.accessor = accessor;
    if (name) {
      this.name = name;
    } else {
      this.name = accessor.replace(STRING_DECAMELIZE_REGEXP, '$1 $2');
    }
  }

  public disabled(): PoColumn {
    this.enabled = false;
    return this;
  }

  public setType(type: string): PoColumn {
    this.type = type;
    return this;
  }

  public setUnitAccessor(unitAccessor: string): PoColumn {
    this.unitAccessor = unitAccessor;
    return this;
  }




}
