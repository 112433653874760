import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SsoUser, User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss'],
})
export class ImpersonateUserComponent implements OnInit {
  private _userList: User[] = [];
  public searchTerm = '';

  constructor(
    private dialogRef: MatDialogRef<ImpersonateUserComponent>,
    private toastr: ToastrService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.getAllUsers();
  }

  ngOnInit(): void {}

  public get userList(): User[] {
    return this._userList.filter(user => user.email.toLowerCase().includes(this.searchTerm.toLowerCase()));
  }

  private getAllUsers(): void {
    this.userService.getAllOmsUsers().subscribe(result => {
      this._userList = result;
    });
  }

  public impersonate(userId: string): void {
    this.dialogRef.close();
    this.toastr.info('Loading user information', 'Impersonating...');
    console.info('Beginning impersonation... Loading user information.');
    this.authService.requestImpersonation(userId);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
