import { Report } from '@models/report.model';

export class SaveReport {
  public static readonly type = '[Report] SaveReport';

  constructor(public payload: Report) {}
}
export class CompleteReport {
  public static readonly type = '[Report] CompleteReport';

  constructor(public payload: Report) {}
}

export class CreateReport {
  public static readonly type = '[Report] CreateReport';

  constructor() {}
}

export class DeleteReport {
  public static readonly type = '[Report] DeleteReport';

  constructor(public reportID: string) {}
}

export class GetReportById {
  public static readonly type = '[Report] GetReportById';

  constructor(public reportID: string) {}
}

export class GetReports {
  public static readonly type = '[Report List] GetReports';

  constructor() {}
}

export class SetReportName {
  public static readonly type = '[Report] Set Report Name';

  constructor(public name: string) {}
}
export class SetDeliverEmptyReport {
  public static readonly type = '[Report] Deliver Empty Report';

  constructor(public deliverEmpty: boolean) {}
}
export class SetReportScheduled {
  public static readonly type = '[Report] Set Report Scheduled';

  constructor(public scheduled: boolean) {}
}

export class SetReportFrequency {
  public static readonly type = '[Report] Set Report Frequency';

  constructor(public frequency: number) {}
}

export class SetReportDate {
  public static readonly type = '[Report] Set Report Date';

  constructor(public date: Date) {}
}

export class SetReportTime {
  public static readonly type = '[Report] Set Report Time';

  constructor(public time: string) {}
}

export class SetReportTimezone {
  public static readonly type = '[Report] Set Report Timezone';

  constructor(public timezone: any) {}
}

export class SetReportType {
  public static readonly type = '[Report] Set Report Type';

  constructor(public type: number) {}
}

export class ToggleReportFrequencyWeekday {
  public static readonly type = '[Report] Toggle Report Frequency Weekday';

  constructor(public weekdays: string[]) {}
}

export class SetReportDataColumns {
  public static readonly type = '[Report] Set Report Data Columns';

  constructor(public columnGroups: any[]) {}
}

export class SetReportDataColumnSorts {
  public static readonly type = '[Report] Set Report Data Column Sorts';

  constructor(public columnSortGroups: any[]) {}
}

export class SetReportDataFilters {
  public static readonly type = '[Report] Set Report Data Filters';

  constructor(public filterGroups: any[]) {}
}
