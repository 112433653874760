import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
    issuer: environment.issuerUri,
    redirectUri: environment.home,
    clientId: environment.clientId,
    responseType: environment.responseType,
    scope: environment.scope,
    showDebugInformation: true,
    timeoutFactor: 0.5,
    disablePKCE: true
};

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: [environment.apiBaseURL, environment.issuerUri, environment.clearViewUrl],
        sendAccessToken: true
    }
};
