import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { OMCardComponent } from './components/om-card/om-card.component';
import { OMCardHeaderComponent } from './components/om-card/om-card-header.component';
import { OmCardContentComponent } from './components/om-card/om-card-content.component';
import { OmCardFooterComponent } from './components/om-card/om-card-footer.component';

import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AuditHistoryComponent } from './components/history/audit-history.component';
import { FileBrowserComponent } from './components/file-browser/file-browser.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileDragDirective } from './components/file-upload/file-drag.directive';
import { FileSelectorComponent } from './components/file-upload/file-selector.component';
import { UnlocoComponent } from './components/unloco/unloco.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationSelectorComponent } from './components/network-organization-selector/network-organization-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AuditHistoryContentComponent } from './components/history/audit-history-content.component';
import { AuditHistoryHeaderComponent } from './components/history/audit-history-header.content';
import { TransformObserverDirective } from './directives/transform-observer.directive';
import { ScrollObserverDirective } from './directives/scroll-observer.directive';
import { PhoneMaskDirective } from './components/phone-masker/phone-mask.directive';
import { ColumnConfigurationGroupComponent } from './components/column-configuration-group/column-configuration-group.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { OmTableColumnCustomizerComponent } from './components/column-customizer/om-table-column-customizer.component';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UnlocoCountryComponent } from './components/unloco-country/unloco-country.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OmTableComponent } from './components/om-table/om-table.component';
import { OmTableStaticFilterComponent } from './components/om-table/om-table-static-filter.component';
import { MatTableModule } from '@angular/material/table';
import { CustomCellsWrapperComponent } from './components/om-table/custom-cells-wrapper.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateRangeSlicerComponent } from './components/date-range-slicer/date-range-slicer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SlicerMenuComponent } from './components/slicer-menu/slicer-menu.component';
import { OrgSlicerComponent } from './components/org-slicer/org-slicer.component';
import { RemarksComponent } from './components/remarks/remarks.component';
import { LocationPipe } from './pipes/location.pipe';
import { MatSortModule } from '@angular/material/sort';
import { OmTableSearchInput } from './components/om-table/om-table-search-input.component';
import { OmTableFilterGroup } from './components/om-table/om-table-filter-toggle-group.component';
import { CwDocumentPipe } from './pipes/document.pipe';
import { ContactPipe } from './pipes/contact.pipe';
import { OmTableExport } from './components/om-table/om-table-export.component';
import { TableCellPipe } from './pipes/table-cell.pipe';
import { BookingStatusPipe } from './pipes/booking-status.pipe';
import { PurchaseOrderStatusPipe } from './pipes/purchase-order-status.pipe';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { GlobalNetworkSelectorComponent } from './components/global-network-selector/global-network-selector.component';
import { AdminOrgSelectorComponent } from './components/admin-org-selector/admin-org-selector.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { TimeZoneSelectorComponent } from './components/time-zone-selector/time-zone-selector.component';
import { CustomDatepickerHeaderComponent } from './components/custom-datepicker-header/custom-datepicker-header.component';
import { RouterModule } from '@angular/router';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StylePaginatorDirective } from './directives/style-paginator.directive';
import { StylePaginatorTestDirective } from './directives/style-paginator-test.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RefsListDialogComponent } from './components/refs-list-dialog/refs-list-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PendingChangesGuard } from './guard/pending-changes.guard';
import { OmDialogComponent } from './components/om-dialog/om-dialog.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ErrorInfoIconComponent } from './components/error-info-icon/error-info-icon.component';
import { SyncStatus } from "@modules/job/components/sync-status/sync-status";
import { CommonLibModule } from "../common-lib/common-lib.module";

@NgModule({
  imports: [
    CommonLibModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatMenuModule,
    DragDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatBadgeModule,
    RouterModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgxPaginationModule,
    NzPaginationModule,
    UiSwitchModule.forRoot({
      switchColor: '#ffffff',
      defaultBoColor: '#ffffff',
      checkedLabel: 'Priority On',
      uncheckedLabel: 'Priority Off',
      uncheckedTextColor: '#000000',
      checkedTextColor: '#ffffff',
    }),
  ],
  declarations: [
    PageHeaderComponent,
    OMCardComponent,
    OMCardHeaderComponent,
    OmCardContentComponent,
    OmCardFooterComponent,
    AuditHistoryComponent,
    FileBrowserComponent,
    FileUploadComponent,
    FileDragDirective,
    FileSelectorComponent,
    UnlocoComponent,
    UnlocoCountryComponent,
    OrganizationSelectorComponent,
    AuditHistoryContentComponent,
    AuditHistoryHeaderComponent,
    TransformObserverDirective,
    ScrollObserverDirective,
    PhoneMaskDirective,
    ColumnConfigurationGroupComponent,
    OmTableColumnCustomizerComponent,
    OmTableComponent,
    OmTableStaticFilterComponent,
    CustomCellsWrapperComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    DateRangeSlicerComponent,
    SlicerMenuComponent,
    OrgSlicerComponent,
    RemarksComponent,
    LocationPipe,
    OmTableSearchInput,
    OmTableFilterGroup,
    CwDocumentPipe,
    ContactPipe,
    TableCellPipe,
    OmTableExport,
    BookingStatusPipe,
    PurchaseOrderStatusPipe,
    GlobalNetworkSelectorComponent,
    AdminOrgSelectorComponent,
    TruncatePipe,
    MenuItemComponent,
    TimeZoneSelectorComponent,
    CustomDatepickerHeaderComponent,
    SafeUrlPipe,
    StylePaginatorDirective,
    StylePaginatorTestDirective,
    RefsListDialogComponent,
    OmDialogComponent,
    DatePickerComponent,
    ErrorInfoIconComponent,
    SyncStatus
  ],
  exports: [
    PageHeaderComponent,
    OMCardComponent,
    OMCardHeaderComponent,
    OmCardContentComponent,
    OmCardFooterComponent,
    FileBrowserComponent,
    FileUploadComponent,
    FileDragDirective,
    FileSelectorComponent,
    UnlocoComponent,
    UnlocoCountryComponent,
    OrganizationSelectorComponent,
    AuditHistoryContentComponent,
    AuditHistoryHeaderComponent,
    TransformObserverDirective,
    ScrollObserverDirective,
    PhoneMaskDirective,
    ColumnConfigurationGroupComponent,
    OmTableColumnCustomizerComponent,
    OmTableComponent,
    OmTableStaticFilterComponent,
    CustomCellsWrapperComponent,
    DateRangeSlicerComponent,
    SlicerMenuComponent,
    OrgSlicerComponent,
    RemarksComponent,
    LocationPipe,
    OmTableSearchInput,
    OmTableFilterGroup,
    CwDocumentPipe,
    ContactPipe,
    DatePipe,
    TableCellPipe,
    OmTableExport,
    PurchaseOrderStatusPipe,
    BookingStatusPipe,
    GlobalNetworkSelectorComponent,
    AdminOrgSelectorComponent,
    TruncatePipe,
    MenuItemComponent,
    TimeZoneSelectorComponent,
    CustomDatepickerHeaderComponent,
    MatPaginatorModule,
    SafeUrlPipe,
    OmDialogComponent,
    DatePickerComponent,
    ErrorInfoIconComponent,
    SyncStatus,
    CommonLibModule
  ],
  providers: [DatePipe, TableCellPipe, PendingChangesGuard, LocationPipe, ContactPipe],
})
export class SharedModule {}
