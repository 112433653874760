export function getDownloadFileName(defaultName: string = '', res: any): string {
  let filename = defaultName;
  if (res.headers) {
    let disposition = res.headers.get('Content-Disposition');
    if (!disposition) {
      disposition = res.headers['Content-Disposition'];
    }
    if (!!disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
        filename = decodeURIComponent(filename);
      }
    }
  }
  return filename;
}
