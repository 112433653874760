import { Injectable, NgZone } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
  NextShipment,
  PreviousShipment,
  ResetShipmentState,
  SelectShipment,
  SetAirShipmentList,
  SetContainerShipmentList,
  SetRaiShipmentList,
  SetRoaShipmentList,
  SetSeaShipmentList,
} from '../actions/shipment.actions';
import { Shipment } from '@modules/shipment/models/cw-shipment.model';

export interface IShipment {
  isAir: boolean;
  isRoa: boolean;
  isRai: boolean;
  isSea: boolean;
  shipment: Shipment;
}

export interface IShipmentState {
  shipmentId: string;
  shipments: IShipment[];
}

@State<IShipmentState>({
  name: 'shipment',
  defaults: {
    shipmentId: null,
    shipments: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ShipmentState {
  constructor() {}

  @Selector()
  public static canViewNext(state: IShipmentState): boolean {
    const currentIndex = state.shipments.findIndex(s => s.shipment.id === state.shipmentId);
    return currentIndex > -1 && currentIndex < state.shipments.length - 1;
  }

  @Selector()
  public static canViewPrevious(state: IShipmentState): boolean {
    const currentIndex = state.shipments.findIndex(s => s.shipment.id === state.shipmentId);
    return currentIndex > -1 && currentIndex > 0;
  }

  @Selector()
  public static shipments(state: IShipmentState): IShipment[] {
    return state.shipments;
  }

  @Selector()
  public static shipmentId(state: IShipmentState): string {
    return state.shipmentId;
  }

  @Selector()
  public static shipmentIndex(state: IShipmentState): number {
    const currentIndex = state.shipments.findIndex(s => s.shipment.id === state.shipmentId);
    return currentIndex > -1 ? currentIndex + 1 : 0; // Human readable index
  }

  @Selector()
  public static shipmentsLength(state: IShipmentState): number {
    return state.shipments.length;
  }

  @Action(SetAirShipmentList)
  public setShipments(ctx: StateContext<IShipmentState>, action: SetAirShipmentList): void {
    const shipments = action.payload.map(s => ({ isAir: true, shipment: s, isRoa: true, isRai: false, isSea: false }));
    ctx.patchState({
      shipments,
    });
  }

  @Action(SetSeaShipmentList)
  public setSeaShipments(ctx: StateContext<IShipmentState>, action: SetSeaShipmentList): void {
    const shipments = action.payload.map(s => ({ isAir: false, shipment: s, isRoa: true, isRai: false, isSea: true }));
    ctx.patchState({
      shipments,
    });
  }

  @Action(SetRoaShipmentList)
  public setRoaShipments(ctx: StateContext<IShipmentState>, action: SetRoaShipmentList): void {
    const shipments = action.payload.map(s => ({ isAir: false, shipment: s, isRoa: true, isRai: false, isSea: false }));
    ctx.patchState({
      shipments,
    });
  }

  @Action(SetRaiShipmentList)
  public setRaiShipments(ctx: StateContext<IShipmentState>, action: SetRaiShipmentList): void {
    const shipments = action.payload.map(s => ({ isAir: false, shipment: s, isRoa: false, isRai: true, isSea: false }));
    ctx.patchState({
      shipments,
    });
  }

  @Action(SetContainerShipmentList)
  public setContainerShipments(ctx: StateContext<IShipmentState>, action: SetContainerShipmentList): void {
    let shipments = [];
    const containers = action.payload;
    containers.forEach(c => {
      if (c.consolidation?.shipments) {
        const csl = c.consolidation.shipments.map(s => ({ isAir: c.isAir, shipment: s }));
        shipments = shipments.concat(csl);
      }
    });
    ctx.patchState({
      shipments,
    });
  }

  @Action(SelectShipment)
  public selectShipment(ctx: StateContext<IShipmentState>, action: SelectShipment): void {
    ctx.patchState({
      shipmentId: action.id,
    });
  }

  @Action(PreviousShipment)
  public previousShipment(ctx: StateContext<IShipmentState>): void {
    const state = ctx.getState();
    let currentIndex = state.shipments.findIndex(s => s.shipment.id === state.shipmentId);
    if (currentIndex !== -1 && currentIndex > 0) {
      currentIndex = currentIndex - 1;
      ctx.dispatch(new SelectShipment(state.shipments[currentIndex].shipment.id, false));
    }
  }

  @Action(NextShipment)
  public nextShipment(ctx: StateContext<IShipmentState>): void {
    const state = ctx.getState();
    let currentIndex = state.shipments.findIndex(s => s.shipment.id === state.shipmentId);
    if (currentIndex !== -1 && currentIndex < state.shipments.length - 1) {
      currentIndex = currentIndex + 1;
      ctx.dispatch(new SelectShipment(state.shipments[currentIndex].shipment.id, false));
    }
  }

  @Action(ResetShipmentState)
  public resetState(ctx: StateContext<IShipmentState>): void {
    ctx.setState({
      shipmentId: null,
      shipments: [],
    });
  }
}
