<mat-card id="optionsComponent" class="mat-elevation-z4 p-0">
  <mat-tab-group [selectedIndex]="tabIndex" class="cf-custom-tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon [inline]="true">
          <mat-icon [inline]="true">filter_alt</mat-icon>
        </mat-icon>
        &nbsp;FILTERS
      </ng-template>
      <div class="row m-0">
        <div class="col">
          <app-filters (close)="closeAll()"></app-filters>
        </div>
      </div>
    </mat-tab>
    <!--<mat-tab>-->
      <!--<ng-template mat-tab-label>-->
        <!--<mat-icon [inline]="true">sort</mat-icon>-->
        <!--&nbsp;SORT BY-->
      <!--</ng-template>-->
      <!--<div class="row m-0">-->
        <!--<div class="col">-->
          <!--<app-sort></app-sort>-->
        <!--</div>-->
      <!--</div>-->
    <!--</mat-tab>-->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon [inline]="true">view_list</mat-icon>
        &nbsp;COLUMN SELECTION
      </ng-template>
      <div class="row m-0">
        <div class="col">
          <app-filter-columns></app-filter-columns>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
