<div fxLayout="row" fxLayoutGap="10px">
  <mat-form-field appearance="outline" fxFlex="1 1 auto">
    <mat-label>Start Date</mat-label>
    <input matInput [min]="minDate" [matDatepicker]="startPicker" [ngModel]="dates[0]" (ngModelChange)="changeDate(DateTypes.START, $event)">
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" fxFlex="1 1 auto">
    <mat-label>End Date</mat-label>
    <input matInput [min]="minDate" [matDatepicker]="endPicker" [ngModel]="dates[1]" (ngModelChange)="changeDate(DateTypes.END, $event)">
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>
</div>
<div [fxLayout]="'column'">
  <button fxFlex="1 1 auto" mat-flat-button (click)="selectRange(range)" [color]="selectedRange === range ? 'primary' : undefined" *ngFor="let range of ranges">{{range.name}}</button>
</div>