import { Directive, ElementRef, HostListener, EventEmitter, Input, Output } from "@angular/core";

@Directive({
	selector: '[fileDragArea]'
})
export class FileDragDirective {
	@Input() disabled: boolean = false;
	@Output() filesSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

	constructor(private el: ElementRef) {}

	@HostListener('dragenter', ['$event']) onDragEnter(e: any): void {
		this.preventEventDefaults(e);
		if (this.disabled) return;

		this.highlightHost();
	}

	@HostListener('dragover', ['$event']) onDragOver(e: any): void {
		this.preventEventDefaults(e);
		if (this.disabled) return;

		this.highlightHost();
	}

	@HostListener('dragleave', ['$event']) onDragLeave(e: any): void {
		this.preventEventDefaults(e);
		if (this.disabled) return;

		this.removeHostHightlight();
	}

	@HostListener('drop', ['$event']) onDrop(e: any): void {
		this.preventEventDefaults(e);
		if (this.disabled) return;

		this.removeHostHightlight();

		const dataTransfer = e.dataTransfer;
		const files = dataTransfer.files;
		this.filesSelected.emit(files);
	}

	private highlightHost(): void {
		this.el.nativeElement;
	}

	private removeHostHightlight(): void {
		this.el.nativeElement;
	}

	private preventEventDefaults(event: any): void {
		event.preventDefault();
		event.stopImmediatePropagation();
	}
}