import { BookingContainer, SaveBookingContainer } from './../models/container.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContainerType } from '@models/container.model';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  constructor(private httpClient: HttpClient) {}

  public getContainerTypes(): Observable<ContainerType[]> {
    return this.httpClient.get<ContainerType[]>(`${environment.apiBaseURL}container/getContainerTypes`);
  }

  public getBookingContainers(bookingId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}container/getBookingContainers/${bookingId}`);
  }

  public getContainerDetail(bookedEquipmentId: number, containerId: number): Observable<BookingContainer> {
    return this.httpClient.get<BookingContainer>(
      `${environment.apiBaseURL}container/getContainerDetail/${bookedEquipmentId}?containerId=${containerId}`
    );
  }

  public saveContainer(data: SaveBookingContainer): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}container/saveContainer`, data);
  }

  public deleteContainer(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseURL}container/deleteContainer`, data);
  }
}
