<app-om-dialog [title]="'Upload Org Logo'" [showFooter]="true" (closeDialog)="cancel()">
  <div class="main-content">
    <div class="row file-content">
      <div class="inner" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
        <ng-container *ngIf="isUploadingDoc">
          <div class="uploading-text">Uploading Logo....</div>
        </ng-container>
        <div class="select-file" *ngIf="!isUploadingDoc" (click)="contentDoc.click()">
          <mat-icon *ngIf="!docItem.url">description</mat-icon>
          <div class="text" *ngIf="!docItem.url">Please Select Logo</div>
          <div *ngIf="docItem.url" class="file-name">
            <img [src]="docItem.url" class="logo" />
          </div>
          <input [id]="'contentDoc'" #contentDoc (change)="fileChanged($event)" type="file" style="display: none" />
        </div>
      </div>
    </div>
  </div>
  <ng-container footer>
    <button mat-raised-button color="warn" (click)="cancel()">
      Cancel
      <mat-icon>delete_forever</mat-icon>
    </button>
    <button mat-raised-button color="primary" [disabled]="isUploadingDoc" (click)="saveDoc()">
      Save
      <mat-icon>check</mat-icon>
    </button>
  </ng-container>
</app-om-dialog>
