<div
  *ngIf="dataSource.length > 1"
  class="d-flex justify-content-center align-items-center my-2" style="min-width: 210px">
  <div class="d-flex justify-content-center align-items-center">
    <button
      mat-icon-button
      mat-stroked-button
      class="mr-3"
      (click)="viewPreviousShipment()"
      [disabled]="!canViewPrevious || loading">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <p style="font-size: 14px; font-weight: normal; text-transform: none" class="m-0">{{'Viewing: ' + shipmentIndex + ' of ' + dataSource.length}}</p>
    <button
      mat-icon-button
      mat-stroked-button
      class="ml-3"
      (click)="viewNextShipment()"
      [disabled]="!canViewNext || loading">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
