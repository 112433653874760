import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@models/location.model';

@Pipe({
  name: 'location',
})
export class LocationPipe implements PipeTransform {
  transform(location: Location, format?: 'full' | 'code' | 'table') {
    if (!location) return '';
    if (format === 'full') {
      return (
        (location.addressLine1 ? location.addressLine1 + '\n' : '') +
        (location.addressLine2 ? location.addressLine2 + '\n' : '') +
        (location.city ? location.city + ', ' : '') +
        (location.state ? location.state + ', ' : '') +
        location.country
      );
    } else if (format === 'table') {
      return (
        (location.addressLine1 ? location.addressLine1 + ', ' : '') +
        (location.addressLine2 ? location.addressLine2 + ', ' : '') +
        (location.city ? location.city + ', ' : '') +
        (location.state ? location.state + ', ' : '') +
        location.country
      );
    } else {
      return location.code;
    }
  }
}
