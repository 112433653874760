import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "@modules/auth/services/user.service";
import { NetworkDenyReasonApiService } from "@services/network-deny-reason-api.service";
import { NetworkDenyReason } from "@models/networkDenyReason.model";
import { NetworkDenyReasonType } from "@models/helpers";

@Component(({
  selector: 'app-om-po-reject-prompt',
  styles: [''],
  template: `
    <h2 mat-dialog-title>Reject Purchase Order</h2>
    <mat-dialog-content class="center-align-dialog-content">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12 form-field">
            <mat-form-field appearance="fill" class="extended-width">
              <mat-label>Reason </mat-label>
              <mat-select formControlName="rejectionCode">
                <mat-option *ngFor="let item of reasons" [value]="item.reason">{{item.reason}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-field">
            <mat-form-field appearance="fill" floatLabel="always" class="extended-width">
              <mat-label>Message</mat-label>
              <textarea matInput placeholder="" formControlName="rejectionMessage"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="cancelReject($event)">Cancel</button>
      <button mat-raised-button color="warn" [disabled]="!form.valid" (click)="confirmReject($event)">Reject</button>
    </mat-dialog-actions>

  `
}))
export class PoRejectPromptComponent implements OnInit {
  public form: UntypedFormGroup;
  public reasons: NetworkDenyReason[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string },
    private fb: UntypedFormBuilder,
    private networkDenyReasonService: NetworkDenyReasonApiService,
    private userService: UserService,
    public dialogRef: MatDialogRef<PoRejectPromptComponent>) { }

  ngOnInit() {
    this.networkDenyReasonService.getNetworkDenyReasons(this.userService.userValue.currentNetworkId, NetworkDenyReasonType.PurchaseOrder, false).subscribe(res => {
      this.reasons = res;
    })
    this.form = this.fb.group({
      rejectionCode: [null, Validators.required],
      rejectionMessage: ""
    });
  }

  public cancelReject(event: any): void {
    event.stopImmediatePropagation();
    this.dialogRef.close(null);
  }

  public confirmReject(event: any): void {
    event.stopImmediatePropagation();

    if (!this.form.valid) return;
    this.dialogRef.close(this.form.value);
  }
}
