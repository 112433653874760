import { Component } from "@angular/core";

@Component({
    selector: 'app-static-page-layout',
    styleUrls: ['./static-page-layout.component.scss'],
    template: `
    <div id="static-page-layout">
    <header id="static-page-header">
      <nav>
        <ul>
          <li class="logo-nav-item">
           <img id="app-logo" src="/assets/images/logo-un.png">
          </li>
        </ul>
      </nav>
    </header>
    <main>
      <div id="static-page-main-content">
        <ng-content></ng-content>
      </div>
    </main>
    </div>

    `
})
export class StaticPageLayout {
    constructor() {}
}