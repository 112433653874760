import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Unloco, UnlocoCountry } from '@models/unloco.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UnlocoApiService {

  constructor( private httpClient: HttpClient) { }

  public getUnlocoCodes(text: string): Observable<Unloco[]> {
    return this.httpClient.get<Unloco[]>(`${environment.apiBaseURL}Unloco/${text}`);
  }

  public getUnlocoCountries(text: string): Observable<UnlocoCountry[]> {
    return this.httpClient.get<UnlocoCountry[]>(`${environment.apiBaseURL}Unloco/country/${text}`);
  }

}
