import { Injectable } from '@angular/core';
import { Palette } from 'src/app/core/models/palette.model';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ColorService {
  public userPalette: Palette = null;

  constructor(
    private userService: UserService
  ) {
    this.prepApplication();
  }

  private async prepApplication(): Promise<void> {
    this.userPalette = new Palette('');

    await this.userService.prepUser().finally(() => {
      this.userPalette = new Palette(this.userService.userCountryCode);
    });
  }
}
