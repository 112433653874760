import { CdkDragDrop } from "@angular/cdk/drag-drop";

export class ToggleTableColumn {
  static readonly type = '[Table] Toggle Column';
  constructor(public tableKey: string, public columnKey: string) {}
}

export class MoveColumn {
  static readonly  type ='[Table] Move Column';
  constructor(public tableKey: string, public cdkDragDropEvent: CdkDragDrop<any[]>) {
  }
}

export class LoadNewTablePreferences {
  static readonly type = '[Table] Load New Preferences';
  constructor(public tableKey: string) {}
}

