import { EventEmitter, Injectable } from '@angular/core';
import { Filter } from '@modules/shipment/models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private containerFilters: Filter[] = [];
  private airShipmentFilters: Filter[] = [];
  private seaShipmentFilters: Filter[] = [];
  public filtersUpdated = new EventEmitter();

  constructor() {
    const filterCache = JSON.parse(localStorage.getItem('filters'));
    if (filterCache) {
      this.containerFilters = filterCache.containerFilters ? filterCache.containerFilters : [];
      this.airShipmentFilters = filterCache.airShipmentFilters ? filterCache.airShipmentFilters : [];
      this.seaShipmentFilters = filterCache.seaShipmentFilters ? filterCache.seaShipmentFilters : [];
    }
  }

  public getContainerFilters(filtered?: boolean): Filter[] {
    if (filtered) {
      return this.containerFilters.filter(filter => filter.selected);
    } else {
      return this.containerFilters;
    }
  }

  public getAirShipmentFilters(filtered?: boolean): Filter[] {
    if (filtered) {
      return this.airShipmentFilters.filter(filter => filter.selected);
    } else {
      return this.airShipmentFilters;
    }
  }

  public getSeaShipmentFilters(filtered?: boolean): Filter[] {
    if (filtered) {
      return this.seaShipmentFilters.filter(filter => filter.selected);
    } else {
      return this.seaShipmentFilters;
    }
  }

  public applyContainerFilters(filterArray: Filter[]): void {
    this.containerFilters = filterArray;
    this.saveFilters();
  }

  public applyAirShipmentFilters(filterArray: Filter[]): void {
    this.airShipmentFilters = filterArray;
    this.saveFilters();
  }

  public applySeaShipmentFilters(filterArray: Filter[]): void {
    this.seaShipmentFilters = filterArray;
    this.saveFilters();
  }

  private saveFilters(): void {
    const filterObj = {
      containerFilters: this.containerFilters,
      airShipmentFilters: this.airShipmentFilters,
      seaShipmentFilters: this.seaShipmentFilters,
    };
    localStorage.setItem('filters', JSON.stringify(filterObj));
    this.filtersUpdated.emit();
  }
}
