import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-oms-manufacturer-change',
  styleUrls: ['./manufacturer-change.component.scss'],
  templateUrl: './manufacturer-change.component.html'
})
export class ManufacturerChangeComponent implements OnInit {
  
  @Output() onManufacturerChangeCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onManufacturerChangeConfirm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<ManufacturerChangeComponent>
  ) { 
    
  }

  public ngOnInit() {
    
  }

  public cancelManufacturerChange(){
    this.onManufacturerChangeCancel.emit();
  }

  public confirmManufacturerChange(){
    this.onManufacturerChangeConfirm.emit();
  }
}
