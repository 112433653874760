import { UntypedFormGroup, AbstractControl } from "@angular/forms";

export function getRequiredFields(form: UntypedFormGroup, excludedFields: string[] = []) {
  let requiredFields = [];
  for (const field in form.controls) { // 'field' is a string
    if (excludedFields && excludedFields.length > 0 && excludedFields.indexOf(field) >= 0) {
      continue;
    }
    const control = form.get(field); // 'control' is a FormControl  
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        requiredFields.push(field);
      }
    }
  }
  return requiredFields;
}
