import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Contact } from '@models/contact.model';
import { Organization } from '@models/organization.model';
import { Unloco } from '@models/unloco.model';
import { DatepickerService } from '@services/datepicker.service';
import { CustomDatepickerHeaderComponent } from 'src/app/shared/components/custom-datepicker-header/custom-datepicker-header.component';

@Component({
  selector: 'app-om-job-booking-routing-form',
  styles: [''],
  template: `
    <form [formGroup]="form">
      <div class="input-container edit-section">
        <div class="row">
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <app-unloco
                  label="Port of Load"
                  (unlocoSelected)="selectPortOfLoad($event)"
                  [marked]="loadingPortMarked"
                  formControlName="portOfLoading"
                  [required]="true"
                  [extendedWidth]="true"
                  class="unloco-width"
                ></app-unloco>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <app-unloco
                  label="Port of Discharge"
                  (unlocoSelected)="selectPortOfDischarge($event)"
                  [marked]="dischargePortMarked"
                  formControlName="portOfDischarge"
                  [required]="true"
                  [extendedWidth]="true"
                  class="unloco-width"
                ></app-unloco>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <label class="detail-field-title">Forwarder</label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <mat-select matInput type="text" [compareWith]="compareFn" formControlName="forwarder">
                    <mat-option [value]="defaultOrg">Clear Freight</mat-option>
                    <mat-option *ngFor="let org of forwarders" [value]="org" class="multiline-mat-option">
                      {{ org.name }}
                    </mat-option>
                  </mat-select>
                  <mat-icon
                    *ngIf="form.controls.forwarder.value"
                    matSuffix
                    class="icon-action-red"
                    (click)="clearField($event, 'forwarder')"
                  >
                    delete
                  </mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <label class="detail-field-title">Forwarder Contact</label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <mat-select multiple [compareWith]="compareContactFn" formControlName="contactIds">
                    <mat-option *ngFor="let contact of contacts" [value]="contact.id" class="multiline-mat-option">
                      {{ contact.name }}
                    </mat-option>
                  </mat-select>
                  <mat-icon
                    *ngIf="form.controls.contactIds.value"
                    matSuffix
                    class="icon-action-red"
                    (click)="clearField($event, 'contactIds')"
                  >
                    delete
                  </mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <label class="detail-field-title">
                  ETD
                  {{
                    form.controls.portOfLoadingTimezone.value
                      ? '(' + form.controls.portOfLoadingTimezone.value + ')'
                      : ''
                  }}
                </label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <input matInput [matDatepicker]="etd" (click)="openPicker('etd')" formControlName="etdValue" />
                  <mat-datepicker-toggle matSuffix [for]="etd" (click)="datepickerClick('etd')"></mat-datepicker-toggle>
                  <mat-datepicker #etd [calendarHeaderComponent]="customDatepickerHeader"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-field">
              <div class="row">
                <label class="detail-field-title">
                  ETA
                  {{
                    form.controls.portOfDischargeTimezone.value
                      ? '(' + form.controls.portOfDischargeTimezone.value + ')'
                      : ''
                  }}
                </label>
              </div>
              <div class="row detail-field-content">
                <mat-form-field appearance="outline" class="extended-width">
                  <input matInput [matDatepicker]="eta" (click)="openPicker('eta')" formControlName="etaValue" />
                  <mat-datepicker-toggle matSuffix [for]="eta" (click)="datepickerClick('eta')"></mat-datepicker-toggle>
                  <mat-datepicker #eta [calendarHeaderComponent]="customDatepickerHeader"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class JobBookingRoutingFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() loadingPortMarked = false;
  @Input() dischargePortMarked = false;
  @Input() forwarders: Organization[];
  @Input() contacts: Contact[];

  @ViewChild('etd') etdPicker!: MatDatepicker<Date>;
  @ViewChild('eta') etaPicker!: MatDatepicker<Date>;

  public customDatepickerHeader = CustomDatepickerHeaderComponent;
  public defaultOrg = new Organization();

  constructor(public datepickerService: DatepickerService) {}

  ngOnInit(): void {}

  openPicker(type: string) {
    if (type === 'etd') {
      this.etdPicker.open();
    } else if (type === 'eta') {
      this.etaPicker.open();
    }

    this.datepickerClick(type);
  }

  public selectPortOfLoad(item: Unloco): void {
    this.setTimezone('etd', item);
  }

  public selectPortOfDischarge(item: Unloco): void {
    this.setTimezone('eta', item);
  }

  private setTimezone(name: string, item: Unloco) {
    let value = null;
    if (item) {
      value = item.timezone;
    }
    if (name == 'etd') {
      this.form.controls.portOfLoadingTimezone.setValue(value);
    } else if (name == 'eta') {
      this.form.controls.portOfDischargeTimezone.setValue(value);
    }
  }

  public datepickerClick(name: string) {
    if (name == 'etd') {
      this.datepickerService.currentPortTimezone = this.form.controls.portOfLoadingTimezone.value;
    } else if (name == 'eta') {
      this.datepickerService.currentPortTimezone = this.form.controls.portOfDischargeTimezone.value;
    }
  }

  public compareFn(optionA: any, optionB: string | any): boolean {
    if (optionA && optionB) {
      return optionA.code == optionB.code;
    } else {
      return false;
    }
  }

  public compareContactFn(o1: number, o2: number): boolean {
    return o1 && o2 ? o1 === o2 : false;
  }

  public clearField(event: any, field: string) {
    event.stopImmediatePropagation();
    this.form.get(field).setValue(null);
  }
}
