import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Location } from '../models/location.model';

@Injectable()
export class LocationApiService {

  constructor(private httpClient : HttpClient) { }

  public getLocationByID(id : number): Observable<Location>{
    return this.httpClient.get<Location>(`${environment.apiBaseURL}location/${id}`);
  }

  public getLocations(): Observable<Location[]>{
    return this.httpClient.get<Location[]>(`${environment.apiBaseURL}location/`);
  }

  public getLocationsForOrg(orgCode: string): Observable<Location[]> {
    return this.httpClient.get<Location[]>(`${environment.apiBaseURL}location/getLocationsForOrg/${orgCode}`);
  }

  public getLocationsForOrgs(orgCodes: string[]): Observable<Location[]> {
    return this.httpClient.post<Location[]>(`${environment.apiBaseURL}location/getLocationsForOrgs`, orgCodes);
  }

//   public GetOrganizationLocation(organizationID : number): Observable<Location>{
//     return this.httpClient.get<Location>(`${environment.apiBaseURL}location/${organizationID}`);
//   }
}