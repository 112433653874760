import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { Organization } from '@models/organization.model';
import { Location } from '@models/location.model';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { Contact } from '@models/contact.model';
import { LocationApiService } from '@services/location-api.service';

@Component({
  selector: 'app-om-booking-defaults',
  styleUrls: [],
  templateUrl: './booking-defaults.component.html',
})
export class BookingDefaultsComponent implements OnInit, OnChanges {
  incoterms = Constants.incoterms.map((item: any) => {
    return {
      ...item,
      fullText: item.code + ' - ' + item.description
    }
  });
  @Input() supplierCode: string;
  @Input() manufacturers: Organization[];
  @Input() consignees: Organization[];
  @Input() forwarders: Organization[];
  @Input() contacts: Contact[] = [];
  @Input() customer: Organization;
  @Input() form: UntypedFormGroup;
  public forwarderContacts: Contact[] = [];
  public customerLocations: Location[] = [];
  public shipperLocations: Location[] = [];
  public consigneeLocations: Location[] = [];
  public manufactureFilter = [];
  public consigneesFilter = [];
  public consigneeLocationFilter = [];
  public shipToLocationsFilter = [];
  public customerLocationFilter = [];
  public incotermFilter = this.incoterms;
  public shipperLocationsFilter = [];
  public pickupLocationsFilter = [];
  public forwardersFilter = [];
  public forwarderContactsFilter = [];

  constructor(private userNetworkService: UserNetworkApiService, private locationApi: LocationApiService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.supplierCode?.currentValue) {
      this.userNetworkService.getCWLocationsForOrg(this.supplierCode).subscribe(locations => {
        this.shipperLocations = locations;
        this.shipperLocationsFilter = locations;
        this.pickupLocationsFilter = locations;
      });
    }
    if (changes?.customer?.currentValue) {
      this.locationApi.getLocationsForOrg(this.customer.code).subscribe((locations: Location[]) => {
        this.customerLocations = locations;
        this.customerLocationFilter = locations;
      });
    }
    if (changes.manufacturers) {
      this.manufactureFilter = changes.manufacturers.currentValue
    }
    if (changes.consignees) {
      this.consigneesFilter = changes.consignees.currentValue
    }

    if (changes.forwarders) {
      this.forwardersFilter = changes.forwarders.currentValue
    }
  }

  ngOnInit() {
    this.form.controls.consigneeOrgCode.valueChanges.subscribe(orgCode => {
      if (orgCode) {
        this.locationApi.getLocationsForOrg(orgCode).subscribe((locations: Location[]) => {
          this.consigneeLocations = locations;
          this.consigneeLocationFilter = locations
          this.shipToLocationsFilter = locations
        });
      }
    });

    this.form.controls.forwarder.valueChanges.subscribe(_ => {
      this.forwarderContacts = [
        ...this.contacts.filter(c => c.networkId === this.form.controls.forwarder.value?.networkId),
      ];
      this.forwarderContactsFilter = this.forwarderContacts
    });
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}
