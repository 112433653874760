import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { UserService } from "@modules/auth/services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class NetworkAdminGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const isOmsAdmin = this.userService.userValue.isOmsAdmin;
    const isNetworkAdmin = this.userService.currentOrg.isNetworkAdmin;
    const isClearfreight = this.userService.currentOrg.isClearfreight;

    if (!isNetworkAdmin && !isOmsAdmin && !isClearfreight) {
      return this.router.parseUrl('/unauthorized');
    }

    return true;
  }
}