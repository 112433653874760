<div>
  <div class="title-text">API Access Key</div>
  <div class="title-switch">
    <ui-switch
      size="small"
      defaultBgColor="#26282D3F"
      checkedLabel="&nbsp;&nbsp;&nbsp;"
      uncheckedLabel="&nbsp;&nbsp;&nbsp;"
      [checked]="apiAccessKeyInfo?.enabled"
      (change)="toggleAccessKeyEnabled($event)"
    ></ui-switch>
  </div>
  <div class="row justify-content-start mt-3">
    <div class="col-12">
      <button mat-raised-button color="primary" (click)="generateAccessKey($event)" [disabled]="loading">
        {{ apiAccessKeyInfo?.accessKey ? 'Re-generate Access Key' : 'Generate Access Key' }}
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-start mt-3" *ngIf="apiAccessKeyInfo?.accessKey">
    <div class="col-12">
      <span class="key-title">Api Access Key:</span>
      <span class="key-content">{{ apiAccessKeyInfo?.accessKey }}</span>
    </div>
  </div>
  <div class="row justify-content-start mt-3" *ngIf="apiAccessKeyInfo?.accessKey">
    <div class="col-3 toggle-ip-enabled justify-content-center">
      <div class="ip-title">IP Restriction</div>
      <div class="ip-switch">
        <ui-switch
          size="small"
          defaultBgColor="#26282D3F"
          checkedLabel="&nbsp;&nbsp;&nbsp;"
          uncheckedLabel="&nbsp;&nbsp;&nbsp;"
          [checked]="apiAccessKeyInfo?.ipRestrictionEnabled"
          [disabled]="!apiAccessKeyInfo?.enabled"
          (change)="toggleIpRestrictionEnabled($event)"
        ></ui-switch>
      </div>
    </div>
  </div>
  <div class="row justify-content-start mt-3" *ngIf="apiAccessKeyInfo?.ipRestrictionEnabled">
    <div class="col-12">IP Restriction Range</div>
  </div>
  <div class="row justify-content-start" *ngIf="apiAccessKeyInfo?.ipRestrictionEnabled">
    <div class="col-6">
      <app-om-table
        tableKey="ip-restriction-editor-table"
        [dataSource]="apiAccessKeyInfo?.apiIpRestrictions"
        [allowRowExpansion]="false"
        [whiteBackground]="true"
        [equipmentTable]="true"
      >
        <app-om-table-custom-cells-wrapper customCells>
          <ng-container matColumnDef="startIp">
            <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">Start IP</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
              <ng-container>
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{ 'mat-form-field-invalid': errorRestrictionsList.indexOf('start-' + i) !== -1 }"
                >
                  <input
                    matInput
                    mask="IP"
                    [(ngModel)]="element.startIp"
                    (change)="restrictionChanged($event, 'start', i)"
                  />
                </mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="endIp">
            <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">End IP</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
              <ng-container>
                <mat-form-field
                  appearance="outline"
                  [ngClass]="{ 'mat-form-field-invalid': errorRestrictionsList.indexOf('end-' + i) !== -1 }"
                >
                  <input
                    matInput
                    mask="IP"
                    [(ngModel)]="element.endIp"
                    (change)="restrictionChanged($event, 'end', i)"
                  />
                </mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">Operation</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
              <mat-icon color="warn" (click)="removeRestriction($event, i)">delete</mat-icon>
            </td>
          </ng-container>
        </app-om-table-custom-cells-wrapper>
      </app-om-table>
      <button mat-raised-button color="primary" class="send" (click)="addRestriction()">
        Add
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </div>
</div>
